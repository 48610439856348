import {useSheetSelection} from "./SheetSelectionContext";
import {Col, Row} from "reactstrap";
import React from "react";
import {getClassNames} from "../../../../../classNameUtils";
import styles from "./SheetControllerRows.module.scss";
import Checkbox from "../../../../../atoms/Checkbox";
import ProductSelectorInput from "./ProductSelectorInput";

type SheetControllerRowsProps = {
  setSelectedSheetName: (n: string | null) => void,
  selectedSheetName: string | null
}

export default function SheetControllerRows({setSelectedSheetName, selectedSheetName}: SheetControllerRowsProps) {
  const {sheets, enableSheet, disableSheet, updateProductSelectors} = useSheetSelection();

  return <Row style={{
    marginLeft: '-0.75rem',
    marginRight: '-0.75rem',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  }}>
    <Col className={'px-0'}>
      {sheets.map((sheet, index) => {
        let isLastRow = index === sheets.length - 1;

        return <React.Fragment key={sheet.mappingConfigId}>
          <Row className={getClassNames([
            {className: styles.sheetRow, condition: true},
            {className: 'mx-0', condition: true},
            {className: styles.lastSheetRow, condition: isLastRow && !sheet.isActive}
          ])}>
            <Col>
              <Checkbox
                onClick={() => {
                  if (sheet.isActive) {
                    if (selectedSheetName === sheet.name) {
                      setSelectedSheetName(null);
                    }
                    disableSheet(sheet.name)
                  } else {
                    enableSheet(sheet.name);
                  }
                }}
                checked={sheet.isActive}
                label={<>Enable <strong>{sheet.name}</strong> sheet.</>}/>
            </Col>
          </Row>
          {sheet.isActive && <ProductSelectorInput
            isLastRow={isLastRow}
            productTemplateId={sheet.productTemplateId}
            value={sheet.productSelectors}
            onChange={newValues => updateProductSelectors(sheet.name, newValues)}
            onRemove={index => {
              const productSelectors = [...sheet.productSelectors]
              productSelectors.splice(index, 1)
              updateProductSelectors(sheet.name, productSelectors)
            }}
          />}
          <div className={styles.divider}/>
        </React.Fragment>
      })}
    </Col>
  </Row>
}
