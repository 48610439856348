import React from "react";
import {Environment, graphql} from "react-relay";
import {PollingQueryRenderer} from "../../../general/graphql/PollingQueryRenderer";
import {LoadingContentOutputProcessQuery} from "./__generated__/LoadingContentOutputProcessQuery.graphql";
import {ourToast} from "../../../atoms/Toast";
import styles from "./LoadingModalContent.module.scss";
import Button from "../../../atoms/Button";


export function LoadingContentSpinner({toggle}: {toggle: () => void}) {
  return <div className={`d-flex flex-column align-items-center justify-content-center p-5`}>
    <i className={`fa-solid fa-spinner fa-spin ${styles.spinner}`}/>
    <h5 className={`mt-4 ${styles.title}`}>Please Wait...</h5>
    <Button onClick={toggle}
            className={'rounded-pill'}>
      <p className={`m-0 ${styles.buttonText}`}>Cancel</p>
    </Button>
  </div>;
}

const query = graphql`
  query LoadingContentOutputProcessQuery($id: ID!) {
    getDistributeDataOutputProcess(id: $id) {
      id
      state
      errorMessage
      output {
        id
      }
    }
  }
`;

type LoadingContentProps = {
  environment: Environment,
  outputProcessId: string | null,
  onFinished: (val: {id: string}) => void,
  onFailed: (val: string) => void,
  children: JSX.Element | JSX.Element[]
}

export default function LoadingContent({environment, outputProcessId, onFinished, onFailed, children}: LoadingContentProps) {
  if (outputProcessId) {
    return <PollingQueryRenderer<LoadingContentOutputProcessQuery>
      environment={environment}
      query={query}
      variables={{id: outputProcessId}}
      delay={1500}
      onData={props => {
        const outputProcess = props.getDistributeDataOutputProcess;
        if(outputProcess) {
          if (['finished', 'failed'].includes(outputProcess.state.toLowerCase())) {
            if (outputProcess?.output) {
              onFinished({id: outputProcess.output.id});
            }
            else {
              onFailed(outputProcess?.errorMessage || 'An error occurred.');
            }
          }
        } else {
          ourToast("error", "Something went wrong!");
        }
      }}
      onError={err => {
        onFailed(typeof err === 'string' ? err : err.message);
      }}
    >
      {children}
    </PollingQueryRenderer>
  } else {
    return <>{children}</>;
  }
}
