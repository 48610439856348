import React, {useEffect, useState} from "react";
import GrayModal from "../../dataDisplay/GrayModal";
import styles from "./DownloadButtonWithDdtModal.module.scss"
import DistributeDataModalContentControl from "./DistributeDataModalContentControl";
import Button from "../../atoms/Button";
import {QueryRenderer, graphql} from "react-relay";
import Loading from "../../atoms/Loading";
import {ErrorAlert} from "../../commons/errors";
import {DownloadButtonWithDdtModalQuery} from "./__generated__/DownloadButtonWithDdtModalQuery.graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {pluralizeByCount} from "../../stringUtils";
import GenerateDdtOutputWithOrdersMutation from "../../mutations/output/GenerateDdtOutputWithOrdersMutation";
import {DdtType} from "./DistributeDataModalContentComponents/SelectDdtContent";

const query = graphql`
    query DownloadButtonWithDdtModalQuery {
        listDistributeDataTemplates {
          ...OrderConfirmationUploadWizardModal_ddts
          ...SelectDdtContent_ddts
          }
    }
`

export const steps = {
  SELECTING_DDT: 0,
  DOING_INITIAL_MUTATION: 1,
  ERROR: 2,
  WAITING_FOR_OUTPUT_TO_BE_GENERATED: 3,
  OUTPUT_READY: 4
}

type DownloadButtonWithDdtModalProps = {
  orderId: string | null,
  environment: RelayModernEnvironment,
  orderNumbers: string[]
  dataTestId?: string,
  color: "primary" | "secondary"
}

function DownloadButtonWithDdtModal({
                                      orderId,
                                      orderNumbers,
                                      environment,
                                      dataTestId,
                                      color
                                    }: DownloadButtonWithDdtModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(steps.SELECTING_DDT);
  const [selectedDdt, setSelectedDdt] = useState<DdtType | null>(null)
  const [result, setResult] = useState<{id: string} | null>(null);
  const [distributeError, setDistributeError] = useState<string | null>(null);
  const [downloadImages, setDownloadImages] = useState(false)
  const [mergeSameProducts, setMergeSameProducts] = useState(true);
  const [outputProcessId, setOutputProcessId] = useState<string | null>(null);
  const hasHeader = currentStep !== steps.WAITING_FOR_OUTPUT_TO_BE_GENERATED && currentStep !== steps.DOING_INITIAL_MUTATION && currentStep !== steps.OUTPUT_READY;

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    setCurrentStep(steps.SELECTING_DDT);
    setResult(null);
    setDistributeError(null);
    setDownloadImages(false);
    setMergeSameProducts(true);
    setOutputProcessId(null);
  }, [isOpen]);

  function onDistribute(ddtId: string) {
    setDistributeError(null);
    setResult(null);
    setCurrentStep(steps.DOING_INITIAL_MUTATION)
    GenerateDdtOutputWithOrdersMutation(
      environment,
      {
        ddt: ddtId,
        withImages: downloadImages,
        orders: [orderId || ''],
        allowOrdersWithoutOrderConfirmationBatches: true,
        mergeSameProducts
      },
      result => {
        setCurrentStep(steps.WAITING_FOR_OUTPUT_TO_BE_GENERATED);
        setOutputProcessId(result?.generateDdtOutputWithOrders?.outputProcess?.id || '');
      },
      error => {
        setCurrentStep(steps.ERROR);
        setDistributeError(error.message ? error.message : (error as any)[0].message);
      }
    )
  }

  return <>
    <Button onClick={toggle} color={color} className={styles.downloadButton} data-testid={dataTestId}>
      <i className="fa-regular fa-arrow-down-to-line mr-2"/>
      Download
    </Button>
    <GrayModal
      isOpen={isOpen}
      className={styles.modal}
      title={hasHeader ? pluralizeByCount("Download order", orderNumbers) : undefined}
      primaryHeaderButton={
        currentStep === steps.SELECTING_DDT ? {
          text: "Next",
          onClick: () => selectedDdt && onDistribute(selectedDdt.value),
          dataTestId: 'select-ddt-download-button',
          isDisabled: !selectedDdt
        } : undefined
      }
      secondaryHeaderButton={
        hasHeader ? {
          text: 'Cancel',
          onClick: toggle
        } : undefined}
      bodyContent={
        <QueryRenderer<DownloadButtonWithDdtModalQuery>
          environment={environment}
          query={query}
          variables={{}}
          render={({props, error}) => {
            if (error) {
              return <ErrorAlert error={error}/>
            }
            if (props) {
              const ddtList = props?.listDistributeDataTemplates;
              return <DistributeDataModalContentControl
                toggle={toggle}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                ddts={ddtList}
                selectedDdt={selectedDdt}
                setSelectedDdt={setSelectedDdt}
                downloadImages={downloadImages}
                setDownloadImages={setDownloadImages}
                mergeSameProducts={mergeSameProducts}
                setMergeSameProducts={setMergeSameProducts}
                orderNumbers={orderNumbers}
                outputProcessId={outputProcessId}
                setOutputProcessId={setOutputProcessId}
                result={result}
                setResult={setResult}
                error={distributeError}
                setError={setDistributeError}
                environment={environment}
              />
            }
            return <Loading/>
          }}/>
      }
      toggle={toggle}/>
  </>
}


export default DownloadButtonWithDdtModal;

