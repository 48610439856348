import React from "react";
import {ourToast} from "../../../atoms/Toast";
import styles from './LoadingModalContent.module.scss';
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import enableSendEmailWithOutputOnCompletionMutation
  from "../../../mutations/EnableSendEmailWithOutputOnCompletionMutation";
import Pill from "../../../atoms/Pill";

type LoadingModalContentProps = {
  toggle: () => void,
  hasDownloadImages: boolean,
  environment: RelayModernEnvironment,
  outputProcessId: string | null,
  outputType?: string
}

export default function LoadingModalContent({toggle, hasDownloadImages, outputType, environment, outputProcessId}: LoadingModalContentProps) {
  const infoText = hasDownloadImages ? 'This may take a while due to the processing of images' : 'This may take a while as we are changing the data to your standards';

  return <div className={`d-flex flex-column align-items-center justify-content-center p-5`}>
    <i className={`fa-solid fa-spinner fa-spin ${styles.spinner}`}/>
    <h5 className={`mt-4 ${styles.title}`}>Please Wait...</h5>
    <p className={`mt-1 mb-3 ${styles.infoText}`}>{infoText}</p>
    {outputType === "TEMPLATE" && <Pill
      className={'mb-2'}
      onClick={() => {
        if (outputProcessId) {
          enableSendEmailWithOutputOnCompletionMutation(
            environment,
            {ddtOutputProcessId: outputProcessId},
            (result) => {
              if (result.enableSendEmailWithOutputOnCompletion?.success) {
                ourToast("success", "An email will be sent when ready")
                toggle();
              } else {
                ourToast("error", "Something went wrong");
              }
            },
            (error) => {
              ourToast("error", "Something went wrong", error.message ? error.message : (error as any)[0].message);
            }
          )
        } else {
          ourToast("error", "Something went wrong");
        }
      }}
    >
      Get notified via email when ready
    </Pill>}
    <Pill onClick={toggle}
            className={'rounded-pill'}>
      Cancel
    </Pill>
  </div>;
}
