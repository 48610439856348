import React, {ReactNode, useContext, useState} from 'react';
import {ProductSelectorType, ProductTemplateFieldType, ProductTemplateInfoType, SheetType} from "./types";


type ContextType = {
  sheets: SheetType[],
  setSheets: (val: SheetType[]) => void,
  enableSheet: (sheetName: string) => void,
  disableSheet: (sheetName: string) => void,
  updateProductSelectors: (sheetName: string, newValues: ProductSelectorType[]) => void,
  getFieldsForTemplate: (id: string) => ReadonlyArray<ProductTemplateFieldType> | null;
  getTemplateFieldDisplay: (id: string, path: string) => string | null;
  getTemplateFieldType: (id: string, path: string) => string | null;
  setTemplates: (tmpls: ProductTemplateInfoType[]) => void;
  updateConfigForSheet: (sheetName: string, config: object) => void,
  updateProductRowConfigForSheet: (sheetName: string, config: object) => void,
  updateSkipFirstVariant: (sheetName: string, config: boolean) => void,
  reset: () => void,
}

function noop() {
}

let noopReturningNull = () => {
  return null
};

let defaultValue = {
  sheets: [],
  setSheets: noop,
  enableSheet: noop,
  disableSheet: noop,
  updateProductSelectors: noop,
  getFieldsForTemplate: noopReturningNull,
  getTemplateFieldDisplay: noopReturningNull,
  getTemplateFieldType: noopReturningNull,
  setTemplates: noop,
  updateConfigForSheet: noop,
  updateProductRowConfigForSheet: noop,
  updateSkipFirstVariant: noop,
  reset: noop
};

const SheetSelectionContext = React.createContext<ContextType>(defaultValue);

export function SheetSelectionProvider({
                                         children,
                                         initialData,
                                         productTemplates
                                       }: {
  children: ReactNode,
  initialData: SheetType[],
  productTemplates: ProductTemplateInfoType[]
}) {
  const [sheets, setSheets] = useState(initialData);
  const [templates, setTemplates] = useState(productTemplates);

  function _setSheetValue(name: string, key: string, value: any) {
    setSheets(sheets.map(sh => {
      if (sh.name !== name) {
        return sh
      }
      return {
        ...sh,
        [key]: value
      }
    }))
  }

  function enableSheet(name: string) {
    _setSheetValue(name, 'isActive', true)
  }

  function disableSheet(name: string) {
    _setSheetValue(name, 'isActive', false)
  }

  function updateProductSelectors(name: string, values: ProductSelectorType[]) {
    _setSheetValue(name, 'productSelectors', values);
  }

  function getFieldsForTemplate(id: string) {
    let found = templates.filter(obj => obj.id === id)
    if (!found) {
      return null;
    }
    return found[0].fields
  }

  function getTemplateFieldDisplay(id: string, path: string) {
    let tmpl = getFieldsForTemplate(id)
    if (!tmpl) {
      return null;
    }
    for (let f of tmpl) {
      if (f.path === path) {
        return f.name
      }
    }
    return null;
  }

  function getTemplateFieldType(id: string, path: string) {
    let tmpl = getFieldsForTemplate(id)
    if (!tmpl) {
      return null;
    }
    for (let f of tmpl) {
      if (f.path === path) {
        return f.type
      }
    }
    return null;
  }

  function updateConfigForSheet(sheetName: string, config: object) {
    setSheets(prevSheets =>
      prevSheets.map(sh => {
        if (sh.name !== sheetName) {
          return sh
        }
        return {...sh, config: config}
      })
    )
  }

  function updateProductRowConfigForSheet(sheetName: string, config: object) {
    setSheets(prevSheets =>
      prevSheets.map(sh => {
        if (sh.name !== sheetName) {
          return sh
        }
        return {...sh, configBeforeVariant: config}
      })
    )
  }

  function updateSkipFirstVariant(sheetName: string, config: boolean) {
    setSheets(prevSheets =>
      prevSheets.map(sh => {
        if (sh.name !== sheetName) {
          return sh
        }
        return {...sh, skipFirstVariant: config}
      })
    )
  }

  function reset() {
    setSheets([]);
    setTemplates([]);
  }

  return <SheetSelectionContext.Provider
    value={{
      sheets,
      enableSheet,
      disableSheet,
      updateProductSelectors,
      getFieldsForTemplate,
      getTemplateFieldDisplay,
      getTemplateFieldType,
      setSheets,
      setTemplates,
      updateConfigForSheet,
      updateProductRowConfigForSheet,
      updateSkipFirstVariant,
      reset
    }}>
    {children}
  </SheetSelectionContext.Provider>
}

export function useSheetSelection() {
  return useContext(SheetSelectionContext);
}
