/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderViaBarcodesMissingBarcodesWarningQueryVariables = {
    brand: string;
    barcodes: Array<string>;
};
export type OrderViaBarcodesMissingBarcodesWarningQueryResponse = {
    readonly checkIfGtinsExist: {
        readonly notFound: ReadonlyArray<string> | null;
        readonly found: ReadonlyArray<string> | null;
    } | null;
};
export type OrderViaBarcodesMissingBarcodesWarningQuery = {
    readonly response: OrderViaBarcodesMissingBarcodesWarningQueryResponse;
    readonly variables: OrderViaBarcodesMissingBarcodesWarningQueryVariables;
};



/*
query OrderViaBarcodesMissingBarcodesWarningQuery(
  $brand: ID!
  $barcodes: [String!]!
) {
  checkIfGtinsExist(brand: $brand, gtins: $barcodes) {
    notFound
    found
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "barcodes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brand"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brand",
        "variableName": "brand"
      },
      {
        "kind": "Variable",
        "name": "gtins",
        "variableName": "barcodes"
      }
    ],
    "concreteType": "ProductGtins",
    "kind": "LinkedField",
    "name": "checkIfGtinsExist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "notFound",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "found",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderViaBarcodesMissingBarcodesWarningQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderViaBarcodesMissingBarcodesWarningQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8bab635f886e80b8a358b9695efc93ca",
    "id": null,
    "metadata": {},
    "name": "OrderViaBarcodesMissingBarcodesWarningQuery",
    "operationKind": "query",
    "text": "query OrderViaBarcodesMissingBarcodesWarningQuery(\n  $brand: ID!\n  $barcodes: [String!]!\n) {\n  checkIfGtinsExist(brand: $brand, gtins: $barcodes) {\n    notFound\n    found\n  }\n}\n"
  }
};
})();
(node as any).hash = '513b45069e755f27157e223625e030ff';
export default node;
