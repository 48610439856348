import React, {useState} from 'react';
import VariantBuilder from "../VariantBuilder";
import ProductModalContent from "./ProductModalContent";
import {UniqueValueTranslatorProvider} from "../../../context/UniqueValueTranslatorContext";


/**
 * Responsibilities:
 * - translates the native product data to the props for the @vuuh/ui-kit display component
 * - handles the image upload process (modal lifecycle + selecting image states, uploading, refreshing the data).
 * - displaying the product
 */


function SingleProductModal({product, renderConfig, isOpen, toggle}) {
  const variants = new VariantBuilder(
    product,
    renderConfig.variant_field,
    renderConfig.subvariant_field,
    renderConfig.variant_field_display,
    renderConfig.secondary_variant_field
  ).build();
  const [selectedVariant, setSelectedVariant] = useState(variants[0].id);

  let currentVariant = variants.filter(x => x.id === selectedVariant)[0];
  return <React.Fragment>
    <UniqueValueTranslatorProvider legend={product && product.attachedUniqueValues}>
      <ProductModalContent
        isOpen={isOpen}
        toggle={toggle}
        renderConfig={renderConfig}
        productData={product}
        variants={variants}
        currentVariant={currentVariant}
        onAddNewImageClick={() => {
        }}
        setCurrentVariant={variant => {
          setSelectedVariant(variant.id);
        }}
      />
    </UniqueValueTranslatorProvider>
  </React.Fragment>
}


export default SingleProductModal;
