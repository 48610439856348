import {Row} from "reactstrap";
import React from "react";
import {createFragmentContainer, graphql} from "react-relay";

type OrdersWithoutOrderConfirmationModalContentProps = {
  orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly orderNumber: string;
      } | null;
    } | null>;
  } | null,
  toggle: () => void
}

function OrdersWithoutOrderConfirmationModalContent({orders, toggle}: OrdersWithoutOrderConfirmationModalContentProps) {
  return <div className={"m-4 px-4 pt-2"}>
    <p>The following orders have no order confirmation attached. Please retry the process without them: </p>
    {orders?.edges.map((order, i) =>
      <p key={i} className={'text-danger'}>{order?.node?.orderNumber} </p>
    )}
    <Row className={"m-0 py-3"}>
      <p onClick={toggle} className={"text-primary"} style={{cursor: "pointer"}}>
        Back to Orders
      </p>
    </Row>
  </div>;
}

export default createFragmentContainer(
  OrdersWithoutOrderConfirmationModalContent,
  {
    orders: graphql`
      fragment OrdersWithoutOrderConfirmationModalContent_orders on OrderNodeConnection {
        edges {
          node {
            id
            orderNumber
          }
        }
      }
    `
  }
)
