import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";
import {
  SimulateOutputTokenConfigForProductMutation
} from "./__generated__/SimulateOutputTokenConfigForProductMutation.graphql";


const mutation = graphql`
  mutation SimulateOutputTokenConfigForProductMutation($input: SimulateOutputTokenConfigForProductMutationInput!) {
    simulateOutputTokenConfigForProduct(input: $input) {
      result
    }
  }
`;

export default createMutationBoilerplate<SimulateOutputTokenConfigForProductMutation>(mutation);
