export const POSSIBLE_ERRORS = {
  "general.permission_denied": "Insufficient privileges to perform this action, please contact Vuuh Support team",
  "general.bad_request": "Something went wrong, please contact Vuuh Support team",
  "general.missing_parameters('retailer_id')": "Retailer id not found, please contact Vuuh Support team",
  "general.missing_parameters": "Retailer id not found, please contact Vuuh Support team",
  "retailer.store_already_exists(name, retailer.get_graphql_id())": "Another location with the same name found, please try another name",
  "retailer.store_already_exists": "Another location with the same name found, please try another name",
  "general.not_found(id)": "Something went wrong, please contact Vuuh Support team",
  "general.not_found": "Something went wrong, please contact Vuuh Support team",
  "retailer.cannot_delete_all_the_stores_from_a_retailer": "Cannot remove the last Location available"
}

export default function translateErrorMessage(errorMessage: string | null) {
  if (errorMessage && Object.keys(POSSIBLE_ERRORS).includes(errorMessage)) {
    return POSSIBLE_ERRORS[errorMessage as keyof typeof POSSIBLE_ERRORS]
  } else return errorMessage
}