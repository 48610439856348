/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UniqueValueSetDropdownMenuTagQueryVariables = {
    id: string;
};
export type UniqueValueSetDropdownMenuTagQueryResponse = {
    readonly getUniqueValueSet: {
        readonly values: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly value: string;
                    readonly jsonSchemaValue: string | null;
                } | null;
            } | null>;
        };
    } | null;
};
export type UniqueValueSetDropdownMenuTagQuery = {
    readonly response: UniqueValueSetDropdownMenuTagQueryResponse;
    readonly variables: UniqueValueSetDropdownMenuTagQueryVariables;
};



/*
query UniqueValueSetDropdownMenuTagQuery(
  $id: ID!
) {
  getUniqueValueSet(id: $id) {
    values {
      edges {
        node {
          value
          jsonSchemaValue
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonSchemaValue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UniqueValueSetDropdownMenuTagQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UniqueValueSetNode",
        "kind": "LinkedField",
        "name": "getUniqueValueSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UniqueValueNodeConnection",
            "kind": "LinkedField",
            "name": "values",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UniqueValueNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UniqueValueNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UniqueValueSetDropdownMenuTagQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UniqueValueSetNode",
        "kind": "LinkedField",
        "name": "getUniqueValueSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UniqueValueNodeConnection",
            "kind": "LinkedField",
            "name": "values",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UniqueValueNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UniqueValueNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a86d6be4148cc537390a025118fd4b43",
    "id": null,
    "metadata": {},
    "name": "UniqueValueSetDropdownMenuTagQuery",
    "operationKind": "query",
    "text": "query UniqueValueSetDropdownMenuTagQuery(\n  $id: ID!\n) {\n  getUniqueValueSet(id: $id) {\n    values {\n      edges {\n        node {\n          value\n          jsonSchemaValue\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '3132e44e76e8b506f1699ea5aa3a9860';
export default node;
