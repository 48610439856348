import React, {useState, useRef} from "react";
import {
  Dropdown as ReactstrapDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip
} from 'reactstrap';
import searchIconSvg from '../assets/svg/search.svg';
import Button from "../general/Button";
import {getRandomString} from "../stringUtils";
import styles from './Dropdown.module.scss';

function DefaultDropdownToggle({className, ...props}) {
  if (className) {
    className += ' ' + styles.defaultToggleButton;
  } else {
    className = styles.defaultToggleButton
  }
  return <Button className={className} {...props}/>
}

function Dropdown({
                    options = [], value = null, onChange,
                    currentValueRenderer = v => v && v.label,
                    searchable = false, searchText = null, showTooltip = true,
                    clearable = false, disabled = false,
                    placeholder = 'Select...',
                    caret = true, toggleButtonProps = {}, menuProps = {},
                    overflowTooltip = true,
                    ...props
                  }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const dropDownToggleValue = useRef(null);
  const [uniqueDropdownId] = useState(getRandomString(5));

  if (!searchable && options.length >= 8) {
    searchable = true;
  }

  let displayOptions = options.filter(op => {
    if (op.divider || op.header) {
      return true;
    }
    let searchIn = typeof (op.label) === 'string' ? op.label : ''
    searchIn += ' ' + op.value;
    if (op.search) {
      searchIn = op.search = ' ' + searchIn;
    }
    return searchIn.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
  })

  const isOverflow = (element) => {
    if (!overflowTooltip) {
      return false;
    }
    if (element) {
      return (element.offsetWidth > element.parentElement.clientWidth - 45)
      // -45 is not an arbitrary number. it's the length of the padding and the dots at the end
    }
    return false;
  }

  // let finalMenuProps = {...menuProps};
  // if (searchable) {
  //   finalMenuProps.style = {minWidth: '20rem'};
  // }

  let currentValue = value && currentValueRenderer(value);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  return <ReactstrapDropdown isOpen={dropdownOpen} toggle={toggle} {...props}>
    <DropdownToggle id={'dropdown-' + uniqueDropdownId} caret={caret} tag={DefaultDropdownToggle}
                    disabled={disabled} {...toggleButtonProps}>
      {
        currentValue ?
          <span ref={dropDownToggleValue}>{currentValue}</span> :
          <span className="placeholder">{placeholder}</span>
      }
      {currentValue && showTooltip && isOverflow(dropDownToggleValue.current) &&
      <UncontrolledTooltip target={'dropdown-' + uniqueDropdownId}
                           placement={"bottom"}>
        {currentValue}
      </UncontrolledTooltip>
      }
      {clearable && value && <span className={'mx-2'}
                                     style={{'zIndex': 1000, border: 'none', background: 'none'}}
                                     onClick={e => {
                                       e.preventDefault();
                                       setDropdownOpen(false);
                                       onChange(null);
                                       e.stopPropagation();
                                     }}>
        <i className="fas fa-times"/>
      </span>}
    </DropdownToggle>
    <DropdownMenu className={styles.dropdownContainer} {...menuProps}>
      {searchable && <div className={styles.dropdownSearch}>
        <img src={searchIconSvg} alt="Search"/>
        <input type="text" autoFocus className={'form-control custom-form-group'} placeholder={searchText || 'Search'}
               value={searchInput}
               onChange={e => setSearchInput(e.target.value)}
        />
      </div>}
      {searchable && <DropdownItem divider/>}
      {displayOptions.map((op, index) => {
        if (op.header) {
          return <DropdownItem key={index} header className={styles.dropdownHeader}>{op.label}</DropdownItem>;
        }
        if (op.divider) {
          return <DropdownItem divider key={index}/>;
        }
        return <DropdownItem key={index} toggle={op.toggle} onClick={() => onChange(op)} disabled={op.disabled || false}
                             className={`${styles.dropdownItem} ${op.disabled ? 'text-muted' : 'text-dark'}`}>
          {op.icon && <i className={`fas ${op.icon} mr-2 ${op.disabled ? 'text-muted' : 'text-dark'}`}/>} {op.label}
        </DropdownItem>;
      })}
      {displayOptions.length === 0 && <p className="text-muted text-center">No options available</p>}
    </DropdownMenu>
  </ReactstrapDropdown>
}

export default Dropdown;
