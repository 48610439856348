import {areObjectsEqual} from "./objectUtils";

export function arraysOfObjectsHaveTheSameElements (arr1: any[], arr2: any[]) {
  return (arraysHaveTheSameElements(arr1.map(e => JSON.stringify(e)), arr2.map(e => JSON.stringify(e))))
}


export function arraysHaveTheSameElements(arr1: any[], arr2: any[]) {

  if (arr1.length !== arr2.length) {
    return false;
  }
  let first = arr1;
  let second = [...arr2];
  for (let i = 0; i < first.length; i++) {
    if(typeof first[i] === 'object' && first[i] !== null){
      let matchFound = false
      for (let j = 0; j < second.length; j++) {
        if (areObjectsEqual(first[i], second[j]) && !matchFound) {
          matchFound = true
          second.splice(j, 1)
        }
      }

      if (!matchFound) {
        return false
      }
    } else {
      let foundIndex = second.indexOf(first[i]);
      if (foundIndex === -1) {
        return false;
      }
      delete second[foundIndex];
    }
  }
  return second.filter(x => x !== undefined).length === 0;
}

export function arrayElementsSum(array: number[]) {
  return array.reduce((partial_sum, a) => partial_sum + a, 0)
}

export function hackyGetRelayArraySlice(first: string, last: string) {
  let firstIndex = atob(first).split(':').pop()
  let lastIndex = atob(last).split(':').pop()
  return [parseInt(firstIndex || "0"), parseInt(lastIndex || "0")]
}

export function getArrayWithReplacedElement<T, Q = T>(arr: T[], index: number, newValue: Q): (T | Q)[] {
  return arr.map((e, i) => {
    if (i === index) {
      return newValue
    } else {
      return e
    }
  })
}
