import React, {useContext} from "react";
import OrderTable from "../../../ui-kit/src/specialized/orders/OrderTable";
import integrationEnvironment from "../../../integrationEnvironment";
import {useHistory} from "react-router";
import IntegrationContext from "../../../context/IntegrationContext";

export default function IntegrationOrders({}) {
  const history = useHistory()
  const context = useContext(IntegrationContext)

  return <OrderTable isRetailer={true}
                     scopeId={context.owner}
                     environment={integrationEnvironment}
                     onOrderClick={(id) => history.push("/integrations/woocommerce/order/" + id)}/>
}