import React from "react";
import styles from './ProductImagePlaceholder.module.scss';


const sizes = {
  normal: styles.sizeNormal,
  lg: styles.sizeLg
}

function ProductImagePlaceholder({size = 'normal', style}) {
  return <div className={`product-thumbnail bg-muted mr-2 ${sizes[size]}`} style={{borderRadius: '8px', ...style}}>
    <div
      className={styles.productImagePlaceholder + ' ' + sizes[size] + ' d-flex align-items-center justify-content-center'}>
      <i className="fas fa-image fa-2x"/>
    </div>
  </div>
}

export default ProductImagePlaceholder;
