/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SimulateOutputTokenConfigForProductMutationInput = {
    config: unknown;
    product: string;
    ddt: string;
    clientMutationId?: string | null | undefined;
};
export type SimulateOutputTokenConfigForProductMutationVariables = {
    input: SimulateOutputTokenConfigForProductMutationInput;
};
export type SimulateOutputTokenConfigForProductMutationResponse = {
    readonly simulateOutputTokenConfigForProduct: {
        readonly result: unknown | null;
    } | null;
};
export type SimulateOutputTokenConfigForProductMutation = {
    readonly response: SimulateOutputTokenConfigForProductMutationResponse;
    readonly variables: SimulateOutputTokenConfigForProductMutationVariables;
};



/*
mutation SimulateOutputTokenConfigForProductMutation(
  $input: SimulateOutputTokenConfigForProductMutationInput!
) {
  simulateOutputTokenConfigForProduct(input: $input) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SimulateOutputTokenConfigForProductMutationPayload",
    "kind": "LinkedField",
    "name": "simulateOutputTokenConfigForProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SimulateOutputTokenConfigForProductMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SimulateOutputTokenConfigForProductMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed7d6eef39e59fa0636e8c88778935df",
    "id": null,
    "metadata": {},
    "name": "SimulateOutputTokenConfigForProductMutation",
    "operationKind": "mutation",
    "text": "mutation SimulateOutputTokenConfigForProductMutation(\n  $input: SimulateOutputTokenConfigForProductMutationInput!\n) {\n  simulateOutputTokenConfigForProduct(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '09b5cb0e6a1d6a3525707c439e557727';
export default node;
