import React, {useEffect} from 'react';
import styles from './OrderViaBarcodesAddInfo.module.scss'
import {Row} from "reactstrap";
import Dropdown, {Option, SelectableOption} from "../../../atoms/Dropdown";
import {DefaultInput} from "../../../atoms/Input";
import {
  CurrencyOptionType,
  OrderViaBarcodesAddInfoProps,
  RetailerStoreDropdownProps,
  RetailerStoreDropdownQueryProps
} from "./types";
import {graphql, QueryRenderer} from "react-relay";
import {
  OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery
} from "./__generated__/OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery.graphql";
import {ErrorAlert} from "../../../commons/errors";
import {
  OrderViaBarcodesAddInfoLocationDropdownQuery
} from "./__generated__/OrderViaBarcodesAddInfoLocationDropdownQuery.graphql";
import Tooltip from "../../../atoms/Tooltip";
import Loading from "../../../atoms/Loading";
import {isNumeric} from "../../../stringUtils";

const brandOrRetailerQuery =  graphql`
  query OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery {
    listRetailerDataSubscriptions(state: "active") {
      edges {
        node {
          id
          brand {
            id
            name
          }
          retailer {
            id
            name
          }
        }
      }
    }
  }
`

const locationQuery = graphql`
  query OrderViaBarcodesAddInfoLocationDropdownQuery($id: ID!) {
    getRetailerLocations(id: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

function RetailerStoreDropdown({retailerStore, setRetailerStore, retailerStoreOptions}: RetailerStoreDropdownProps) {
  useEffect(() => {
    if (
      typeof retailerStoreOptions[0]?.value === "string" &&
      typeof retailerStoreOptions[0]?.label === "string" &&
      !retailerStoreOptions[0]?.disabled
    ) {
      // Automatically select the first retailer store
      if (!retailerStore) {
        setRetailerStore({
          id: retailerStoreOptions[0].value,
          name: retailerStoreOptions[0].label
        })
      }
    }
  }, [retailerStoreOptions]);

  return <Dropdown currentValue={{label: retailerStore?.name || "Select"}}
                   options={retailerStoreOptions}
                   onSelectOption={op => {
                     if (typeof op.value === "string" && typeof op.label === "string") {
                       setRetailerStore({
                         id: op.value,
                         name: op.label
                       })
                     }
                   }}
  />
}

function RetailerStoreDropdownQuery({retailerId, retailerStore, setRetailerStore, environment}: RetailerStoreDropdownQueryProps) {
  if(retailerId) {
    return <QueryRenderer<OrderViaBarcodesAddInfoLocationDropdownQuery>
      environment={environment}
      query={locationQuery}
      variables={{id: retailerId}}
      render={({props, error}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        }

        if (retailerId && props && props.getRetailerLocations) {
          const retailerStoreOptions: SelectableOption[] = props.getRetailerLocations.edges
            .filter(e => e?.node?.name && e?.node?.id)
            .map(e => ({
              label: e?.node?.name || "",
              value: e?.node?.id || ""
            })) || [];

          if (retailerStoreOptions.length === 0) {
            retailerStoreOptions.push({
              label: "No location found",
              value: null,
              disabled: true,
            });
          }

          return <RetailerStoreDropdown
            retailerStore={retailerStore}
            setRetailerStore={setRetailerStore}
            retailerStoreOptions={retailerStoreOptions}
          />
        }

        return <Dropdown currentValue={{label: "Select"}}
                         options={[{loading: true}]}/>
      }}/>
  }

  return <Dropdown currentValue={{label: "Select"}}
                   options={[]}
                   disabled={true}/>
}

const currencyOptions: CurrencyOptionType[] = [
  { value: 'chile', currency: "USD", label: "Chile (USD)" },
  { value: 'india', currency: "INR", label: "India (INR)" },
  { value: 'italy', currency: "EUR", label: "Italy (EUR)" },
  { value: 'spain', currency: "EUR", label: "Spain (EUR)" },
  { value: 'serbia', currency: "EUR", label: "Serbia (EUR)" },
  { value: 'canada', currency: "CAD", label: "Canada (CAD)" },
  { value: 'france', currency: "EUR", label: "France (EUR)" },
  { value: 'greece', currency: "EUR", label: "Greece (EUR)" },
  { value: 'mexico', currency: "MXN", label: "Mexico (MXN)" },
  { value: 'norway', currency: "NOK", label: "Norway (NOK)" },
  { value: 'panama', currency: "USD", label: "Panama (USD)" },
  { value: 'poland', currency: "PLN", label: "Poland (PLN)" },
  { value: 'russia', currency: "RUB", label: "Russia (RUB)" },
  { value: 'sweden', currency: "SEK", label: "Sweden (SEK)" },
  { value: 'turkey', currency: "EUR", label: "Turkey (EUR)" },
  { value: 'austria', currency: "EUR", label: "Austria (EUR)" },
  { value: 'belgium', currency: "EUR", label: "Belgium (EUR)" },
  { value: 'croatia', currency: "EUR", label: "Croatia (EUR)" },
  { value: 'czechia', currency: "CZK", label: "Czechia (CZK)" },
  { value: 'denmark', currency: "DKK", label: "Denmark (DKK)" },
  { value: 'finland', currency: "EUR", label: "Finland (EUR)" },
  { value: 'germany', currency: "EUR", label: "Germany (EUR)" },
  { value: 'hungary', currency: "EUR", label: "Hungary (EUR)" },
  { value: 'ireland', currency: "EUR", label: "Ireland (EUR)" },
  { value: 'lebanon', currency: "USD", label: "Lebanon (USD)" },
  { value: 'ukraine', currency: "EUR", label: "Ukraine (EUR)" },
  { value: 'uruguay', currency: "USD", label: "Uruguay (USD)" },
  { value: 'slovenia', currency: "EUR", label: "Slovenia (EUR)" },
  { value: 'australia', currency: "AUD", label: "Australia (AUD)" },
  { value: 'netherlands', currency: "EUR", label: "Netherlands (EUR)" },
  { value: 'new_zealand', currency: "NZD", label: "New Zealand (NZD)" },
  { value: 'switzerland', currency: "CHG", label: "Switzerland (CHG)" },
  { value: 'great_britain', currency: "GBP", label: "Great Britain (GBP)" },
  { value: 'united_states', currency: "USD", label: "United States (USD)" },
].sort((a, b) => a.label.localeCompare(b.label));

export default function OrderViaBarcodesAddInfo({
                                                  brandOrRetailer, setBrandOrRetailer,
                                                  orderNumber, setOrderNumber,
                                                  retailerStore, setRetailerStore,
                                                  currency, setCurrency,
                                                  isRetailer, environment,
                                                  scopeId, noBrandsOrRetailersHandler
                                                }: OrderViaBarcodesAddInfoProps) {
  useEffect(() => {
    // Reset selected retailer store when the selected retailer changes
    if(!isRetailer) {
      setRetailerStore(undefined)
    }
  }, [brandOrRetailer]);

  return <QueryRenderer<OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery>
    environment={environment}
    query={brandOrRetailerQuery}
    variables={{}}
    render={({props, error}) => {
      if(error) {
        return <ErrorAlert error={error} />
      }

      if(props) {
        const brandOrRetailerOptions: Option[] = props.listRetailerDataSubscriptions?.edges
          .filter(e => e?.node?.brand && e?.node?.retailer)
          .map(e => {
            if(isRetailer) {
              return {
                label: e?.node?.brand.name || "",
                value: e?.node?.brand.id || "",
              }
            } else {
              return {
                label: e?.node?.retailer.name || "",
                value: e?.node?.retailer.id || "",
              }
            }
          }) || [];

        if(!brandOrRetailerOptions.length) {
          noBrandsOrRetailersHandler();
        }

        return <div className={styles.container}>
          <Row className={'m-0 align-items-center'}>
            <div className={styles.textContainer}>
              <p className={styles.text}>{isRetailer ? "Brand" : "Retailer"}</p>
            </div>
            <Dropdown currentValue={{label: brandOrRetailer?.name || "Select"}}
                      options={brandOrRetailerOptions}
                      onSelectOption={op => {
                        if (typeof op.value === "string" && typeof op.label === "string") {
                          setBrandOrRetailer({id: op.value, name: op.label})
                        }
                      }}
            />
            <Tooltip text={"Select the retailer this order is for."}
                     placement={'right'}
                     mode={'help'}>
              <i className={`fa-thin fa-circle-question ${styles.infoIcon}`}/>
            </Tooltip>
          </Row>
          <Row className={'m-0 mt-3 align-items-center'}>
            <div className={styles.textContainer}>
              <p className={styles.text}>Order number</p>
            </div>
            <DefaultInput value={orderNumber || ""}
                          onChange={e => setOrderNumber(e.target.value)}
                          placeholder={"Type in order number here"}
                          width={'12.75rem'}
                          className={styles.orderNumberInput}/>
          </Row>
          <Row className={'m-0 mt-3 align-items-center'}>
            <div className={styles.textContainer}>
              <p className={styles.text}>Location</p>
            </div>
            <RetailerStoreDropdownQuery retailerId={isRetailer ? scopeId : brandOrRetailer?.id}
                                   retailerStore={retailerStore}
                                   setRetailerStore={setRetailerStore}
                                   environment={environment} />
            <Tooltip text={"Select the retailer location this order is for."}
                     placement={'right'}
                     mode={'help'}>
              <i className={`fa-thin fa-circle-question ${styles.infoIcon}`}/>
            </Tooltip>
          </Row>
          <Row className={'m-0 mt-3 align-items-center'}>
            <div className={styles.textContainer}>
              <p className={styles.text}>Currency</p>
            </div>
            <Dropdown currentValue={{label: currency?.label || "Select"}}
                      options={currencyOptions}
                      onSelectOption={(op: any) => {
                        if (typeof op.value === "string" && typeof op.label === "string" && typeof op.currency === "string") {
                          setCurrency({currency: op.currency, country: op.value, label: op.label})
                        }
                      }}/>
            <Tooltip text={"Select the currency used for this order."}
                     placement={'right'}
                     mode={'help'}>
              <i className={`fa-thin fa-circle-question ${styles.infoIcon}`}/>
            </Tooltip>
          </Row>
        </div>
      }

      return <div className={`${styles.container} d-flex`}>
        <Loading className={'mx-auto'}/>
      </div>
    }}/>
}
