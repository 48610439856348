import React from "react";
import ReplaceTextOptions from "../commons/ReplaceTextOptions";
import Input from "../../../../../../../atoms/Input";
import RuleExtrasDropdown from "../commons/RuleExtrasDropdown";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {ReplaceCharactersRuleType, TokenRuleException} from "../../../../types";

type ReplaceCharactersRuleProps = {
  onChange: (val: Omit<ReplaceCharactersRuleType, "type">) => void,
  exceptions: TokenRuleException[],
  onRemove: () => void
} & Omit<ReplaceCharactersRuleType, "type">;

export default function ReplaceCharactersRule({
                                                replace_target,
                                                replace_with,
                                                keep_text_case,
                                                where,
                                                onChange,
                                                exceptions,
                                                onRemove
                                              }: ReplaceCharactersRuleProps) {

  return <div className={'d-flex align-items-center mt-4 ml-4'}>
    <span className={'mr-2'}>#</span>
    <Input value={replace_target ? replace_target : ""}
           placeholder={'Characters to replace'}
           width={"10.5rem"}
           onChange={e => onChange({replace_target: e.target.value, replace_with, where, keep_text_case})}/>
    <span className={'mx-2'}>to</span>
    <Input value={replace_with ? replace_with : ""}
           placeholder={'Desired characters'}
           width={"10.5rem"}
           onChange={e => onChange({replace_with: e.target.value, replace_target, where, keep_text_case})}/>
    <ReplaceTextOptions where={where}
                        keep_text_case={keep_text_case}
                        onChange={op => onChange({
                          replace_target,
                          replace_with,
                          where: op.where,
                          keep_text_case: op.keep_text_case != null ? op.keep_text_case : keep_text_case,
                        })}/>
    <RuleExtrasDropdown<Omit<ReplaceCharactersRuleType, "type">>
      onChange={onChange}
      ruleData={{
        replace_target,
        replace_with,
        where,
        keep_text_case,
        exceptions
      }}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
