import React from "react";

import AuthenticationFrame from "../../../ui-kit/src/specialized/integrations/AuthenticationFrame";
import OutputIntegration from "../../../ui-kit/src/specialized/integrations/OutputIntegration";
import IntegrationDistributeProductView from "./IntegrationDistributeProductView";
import {BrowserRouter, Route, Switch, useLocation} from "react-router-dom";
import WoocommerceIntegration from "../../../ui-kit/src/specialized/integrations/WoocommerceIntegration";
import integrationEnvironment, {
  deleteIntegrationToken,
  setIntegrationToken,
  getIntegrationToken
} from "../../../integrationEnvironment";
import PathNotFound from "../../../ui-kit/src/specialized/integrations/PathNotFound";
import IntegrationContext from "../../../context/IntegrationContext";
import IntegrationOrders from "./IntegrationOrders";
import IntegrationOrderView from "./IntegrationOrderView";

function IntegrationsLayout({}) {
  const location = useLocation();

  return <WoocommerceIntegration
    environment={integrationEnvironment}
    setIntegrationToken={setIntegrationToken}
    deleteIntegrationToken={deleteIntegrationToken}
    isAuth={location.pathname === '/integrations/woocommerce/auth'}
  >
    <BrowserRouter>
      <Switch>
        <Route exact path={'/integrations/woocommerce/auth'}>
          <AuthenticationFrame integrationContext={IntegrationContext}/>
        </Route>
        <Route exact path={'/integrations/woocommerce/output'}>
          <OutputIntegration integrationContext={IntegrationContext}/>
        </Route>
        <Route exact path={'/integrations/woocommerce/distribute'}>
          <IntegrationDistributeProductView integrationContext={IntegrationContext}/>
        </Route>
        <Route exact path={'/integrations/woocommerce/orders'}>
          <IntegrationOrders/>
        </Route>
        <Route exact path={'/integrations/woocommerce/order/:id'}>
          <IntegrationOrderView/>
        </Route>
        <Route path={'/integrations/woocommerce'}>
          <PathNotFound/>
        </Route>
      </Switch>
    </BrowserRouter>
  </WoocommerceIntegration>
}

export default IntegrationsLayout