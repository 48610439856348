import React, {useState} from "react";
import Dropdown, {
  Option,
  SelectableOption,
  useDropdownState
} from "../../../../../../atoms/Dropdown";
import styles from '../FieldRules.module.scss'
import ConditionsAndActionsFieldRuleBase from "./ConditionsAndActionsFieldRuleBase";
import ReplacementBase from "./ReplacementBase";
import ReplacementSeparator from "./ReplacementSeparator";
import ReplacementRemoveText from "./ReplacementRemoveText";
import MultiTextInput from "../../../../../../atoms/MultiTextInput";
import UniqueValueMultipleSelectDropdown from "../../dropdowns/UniqueValueMultipleSelectDropdown";
import {UniqueValueDetailType, useDDTMappingConfigFormContext} from "../../../DDTMappingConfigFormContext";
import {optionsValues, ReplacementText} from "./ReplacementText";
import {
  FieldRuleType,
  isProductPathToken, isReplaceContentFieldRule,
  isSeparatorTokenType,
  ProductPathTokenType, ReplaceContentFieldRuleType,
  SeparatorTokenType,
  TokenType
} from "../../../types";
import {ProductFieldsProvider} from "../../../utilities";
import {DefaultInput} from "../../../../../../atoms/Input";
import {isIntegerString} from "../../../../../../stringUtils";

function _normalizeUniqueValueArray(
                                    value: string[] | null,
                                    getUniqueValueDetailsByUniqueValue: (string: string) => UniqueValueDetailType | null
): (SelectableOption | null)[]{
  if (!value) {
    return []
  }
  if (!Array.isArray(value)) {
    value = [value];
  }
  return value.map(val => {
    if (val && getUniqueValueDetailsByUniqueValue(val)) {
      return {label: getUniqueValueDetailsByUniqueValue(val)?.value || '', value: val}
    } else {
      return null
    }
  }).filter(x => x);
}

type ConditionProps = {
  path: string | null,
  match_on_token: number | null,
  values: string[] | null,
  op: string,
  tokens: ProductPathTokenType[],
  canBeRemoved: boolean,
  onChange: (val: {
    value?: string[] | string | null,
    match_on_token?: number | null,
    path?: string | null,
    op?: string | null
  }) => void,
  onRemove: () => void,
  productFieldsProvider: ProductFieldsProvider,
}

export function Condition({
                            path, match_on_token,
                            values, op, tokens,
                            canBeRemoved,
                            onChange, onRemove,
                            productFieldsProvider,
                          }: ConditionProps) {

  type currentFieldProps = {
    name: string | null,
    type: string[] | null,
    value?: string | null,
    match_on_token?: number | null,
    category?: string | null,
    path?: string | null
  }

  let currentField: currentFieldProps = {
    name: null,
    value: null,
    type: [],
  }

  let {getUniqueValueDetailsByUniqueValue} = useDDTMappingConfigFormContext()
  const [editedValue, setEditedValue] = useState("")

  const onSubmit = () => {
    if (values) {
      onChange({value: [...values, editedValue]})
    } else {
      onChange({value: [editedValue]})
    }
    setEditedValue("")
  }

  let isFromDisplayed = (match_on_token && match_on_token !== 0) || typeof match_on_token === "number";

  if (path) {
    currentField = productFieldsProvider.getByPath(path) || currentField;
  } else if (isFromDisplayed) {
    currentField = {
      name: match_on_token !== null ? tokens[match_on_token].display : '',
      type: (match_on_token !== null && productFieldsProvider.getByPath(tokens[match_on_token].path || '')?.type) || null,
      match_on_token: match_on_token
    }
  }

  let isUniqueValue = false
  if (Array.isArray(currentField.type) && currentField?.type.length) {
    isUniqueValue = currentField?.type?.filter(typename => typename.startsWith('$$')).length > 0
  }

  const getOptionsWithTokens = (options: Option[], tokens: ProductPathTokenType[]): Option[] => {
    const tokenOptions = tokens.map((token, i) => {
      return {label: token.display, value: i.toString()}
    }).filter((op) => !!op?.label) as SelectableOption[]

    const newOptions = [
      {header: true, label: "Displayed"},
      ...tokenOptions,
      {divider: true},
      {header: true, label: "Unedited"}
    ]

    return [...newOptions, ...options]
  }

  const operatorDropdown = useDropdownState({
    options: [
      {value: 'eq', label: 'is'},
      {value: 'neq', label: 'is not'},
      {value: 'length_above', label: "is above X characters"},
      {value: 'length_below', label: "is below X characters"},
      {value: 'length_equals', label: "is X characters"},
      {value: 'contains', label: 'contains'},
      {value: 'contains_word', label: 'contains word'},
      {value: 'not_contains', label: 'does not contain'},
      {value: 'not_contains_word', label: 'does not contain word'},
      {value: 'starts_with', label: 'starts with'},
      {value: 'starts_with_word', label: 'starts with word'},
      {value: 'ends_with', label: 'ends with'},
      {value: 'ends_with_word', label: 'ends with word'},
      {value: 'is_empty', label: 'is empty'},
    ],
    currentValue: op || 'eq'
  })

  const isOperatorPartialMatch = operatorDropdown.value.value !== "eq" && operatorDropdown.value.value !== "neq"
  let valueComponent

  if (operatorDropdown.value.value === 'length_above' || operatorDropdown.value.value === 'length_below' || operatorDropdown.value.value === 'length_equals') {
    valueComponent = <DefaultInput
      value={values ? values[0] : ""}
      onChange={event => {
        if(isIntegerString(event.target.value) || event.target.value === "") {
          onChange({value: event.target.value})
        }
      }} width={"2.75rem"}
      invalid={!!values && !isIntegerString(values[0])}/>;
  } else if (isUniqueValue && currentField.path && !isOperatorPartialMatch) {
      const valueOptions = _normalizeUniqueValueArray(values, getUniqueValueDetailsByUniqueValue) ?
          _normalizeUniqueValueArray(values, getUniqueValueDetailsByUniqueValue) :
      []

    valueComponent = <UniqueValueMultipleSelectDropdown
      value={valueOptions as SelectableOption[]}
      onChange={(options) => {
        if (options.value.filter(val => !getUniqueValueDetailsByUniqueValue(val)).length > 0) {
          return
        }
        onChange(options)
      }}
      type={currentField.type?.length ? currentField.type[0] : ''}
    />
  } else {
    valueComponent = <MultiTextInput value={editedValue}
                                     width={"12.625rem"}
                                     onRemove={(indexOfValue) => {
                                       let newValue = values
                                       newValue && newValue.splice(indexOfValue, 1)
                                       onChange({value: newValue})
                                     }}
                                     onChange={e => {
                                       setEditedValue(e.target.value)
                                     }}
                                     onSubmit={onSubmit}
                                     otherValues={values}/>
  }

  return <>
    <div className="d-inline-block mx-2">
      <Dropdown
        placeholder={'Select a data point'}
        currentValue={{label: currentField.name || "Select a data point"}}
        options={getOptionsWithTokens(productFieldsProvider.getMultiMenuOptions(), tokens)}
        onRemove={canBeRemoved ? onRemove : undefined}
        className={isFromDisplayed ? styles.fromDisplayToken : undefined}
        onSelectOption={({value}) => {
          if(value) {
            if (!Number.isNaN(parseInt(value, 10))) {
              onChange({match_on_token: parseInt(value, 10), value: null, path: null})
            } else {
              onChange({match_on_token: null, path: value, value: null})
            }
          }
        }}
      />
    </div>
    <span className="mx-1">
      <Dropdown
        options={operatorDropdown.options}
        currentValue={operatorDropdown.value}
        onSelectOption={option => {
          if (Array.isArray(values) && ["contains", "not_contains", "starts_with", "ends_with"].includes(option.value || '')) {
            onChange({value: null, path, op: option.value})
          } else {
            onChange({value: values, path, op: option.value})
          }
        }}/>
    </span>
    {operatorDropdown.value.value !== "is_empty" && <div className="d-inline-block mx-2">
      {valueComponent}
    </div>}
  </>
}

type ReplacementProps = {
  index: number,
  value: string,
  onChange: (val: {
    index?: number | null,
    value?: string[] | string | null,
    isSeparator?: boolean
    isRemove?: boolean,
    target?: string,
    where?: string,
    keep_text_case?: boolean
  }) => void,
  onRemove: () => void,
  tokens: TokenType[],
  isSeparator: boolean | undefined,
  isRemove: boolean | undefined,
  isReplaceText: boolean | undefined,
  keep_text_case: boolean,
  target: string,
  where: string,
}

export function Replacement({index, value, onChange, onRemove,
                              tokens, isSeparator, isRemove,
                              isReplaceText, target,
                              where, keep_text_case}: ReplacementProps) {
  if (isSeparator || (index && isSeparatorTokenType(tokens[index]) && (tokens[index] as SeparatorTokenType).string)) {
    return <ReplacementSeparator
      index={index}
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      tokens={tokens}/>
  } else if (isRemove) {
    return <ReplacementRemoveText
      tokens={tokens.map(token => ({
        display: (isProductPathToken(token) && token.display) || '',
        path: (isProductPathToken(token) && token.path) || ''
      }))}
      index={index}
      onChange={onChange}
      onRemove={onRemove}/>
  } else if (isReplaceText) {
    return <ReplacementText
      onChange={onChange}
      onRemove={onRemove}
      value={value}
      target={target}
      where={where}
      keep_text_case={keep_text_case}/>
  } else {
    return <ReplacementBase
      index={index}
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      tokens={tokens}/>
  }
}


const ADD_DATA_POINT = 'add';
const REPLACE_CONTENTS = 'replace_contents';
const REPLACE_SEPARATOR = 'replace_separator';
const REPLACE_TEXT = 'replace_text';
const REMOVE_TEXT = 'remove_text'

type AlterContentFieldRuleProps = {
  value: FieldRuleType,
  onChange: (val: ReplaceContentFieldRuleType) => void,
  onRemove: () => void,
  productFieldsProvider: ProductFieldsProvider,
  tokens: TokenType[],
}

function AlterContentFieldRule({value, onChange, onRemove, productFieldsProvider, tokens}: AlterContentFieldRuleProps) {
  return <ConditionsAndActionsFieldRuleBase
    value={value}
    onChange={onChange}
    onRemove={onRemove}
    productFieldsProvider={productFieldsProvider}
    tokens={tokens}
    options={[
      {
        icon: <i className={'fas fa-plus-circle'}/>,
        value: ADD_DATA_POINT,
        label: 'Add Another Data Point',
        onClick: () => {
          isReplaceContentFieldRule(value) && onChange({...value, conditions: [...value.conditions, {path: null, value: null}]})
        }
      },
      {
        icon: <i className={'fas fa-exchange-alt'}/>,
        value: REPLACE_CONTENTS,
        label: 'Replace Contents',
        onClick: () => {
          isReplaceContentFieldRule(value) && onChange({...value, replacements: [...value.replacements, {index: null, value: ""}]});
        }
      },
      {
        icon: <i className={'far far fa-minus-circle'}/>,
        value: REMOVE_TEXT,
        label: 'Remove Data Points',
        onClick: () => {
          isReplaceContentFieldRule(value) && onChange({...value, replacements: [...value.replacements, {index: null, value: null, isRemove: true}]});
        }
      },
      {
        icon: <i className={'fas fa-minus-circle'}/>,
        value: REPLACE_SEPARATOR,
        label: 'Transform Separator',
        onClick: () => {
          isReplaceContentFieldRule(value) && onChange({...value, replacements: [...value.replacements, {index: null, value: null, isSeparator: true}]});
        }
      },   {
        icon: <i className={'fas fa-exchange-alt'}/>,
        value: REPLACE_TEXT,
        label: 'Replace Text',
        onClick: () => {
          isReplaceContentFieldRule(value) && onChange({...value,
            replacements: [...value.replacements,
              {target: '', value: '', where: optionsValues.ALL_OCCURRENCES, keep_text_case: false}
            ]
          });
        }
      },
    ]}
    ActionComponent={Replacement}
    actionsKey={'replacements'}
  />
}

export default AlterContentFieldRule;
