/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AcknowledgeOrderErrorsMutationInput = {
    order: string;
    clientMutationId?: string | null | undefined;
};
export type AcknowledgeOrderErrorsMutationVariables = {
    input: AcknowledgeOrderErrorsMutationInput;
};
export type AcknowledgeOrderErrorsMutationResponse = {
    readonly acknowledgeOrderErrors: {
        readonly order: {
            readonly id: string;
            readonly acknowledged?: boolean | undefined;
        } | null;
    } | null;
};
export type AcknowledgeOrderErrorsMutation = {
    readonly response: AcknowledgeOrderErrorsMutationResponse;
    readonly variables: AcknowledgeOrderErrorsMutationVariables;
};



/*
mutation AcknowledgeOrderErrorsMutation(
  $input: AcknowledgeOrderErrorsMutationInput!
) {
  acknowledgeOrderErrors(input: $input) {
    order {
      __typename
      id
      ... on OrderNode {
        acknowledged
      }
      ... on ManuallyUploadedOrderNode {
        acknowledged
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "acknowledged",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "OrderNode",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "ManuallyUploadedOrderNode",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcknowledgeOrderErrorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcknowledgeOrderErrorsMutationPayload",
        "kind": "LinkedField",
        "name": "acknowledgeOrderErrors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcknowledgeOrderErrorsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcknowledgeOrderErrorsMutationPayload",
        "kind": "LinkedField",
        "name": "acknowledgeOrderErrors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be569f3697e18b8889a39e2c0480f28e",
    "id": null,
    "metadata": {},
    "name": "AcknowledgeOrderErrorsMutation",
    "operationKind": "mutation",
    "text": "mutation AcknowledgeOrderErrorsMutation(\n  $input: AcknowledgeOrderErrorsMutationInput!\n) {\n  acknowledgeOrderErrors(input: $input) {\n    order {\n      __typename\n      id\n      ... on OrderNode {\n        acknowledged\n      }\n      ... on ManuallyUploadedOrderNode {\n        acknowledged\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8d2fca9b625be5cc9d73b77e9b8bac6e';
export default node;
