import React, {ReactNode, useContext, useState} from "react";
import {Environment} from "react-relay";
import {Network, RecordSource, Store, Environment as RelayEnvironment} from "relay-runtime";
import {noop} from "../../../commons/misc";
import {MeasurementDataType} from "./types";

export type UniqueValueType = {
  value: string,
  jsonSchemaValue: string
}

export type UniqueValueDetailType = { value: string, jsonSchemaValue: string }

type DDTMappingConfigFormContextType = {
  getUniqueValuesDetailsBySetType: (type: string) => UniqueValueType[],
  updateUniqueValues: (newUniqueValues: UniqueValueType[], type: string) => void
  isSetPresent: (set: string) => boolean
  getUniqueValueDetailsByUniqueValue: (string: string) => UniqueValueDetailType | null,
  environment: Environment,
  allMeasurements: MeasurementDataType[] | null
}

const defaultEnvironment = new RelayEnvironment({
  network: Network.create(() => {
    // should never happen
    throw new Error('Unable to access the GraphQL API with a default environment')
  }),
  store: new Store(new RecordSource()),
});

const DDTMappingConfigFormContext = React.createContext<DDTMappingConfigFormContextType>({
  environment: defaultEnvironment,
  getUniqueValuesDetailsBySetType: () => [],
  isSetPresent: () => false,
  updateUniqueValues: noop,
  getUniqueValueDetailsByUniqueValue: () => {
    return null
  },
  allMeasurements: null
})

type ModalContextProviderProps = {
  children: ReactNode,
  usedUniqueValues: UniqueValueType[],
  environment: Environment,
  allMeasurements?: MeasurementDataType[]
}

export function DDTMappingConfigFormContextProvider({
                                                      children,
                                                      usedUniqueValues,
                                                      environment,
                                                      allMeasurements
                                                    }: ModalContextProviderProps): JSX.Element {
  let [presentUniqueValueSets, setPresentUniqueValueSets] = useState<string[]>([])
  let [uniqueValues, setUniqueValues] = useState(usedUniqueValues.map(usedUniqueValue => {
    return {value: usedUniqueValue.value, jsonSchemaValue: usedUniqueValue.jsonSchemaValue}
  }).filter(v => v) as UniqueValueDetailType [])

  const getUniqueValueDetailsByUniqueValue = (uniqueValue: string) => {
    return uniqueValues.filter(value => {
      return value.jsonSchemaValue === uniqueValue
    })[0] || null
  }

  const isSetPresent = (set: string) => {
    return presentUniqueValueSets.includes(set)
  }

  const getUniqueValuesDetailsBySetType = (type: string) => {
    return uniqueValues.filter(value => value.jsonSchemaValue.startsWith(type))
  }

  const updateUniqueValues = (newUniqueValues: UniqueValueType[], type: string) => {
    if (isSetPresent(type)) {
      return;
    }
    let mappedValues = newUniqueValues.map(uniqueValue => {
      return {value: uniqueValue.value, jsonSchemaValue: uniqueValue.jsonSchemaValue}
    }).filter(v => v) as UniqueValueDetailType[]

    let filteredValues = mappedValues.filter(value => {
      let isPresent = false
      for (let presentValue of uniqueValues) {
        if (presentValue.jsonSchemaValue === value.jsonSchemaValue) {
          isPresent = true
        }
      }
      return !isPresent
    })

    if (filteredValues.length) {
      setUniqueValues(uniqueValues.concat(filteredValues))
    }

    setPresentUniqueValueSets([...presentUniqueValueSets, type])
  }

  return <DDTMappingConfigFormContext.Provider
    value={{
      getUniqueValueDetailsByUniqueValue,
      updateUniqueValues,
      environment,
      isSetPresent,
      getUniqueValuesDetailsBySetType,
      allMeasurements: allMeasurements || null
    }}>
    {children}
  </DDTMappingConfigFormContext.Provider>
}

export function useDDTMappingConfigFormContext(): DDTMappingConfigFormContextType {
  return useContext<DDTMappingConfigFormContextType>(DDTMappingConfigFormContext);
}
