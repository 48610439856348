import React, {CSSProperties, ReactNode} from "react";
import styles from "./GrayTableHeader.module.scss"
import addClassNames from "../../classNameUtils";

type GrayTableHeaderProps = {
  children: ReactNode,
  className?: string,
  style?: CSSProperties,
  theadClassName?: string
}

export default function GrayTableHeader({children, className, style, theadClassName}: GrayTableHeaderProps) {
  return <thead className={theadClassName}>
  <tr style={style}
      className={addClassNames([
        {className: styles.grayTableHeader, condition: true},
        {className: className, condition: !!className}
      ])}>
    {children}
  </tr>
  </thead>
}

interface GrayTableHeaderCellProps extends React.ComponentPropsWithoutRef<"th"> {
  children?: ReactNode,
  width?: number | undefined,
  colSpan?: number,
}

export function GrayTableHeaderCell({children, width = undefined, colSpan = 1, className, ...rest}: GrayTableHeaderCellProps) {
  let props = {colSpan, ...(width && {width: `${width}%`}), className, ...rest};

  let classes = addClassNames([
    {className:styles.grayTableCell, condition: true},
    {className: className, condition: !!className}
  ])
  return <th {...props} className={classes}>
    <span>{children}</span>
  </th>
}
