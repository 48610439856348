import React from 'react';
import {QueryRenderer, graphql} from 'react-relay';
import {Alert} from "reactstrap";
import ProductList, {ProductType} from "./commons/ProductList";
import {usePagination} from "../../../../commons/pagination";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  PriceCatalogueOrderProductsQuery,
  PriceCatalogueOrderProductsQueryResponse
} from "./__generated__/PriceCatalogueOrderProductsQuery.graphql";
import Loading from "../../../../atoms/Loading";
import {areObjectsEqual} from "../../../../objectUtils";

const query = graphql`
  query PriceCatalogueOrderProductsQuery($order: ID!, $first: Int, $last: Int, $before: String, $after: String) {
    productsTable(first: $first, last: $last, before: $before, after: $after, orders: [$order]) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          styleName
          styleNumber
          thumbnail {
            url
          }
          variants {
            totalCount
            edges {
              node {
                variantName
                variantCode
                hexCode
                missingData {
                  display
                  path
                }
                subvariants {
                  edges {
                    node {
                      id
                      name
                      secondary
                      missingData {
                        path
                        display
                      }
                    }
                  }
                }
              }
            }
          }
          missingData {
            display
            path
          }
          quantity
          price {
            currency
            amount
          }
        }
      }
    }
  }
`;

export type VariantType = {
  node: {
    hexCode: string,
    subvariants: {
      edges: {
        node: {
          id: string,
          name: string,
          secondary: string | null,
          missingData: {
            display: string,
            path: string
          }[]
        }
      }[]
    },
    variantCode: string,
    variantName: string,
    missingData: {
      display: string,
      path: string
    }[]
  }
}

const normalizePriceCatalogue = (productsTable: PriceCatalogueOrderProductsQueryResponse['productsTable']) => {
  const productEdges = productsTable?.edges || [];
  let prods: ProductType[] = [];
  for (let edge of productEdges) {
    const allMissingData = [
      ...edge?.node?.missingData || [],
      ...(edge?.node?.variants?.edges || []).map(variant => variant?.node?.missingData).flat(Infinity),
      ...(edge?.node?.variants?.edges || []).map(variant => (variant?.node?.subvariants?.edges || []).map(sub => sub?.node?.missingData)).flat(Infinity)
    ] as unknown as {display: string, path: string}[];
    const uniqueAllMissingData = allMissingData.filter((data, index, self) => {
      return self.findIndex((data1) => areObjectsEqual(data, data1)) === index;
    });

    prods.push({
      id: edge?.node?.id || "",
      image: edge?.node?.thumbnail?.url || "",
      styleName: edge?.node?.styleName || "",
      styleNumber: edge?.node?.styleNumber || "",
      variants: (edge?.node?.variants || {
        totalCount: 0,
        edges: []
      }) as {totalCount: number, edges: VariantType[]},
      missingData: edge?.node?.missingData?.map(edge => ({
        display: edge.display || "",
        path: edge.path || ""
      })) || [],
      allMissingData: uniqueAllMissingData,
      price: edge?.node?.price ? {
        amount: edge?.node?.price?.amount || 0,
        currency: edge?.node?.price?.currency || ""
      } : null,
      quantity: edge?.node?.quantity || null
    })
  }
  return prods;
}

type PriceCatalogueOrderProductsProps = {
  environment: RelayModernEnvironment,
  orderId: string,
  hasMissingDataStatus: boolean
}

const normalizePaginationInfo = (pageInfo: {
  startCursor: string | null,
  endCursor: string | null
} | undefined, totalCount: number | null | undefined) => {
  return {
    totalCount: totalCount || null,
    pageInfo: {
      startCursor: pageInfo?.startCursor || null,
      endCursor: pageInfo?.endCursor || null
    }
  }
}

export default function PriceCatalogueOrderProducts({environment, orderId, hasMissingDataStatus}: PriceCatalogueOrderProductsProps) {
  const {pagination} = usePagination();

  return <QueryRenderer<PriceCatalogueOrderProductsQuery>
    environment={environment}
    query={query}
    variables={{...pagination, order: orderId}}
    render={({error, props}) => {
      if (error) {
        return <Alert color={'danger'}>Failed to retrieve the products: {error.message}</Alert>
      }
      if (props) {

        return <ProductList
          products={normalizePriceCatalogue(props.productsTable)}
          environment={environment}
          paginationInfo={normalizePaginationInfo(props.productsTable?.pageInfo, props.productsTable?.totalCount)}
          hasMissingDataStatus={hasMissingDataStatus}
          showAggregateColorAndSizesCount/>
      }

      return <Loading className={'d-flex mx-auto'}/>;
    }}
  />
}
