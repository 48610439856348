import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "react-relay";
import {EditRetailerStoreMutation} from "./__generated__/EditRetailerStoreMutation.graphql";

export default createMutationBoilerplate<EditRetailerStoreMutation>(
  graphql`
    mutation EditRetailerStoreMutation($input: UpdateRetailerStoreMutationInput!) {
      updateRetailerStore(input: $input) {
        instance {
          retailer {
            id
            name
            stores{
              edges{
                node{
                  id
                }
              }
            }
          }
          name
          zipCode
          city
          address
          id
        }
      }
    }
  `
)
