import {commitMutation, graphql} from "react-relay";
import Environment from "../Environment";


const query = graphql`
  mutation ChangeCurrentRetailerMutation($input: UserSetCurrentRetailerMutationInput!) {
    setUserCurrentRetailer(input: $input) {
      user {
        id
      }
    }
  }
`

function ChangeCurrentRetailer(retailerId, onSuccess, onError) {

  commitMutation(
      Environment,
      {
        mutation: query,
        variables: {input: {retailerId}},
        onCompleted: (response, errors) => {
          if (errors) {
            onError(errors);
          } else if (response && !errors) {
            onSuccess(response);
          }
        },
        onError: (error) => {
          onError([{message: error}])
        }
      }
  )
}

export default ChangeCurrentRetailer;
