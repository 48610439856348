/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PriceCatalogueOrderProductsQueryVariables = {
    order: string;
    first?: number | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    after?: string | null | undefined;
};
export type PriceCatalogueOrderProductsQueryResponse = {
    readonly productsTable: {
        readonly totalCount: number | null;
        readonly pageInfo: {
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly styleName: string | null;
                readonly styleNumber: string | null;
                readonly thumbnail: {
                    readonly url: string | null;
                } | null;
                readonly variants: {
                    readonly totalCount: number | null;
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly variantName: string | null;
                            readonly variantCode: string | null;
                            readonly hexCode: string | null;
                            readonly missingData: ReadonlyArray<{
                                readonly display: string | null;
                                readonly path: string;
                            }>;
                            readonly subvariants: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly id: string;
                                        readonly name: string | null;
                                        readonly secondary: string | null;
                                        readonly missingData: ReadonlyArray<{
                                            readonly path: string;
                                            readonly display: string | null;
                                        }>;
                                    } | null;
                                } | null>;
                            } | null;
                        } | null;
                    } | null>;
                } | null;
                readonly missingData: ReadonlyArray<{
                    readonly display: string | null;
                    readonly path: string;
                }>;
                readonly quantity: number | null;
                readonly price: {
                    readonly currency: string;
                    readonly amount: number;
                } | null;
            } | null;
        } | null>;
    } | null;
};
export type PriceCatalogueOrderProductsQuery = {
    readonly response: PriceCatalogueOrderProductsQueryResponse;
    readonly variables: PriceCatalogueOrderProductsQueryVariables;
};



/*
query PriceCatalogueOrderProductsQuery(
  $order: ID!
  $first: Int
  $last: Int
  $before: String
  $after: String
) {
  productsTable(first: $first, last: $last, before: $before, after: $after, orders: [$order]) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      node {
        id
        styleName
        styleNumber
        thumbnail {
          url
        }
        variants {
          totalCount
          edges {
            node {
              variantName
              variantCode
              hexCode
              missingData {
                display
                path
              }
              subvariants {
                edges {
                  node {
                    id
                    name
                    secondary
                    missingData {
                      path
                      display
                    }
                  }
                }
              }
            }
          }
        }
        missingData {
          display
          path
        }
        quantity
        price {
          currency
          amount
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingProductDataField",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "orders.0",
            "variableName": "order"
          }
        ],
        "kind": "ListValue",
        "name": "orders"
      }
    ],
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "productsTable",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "styleName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "styleNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "thumbnail",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductVariantConnection",
                "kind": "LinkedField",
                "name": "variants",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductVariantEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductVariant",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "variantName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "variantCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hexCode",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductSubvariantConnection",
                            "kind": "LinkedField",
                            "name": "subvariants",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductSubvariantEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductSubvariant",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "secondary",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "MissingProductDataField",
                                        "kind": "LinkedField",
                                        "name": "missingData",
                                        "plural": true,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v7/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Price",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PriceCatalogueOrderProductsQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PriceCatalogueOrderProductsQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "5dab5e8a405b4aff025b4dca8fa55dc6",
    "id": null,
    "metadata": {},
    "name": "PriceCatalogueOrderProductsQuery",
    "operationKind": "query",
    "text": "query PriceCatalogueOrderProductsQuery(\n  $order: ID!\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n) {\n  productsTable(first: $first, last: $last, before: $before, after: $after, orders: [$order]) {\n    totalCount\n    pageInfo {\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        styleName\n        styleNumber\n        thumbnail {\n          url\n        }\n        variants {\n          totalCount\n          edges {\n            node {\n              variantName\n              variantCode\n              hexCode\n              missingData {\n                display\n                path\n              }\n              subvariants {\n                edges {\n                  node {\n                    id\n                    name\n                    secondary\n                    missingData {\n                      path\n                      display\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n        missingData {\n          display\n          path\n        }\n        quantity\n        price {\n          currency\n          amount\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '47cbe02981d585dc699d66b89489d56d';
export default node;
