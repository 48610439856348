import React, {useEffect} from 'react';
import {HttpService} from "../../../commons/http";
import {
  OrderJobIdResponseType,
  OrderViaBarcodesCreateOrderProps,
  OrderViaBarcodesDataPostType,
  OrderViaBarcodesDataResponseType
} from "./types";
import {ourToast} from "../../../atoms/Toast";
import {useHistory} from "react-router";
import LoadingModalContent from "../../../molecules/loading/LoadingModalContent";
import loadingStyles from "../../../molecules/loading/LoadingModalContent.module.scss";
import Pill from "../../../atoms/Pill";

export default function OrderViaBarcodesCreateOrder({
                                                      orderInfo,
                                                      orderBrand,
                                                      orderRetailer,
                                                      variants,
                                                      toggle
                                                    }: OrderViaBarcodesCreateOrderProps) {
  const history = useHistory();
  if (
    orderBrand == null ||
    orderRetailer == null ||
    orderInfo.orderNumber == null ||
    orderInfo.retailerStore == null ||
    orderInfo.currency == null
  ) {
    ourToast("error", "Invalid order info!");
    toggle();
  }

  const onOrderSubmitSuccess = (jobId: string) => {
    HttpService.GET<OrderJobIdResponseType>(`/api/v1/jobs/${jobId}`)
      .then(res => {
        if (res.job?.error) {
          ourToast("error", res.job.error);
          toggle();
        } else if (res.job?.state === "finished") {
          if (res.job.order_id != null) {
            history.push(`/order/${res.job.order_id}`);
          } else {
            ourToast("error", "No order ID found.");
          }
          toggle();
        } else if (res.job?.state === "running") {
          setTimeout(() => onOrderSubmitSuccess(jobId), 1000);
        }
      })
      .catch(err => {
        ourToast('error', err.message);
        toggle();
      });
  }

  const onOrderSubmit = () => {
    const data: OrderViaBarcodesDataPostType = {
      order: {
        order_number: orderInfo.orderNumber!,
        retailer: orderRetailer?.name || "",
        location: orderInfo.retailerStore!.name
      },
      products: []
    }

    variants.forEach(v => {
      v.subvariants.forEach(s => {
        data.products.push({
          brand: orderBrand?.name || "",
          quantity: Number(s?.quantity || 0),
          gtin: s.gtin,
          prices: [
            {
              type: "rrp",
              country: orderInfo.currency!.country,
              amount: s.prices.rrp?.amount || 0,
              currency: orderInfo.currency!.currency
            },
            {
              type: "wsp_list",
              country: orderInfo.currency!.country,
              amount: s.prices.wsp?.amount || 0,
              currency: orderInfo.currency!.currency
            },
            {
              type: "wsp_net",
              country: orderInfo.currency!.country,
              amount: s.finalPrice || 0,
              currency: orderInfo.currency!.currency,
              discount: Number(s.discount || 0) / 100
            }
          ]
        })
      })
    })

    HttpService.POST<OrderViaBarcodesDataPostType, OrderViaBarcodesDataResponseType>("/api/v1/orders/?existing_products_only=true", data)
      .then(res => {
        if (res.status === "success" && res.job?.id != null) {
          onOrderSubmitSuccess(res.job.id);
        } else {
          ourToast("error", "Failed to create order");
        }
      })
      .catch(err => {
        ourToast('error', err.message);
        toggle();
      });
  }

  useEffect(() => {
    onOrderSubmit();
  }, []);

  return <LoadingModalContent subtitle={"Creating order..."}
                              actionButton={
                                <Pill onClick={toggle} className={`mt-3 ${loadingStyles.button}`}>
                                  <p className={`m-0 ${loadingStyles.buttonText}`}>Close</p>
                                </Pill>
                              }
  />
}
