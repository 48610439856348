import {OrderRow_order, ReceivedDataTypeEnum} from "./__generated__/OrderRow_order.graphql";

export type variant = {
  color: string,
  size: string,
  secondary_size: string,
  gtin: string
}

export type OrderNodeType = OrderRow_order & {
  __typename: string,
  id: string,
  orderNumber: string,
  dateCreated: string,
  customerReference: string | null,
  orderConfirmationMissingProducts: {
    totalCount: number,
  }
  orderConfirmationExtraProducts: {
    totalCount: number,
  }
  orderConfirmationBatch: {
    id: string,
    errorCount: number,
  }
  fromBrand: {
    id: string,
    name: string,
  }
  toRetailer: {
    id: string,
    name: string,
  }
  lastDownload: {
    endDate: string
  } | null;
  retailerStore: {
    name: string
  } | null
  missingData: {
    percent: number
  } | null
  deliveryPeriod: {
    startDate: string
  } | null;
  receivedData: {
    type: ReceivedDataTypeEnum
  }[] | null;
  brandComment: string | null;
  retailerComment: string | null;
}

export type errors = {
  reason: string,
  product: { style_number: string },
  variant: variant,
} []

export type ManuallyUploadedOrderNodeType = OrderRow_order & {
  __typename: string,
  id: string,
  orderNumbers: string [],
  uploaded: string,
  batch: {
    error: string,
    errors: errors,
    uploads: {
      edges: {
        node: {
          name: string,
        }
      } [],
    }
  }
  fromBrand: {
    id: string,
    name: string,
  }
  toRetailer: {
    id: string,
    name: string,
  }
  missingData: {
    percent: number
  } | null
}

export type order = OrderNodeType | ManuallyUploadedOrderNodeType

export const isOrderNode = (order: OrderRow_order): order is OrderNodeType => {
  return (order as OrderNodeType).__typename === "OrderNode";
}

export const isManuallyUploadedOrderNode = (order: OrderRow_order): order is ManuallyUploadedOrderNodeType => {
  return (order as ManuallyUploadedOrderNodeType).__typename === "ManuallyUploadedOrderNode";
}
