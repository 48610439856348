/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrdersPagination_pagination = {
    readonly totalCount: number | null;
    readonly pageInfo: {
        readonly startCursor: string | null;
        readonly endCursor: string | null;
    };
    readonly " $refType": "OrdersPagination_pagination";
};
export type OrdersPagination_pagination$data = OrdersPagination_pagination;
export type OrdersPagination_pagination$key = {
    readonly " $data"?: OrdersPagination_pagination$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrdersPagination_pagination">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrdersPagination_pagination",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NodeConnection",
  "abstractKey": null
};
(node as any).hash = '8e9cf82db4b9f735762536db7178018f';
export default node;
