import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "react-relay";

export default createMutationBoilerplate(
  graphql`
    mutation DeleteRetailerStoreMutation($input: DeleteRetailerStoreMutationInput!) {
      deleteRetailerStore(input: $input) {
        successful
        retailer {
          id
          name
          stores{
            edges{
              node{
                id
              }
            }
          }
        }
      }
    }
  `
)
