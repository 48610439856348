import React, { useEffect, useRef, useState} from "react";
import styles from "./OrderRow.module.scss"
import {createFragmentContainer, graphql} from "react-relay";
import {Popover, PopoverBody} from "reactstrap";
import {capitalize, normalizeDate, sanitizeValueForDomId} from "../../stringUtils";
import SimpleTableRow, {SimpleTableCell} from "../../molecules/tables/SimpleTableRow";
import {
  errors,
  isManuallyUploadedOrderNode,
  isOrderNode,
  ManuallyUploadedOrderNodeType,
  order,
  OrderNodeType,
  variant
} from "./types";
import addClassNames from "../../classNameUtils";
import {OrderRow_order} from "./__generated__/OrderRow_order.graphql";
import Checkbox from "../../atoms/Checkbox";
import CircularProgressIndicator from "../../atoms/CircularProgressIndicator";
import Pill from "../../atoms/Pill";
import Tooltip from "../../atoms/Tooltip";
import DownloadOrdersModal from "../../specialized/orders/DownloadOrdersModal";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {useHistory} from "react-router";
import * as H from "history";
import {availableColumns} from "../../organisms/Orders/ColumnsSelectorDropdown";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import UpdateOrderCommentMutation from "../../mutations/UpdateOrderCommentMutation";
import {ourToast} from "../../atoms/Toast";


function companyName(company: { name: string }) {
  if (!company) {
    return 'Not available'
  }
  return company.name;
}

class BaseOrderAdapter {
  protected order: order;
  protected readonly isRetailer: boolean;
  private _errors: null;

  constructor(data: order, isRetailer: boolean) {
    this.order = data
    this.isRetailer = isRetailer;

    this._errors = null;
  }

  getCompanyName() {
    return this.isRetailer ? this.getBrandName() : this.getRetailerName();
  }

  getBrandName() {
    return companyName(this.order.fromBrand);
  }

  getRetailerName() {
    return companyName(this.order.toRetailer);
  }
}


export class ManualOrderAdapter extends BaseOrderAdapter {
  ORDER_NUMBER_LENGTH_BEFORE_HIDING = 30;

  protected order: ManuallyUploadedOrderNodeType

  constructor(order: ManuallyUploadedOrderNodeType, isRetailer: boolean) {
    super(order, isRetailer)
    this.order = order
  }

  getErrors() {
    if (this.order.batch) {
      return this.order.batch.errors;
    } else {
      return null
    }
  }

  getDateCreated() {
    return this.order.uploaded;
  }

  getLastDownloadDate() {
    return null;
  }

  getLastDownloadType() {
    return null;
  }

  getCustomerRef() {
    return null;
  }

  getOrderNumbers() {
    return this.order.orderNumbers.join(', ')
  }

  getLocation() {
    let locations = new Set();
    if (this.order.batch) {
      for (let upl of this.order.batch.uploads.edges) {
        if (upl.node.name) {
          locations.add(capitalize(upl.node.name.split('_')[0]));
        }
      }
    }
    return locations.size ? [...locations].join(', ') : "Location deleted"
  }

  getDownloadOrderData() {
    return [{
      id: this.order.id,
      orderNumbers: this.order.orderNumbers
    }]
  }

  getDataCompletionPercent() {
    return typeof this.order.missingData?.percent === "number" ? this.order.missingData.percent : null;
  }

  getDeliveryStartPeriod() {
    return null;
  }

  getReceivedData() {
    return [];
  }

  getComment() {
    return null;
  }
}


class AutoOrderAdapter extends BaseOrderAdapter {
  protected order: OrderNodeType

  constructor(order: OrderNodeType, isRetailer: boolean) {
    super(order, isRetailer)
    this.order = order
  }

  getErrors() {
    return null;
  }

  getDateCreated() {
    return this.order.dateCreated;
  }

  getLastDownloadDate() {
    if(this.order.lastDownload?.endDate !== null) {
      return this.order.lastDownload?.endDate;
    }
    return null;
  }

  getLastDownloadType() {
    if (this.order.lastDownload && 'type' in this.order.lastDownload) {
      return this.order.lastDownload.type;
    }
    return null;
  }

  getCustomerRef() {
    if(this.order.customerReference !== null) {
      return this.order.customerReference;
    }
    return null;
  }

  getOrderNumbers() {
    return this.order.orderNumber;
  }

  getLocation() {
    if(this.order.retailerStore) {
      return this.order.retailerStore.name
    }
    return null;
  }

  getDownloadOrderData() {
    return [{
      id: this.order.id,
      orderNumbers: [this.order.orderNumber]
    }]
  }

  getDataCompletionPercent() {
    return typeof this.order.missingData?.percent === "number" ? this.order.missingData.percent : null;
  }

  getDeliveryStartPeriod() {
    return this.order?.deliveryPeriod?.startDate || null;
  }

  getReceivedData() {
    return this.order?.receivedData || [];
  }

  getComment() {
    if(this.isRetailer) {
      return this.order?.retailerComment || ""
    }

    return this.order?.brandComment || ""
  }
}

function NoUnifiedProductError({product, variant}: { variant: variant, product: { style_number: string } }) {
  return <>`No product with style number "${product.style_number}", color "${variant.color}" and
    size "${variant.size} ${variant.secondary_size ? '/' : ''} ${variant.secondary_size || ''}"`</>
}


function NoStyleNumberError({product}: { product: { style_number: string } }) {
  return <>`No product with the "${product.style_number}" style number found.`</>;
}

function BarcodeNotFound({variant}: { variant: variant }) {
  if (variant && variant.gtin) {
    return <>`No product with the "${variant.gtin}" bar code found.`</>;
  } else {
    return <>`No product with one or more of the specified bar codes found.`</>;
  }
}

type ErrorsProps = {
  errors: errors | null,
  id: string
}

function Errors({errors, id}: ErrorsProps) {
  const [isOpen, setIsOpen] = useState(false);
  if (!errors || errors.length === 0) {
    return null;
  }
  return <>
    <Popover isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} target={id} trigger={'focus hover'}>
      <PopoverBody onClick={e => e.stopPropagation()}>
        There were some errors when processing this order confirmation upload:
        <ul style={{listStyle: 'unset'}}>
          {errors.map((err, i: number) => {
            return <li key={i}>
              {err.reason === 'no_unified_product' && <NoUnifiedProductError {...err}/>}
              {err.reason === 'no_style_number' && <NoStyleNumberError {...err}/>}
              {err.reason === 'barcode_not_found' && <BarcodeNotFound {...err}/>}
            </li>
          })}
        </ul>
      </PopoverBody>
    </Popover>
    <i className="fas fa-exclamation-circle text-warning mr-2" id={id}/>
  </>
}

function TruncatedText({ text, className }: { text: string; className?: string }) {
  const textContainerRef = useRef<HTMLDivElement | null>(null);
  const [truncatedText, setTruncatedText] = useState(text);
  const context = document.createElement('canvas').getContext('2d');

  const truncateText = () => {
    const container = textContainerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const fullTextWidth = container.scrollWidth;
      if(context) {
        if (fullTextWidth > containerWidth) {
          let truncated = text;
          context.font = window.getComputedStyle(container).font;

          // Decrease the text length until it fits
          while (context.measureText(truncated + '...').width > containerWidth) {
            truncated = truncated.slice(0, -1);
          }

          setTruncatedText(truncated + '...');
        }
      }
    } else {
      setTruncatedText(text);
    }
  };

  useEffect(() => {
    truncateText();
    window.addEventListener('resize', truncateText);

    return () => window.removeEventListener('resize', truncateText);
  }, [text, context]);

  if(text === truncatedText) {
    return <div ref={textContainerRef} className={addClassNames([
      {className: 'text-nowrap', condition: true},
      {className: className, condition: !!className},
    ])}>
      {truncatedText}
    </div>
  }

  return <Tooltip text={text}
                  placement={'top'}>
    <div ref={textContainerRef} className={addClassNames([
      {className: 'text-nowrap', condition: true},
      {className: className, condition: !!className},
    ])}>
      {truncatedText}
    </div>
  </Tooltip>
}

type DataProgressIndicatorProps = {
  dataPercentage: number | null,
  lastDownload: string | null,
  downloadType: string | null,
}

function DataProgressIndicator({dataPercentage, lastDownload, downloadType}: DataProgressIndicatorProps) {
  const isUnknown = dataPercentage === null || dataPercentage === undefined;
  const isSentViaIntegration = !!lastDownload && downloadType === "SENT";

  return <div className={'d-flex align-items-center'}>
    <CircularProgressIndicator progress={dataPercentage}/>
    <div className={styles.dataStatusContainer}>
      {dataPercentage === 100 ? <div>
        <div className={"d-flex align-items-center"}>
          <i className={`fa-solid fa-circle-check ${styles.dataIcon} ${styles.iconGreen}`}/>
          <p className={styles.dataText}>All data available</p>
        </div>
      </div> : <div className={"d-flex align-items-center"}>
        <i className={addClassNames([
          {className: "fa-solid fa-circle-exclamation", condition: true},
          {className: styles.dataIcon, condition: true},
          {className: styles.iconYellow, condition: !isUnknown},
          {className: styles.iconGrey, condition: isUnknown},
        ])} />
        <p className={styles.dataText}>{!isUnknown ? "Missing data" : "Status not available"}</p>
      </div>}
      <div className={`d-flex align-items-center ${styles.downloadDateContainer}`}>
        <i className={addClassNames([
          {className: "fa-solid fa-circle-arrow-down", condition: !isSentViaIntegration},
          {className: "fa-solid fa-circle-arrow-up-right", condition: isSentViaIntegration},
          {className: styles.dataIcon, condition: true},
          {className: styles.iconGreen, condition: !!lastDownload},
          {className: styles.iconGrey, condition: !lastDownload},
        ])} />
        <p className={styles.dataText}>{lastDownload ? `${isSentViaIntegration ? "Sent" : "Downloaded"} ${normalizeDate(lastDownload, true, true)}` : "Not downloaded"}</p>
      </div>
    </div>
  </div>
}

function DataTags({tags}: {tags: {type: string}[]}) {
  const tagTypes = tags.map(t => t.type);

  return <div className={styles.tagsContainer}>
    <div className={`d-flex align-items-center ${styles.tagContainer}`}>
      <i className={addClassNames([
        {className: 'fa-regular fa-fw', condition: true},
        {className: `fa-check ${styles.iconGreen}`, condition: tagTypes.includes("MASTER_DATA")},
        {className: `fa-xmark ${styles.iconGrey}`, condition: !tagTypes.includes("MASTER_DATA")},
      ])} />
      <p className={styles.tagText}>Master data</p>
    </div>
    <div className={`d-flex align-items-center ${styles.tagContainer}`}>
      <i className={addClassNames([
        {className: 'fa-regular fa-fw', condition: true},
        {className: `fa-check ${styles.iconGreen}`, condition: tagTypes.includes("ORDER_DATA")},
        {className: `fa-xmark ${styles.iconGrey}`, condition: !tagTypes.includes("ORDER_DATA")},
      ])} />
      <p className={styles.tagText}>Order data</p>
    </div>
    <div className={`d-flex align-items-center`}>
      <i className={addClassNames([
        {className: 'fa-regular fa-fw', condition: true},
        {className: `fa-check ${styles.iconGreen}`, condition: tagTypes.includes("SHIPMENT_DATA")},
        {className: `fa-xmark ${styles.iconGrey}`, condition: !tagTypes.includes("SHIPMENT_DATA")},
      ])} />
      <p className={styles.tagText}>Shipment data</p>
    </div>
  </div>
}

type CommentProps = {
  savedComment: string | null
  isRetailer: boolean,
  orderId: string,
  environment: RelayModernEnvironment
}

function Comment({savedComment, isRetailer, orderId, environment}: CommentProps) {
  const [comment, setComment] = useState<string>(savedComment || "");
  const [isCommentFocused, setIsCommentFocused] = useState(false);

  const updateComment = () => {
    let newComment = comment;
    if (comment.match(/^\s*$/)) {
      setComment("");
      newComment = "";
    }

    setIsCommentFocused(false)
    UpdateOrderCommentMutation(
      environment,
      {
        order: orderId,
        [isRetailer ? "retailerComment" : "brandComment"]: newComment,
      },
      rsp => {},
      err => {
        if(Array.isArray(err) && err.length && err[0]?.message === "general.permission_denied") {
          ourToast('error', "Permission denied.")
          setComment(savedComment || "")
        } else {
          ourToast('error', err)
        }
      }
    )
  }

  if (isCommentFocused) {
    return <Input value={comment} autoFocus={true}
                  onChange={el => setComment(el.target.value)}
                  onBlur={updateComment}
                  onSubmit={updateComment}
                  onKeyUp={e => {
                    if (e.key === "Enter") {
                      updateComment()
                    } else if(e.key === "Escape") {
                      setComment(savedComment || "");
                      setIsCommentFocused(false);
                    }
                  }}
                  className={styles.commentInput}/>
  }

  return (
  <div className={"position-relative w-100 d-flex align-items-center"}>
    <Tooltip text={comment}
             isDisplayed={!!comment}
             wrapperClassName={styles.commentTooltipWrapper}>
      <Button onClick={() => setIsCommentFocused(true)}
              className={styles.commentButton} color={"transparent"}
              disabled={savedComment === null}>
        {comment ? <p className={styles.commentText}>{comment}</p> :
          <p className={styles.commentText}>
            <i className={`fa-light fa-plus ${styles.iconIdleGrey} ${styles.commentIcon}`}/>
            Add
          </p>
        }
      </Button>
    </Tooltip>
  </div>
  )
}

export const redirectToProducts = (orderId: string, retailerName: string, orderNumbers: string[], history: H.History) => {
  const collectionName = encodeURIComponent(`${retailerName} - ${orderNumbers.join(", ")}`);
  history.push(`/product/catalog/?collectionId=${orderId}&collectionName=${collectionName}`);
}

type OrderRowProps = {
  order: OrderRow_order,
  environment: RelayModernEnvironment,
  orderRowHeight: number,
  isRetailer: boolean,
  isEndOfPage: boolean,
  onOrderClick: (id: string) => void,
  asAdmin?: boolean,
  onOrderSelect: (id: string, orderNumbers: string[]) => void,
  isSelected: boolean,
  activeColumns: string[],
}

function OrderRow({
                    order,
                    environment,
                    orderRowHeight,
                    isRetailer,
                    isEndOfPage,
                    onOrderClick,
                    asAdmin = false,
                    onOrderSelect,
                    isSelected,
                    activeColumns,
                  }: OrderRowProps) {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const toggle = () => setIsDownloadModalOpen(!isDownloadModalOpen);
  const history = useHistory();

  if (order.__typename === "%other") {
    return null
  } else {

    const isManuallyUploaded = order.__typename === 'ManuallyUploadedOrderNode';
    const orderAdapter = isManuallyUploaded ?
      new ManualOrderAdapter(order as ManuallyUploadedOrderNodeType, isRetailer) :
      new AutoOrderAdapter(order as OrderNodeType, isRetailer);
    const hasDownloadOrderButton = isRetailer || [100, null, undefined].includes(orderAdapter.getDataCompletionPercent())

    const rowClasses = addClassNames([
      {className: styles.orderRow, condition: true},
      {className: styles.orderRowLastItem, condition: isEndOfPage},
      {className: styles.purpleBackground, condition: isSelected},
      {className: styles.yellowBackground, condition : typeof orderAdapter.getDataCompletionPercent() === "number" && orderAdapter.getDataCompletionPercent() !== 100 && !isSelected}
    ])

    const isOptionSelected = (option: string) => {
      return activeColumns.includes(option)
    }

    return <>
      <SimpleTableRow className={rowClasses}
                      style={{height: orderRowHeight}}>
        <SimpleTableCell width={3} className={styles.orderCell + " px-4"}>
          {onOrderSelect && <Checkbox
            data-testid={'orders-checkbox-select-row'}
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              onOrderSelect(
                order.id,
                (isOrderNode(order) && [order.orderNumber]) || (isManuallyUploadedOrderNode(order) && order.orderNumbers) || []
              );
            }}
            className={styles.checkbox}/>}
        </SimpleTableCell>

        {asAdmin ? <>
            <SimpleTableCell width={15} className={styles.orderCell}>
              {orderAdapter.getBrandName()}
            </SimpleTableCell>
            <SimpleTableCell width={15} className={styles.orderCell}>
              {orderAdapter.getRetailerName()}
            </SimpleTableCell>
          </> :
          <SimpleTableCell width={12} className={styles.orderCell}>
          <span className={'mw-100'}>
            <div>
              <TruncatedText text={orderAdapter.getCompanyName()} className={styles.orderCompanyName} />
              <TruncatedText text={orderAdapter.getOrderNumbers()} className={styles.orderNumber}/>
            </div>
          </span>
          </SimpleTableCell>
        }
        {isOptionSelected(availableColumns.LOCATION) && <SimpleTableCell width={11} className={styles.orderCell}>
          <TruncatedText text={orderAdapter.getLocation() || "-"} className={styles.orderText}/>
        </SimpleTableCell>}
        {isOptionSelected(availableColumns.DATE_ADDED) && <SimpleTableCell width={10} className={styles.orderCell}>
          <p className={styles.orderText}>{capitalize(normalizeDate(orderAdapter.getDateCreated(), false, true))}</p>
        </SimpleTableCell>}
        {isOptionSelected(availableColumns.CUSTOMER_REFERENCE) && <SimpleTableCell width={10} className={styles.orderCell}>
          <p className={styles.orderText}>{orderAdapter.getCustomerRef() || "-"}</p>
        </SimpleTableCell>}
        {isOptionSelected(availableColumns.DELIVERY_PERIOD) && <SimpleTableCell width={10} className={styles.orderCell}>
          <p className={styles.orderText}>
            {orderAdapter.getDeliveryStartPeriod() ? normalizeDate(orderAdapter.getDeliveryStartPeriod()!) : "-"}
          </p>
        </SimpleTableCell>}
        {isOptionSelected(availableColumns.COMMENT) && <SimpleTableCell width={11} className={styles.orderCell}>
          <Comment savedComment={orderAdapter.getComment()} isRetailer={isRetailer} orderId={order.id} environment={environment}/>
        </SimpleTableCell>}
        <SimpleTableCell className={`${styles.orderCell} ml-auto pr-4`}>
          {isOptionSelected(availableColumns.STATUS_DETAILS) && <DataTags tags={orderAdapter.getReceivedData()}/>}
          <div className={addClassNames([
            {className: styles.missingDataIndicatorContainer, condition: isOptionSelected(availableColumns.STATUS_DETAILS)},
            {className: styles.missingDataIndicatorContainerWithoutTags, condition: !isOptionSelected(availableColumns.STATUS_DETAILS)},
          ])}>
            <DataProgressIndicator dataPercentage={orderAdapter.getDataCompletionPercent()}
                                   lastDownload={orderAdapter.getLastDownloadDate() || null}
                                   downloadType={orderAdapter.getLastDownloadType()}/>
          </div>
          <div className={styles.errorsIconContainer}>
            <Errors id={'order-' + sanitizeValueForDomId(order.id)} errors={orderAdapter.getErrors()}/>
          </div>
          <div className={`${styles.downloadButtonContainer} mr-3`}>
            {hasDownloadOrderButton
              ? <Pill onClick={toggle}>Download</Pill>
              : <Pill onClick={() => redirectToProducts(
                order.id,
                order.toRetailer?.name || "",
                isManuallyUploaded ? [...order.orderNumbers] : [order.orderNumber],
                history
              )}>Add missing data</Pill>
            }
          </div>
          <i
            className={"fas fa-caret-right " + styles.rightCaretIcon}
            onClick={() => onOrderClick(order.id)}
            data-testid="orders-button-open-order"/>
        </SimpleTableCell>
      </SimpleTableRow>
      {isDownloadModalOpen && <DownloadOrdersModal
        orders={orderAdapter.getDownloadOrderData()}
        isOpen={isDownloadModalOpen}
        toggle={toggle}
        environment={environment}
      />}
    </>
  }
}


export default createFragmentContainer(
  OrderRow,
  {
    order: graphql`
      fragment OrderRow_order on Node {
        __typename
        ... on OrderNode {
          id
          orderNumber
          dateCreated
          customerReference
          retailerStore {
            name
          }
          orderConfirmationMissingProducts {
            totalCount
          }
          orderConfirmationExtraProducts {
            totalCount
          }
          orderConfirmationBatch {
            id
            errorCount
          }
          fromBrand {
            id
            name
          }
          toRetailer {
            id
            name
          }
          lastDownload {
            type
            endDate
          }
          missingData {
            percent
          }
          deliveryPeriod {
            startDate
          }
          receivedData {
            type
          }
          brandComment
          retailerComment
        }
        ... on ManuallyUploadedOrderNode {
          id
          orderNumbers
          uploaded
          batch {
            error
            errors {
              reason
              product
              variant
            }
            uploads {
              edges {
                node {
                  name
                }
              }
            }
          }
          fromBrand {
            id
            name
          }
          toRetailer {
            id
            name
          }
          missingData {
              percent
          }
        }
      }
    `
  }
)
