import React, {useState} from "react";
import {Modal, Row, Col, Nav, NavItem, TabPane, TabContent, NavLink, ModalFooter} from "reactstrap";
import styles from './ProductModalContent.module.scss';
import Dropdown from "../../../dataInput/Dropdown";
import PrimaryActionButton from "../../../general/PrimaryActionButton";
import ErrorBoundary from "../../../general/ErrorBoundary";
import GenericTab from "./tabs/GenericTab";
import VariantViewTab from "./tabs/VariantViewTab";
import ImageCarousel from "../../../dataDisplay/ImageCarousel";


function ProductHeader({variants, currentVariant, setCurrentVariant}) {

  return <div className={styles.productInfoHeader}>
    <Row className={'m-0'}>
      <Col md={8} className={'p-0'}>
        <h5>Product information</h5>
        <p>
          The information below is for the selected color
        </p>
        <Dropdown options={variants}
                  currentValueRenderer={e => 'Color: ' + e.name}
                  placeholder={'Select color'}
                  searchable
                  onChange={setCurrentVariant}
                  value={currentVariant}
        />
      </Col>
      <Col md={4} className={'p-0'}>
        <div className="d-flex w-100 justify-content-center">
          <ImageCarousel images={currentVariant.images}/>
        </div>
      </Col>
    </Row>
  </div>;
}

function ProductInfoNavigation({currentTab, toggleTab, tabs}) {
  return <Nav tabs className={styles.navigationNav}>
    {tabs.map((t, i) => ({name: t.tab_name, tab: i})).map(item => {
      return <NavItem key={item.tab}>
        <NavLink
          className={styles.navLink + ' ' + (currentTab === item.tab ? styles.navLinkActive : '')}
          onClick={() => toggleTab(item.tab)}>
          {item.name}
        </NavLink>
      </NavItem>
    })}
  </Nav>;
}

function noop() {
}

function ProductModalContent({
                               isOpen, toggle,
                               renderConfig,
                               productData,
                               variants,
                               currentVariant,
                               setCurrentVariant = noop,
                               // action handlers
                               onAddNewImageClick
                             }) {
  const [currentTab, setCurrentTab] = useState(0);
  const toggleTab = (num) => {
    if (currentTab !== num) {
      setCurrentTab(num);
    }
  }
  return <Modal isOpen={isOpen} toggle={toggle} className={styles.modal} contentClassName={styles.modalContent}>

    <ProductHeader
      variants={variants.map((v => {
        return {label: v.name, id: v.id}
      }))}
      currentVariant={currentVariant}
      setCurrentVariant={setCurrentVariant}/>

    <ProductInfoNavigation currentTab={currentTab}
                           toggleTab={toggleTab}
                           tabs={renderConfig && (renderConfig.tabs || [])}/>

    <ErrorBoundary className={"m-4"}>
      <TabContent activeTab={currentTab} className={styles.tabContent}>
        {(renderConfig && renderConfig.tabs) && renderConfig.tabs.map((t, i) => {
          return <TabPane key={i} tabId={i}>
            {t.plugin === 'generic' && <GenericTab tabConfig={t} currentVariant={currentVariant}/>}
            {t.plugin === 'variant_view' && <VariantViewTab tabConfig={t}
                                                            variants={variants}
                                                            currentVariant={currentVariant}/>}
          </TabPane>
        })}
      </TabContent>
    </ErrorBoundary>
    <ModalFooter>
      <PrimaryActionButton onClick={toggle}>Close</PrimaryActionButton>
    </ModalFooter>
  </Modal>
}

export default ProductModalContent;
