/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DistributeDataMappingConfigUseSystem = "JMESPATH" | "V2_CONFIG" | "%future added value";
export type V2DDTConfigModalQueryVariables = {
    ddtId: string;
};
export type V2DDTConfigModalQueryResponse = {
    readonly listProductTemplates: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly fields: ReadonlyArray<{
                    readonly name: string | null;
                    readonly path: string | null;
                    readonly type: ReadonlyArray<string | null> | null;
                } | null> | null;
            } | null;
        } | null>;
        readonly " $fragmentRefs": FragmentRefs<"ModalContent_productTemplates">;
    } | null;
    readonly getDdt: {
        readonly name: string;
        readonly distributedatamappingconfigSet: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly useSystem: DistributeDataMappingConfigUseSystem;
                    readonly isActive: boolean;
                    readonly sheetName: string | null;
                    readonly sheetProductSelectors: unknown | null;
                    readonly config: unknown | null;
                    readonly configBeforeVariant: unknown | null;
                    readonly skipFirstVariant: boolean;
                    readonly productTemplate: {
                        readonly id: string;
                    } | null;
                } | null;
            } | null>;
        };
        readonly " $fragmentRefs": FragmentRefs<"ModalContent_ddt">;
    } | null;
    readonly listMeasurements: ReadonlyArray<{
        readonly name: string;
        readonly category: string;
    } | null>;
    readonly " $fragmentRefs": FragmentRefs<"ModalContent_exampleProducts">;
};
export type V2DDTConfigModalQuery = {
    readonly response: V2DDTConfigModalQueryResponse;
    readonly variables: V2DDTConfigModalQueryVariables;
};



/*
query V2DDTConfigModalQuery(
  $ddtId: ID!
) {
  listProductTemplates {
    ...ModalContent_productTemplates_3leoy
    edges {
      node {
        id
        name
        fields: schemaFields {
          name
          path
          type
        }
      }
    }
  }
  getDdt(id: $ddtId) {
    ...ModalContent_ddt_2ykinc
    name
    distributedatamappingconfigSet {
      edges {
        node {
          id
          useSystem
          isActive
          sheetName
          sheetProductSelectors
          config
          configBeforeVariant
          skipFirstVariant
          productTemplate {
            id
          }
        }
      }
    }
    id
  }
  listMeasurements(kind: "All") {
    name
    category
  }
  ...ModalContent_exampleProducts
}

fragment ModalContent_ddt_2ykinc on DistributeDataTemplateNode {
  id
  name
  schema
  ordering
  distributedatamappingconfigSet {
    edges {
      node {
        id
        isActive
        config
        configBeforeVariant
        useSystem
        sheetName
        skipFirstVariant
        usedUniqueValues {
          edges {
            node {
              id
              value
              jsonSchemaValue
            }
          }
        }
        productTemplate {
          id
        }
      }
    }
  }
}

fragment ModalContent_exampleProducts on Query {
  productsExampleCandidates(first: 1, search: "") {
    edges {
      node {
        id
        name
        styleNumber
        brandName
        collectionName
      }
    }
  }
}

fragment ModalContent_productTemplates_3leoy on ProductTemplateNodeConnection {
  edges {
    node {
      id
      name
      schemaFields(ddt: $ddtId, includeImageFields: true) {
        path
        name
        type
        category
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ddtId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": "fields",
  "args": null,
  "concreteType": "ProductTemplateField",
  "kind": "LinkedField",
  "name": "schemaFields",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "configureImageNaming",
  "value": false
},
v7 = {
  "kind": "Variable",
  "name": "ddt",
  "variableName": "ddtId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ddtId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useSystem",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sheetName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sheetProductSelectors",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "configBeforeVariant",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skipFirstVariant",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductTemplateNode",
  "kind": "LinkedField",
  "name": "productTemplate",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "kind",
      "value": "All"
    }
  ],
  "concreteType": "MeasurementNode",
  "kind": "LinkedField",
  "name": "listMeasurements",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": "listMeasurements(kind:\"All\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "V2DDTConfigModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductTemplateNodeConnection",
        "kind": "LinkedField",
        "name": "listProductTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTemplateNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTemplateNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ModalContent_productTemplates"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "DistributeDataTemplateNode",
        "kind": "LinkedField",
        "name": "getDdt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataMappingConfigNodeConnection",
            "kind": "LinkedField",
            "name": "distributedatamappingconfigSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataMappingConfigNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DistributeDataMappingConfigNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": [
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ModalContent_ddt"
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ModalContent_exampleProducts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "V2DDTConfigModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductTemplateNodeConnection",
        "kind": "LinkedField",
        "name": "listProductTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTemplateNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductTemplateNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v7/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "includeImageFields",
                        "value": true
                      }
                    ],
                    "concreteType": "ProductTemplateField",
                    "kind": "LinkedField",
                    "name": "schemaFields",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "DistributeDataTemplateNode",
        "kind": "LinkedField",
        "name": "getDdt",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schema",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordering",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataMappingConfigNodeConnection",
            "kind": "LinkedField",
            "name": "distributedatamappingconfigSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataMappingConfigNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DistributeDataMappingConfigNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v10/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UsedUniqueValueConnection",
                        "kind": "LinkedField",
                        "name": "usedUniqueValues",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UsedUniqueValueEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UniqueValueNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "value",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "jsonSchemaValue",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v18/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          }
        ],
        "concreteType": "ExampleProductCandidateConnection",
        "kind": "LinkedField",
        "name": "productsExampleCandidates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExampleProductCandidateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExampleProductCandidate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "styleNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collectionName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "productsExampleCandidates(first:1,search:\"\")"
      }
    ]
  },
  "params": {
    "cacheID": "e99aa430b34f6ee46c1afe6249ab7e16",
    "id": null,
    "metadata": {},
    "name": "V2DDTConfigModalQuery",
    "operationKind": "query",
    "text": "query V2DDTConfigModalQuery(\n  $ddtId: ID!\n) {\n  listProductTemplates {\n    ...ModalContent_productTemplates_3leoy\n    edges {\n      node {\n        id\n        name\n        fields: schemaFields {\n          name\n          path\n          type\n        }\n      }\n    }\n  }\n  getDdt(id: $ddtId) {\n    ...ModalContent_ddt_2ykinc\n    name\n    distributedatamappingconfigSet {\n      edges {\n        node {\n          id\n          useSystem\n          isActive\n          sheetName\n          sheetProductSelectors\n          config\n          configBeforeVariant\n          skipFirstVariant\n          productTemplate {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n  listMeasurements(kind: \"All\") {\n    name\n    category\n  }\n  ...ModalContent_exampleProducts\n}\n\nfragment ModalContent_ddt_2ykinc on DistributeDataTemplateNode {\n  id\n  name\n  schema\n  ordering\n  distributedatamappingconfigSet {\n    edges {\n      node {\n        id\n        isActive\n        config\n        configBeforeVariant\n        useSystem\n        sheetName\n        skipFirstVariant\n        usedUniqueValues {\n          edges {\n            node {\n              id\n              value\n              jsonSchemaValue\n            }\n          }\n        }\n        productTemplate {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ModalContent_exampleProducts on Query {\n  productsExampleCandidates(first: 1, search: \"\") {\n    edges {\n      node {\n        id\n        name\n        styleNumber\n        brandName\n        collectionName\n      }\n    }\n  }\n}\n\nfragment ModalContent_productTemplates_3leoy on ProductTemplateNodeConnection {\n  edges {\n    node {\n      id\n      name\n      schemaFields(ddt: $ddtId, includeImageFields: true) {\n        path\n        name\n        type\n        category\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7c6a79cd4a482b785f40c837a4d29c87';
export default node;
