import React from "react";
import SelectDdtContent, {DdtType} from "../../products/DistributeDataModalContentComponents/SelectDdtContent";
import LoadingContent from "../../products/DistributeDataModalContentComponents/LoadingContent";
import ErrorModalContent from "../../products/ErrorModalContent";
import OrdersWithoutOrderConfirmationModalContent from "./OrdersWithoutOrderConfirmationModalContent";
import OutputReadyModalContent from "../../products/OutputReadyModalContent";
import {DownloadOrderDataType, steps} from "../DownloadOrdersModal";
import LoadingModalContent from "../../products/DistributeDataModalContentComponents/LoadingModalContent";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {DownloadOrdersModalQueryResponse} from "../__generated__/DownloadOrdersModalQuery.graphql";
import {
  OrdersWithoutOrderConfirmationModalContent_orders$data
} from "./__generated__/OrdersWithoutOrderConfirmationModalContent_orders.graphql";

type DownloadOrdersModalContentProps = {
  step: number,
  setStep: (val: number) => void,
  toggle: () => void,
  ddts: DownloadOrdersModalQueryResponse["listDistributeDataTemplates"],
  orders: DownloadOrderDataType,
  selectedDdt: DdtType | null,
  setSelectedDdt: (val: DdtType) => void,
  downloadImages: boolean,
  setDownloadImages: (val: boolean) => void,
  mergeSameProducts: boolean,
  setMergeSameProducts: (val: boolean) => void,
  outputProcessId: string | null,
  setOutputProcessId: (val: string | null) => void,
  result: {id: string} | null,
  setResult: (val: {id: string}) => void,
  generationError: string | null,
  setGenerationError: (val: string) => void,
  ordersWithoutOrderConfirmationBatches: OrdersWithoutOrderConfirmationModalContent_orders$data | null,
  environment: RelayModernEnvironment
}

export function DownloadOrdersModalContent({
                                             step,
                                             setStep,
                                             toggle,
                                             ddts,
                                             orders,
                                             selectedDdt,
                                             setSelectedDdt,
                                             downloadImages,
                                             setDownloadImages,
                                             mergeSameProducts,
                                             setMergeSameProducts,
                                             outputProcessId,
                                             setOutputProcessId,
                                             result,
                                             setResult,
                                             generationError,
                                             setGenerationError,
                                             ordersWithoutOrderConfirmationBatches,
                                             environment
                                           }: DownloadOrdersModalContentProps) {
  const hasMultipleOrderNumbers = orders.length > 1 || (orders.length === 1 && orders[0].orderNumbers.length > 1)

  let ContentComponent = {
    [steps.SELECT]:
      <SelectDdtContent
        ddts={ddts!}
        ddt={selectedDdt}
        setDdt={setSelectedDdt}
        downloadImages={downloadImages}
        setDownloadImages={setDownloadImages}
        mergeSameProducts={mergeSameProducts}
        setMergeSameProducts={setMergeSameProducts}
        hasMultipleOrderNumbers={hasMultipleOrderNumbers}
      />,
    [steps.LOADING]:
      <LoadingContent
        environment={environment}
        outputProcessId={outputProcessId}
        onFinished={(output) => {
          setOutputProcessId(null);
          setResult(output);
          setStep(steps.FINISHED)
        }}
        onFailed={err => {
          setStep(steps.ERROR);
          setOutputProcessId(null);
          setGenerationError(err);
        }}>
        <LoadingModalContent toggle={toggle} hasDownloadImages={downloadImages} environment={environment}
                             outputProcessId={outputProcessId} outputType={selectedDdt?.outputType}/>
      </LoadingContent>,
    [steps.ERROR]:
      <ErrorModalContent
        error={generationError}
        toggle={toggle}
        isFromOrders={true}/>,
    [steps.ORDERS_WITHOUT_ORDER_CONFIRMATION_WARNING]:
      <OrdersWithoutOrderConfirmationModalContent
        orders={ordersWithoutOrderConfirmationBatches} toggle={toggle}/>,
    [steps.FINISHED]:
      <OutputReadyModalContent
        environment={environment}
        toggle={toggle}
        result={result}
        isFromOrders={true}/>
  }
  return ContentComponent[step]
}
