import React from "react";
import styles from "./MultiplicativeBlockRuleContainer.module.scss"
import {Container, Row} from "reactstrap";
import {GeneralTokenRule} from "../../types";

type BlockRuleContainerProps = {
  icon: string,
  title: string,
  rules: { rule: GeneralTokenRule, index: number } []
  createRuleElement: (rule: GeneralTokenRule, index: number) => JSX.Element
  isARuleBlockAfterThis: boolean
}

export default function MultiplicativeBlockRuleContainer({
                                                           icon,
                                                           title,
                                                           rules,
                                                           createRuleElement,
                                                           isARuleBlockAfterThis
                                                         }: BlockRuleContainerProps) {
  return <Container className={'px-0'}>
    <Row className={'mx-0'}>
      <i className={icon + " " + styles.brandRuleIcon}/>
      <span>{title}</span>
    </Row>
    {rules.map((indexedRule, i) => {
      return <div key={i}>
        {createRuleElement(indexedRule.rule, indexedRule.index)}
      </div>
    })}
    {isARuleBlockAfterThis && <hr style={{marginBlock: '1.5rem'}} className={styles.horizontalLine}/>}
  </Container>
}
