import React, {useEffect, useState} from 'react';
import {graphql, QueryRenderer} from "react-relay";
import {ErrorAlert} from "../../../commons/errors";
import {
  OrderViaBarcodesMissingBarcodesWarningProps,
  OrderViaBarcodesMissingBarcodesWarningRendererProps
} from "./types";
import {
  OrderViaBarcodesMissingBarcodesWarningQuery
} from "./__generated__/OrderViaBarcodesMissingBarcodesWarningQuery.graphql";
import styles from './OrderViaBarcodesMissingBarcodesWarning.module.scss'
import yellowExclamationMarkIcon from "../../../assets/svg/yellowExclamationMarkIcon.svg"
import Pill from "../../../atoms/Pill";
import {pluralizeByCount} from "../../../stringUtils";
import Tooltip from "../../../atoms/Tooltip";
import {ourToast} from "../../../atoms/Toast";
import {useHistory} from "react-router";
import LoadingModalContent from "../../../molecules/loading/LoadingModalContent";
import loadingStyles from "../../../molecules/loading/LoadingModalContent.module.scss";

const query = graphql`
  query OrderViaBarcodesMissingBarcodesWarningQuery($brand: ID!, $barcodes: [String!]!) {
    checkIfGtinsExist(brand: $brand, gtins: $barcodes) {
      notFound
      found
    }
  }
`

const tooltipText = <p className={"m-0"}>
  Products related to the barcodes that are not<br />
  on our system will not be part of the order.
</p>

export function OrderViaBarcodesMissingBarcodesWarning({
                                                         isBrand,
                                                         missingBarcodes,
                                                         foundBarcodes,
                                                         setExistingBarcodes,
                                                         toAddQuantitiesAndPrices,
                                                         toggle
                                                }: OrderViaBarcodesMissingBarcodesWarningProps) {
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);
  const visibleBarcodes = showAll ? missingBarcodes : missingBarcodes.slice(0, 3);

  useEffect(() => {
    setExistingBarcodes(foundBarcodes);

    if(missingBarcodes.length === 0) {
      toAddQuantitiesAndPrices();
    }
  }, [foundBarcodes, missingBarcodes]);

  const addOrderButtonHandler = () => {
    if(foundBarcodes.length > 0) {
      toAddQuantitiesAndPrices()
    } else {
      ourToast("error", "No valid barcodes found")
      toggle();
    }
  }

  return <div className={styles.container}>
    <div className={styles.closeIconContainer} onClick={toggle}>
      <i className={"fa-light fa-xmark"}/>
    </div>
    <img src={yellowExclamationMarkIcon} alt={"!"}/>
    <h2 className={styles.title}>{missingBarcodes.length} {pluralizeByCount("barcode", missingBarcodes.length)} not on our system</h2>
    <p className={`mt-1 mb-3 ${styles.infoText}`}>To add barcodes to Vuuh, add the related product data, and<br /> then try adding the order again.</p>
    {isBrand && <Pill onClick={() => history.push("/product/collections")}
                      className={`mb-2 ${styles.addOrderPill}`}>
      Add the collection/s containing the missing barcodes
    </Pill>}
    <Tooltip text={tooltipText}
             mode={"light"}>
      <Pill onClick={addOrderButtonHandler}
            icon={"fa-solid fa-circle-exclamation"}
            className={styles.addOrderPill}>
        Add the order without those barcodes
      </Pill>
    </Tooltip>
    <hr className={styles.separator}/>
    <p className={styles.infoText}>Barcodes not on Vuuh</p>
    {visibleBarcodes.length < 3 ? (
      <div className={styles.missingBarcodesSmallContainer}>
        {visibleBarcodes.map(barcode => (
          <p key={barcode}
             className={styles.barcodeText}>
            {barcode}
          </p>
        ))}
      </div>
    ) : (
      <div className={styles.missingBarcodesContainer}>
        {visibleBarcodes.map(barcode => (
          <p key={barcode}
             className={styles.barcodeText}>
            {barcode}
          </p>
        ))}
      </div>
    )}
    {missingBarcodes.length > 3 && <div onClick={() => setShowAll(!showAll)}
                                        className={styles.showAllButton}>
      {showAll && <i className={"fa-regular fa-angle-up"}/>}
      <p className={'m-0'}>Show {!showAll ? "all" : "less"}</p>
      {!showAll && <i className={"fa-regular fa-angle-down"}/>}
    </div>}
  </div>
}

export default function OrderViaBarcodesMissingBarcodesWarningRenderer({
                                                                         isBrand,
                                                                         brandId,
                                                                         barcodes,
                                                                         setExistingBarcodes,
                                                                         toAddQuantitiesAndPrices,
                                                                         environment,
                                                                         toggle,
                                                                         onBack
                                                                       }: OrderViaBarcodesMissingBarcodesWarningRendererProps) {
  return <QueryRenderer<OrderViaBarcodesMissingBarcodesWarningQuery>
    environment={environment}
    query={query}
    variables={{
      brand: brandId,
      barcodes: barcodes
    }}
    render={({props, error}) => {
      if (error) {
        const frontendError = Array.isArray(error) && error[0].message === "brand.gtins_not_found" ? new Error("Invalid barcodes!") : error;

        return <div className={styles.container}>
          <div className={styles.closeIconContainer}
               onClick={toggle}>
            <i className={"fa-light fa-xmark"}/>
          </div>
          <div className={"mt-3 w-100"}>
            <ErrorAlert error={frontendError}/>
          </div>
        </div>
      }

      if (props?.checkIfGtinsExist?.notFound) {
        return <OrderViaBarcodesMissingBarcodesWarning
          isBrand={isBrand}
          missingBarcodes={props.checkIfGtinsExist.notFound as string[]}
          foundBarcodes={props.checkIfGtinsExist.found as string[]}
          setExistingBarcodes={setExistingBarcodes}
          toAddQuantitiesAndPrices={toAddQuantitiesAndPrices}
          toggle={toggle}
        />
      }

      return <LoadingModalContent subtitle={"Reading the list of barcodes..."}
                                  actionButton={
                                    <Pill onClick={onBack} className={`mt-3 ${loadingStyles.button}`}>
                                      <p className={`m-0 ${loadingStyles.buttonText}`}>Cancel</p>
                                    </Pill>
                                  }
      />
    }}
  />
}
