import React from 'react';
import Dropdown, {Option} from "../../../../../atoms/Dropdown";

const indexOptions: Option[] = [
  {
    value: "1",
    label: "1"
  },
  {
    value: "2",
    label: "2"
  },
  {
    value: "3",
    label: "3"
  },
  {
    value: "4",
    label: "4"
  },
  {
    value: "5",
    label: "5"
  }
]

type ImageIndexDropdownProps = {
  imageIndex: number,
  setImageIndex: (newImageIndex: number) => void,
  className?: string
};

export default function ImageIndexDropdown({imageIndex, setImageIndex, className}: ImageIndexDropdownProps) {
  return <Dropdown currentValue={{label: `Image index: ${imageIndex}`}} options={indexOptions}
                   onSelectOption={op => setImageIndex(Number(op.value) || 1)}
                   menuWidth={'7rem'} className={className}/>
}
