import React from "react";
import {RemoveSectionRuleType} from "../../../../types";
import {ProductFieldsProvider} from "../../../../utilities";
import SectionPathDropdown from "../commons/SectionPathDropdown";
import RuleExtrasDropdown from "../commons/RuleExtrasDropdown";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";

type RemoveSectionRuleProps = {
  fieldDisplay: string,
  onChange: (value: Omit<RemoveSectionRuleType, "type">) => void,
  productFieldsProvider: ProductFieldsProvider,
  onRemove: () => void,
} & Omit<RemoveSectionRuleType, "type">;

export default function RemoveSectionRule({
                                            section_path,
                                            fieldDisplay,
                                            onChange,
                                            productFieldsProvider,
                                            onRemove,
                                            exceptions
                                          }: RemoveSectionRuleProps) {
  const currentValue = productFieldsProvider.getByPath(section_path)

  return <div className={'d-flex align-items-center mt-4 ml-4'}>
    <SectionPathDropdown
      currentValue={currentValue}
      onChange={onChange}
      productFieldsProvider={productFieldsProvider}
      fieldDisplay={fieldDisplay}
    />
    <RuleExtrasDropdown<Omit<RemoveSectionRuleType, "type">>
      onChange={onChange}
      ruleData={{
        section_path,
        exceptions
      }}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
