import React from "react";
import {CheckCircleIcon} from "../../..";
import {Col, Row} from "reactstrap";
import {OutputReadyModalContentQueryResponse} from "../__generated__/OutputReadyModalContentQuery.graphql";
import styles from "./DownloadReadyContent.module.scss";
import Button from "../../../atoms/Button";

type IntegrationFinishedContentProps = {
  props: OutputReadyModalContentQueryResponse,
  toggle: () => void
}

export default function IntegrationFinishedContent({props, toggle}: IntegrationFinishedContentProps) {

  return <div className={"m-4 px-4 pt-2"}>
    <Row className={"d-flex justify-content-center"}>
      <CheckCircleIcon/>
    </Row>
    <h6 className={"d-flex justify-content-center"}>Your integration is ready</h6>
    <p className="text-muted">
      {props.getDistributeDataOutput?.productsSuccessCount || 0} items successfully mapped to template.
    </p>
    <p className="text-muted">
      Your integration request had been completed. Check the product page of your site to view them
    </p>
    <Row>
      <Col>
        <Button className={styles.button}
                onClick={toggle}>
          Cancel
        </Button>
      </Col>
      <Col>
        <Button color={"primary"}
                className={styles.button}
                disabled={true}>
          <i className="fas fa-paper-plane mr-2"/>
          <span>Email file</span>
        </Button>
      </Col>
    </Row>
  </div>
}
