import {Flag} from "./FeatureFlagsContext";

export type BrandUserContextType = {
  activeFeatureFlags: Flag[],
  avatar: string,
  brand: {
    hasPermissions: {
      changeBrand: boolean,
      manageBrandUsers: boolean
    },
    id: string,
    logo: string,
    name: string,
    pendingDataSubscriptionRequests: number
  },
  brandSet: {
    id: string,
    name: string
  }[],
  email: string,
  firstName: string,
  id: string,
  isSystemAdmin: boolean,
  lastName: string
};

export type RetailerUserContextType = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  avatar: string | null,
  isSystemAdmin: boolean,
  retailer: {
    stores: {
      edges: {
        node: {
          id: string,
          name: string,
          address: string | null,
          zipCode: string | null,
          city: string | null,
        },
      }[],
    },
    id: string,
    name: string,
    logo: string | null,
    hasPermissions: {
      changeDetails: boolean,
      manageUsers: boolean,
    },
  } | null,
  retailers: {
    edges: {
      node: {
        id: string,
        name: string,
      },
    }[],
  } | null,
  activeFeatureFlags: Flag[],
}

export type AdminUserContextType = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  isSystemAdmin: boolean,
  brand: {
    id: string,
    hasPermissions: {
      changeBrand: boolean
    },
    logo: string | null,
    name: string,
    pendingDataSubscriptionRequests: number
  },
  isAdminApp: boolean
};

export type CurrentUser = BrandUserContextType | RetailerUserContextType | AdminUserContextType;

export const isBrand = (user: any): user is BrandUserContextType => {
  return (user as BrandUserContextType)?.brand !== undefined;
}

export const isRetailer = (user: any): user is RetailerUserContextType => {
  return (user as RetailerUserContextType)?.retailer !== undefined;
}

export const isAdmin = (user: any): user is AdminUserContextType => {
  return (user as AdminUserContextType)?.isAdminApp !== undefined && (user as AdminUserContextType)?.isAdminApp === true;
}
