import React from "react";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import Dropdown, {SelectableOption} from "../../../../../../../atoms/Dropdown";

type LabelWithExampleProps = {
  label: string,
  example: string
}

function LabelWithExample({label, example}: LabelWithExampleProps) {
  return <>{label} <span className="ml-2 float-right text-muted">{example}</span></>
}


const options: SelectableOption[] = [
  {value: 'raw', label: <LabelWithExample label={'As it is'} example={'0.25'}/>},
  {value: 'percentage', label: <LabelWithExample label={'Percentage'} example={'25.00%'}/>},
  {value: 'percentage_number', label: <LabelWithExample label={'Percentage without sign'} example={'25.00'}/>},
  {value: 'comma_raw', label: <LabelWithExample label={'With decimal comma'} example={'0,25'}/>},
  {value: 'comma_percentage', label: <LabelWithExample label={'Percentage with decimal comma'} example={'25,00%'}/>},
  {
    value: 'comma_percentage_number',
    label: <LabelWithExample label={'Percentage with decimal comma, without sign'} example={'25,00'}/>
  },
]

type NumericFormatRuleProps = {
  numeric_format: string | null,
  onChange: (val: {numeric_format: string}) => void,
  onRemove: () => void
}

export default function NumericFormatRule({numeric_format, onChange, onRemove}: NumericFormatRuleProps) {
  const currentValue = options.find(({value}) => value === numeric_format) || null;

  return <div className={'d-flex'}>
    <Dropdown
      caret={false}
      menuWidth={'25rem'}
      currentValue={currentValue}
      placeholder={'Select a format'}
      options={options}
      onSelectOption={({value}) => {
        if (value) {
          onChange({numeric_format: value});
        }
      }}
    />
    <RemoveRuleIcon className={'ml-auto my-auto'} onClick={onRemove}/>
  </div>

}
