import React from 'react'
import {GeneralRulesTypes, GeneralTokenRule} from "../types";
import MultiplicativeBlockRuleContainer from "./token/MultiplicativeBlockRuleContainer";

type RuleMapperProps = {
  rules: GeneralTokenRule[],
  createRuleElement: (rule: GeneralTokenRule, index: number) => JSX.Element
}

export const MULTIPLICATIVE_RULES_TYPES = [
  {type: GeneralRulesTypes.ReplaceCharactersRule, icon: "fas fa-exchange-alt", title: "Replace text"},
  {type: GeneralRulesTypes.RemoveSectionRule, icon: "fa-regular fa-circle-minus", title: "Remove content"},
  {type: GeneralRulesTypes.RemoveCharactersRule, icon: "fas fa-remove-format", title: "Remove text"}
]

let getIndexedMultiplicativeRules = (rules: { rule: GeneralTokenRule; index: number; }[]) => {
  return rules.filter(indexedRule =>
    MULTIPLICATIVE_RULES_TYPES.map(rule => rule.type).includes(indexedRule.rule.type))
}

let getIndexedNonMultiplicativeRules = (rules: { rule: GeneralTokenRule; index: number; }[]) => {
  return rules.filter(indexedRule => {
    return !MULTIPLICATIVE_RULES_TYPES.map(rule => rule.type).includes(indexedRule.rule.type)
  })
}

let isContentAfterThis = (rules: { rule: GeneralTokenRule; index: number; }[], type: GeneralRulesTypes) => {
  let index = MULTIPLICATIVE_RULES_TYPES.indexOf(MULTIPLICATIVE_RULES_TYPES.filter(ruleType => ruleType.type === type)[0]) + 1
  let ruleTypesLeftToDisplay = [...MULTIPLICATIVE_RULES_TYPES].splice(index).map(ruleType => ruleType.type)

  if (ruleTypesLeftToDisplay.length === 0) {
    return false
  } else {
    let isRuleFound = false
    for (let type of ruleTypesLeftToDisplay) {
      if (rules.map(rule => rule.rule.type).includes(type)) {
        isRuleFound = true
      }
    }
    return isRuleFound
  }
}

export default function RuleMapper({rules, createRuleElement}: RuleMapperProps) {
  let indexedRules = rules.map((rule, i) => {
    return {rule: rule, index: i}
  })

  let nonMultiplicativeRules = getIndexedNonMultiplicativeRules(indexedRules)
  let multiplicativeRules = getIndexedMultiplicativeRules(indexedRules)

  return <div>
    {nonMultiplicativeRules.map((indexedRule, i) => {
      return <div key={i}>
        {createRuleElement(indexedRule.rule, indexedRule.index)}
        {(i < nonMultiplicativeRules.length - 1 || multiplicativeRules.length > 0) &&
          <hr style={{marginBlock: '1.5rem', marginLeft: "-1.5rem", marginRight: "-1.5rem"}}/>
        }
      </div>
    })}
    {MULTIPLICATIVE_RULES_TYPES.map((ruleType, i) => {
      let rules = indexedRules.filter(indexedRule => indexedRule.rule.type === ruleType.type)
      if (rules.length > 0) {
        return <MultiplicativeBlockRuleContainer icon={ruleType.icon}
                                                 title={ruleType.title}
                                                 rules={rules}
                                                 key={i}
                                                 createRuleElement={createRuleElement}
                                                 isARuleBlockAfterThis={isContentAfterThis(indexedRules, ruleType.type)}/>
      }
    })}
  </div>
}
