import React from "react";
import {TokenRuleException} from "../../../../types";
import Dropdown from "../../../../../../../atoms/Dropdown";


type RuleExtrasDropdownProps<T> = {
  onChange: (value: (T & { exceptions: TokenRuleException[] })) => void,
  ruleData: T & { exceptions: TokenRuleException[] | undefined }
}

export default function RuleExtrasDropdown<T>({onChange, ruleData}: RuleExtrasDropdownProps<T>) {

  return <Dropdown
    caret={false}
    className={"ml-3"}
    currentValue={{label: <i className="fa-regular fa-plus m-0"/>, value: null}}
    options={[
      {value: 'add-exception', label: <>Add exception</>}
    ]}
    onSelectOption={({value}) => {
      if (value === 'add-exception') {
        onChange({
          ...ruleData,
          exceptions: [
            ...(ruleData.exceptions || []),
            {field: null, op: 'eq', value: null}
          ]
        })
      }
    }}
  />
}
