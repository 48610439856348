import React from "react";
import Dropdown from "../../../../../../../atoms/Dropdown";
import Button from "../../../../../../../atoms/Button";
import styles from "./ReplaceTextOptions.module.scss"
import {getClassNames} from "../../../../../../../classNameUtils";
import Checkbox from "../../../../../../../atoms/Checkbox";
import {Location} from "../../../../types";

const optionLabels = {
  [Location.ALL]: "On all occurrences",
  [Location.WORD]: "As full word",
  [Location.START]: "From the beginning",
  [Location.WORD_BEGIN]: "From the beginning of a word",
  [Location.END]: "From the end",
  [Location.WORD_END]: "From the end of a word",
  [Location.FIRST]: "From the first occurrence",
  [Location.LAST]: "From the last occurrence",
}

const getOption = (option: Location, isSelected: boolean) => {

  let labelClasses = getClassNames([
    {className: styles.purpleText, condition: isSelected},
  ])

  let iconClasses = getClassNames([
    {className: "fas fa-remove-format", condition: true},
    {className: styles.purpleText, condition: isSelected},
  ])

  return {
    label: <span className={labelClasses}> {optionLabels[option]} </span>,
    icon: <i className={iconClasses}/>,
    value: option
  }
}

type ReplaceTextOptionsProps = {
  where: Location,
  keep_text_case?: boolean,
  onChange: (option: { where: Location, keep_text_case?: boolean }) => void
}

export default function ReplaceTextOptions({where, keep_text_case, onChange}: ReplaceTextOptionsProps) {
  let options = []

  for (let location of Object.values(Location)) {
    options.push(getOption(location, location === where))
  }
  if (keep_text_case !== undefined) {
    options.push({divider: true})
    options.push({
      label: <Checkbox
        onClick={(e) => {
          e.stopPropagation()
          onChange({where, keep_text_case: !keep_text_case})
        }}
        checked={keep_text_case}
        label={<span>Ignore text-case rules</span>}
      />
    })
  }

  return <Dropdown
    className={"ml-3 mr-4"}
    ToggleTag={(props) =>
      <Button style={{height: "2rem", width: "2rem"}}
              className={"justify-content-center"}
              onClick={props.toggleDropdowns}>
        <i className="fas fa-sliders-h mr-0"/>
      </Button>}
    options={options}
    onSelectOption={(option) => {
      if (option.value) {
        onChange({where: option.value as Location})
      }
    }}
    currentValue={null}/>
}
