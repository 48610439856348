import React, {useState} from "react";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {uploadFileForCompletion} from "../../../api/uploadFileForCompletion";
import Button from "../../../atoms/Button";
import {downloadFile} from "../../../commons/http";
import GrayModal from "../../../dataDisplay/GrayModal";
import DownloadReadyContent from "../../products/DistributeDataModalContentComponents/DownloadReadyContent";
import FinishedContent from "./modalContent/FinishedContent";
import LoadingContent from "./modalContent/LoadingContent";
import SelectDdtContent from "./modalContent/SelectDdtContent";
import SelectFileContent from "./modalContent/SelectFileContent";
import {ourToast} from "../../../atoms/Toast";


type Step = 'select-ddt' | 'select-file' | 'loading' | 'success' | 'error'


type GetButtonPropsStateType = {
  ddtId: string | null;
  setOutputProcessId: (id: string | null) => void;
  setUploadId: (id: string | null) => void;
  setStep: (s: Step) => void;
  files: File[],
  includeImages: boolean
}

type StepDataType = {
  index: number,
  getButtonProps?(state: GetButtonPropsStateType): { text: string, isDisabled: boolean },
  hidePrimaryButton?: boolean
}

const STEP_DATA: { [key in Step]: StepDataType } = {
  'select-ddt': {
    index: 0,
    getButtonProps: ({ddtId, setStep}: GetButtonPropsStateType) => {
      return {
        text: 'Next',
        isDisabled: !ddtId,
        onClick: () => setStep('select-file')
      }
    }
  },
  'select-file': {
    index: 1,
    getButtonProps({setStep, ddtId, files, setOutputProcessId, setUploadId, includeImages}: GetButtonPropsStateType) {
      return {
        text: 'Next',
        isDisabled: files.length === 0,
        onClick: () => {
          if (!ddtId) {
            ourToast('error', 'No output ID provided');
            return;
          }
          if (files.length === 0) {
            ourToast('error', 'No files selected');
            return;
          }
          uploadFileForCompletion(
            {ddtId, file: files[0], includeImages},
            data => {
              setOutputProcessId(data.distribute_data_output_process_id);
              setUploadId(data.order_confirmation_upload_id);
              setStep('loading');
            },
            error => {
              setStep('error')
            }
          )
        }
      }
    }
  },
  'loading': {
    index: 2,
    hidePrimaryButton: true
  },
  'error': {index: 2},
  'success': {index: 2}
}


type AddDataToFileButtonWithModalProps = {
  environment: RelayModernEnvironment,
  isOpen: boolean,
  setIsOpen: (val: boolean) => void
}

export default function AddDataToFileButtonWithModal({environment, isOpen, setIsOpen}: AddDataToFileButtonWithModalProps) {
  const [step, setStep] = useState<Step>('select-ddt');
  const [files, setFiles] = useState<File[]>([]);
  const [includeImages, setIncludeImages] = useState<boolean>(false);
  const [ddtId, setDdtId] = useState<string | null>(null);
  const [outputProcessId, setOutputProcessId] = useState<string | null>(null);
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<string | null>(null);

  const toggle = () => {
    setIsOpen(!isOpen);
    setStep('select-ddt')
  }

  let currentStepData = STEP_DATA[step];

  let buttonProps = currentStepData.getButtonProps && currentStepData.getButtonProps({
    ddtId,
    setStep,
    files,
    setOutputProcessId,
    setUploadId: setUploadId,
    includeImages
  });

  let _toggle = () => {
    setFiles([]);
    setStep("select-ddt");
    setDdtId(null);
    setOutputProcessId(null);
    setError(null);
    setResult(null);
    toggle()
  };

  return <>
    <GrayModal
      size={'lg'}
      isOpen={isOpen}
      toggle={_toggle}
      title={'Add data to a file'}
      steps={[
        {stepText: 'Select an output'},
        {stepText: 'Add a custom file'},
        {stepText: step === 'success' ? 'Ready' : 'Processing...'},
      ]}
      stepsIndex={currentStepData.index}
      secondaryHeaderButton={{text: 'Cancel', onClick: _toggle}}
      primaryHeaderButton={currentStepData.hidePrimaryButton ? undefined : {
        text: 'Next',
        onClick: _toggle,
        isDisabled: true,
        ...buttonProps
      }}
      bodyContent={<>
        {step === 'select-ddt' && <SelectDdtContent ddtId={ddtId} setDdtId={setDdtId} environment={environment}/>}
        {step === 'select-file' && <SelectFileContent files={files}
                                                      setFiles={setFiles}
                                                      includeImages={includeImages}
                                                      setIncludeImages={setIncludeImages}/>}
        {step === 'loading' && <LoadingContent
          outputProcessId={outputProcessId}
          environment={environment}
          setError={errMessage => {
            setError(errMessage);
            setStep('error');
          }}
          setResult={url => {
            setResult(url);
            setStep('success');
          }}
        />}
        {step === 'error' && <div className="p-3">
          An error occurred: {error}
        </div>}
        {step === 'success' && <div className={'p-3'}>
          <FinishedContent downloadUrl={result} uploadId={uploadId} environment={environment}/>
        </div>}
      </>}
    />
  </>
}
