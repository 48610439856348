import React from "react";
import {Col, Row} from "reactstrap";
import styles from "./ReplacementRemoveText.module.scss";
import RemoveRuleIcon from "../RemoveRuleIcon";
import Dropdown from "../../../../../../atoms/Dropdown";
import Button from "../../../../../../atoms/Button";

type ReplacementRemoveTextProps = {
  onChange: (value: { index: number, value: null }) => void,
  onRemove: () => void,
  text?: string,
  icon?: string
  index: number,
  tokens: { display: string | null, path: string | null }[]
}

export default function ReplacementRemoveText({
                                                tokens,
                                                index,
                                                onChange,
                                                onRemove,
                                                text = 'Remove',
                                                icon = 'fa-minus-circle'
                                              }: ReplacementRemoveTextProps) {

  const options = tokens.map((t, i) => {
    return {
      label: t.display || '',
      value: i,
      keep: t.path || ''
    }
  }).filter(t => t.keep).map(option => {
    return {label: option.label, value: option.value.toString()}
  })

  const selectedValue = options.filter(o => Number(o.value) === index)[0] || null;

  return <Row className={'mx-0 mt-4 pt-2'}>
    <Col className={'d-flex align-items-center px-0'} style={{lineHeight: '2rem'}}>
      <div
        className={'d-flex justify-content-center align-items-center rounded-circle ' + styles.iconBackground}>
        <i className={`far ${icon} text-white`}
           style={{
             fontSize: '12px'
           }}/>
      </div>

      <span className="mx-2">
        {text}
      </span>
      <Dropdown
        className={'mx-1'}
        placeholder={"Select a data point"}
        ToggleTag={(props) =>
          <Button
            onClick={props.toggleDropdowns}
            className={styles.dropdownToggle}>
            {selectedValue?.label ? selectedValue?.label : "Select Data Point"} <i className="fas fa-caret-down pl-1"/>
          </Button>
        }
        options={options}
        currentValue={selectedValue}
        onSelectOption={(option) => {
          // protect against invalid values
          if (!tokens[Number(option.value)] || !tokens[Number(option.value)].path) {
            return;
          }
          onChange({index: Number(option.value), value: null})
        }}
      />
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}
