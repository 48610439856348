import React from 'react';

export const UniqueValueTranslatorContext = React.createContext();

export function UniqueValueTranslatorProvider({legend, children}) {

  const translateUniqueValue = (uniqueValue) => {
    let legendEntry = legend.filter(x => x.jsonSchemaValue === uniqueValue)[0]
    return legendEntry ? legendEntry.value : uniqueValue
  };
  return <UniqueValueTranslatorContext.Provider value={translateUniqueValue}>
    {children}
  </UniqueValueTranslatorContext.Provider>
}

