/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderViaBarcodesQuantitiesAndPricesQueryVariables = {
    gtins?: Array<string> | null | undefined;
    country: string;
};
export type OrderViaBarcodesQuantitiesAndPricesQueryResponse = {
    readonly productsTable: {
        readonly count: number | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly styleNumber: string | null;
                readonly variants: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly variantCode: string | null;
                            readonly variantName: string | null;
                            readonly hexCode: string | null;
                            readonly subvariants: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly id: string;
                                        readonly gtin: string | null;
                                        readonly name: string | null;
                                        readonly prices: {
                                            readonly rrp: {
                                                readonly currency: string;
                                                readonly amount: number;
                                            } | null;
                                            readonly wsp: {
                                                readonly currency: string;
                                                readonly amount: number;
                                            } | null;
                                        } | null;
                                    } | null;
                                } | null>;
                            } | null;
                        } | null;
                    } | null>;
                } | null;
            } | null;
        } | null>;
    } | null;
};
export type OrderViaBarcodesQuantitiesAndPricesQuery = {
    readonly response: OrderViaBarcodesQuantitiesAndPricesQueryResponse;
    readonly variables: OrderViaBarcodesQuantitiesAndPricesQueryVariables;
};



/*
query OrderViaBarcodesQuantitiesAndPricesQuery(
  $gtins: [String!]
  $country: String!
) {
  productsTable(gtins: $gtins) {
    count
    edges {
      node {
        id
        styleNumber
        variants {
          edges {
            node {
              variantCode
              variantName
              hexCode
              subvariants {
                edges {
                  node {
                    id
                    gtin
                    name
                    prices(country: $country) {
                      rrp {
                        currency
                        amount
                      }
                      wsp {
                        currency
                        amount
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "country"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gtins"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "gtins",
        "variableName": "gtins"
      }
    ],
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "productsTable",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "styleNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductVariantConnection",
                "kind": "LinkedField",
                "name": "variants",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductVariantEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductVariant",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "variantCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "variantName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hexCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductSubvariantConnection",
                            "kind": "LinkedField",
                            "name": "subvariants",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductSubvariantEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductSubvariant",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "gtin",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Variable",
                                            "name": "country",
                                            "variableName": "country"
                                          }
                                        ],
                                        "concreteType": "SubvariantPrice",
                                        "kind": "LinkedField",
                                        "name": "prices",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Price",
                                            "kind": "LinkedField",
                                            "name": "rrp",
                                            "plural": false,
                                            "selections": (v3/*: any*/),
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Price",
                                            "kind": "LinkedField",
                                            "name": "wsp",
                                            "plural": false,
                                            "selections": (v3/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderViaBarcodesQuantitiesAndPricesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderViaBarcodesQuantitiesAndPricesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b41bc089760ed7cb7fec6aaa0485890f",
    "id": null,
    "metadata": {},
    "name": "OrderViaBarcodesQuantitiesAndPricesQuery",
    "operationKind": "query",
    "text": "query OrderViaBarcodesQuantitiesAndPricesQuery(\n  $gtins: [String!]\n  $country: String!\n) {\n  productsTable(gtins: $gtins) {\n    count\n    edges {\n      node {\n        id\n        styleNumber\n        variants {\n          edges {\n            node {\n              variantCode\n              variantName\n              hexCode\n              subvariants {\n                edges {\n                  node {\n                    id\n                    gtin\n                    name\n                    prices(country: $country) {\n                      rrp {\n                        currency\n                        amount\n                      }\n                      wsp {\n                        currency\n                        amount\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cab4bd2c823c70e500879329e7c1f853';
export default node;
