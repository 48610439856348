import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// disable ServiceWorker
import {unregister as unregisterServiceWorker} from './registerServiceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));
// disable ServiceWorker
unregisterServiceWorker();
