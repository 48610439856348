import {FieldRuleType, ReduceLengthFieldRuleType, ReplaceContentFieldRuleType} from "./types";

type RemoveContentOldRuleType = {
  type: "remove_content",
  removals: {
    index: number | null
  }[]
}

export class FieldRules {
  static REDUCE_LENGTH: "reduce_length" = 'reduce_length';
  static REPLACE_CONTENT: "replace_content" = 'replace_content';
  static ENUMERATE_SUBVARIANTS: "subvariant_enumerate" = 'subvariant_enumerate';
  // Old rule, should not be used anymore
  static REMOVE_CONTENT: "remove_content" = 'remove_content';

  static all = () => {
    return [this.REDUCE_LENGTH, this.REPLACE_CONTENT, this.ENUMERATE_SUBVARIANTS] as string[]
  }

  static getInitialRuleState = (ruleId: string): FieldRuleType | null => {
    if (ruleId === this.REDUCE_LENGTH) {
      return {type: this.REDUCE_LENGTH, max_length: '', actions: []}
    } else if (ruleId === this.REPLACE_CONTENT) {
      return {type: this.REPLACE_CONTENT, conditions: [], replacements: []}
    } else if (ruleId === this.ENUMERATE_SUBVARIANTS) {
      return {type: this.ENUMERATE_SUBVARIANTS}
    }

    return null;
  }

  static isBadRule(rule: FieldRuleType | RemoveContentOldRuleType) {
    if (rule.type === this.REDUCE_LENGTH) {
      return this.isBadReduceLengthRule(rule)
    }
    if (rule.type === this.REPLACE_CONTENT) {
      return this.isBadReplaceContentRule(rule)
    }
    if (rule.type === this.REMOVE_CONTENT) {
      return this.isBadRemoveContentRule(rule)
    }
  }

  static isDisabled(tokenCount: number, ruleType: string, usedRuleTypes: string[]) {
    // usable only when there are no tokens
    if (ruleType === this.ENUMERATE_SUBVARIANTS) {
      return (tokenCount !== 0)
    }
    // can use as many of these we want
    if (ruleType === this.REPLACE_CONTENT || ruleType === this.REMOVE_CONTENT) {
      return false;
    }
    return tokenCount === 0 || usedRuleTypes.includes(ruleType);
  }

  static isBadReduceLengthRule(rule: ReduceLengthFieldRuleType) {
    return (rule.max_length !== 0 && !rule.max_length)
  }

  static isBadReplaceContentRule(rule: ReplaceContentFieldRuleType) {
    return rule.replacements.some(replacement => replacement.index === null)
  }

  static isBadRemoveContentRule(rule: RemoveContentOldRuleType) {
    return rule.removals.some(removal => removal.index === null)
  }
}


