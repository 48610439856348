import styles from "./OrderProductLayout.module.scss";
import React, {useEffect, useState} from "react";
import {ButtonProps} from "../../../atoms/Button";
import Dropdown from "../../../atoms/Dropdown";
import Pill from "../../../atoms/Pill";
import addClassNames from "../../../classNameUtils";
import Tooltip from "../../../atoms/Tooltip";


export function ProductRowLayout() {
  return null
}

function Image({image}: {image?: string}) {
  const [isUrlInvalid, setIsUrlInvalid] = useState(false)

  if (image && !isUrlInvalid) {
    return <div className={styles.productImage}>
      <img src={image}
           alt={"Product"}
           onError={({currentTarget}) => {
             currentTarget.onerror = null; // prevents looping
             setIsUrlInvalid(true);
           }}/>
    </div>
  }
  return <div className={styles.productImagePlaceholder + ' d-flex align-items-center justify-content-center'}>
    <i className={`${styles.productImagePlaceholderIcon} fa-solid fa-image`}/>
  </div>
}

type VariantsDropdownTagProps = {
  totalCount: number,
  hexCodes: string[],
  onClick: () => void,
  isOpen: boolean,
  hasMissingData: boolean
}

function VariantsDropdownTag({totalCount, hexCodes, onClick, isOpen, hasMissingData}: VariantsDropdownTagProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => setIsActive(isOpen), [isOpen])

  return <Pill
    active={isActive}
    style={{
      boxShadow: "none",
      backgroundColor: (!isHovered && !isActive) ? "transparent" : ""
    }}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={onClick}
    data-testid={"order-dropdown-color-variants"}
  >
    <div
      className={addClassNames([
        {className: styles.variantText, condition: true},
        {className: styles.variantTextActive, condition: isActive}
      ])}
    >{totalCount}</div>
    <div className={'ml-2 d-flex'}>
      {hexCodes.map((hexCode, i) => (
        <div
          key={i}
          style={{backgroundColor: hexCode, zIndex: totalCount - i}}
          className={addClassNames([
            {className: styles.variantTagColor, condition: true},
            {className: styles.variantTagColorMissingData, condition: hasMissingData},
            {className: styles.variantTagColorHovered, condition: isHovered},
            {className: styles.variantTagColorActive, condition: isActive}
          ])}
        />
      ))}
    </div>
  </Pill>
}

type VariantType = {
  hexCode: string,
  subvariants: {
    edges: {
      node: {
        id: string,
        name: string,
        secondary: string | null
      }
    }[]
  },
  variantCode: string,
  variantName: string
}

function ColorVariantOption({variant}: {variant: VariantType}) {
  const [isOpen, setIsOpen] = useState(false)
  const subvariantsLength = variant.subvariants.edges.length;

  return <div onClick={() => setIsOpen(!isOpen)}>
    <div className={'d-flex align-items-center w-100'}>
      <div
        style={{backgroundColor: variant.hexCode}}
        className={styles.variantOptionColor}
      />
      <p className={'ml-2 mb-0'}>{variant.variantName}</p>
      <p className={'ml-auto mb-0'}>{subvariantsLength} size{subvariantsLength > 1 ? "s" : ""}</p>
      <i className={`fas fa-caret-${isOpen ? "up mt-1" : "down"} ml-1 mr-0`}/>
    </div>
    {isOpen && <div style={{marginLeft: '1.9rem'}} className={'mt-1'}>
      {variant.subvariants.edges.map(subvariant => {
        return <p id={subvariant.node.id} style={{paddingBlock: "0.375rem", margin: 0}}>
          {subvariant.node.secondary ? `${subvariant.node.name}/${subvariant.node.secondary}` : subvariant.node.name}
        </p>
      })}
    </div>
    }
  </div>
}

interface VariantsDropdownProps extends ButtonProps {
  variants: {
    totalCount: number,
    edges: {
      node: VariantType
    }[],
  },
  hasMissingData: boolean
}

function VariantsDropdown({variants, hasMissingData}: VariantsDropdownProps) {
  const options =[
    {header: true, label: "Colors & sizes"},
    {divider: true},
    ...variants.edges.map(edge => {
      return {
        label: <ColorVariantOption variant={edge.node}/>,
        value: edge.node.hexCode
      }
    })
  ]

  return <Dropdown
    currentValue={null}
    options={options}
    ToggleTag={({toggleDropdowns, isOpen}) => <VariantsDropdownTag
      totalCount={variants.totalCount}
      hexCodes={variants.edges.map(edge => edge.node.hexCode)}
      onClick={toggleDropdowns}
      isOpen={isOpen}
      hasMissingData={hasMissingData}/>}
    keepOpenAfterSelect
  />
}

function MissingDataInfo({missingData}: { missingData: string[] }) {
  const missingDataText = <p className={styles.missingValuesDropdownText}>{missingData.join(", ")}</p>

  return <Tooltip text={missingDataText}
                  mode={"warning"}>
    <div className={'d-flex align-items-center'}>
      <i className={`fa-solid fa-circle-exclamation ${styles.missingValuesIcon}`}/>
      <p className={styles.missingValuesText}>This product is missing data</p>
    </div>
  </Tooltip>
}

ProductRowLayout.Image = Image;
ProductRowLayout.VariantsDropdown = VariantsDropdown;
ProductRowLayout.MissingDataInfo = MissingDataInfo;

