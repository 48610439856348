/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DownloadOrdersModalQueryVariables = {};
export type DownloadOrdersModalQueryResponse = {
    readonly listDistributeDataTemplates: {
        readonly " $fragmentRefs": FragmentRefs<"SelectDdtContent_ddts">;
    } | null;
};
export type DownloadOrdersModalQuery = {
    readonly response: DownloadOrdersModalQueryResponse;
    readonly variables: DownloadOrdersModalQueryVariables;
};



/*
query DownloadOrdersModalQuery {
  listDistributeDataTemplates {
    ...SelectDdtContent_ddts
  }
}

fragment SelectDdtContent_ddts on DistributeDataTemplateNodeConnection {
  edges {
    node {
      id
      name
      engine
      isGlossaryMapped
      outputType
      orderConfirmationConfigs {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadOrdersModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNodeConnection",
        "kind": "LinkedField",
        "name": "listDistributeDataTemplates",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectDdtContent_ddts"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DownloadOrdersModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNodeConnection",
        "kind": "LinkedField",
        "name": "listDistributeDataTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataTemplateNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataTemplateNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "engine",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGlossaryMapped",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "outputType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreConnection",
                    "kind": "LinkedField",
                    "name": "orderConfirmationConfigs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Stores",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "156fe95c221f84a32bd24dbfec9a1547",
    "id": null,
    "metadata": {},
    "name": "DownloadOrdersModalQuery",
    "operationKind": "query",
    "text": "query DownloadOrdersModalQuery {\n  listDistributeDataTemplates {\n    ...SelectDdtContent_ddts\n  }\n}\n\nfragment SelectDdtContent_ddts on DistributeDataTemplateNodeConnection {\n  edges {\n    node {\n      id\n      name\n      engine\n      isGlossaryMapped\n      outputType\n      orderConfirmationConfigs {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f93fe35c98b830e1e95af32ea06574c3';
export default node;
