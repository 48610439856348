import React from "react";
import {graphql} from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Loading from "../../../../atoms/Loading";
import {PollingQueryRenderer} from "../../../../general/graphql/PollingQueryRenderer";
import {LoadingContentQuery} from "./__generated__/LoadingContentQuery.graphql";


const query = graphql`
  query LoadingContentQuery($outputProcessId: ID!) {
    getDistributeDataOutputProcess(id: $outputProcessId) {
      id
      state
      errorMessage
      output {
        id
        url

      }
    }
  }
`

type LoadingContentProps = {
  outputProcessId: string | null;
  setError: (errMessage: string) => void;
  setResult: (result: string) => void;
  environment: RelayModernEnvironment
}

export default function LoadingContent({outputProcessId, setResult, setError, environment}: LoadingContentProps) {
  return <div className={'px-3 pb-3'}>
    <p className={'text-center my-3'} style={{fontSize: '0.875rem', fontWeight: 400}}>
      The file is being populated with the missing product data.
    </p>
    <div className="px-3">
      {outputProcessId ? <PollingQueryRenderer<LoadingContentQuery>
        environment={environment}
        query={query}
        variables={{outputProcessId}}
        delay={1000}
        onError={err => {

        }}
        onData={data => {
          let state = data.getDistributeDataOutputProcess?.state;
          if (state === 'FAILED') {
            setError(data.getDistributeDataOutputProcess?.errorMessage || 'Unknown error');
            return
          }
          if (state === 'FINISHED') {
            if (!data.getDistributeDataOutputProcess?.output?.url) {
              setError('No valid download URL returned');
              return;
            }
            setResult(data.getDistributeDataOutputProcess.output.url);
            return;
          }
          if (state === 'STARTED' || state === 'RUNNING') {
            return;
          }
          setError(`Unknown state returned: ${state}`);
        }}
      >
        <Loading/>
      </PollingQueryRenderer> : <p>Something went wrong. Please try again.</p>}
    </div>
  </div>
}
