/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FashionProductList_products = {
    readonly totalCount: number | null;
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly collectionName: string | null;
            readonly unifiedproductSet: {
                readonly totalCount: number | null;
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly colorName: string | null;
                        readonly colorCode: string | null;
                        readonly size: string | null;
                    } | null;
                } | null>;
            } | null;
            readonly " $fragmentRefs": FragmentRefs<"FashionProductItem_product">;
        } | null;
    } | null>;
    readonly " $refType": "FashionProductList_products";
};
export type FashionProductList_products$data = FashionProductList_products;
export type FashionProductList_products$key = {
    readonly " $data"?: FashionProductList_products$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FashionProductList_products">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FashionProductList_products",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DisplayProductNodeManualEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DisplayProductNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "collectionName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DisplayUnifiedProductNodeConnection",
              "kind": "LinkedField",
              "name": "unifiedproductSet",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DisplayUnifiedProductNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DisplayUnifiedProductNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "colorName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "colorCode",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "size",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FashionProductItem_product"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DisplayProductNodeManualConnection",
  "abstractKey": null
};
})();
(node as any).hash = 'b2c5d1170cb182d36d921c9b74211fc8';
export default node;
