import React from 'react';
import {Container, Row} from 'reactstrap';
import {Link} from "react-router-dom";

const NoContentYet = ({header, paragraph, shadow = true, icon = 'fa-clipboard', linkText, linkLocation}) => {
  return (
      <div className={`${shadow ? 'shadow-sm' : ''} card no-content card-spaced`}>
        <div>
          <Container className={"text-family-lato"}>
            <Row className={"d-flex justify-content-center"}>
              <div className={"rounded-background rounded-outer d-flex justify-content-center"}>
                <div className={"rounded-background rounded-inner d-flex justify-content-center"}>
                  <i className={`fas ${icon} faded-icon`}/>
                </div>
              </div>
            </Row>
            <Row className={"d-flex justify-content-center"}>
              <h5 className="display-5 text-light-gray">{header}</h5>
            </Row>
            <Row className={"d-flex justify-content-center"}>
              <p className="lead text-light-gray">{paragraph}</p>

            </Row>
            <Row className="d-flex justify-content-center text-decoration-underline">
              {linkText && <p><Link to={linkLocation}>{linkText}</Link></p>}
            </Row>

          </Container>

        </div>
      </div>
  );
};

export default NoContentYet;
