import React, {useContext} from "react";
import {Alert, Card, Col, Container, Row} from "reactstrap";
import MidButton from "../../general/MidButton";

function Content({message, children}) {
  return <div className={"d-grid justify-content-center py-3"}>
    <Row className={'justify-content-center'}>
      <p>{message}</p>
    </Row>
    <Row className={'justify-content-center '}>
      {children}
    </Row>
  </div>
}

export default function AuthenticationFrame({integrationContext}) {
  const integrationDetails = useContext(integrationContext)

  return <div className="app">
    <div className="app-body">
      <main className="main">
        <Container fluid className={'mt-3'}>
          <div className="animated fadeIn mt-4">
            <Row>
              <Col className={'d-flex justify-content-center'}>
                <Card className="card-shadow" style={{width: "728px"}}>
                  {integrationDetails.authenticated ?
                    <Content message={"You are authenticated. Please proceed to the Vuuh Product Page"}>
                      <MidButton onClick={integrationDetails.handleRevokeAuthentication}
                                 loading={integrationDetails.isLoading}>
                        Revoke Authentication!
                      </MidButton>
                    </Content> :
                    <Content message={"Your shop is registered but you must authenticate"}>
                      <MidButton onClick={integrationDetails.handleTriggerAuthentication}
                                 loading={integrationDetails.isLoading}>
                        Authenticate now!
                      </MidButton>
                    </Content>
                  }
                  {integrationDetails.error && <Alert color={'danger'}>{integrationDetails.error}</Alert>}
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </main>
    </div>
  </div>
}

