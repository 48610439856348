import React, {useState} from "react";
import Dropdown from "../../../dataInput/Dropdown";
import styles from "../OrderConfirmationUploadWizardModal.module.scss";


export default function ChooseDDTStep({ddts, onDdtChange, toggle}) {
  const [currentValue, setCurrentValue] = useState(null);

  return <div className={styles.stepChooseDdt}>
    <div className={styles.dottedContainer}>
      <h5 className={'mb-3 font-weight-bold'}>Order confirmation for</h5>
      <Dropdown
        value={currentValue}
        options={ddts.map(item => {
          return {value: item.id, label: item.name, disabled: item.disabled}
        })}
        onChange={setCurrentValue}
      />
      {currentValue &&
      <span className={styles.textButtonUnderline + " " + styles.continueTextButtonUnderline}>
        <button className={styles.continueTextButton}
                style={{border: 'none', background: 'none'}}
                onClick={() => {
                  onDdtChange(currentValue)
                }}
                data-testid={"orders-button-add-oc-continue"}>
          Continue
        </button>
      </span>}
    </div>
  </div>
}
