import React from "react";
import propTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {error: error};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({error: error, errorInfo: errorInfo})
  }

  render() {
    if (this.state.error) {
      return <div color={'danger'} style={{borderRadius: "8px"}}
                  className={'error-boundary d-flex flex-column justify-content-center align-items-center ' + this.props.className}>
        <h3>An error occurred while displaying this.</h3> <br/>
        <p>{this.state.error.toString()}</p>
      </div>
    }
    return this.props.children || null;
  }
}

ErrorBoundary.propTypes = {
  onCaughtError: propTypes.func
}


export default ErrorBoundary;
