import {Row} from "reactstrap";
import React from "react";

type ErrorModalContentProps = {
  error: string | Error | null,
  toggle: () => void,
  isFromOrders?: boolean
}

export default function ErrorModalContent({error, toggle, isFromOrders = false}: ErrorModalContentProps) {
  return <div className={"m-4 px-4 pt-2"}>
    <p>Unable to {isFromOrders ? 'download' : 'load'} the file. The server responded with: </p>
    <p className={'text-danger'}>{error || "Something went wrong!"} </p>
    <Row className={"m-0 py-3"}>
      <p onClick={toggle} className={"text-primary"} style={{cursor: "pointer"}}>
        Back to {isFromOrders ? 'Orders' : 'Products'}
      </p>
    </Row>
  </div>;
}
