/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DistributeDataMappingConfigUseSystem = "JMESPATH" | "V2_CONFIG" | "%future added value";
export type UpdateDistributeDataMappingConfigMutationInput = {
    id: string;
    idProductTemplate?: string | null | undefined;
    idDistributeDataTemplate?: string | null | undefined;
    isActive?: boolean | null | undefined;
    jmespathConvExp?: string | null | undefined;
    useSystem?: string | null | undefined;
    config?: unknown | null | undefined;
    configBeforeVariant?: unknown | null | undefined;
    skipFirstVariant?: boolean | null | undefined;
    sheetName?: string | null | undefined;
    headerIndex?: string | null | undefined;
    startWritingFromRow?: string | null | undefined;
    orderConfirmations?: Array<DDTConfigOrderConfirmationConfigInput | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type DDTConfigOrderConfirmationConfigInput = {
    name: string;
};
export type UpdateDdtMappingConfigMutationVariables = {
    input: UpdateDistributeDataMappingConfigMutationInput;
};
export type UpdateDdtMappingConfigMutationResponse = {
    readonly updateDistributeDataMappingConfig: {
        readonly instance: {
            readonly id: string;
            readonly useSystem: DistributeDataMappingConfigUseSystem;
            readonly config: unknown | null;
            readonly configBeforeVariant: unknown | null;
            readonly isActive: boolean;
            readonly skipFirstVariant: boolean;
        } | null;
    } | null;
};
export type UpdateDdtMappingConfigMutation = {
    readonly response: UpdateDdtMappingConfigMutationResponse;
    readonly variables: UpdateDdtMappingConfigMutationVariables;
};



/*
mutation UpdateDdtMappingConfigMutation(
  $input: UpdateDistributeDataMappingConfigMutationInput!
) {
  updateDistributeDataMappingConfig(input: $input) {
    instance {
      id
      useSystem
      config
      configBeforeVariant
      isActive
      skipFirstVariant
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDistributeDataMappingConfigMutationPayload",
    "kind": "LinkedField",
    "name": "updateDistributeDataMappingConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataMappingConfigNode",
        "kind": "LinkedField",
        "name": "instance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "useSystem",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "configBeforeVariant",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipFirstVariant",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDdtMappingConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDdtMappingConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bee2bb863d19907a6d03b66b3d54c9e4",
    "id": null,
    "metadata": {},
    "name": "UpdateDdtMappingConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDdtMappingConfigMutation(\n  $input: UpdateDistributeDataMappingConfigMutationInput!\n) {\n  updateDistributeDataMappingConfig(input: $input) {\n    instance {\n      id\n      useSystem\n      config\n      configBeforeVariant\n      isActive\n      skipFirstVariant\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aa56abcc5ed55f6094d847006d3f26fe';
export default node;
