import React from "react";
import CustomTextRule from "./token/rules/customText/CustomTextRule";
import RemoveCharactersRule from "./token/rules/removeCharacters/RemoveCharactersRule";
import RemoveSectionRule from "./token/rules/removeSection/RemoveSectionRule";
import DefaultRule from "./token/rules/default/DefaultRule";
import ReplaceCharactersRule from "./token/rules/replaceCharacters/ReplaceCharactersRule";
import {TextCaseRule} from "./token/rules/textCase/TextCaseRule";
import NumericFormatRule from "./token/rules/numericFormat/NumericFormatRule";
import FirstAvailableRule from "./token/rules/firstAvailable/FirstAvailableRule";
import CustomizeValueBrandRule from "./token/rules/customizeValue/CustomizeValueBrandRule";
import {ConditionalRule} from "./token/rules/setConditionalRules/ConditionalRule";
import ReduceContentRule from "./token/rules/reduceContent/ReduceContentRule";
import DateDeltaRule from "./token/rules/dateDelta/DateDeltaRule";
import RoundPeriodRule from "./token/rules/roundPeriod/RoundPeriodRule";
import {GeneralRulesTypes} from "../types";

export function RuleNotKnown({type}: {type: string}) {
  return <strong className="text-muted">Rule not known: {type}</strong>
}

type RuleInfoType = {
  id: string,
  icon: React.ComponentType | string,
  component: React.ComponentType<{[key: string]: any}>,
}

class Rules {
  /**
   * Each such "Rule definition object" contains a bundle of information related to a single rule type.
   * We share the rules between the token and brand rule set, so we don't have to re-implement the logic.
   *
   * Each such bundle must contain:
   *
   * - `id`: The "id" of the bundle, used internally and is required to be contained inside the configuration, to
   *   be able to determine which rule we are rendering
   * - `icon`: The font awesome icon we use in the dropdown (when adding a rule).
   * - `component`: a Component({...ruleData, onChange(newData), productFieldsProvider}) that will be used to
   *   render the rule as a token rule (inline)
   */
  REMOVE_CHARACTERS_RULE = {
    id: GeneralRulesTypes.RemoveCharactersRule,
    icon: <i className={"fa-light fa-fw fa-text-slash"} />,
    component: RemoveCharactersRule,
  }
  REMOVE_SECTION_RULE = {
    id: GeneralRulesTypes.RemoveSectionRule,
    icon: <i className={"fa-light fa-fw fa-ban"} />,
    component: RemoveSectionRule,
  }
  DEFAULT_RULE = {
    id: 'DefaultRule',
    icon: 'fa-star-half-alt',
    component: DefaultRule,
  }
  REPLACE_CHARACTERS_RULE = {
    id: GeneralRulesTypes.ReplaceCharactersRule,
    icon: <i className={'fa-light fa-fw fa-exchange-alt'} />,
    component: ReplaceCharactersRule,
  }
  TEXT_CASE_RULE = {
    id: GeneralRulesTypes.TextCaseRule,
    icon: <i className={"fa-light fa-fw fa-font-case"} />,
    component: TextCaseRule,
  }
  NUMERIC_FORMAT_RULE = {
    id: GeneralRulesTypes.NumericFormatRule,
    icon: 'fa-solid fa-input-numeric',
    component: NumericFormatRule,
  }
  FIRST_AVAILABLE_RULE = {
    id: GeneralRulesTypes.FirstAvailableRule,
    icon: <i className={"fa-light fa-fw fa-circle"} />,
    component: FirstAvailableRule,
  }
  CUSTOM_TEXT_RULE = {
    id: GeneralRulesTypes.CustomTextRule,
    icon: <i className={"fa-light fa-fw fa-text"} />,
    component: CustomTextRule,
  }
  CUSTOMIZE_VALUES_RULE = {
    id: 'CustomizeValuesRule',
    icon: 'fa-exchange-alt',
    component: CustomizeValueBrandRule,
  }
  CONDITIONAL_RULE = {
    id: GeneralRulesTypes.ConditionalRule,
    icon: <i className="fa-light fa-fw fa-star-of-life"/>,
    component: ConditionalRule
  }
  REDUCE_CONTENT_RULE = {
    id: 'ReduceContentRule',
    icon: <i className={"fa-light fa-fw fa-text-slash"} />,
    component: ReduceContentRule
  }
  DATE_DELTA_RULE = {
    id: "DateDeltaRule",
    icon: <i className={"fa-light fa-fw fa-calendar-plus"} />,
    component: DateDeltaRule
  }
  ROUND_PERIOD_RULE = {
    id: "RoundPeriodRule",
    icon: <i className={"fa-light fa-fw fa-calendar-lines"} />,
    component: RoundPeriodRule
  }
  rules = [
    'REMOVE_CHARACTERS_RULE',
    'REMOVE_SECTION_RULE',
    'DEFAULT_RULE',
    'REPLACE_CHARACTERS_RULE',
    'TEXT_CASE_RULE',
    'NUMERIC_FORMAT_RULE',
    'FIRST_AVAILABLE_RULE',
    'CUSTOM_TEXT_RULE',
    'CUSTOMIZE_VALUES_RULE',
    'CONDITIONAL_RULE',
    'REDUCE_CONTENT_RULE',
    'DATE_DELTA_RULE',
    'ROUND_PERIOD_RULE'
  ]

  getRuleComponent = (type: string) => {
    for (let rname of this.rules) {
      const rule = this[rname as keyof typeof RULES] as RuleInfoType;

      if (rule.id === type) {
        return rule.component
      }
    }
    return this.getUnknownRuleInfo().component
  }

  getRuleInfo = (type: string) => {
    for (let rname of this.rules) {
      const rule = this[rname as keyof typeof RULES] as RuleInfoType;

      if (rule.id === type) {
        return rule;
      }
    }
    return this.getUnknownRuleInfo()
  }

  getUnknownRuleInfo = () => {
    return {
      id: 'UnknownRule',
      icon: null,
      component: RuleNotKnown,
    }
  }
}


const RULES = new Rules();
export default RULES;
