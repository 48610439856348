import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";
import {UpdateDdtImageNamingFormatMutation} from "./__generated__/UpdateDdtImageNamingFormatMutation.graphql";


const mutation = graphql`
  mutation UpdateDdtImageNamingFormatMutation($input: UpdateDDTImagesNamingFormatInput!) {
    updateDdtImageNamingFormat(input: $input) {
      ddt {
        id
        imagesNamingFormat
        imageDimensions
      }
    }
  }
`;

export default createMutationBoilerplate<UpdateDdtImageNamingFormatMutation>(mutation);
