/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderConfirmationUploadWizardModalQueryVariables = {};
export type OrderConfirmationUploadWizardModalQueryResponse = {
    readonly listDistributeDataTemplates: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly isGlossaryMapped: boolean | null;
                readonly orderConfirmationConfigs: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly name: string;
                            readonly id: string | null;
                        } | null;
                    } | null>;
                } | null;
            } | null;
        } | null>;
    } | null;
};
export type OrderConfirmationUploadWizardModalQuery = {
    readonly response: OrderConfirmationUploadWizardModalQueryResponse;
    readonly variables: OrderConfirmationUploadWizardModalQueryVariables;
};



/*
query OrderConfirmationUploadWizardModalQuery {
  listDistributeDataTemplates(isActive: true) {
    edges {
      node {
        id
        name
        isGlossaryMapped
        orderConfirmationConfigs {
          edges {
            node {
              name
              id
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "isActive",
        "value": true
      }
    ],
    "concreteType": "DistributeDataTemplateNodeConnection",
    "kind": "LinkedField",
    "name": "listDistributeDataTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataTemplateNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGlossaryMapped",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreConnection",
                "kind": "LinkedField",
                "name": "orderConfirmationConfigs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoreEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stores",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "listDistributeDataTemplates(isActive:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderConfirmationUploadWizardModalQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrderConfirmationUploadWizardModalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "66abe4c34b0560f6a41e8fa5acb0624d",
    "id": null,
    "metadata": {},
    "name": "OrderConfirmationUploadWizardModalQuery",
    "operationKind": "query",
    "text": "query OrderConfirmationUploadWizardModalQuery {\n  listDistributeDataTemplates(isActive: true) {\n    edges {\n      node {\n        id\n        name\n        isGlossaryMapped\n        orderConfirmationConfigs {\n          edges {\n            node {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2d4f5ddb9b1397575c1cf9a4fb95cdd9';
export default node;
