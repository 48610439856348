import React, {useState, createContext, useContext} from "react";
import styles from './Tabs.module.scss'
import addClassNames from "../classNameUtils";

interface TabsProps extends React.ComponentPropsWithoutRef<"div"> {
  active: string
}

interface TabProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
}

interface Context {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

const TabContext = createContext<Context>({
  activeTab: '',
  setActiveTab: () => {
  }
})

export function Tabs(props: TabsProps): JSX.Element {
  const {active, children, className, onClick, ...rest} = props;
  const [activeTab, setActiveTab] = useState(active)


  const classNames = [
    {className: 'd-inline-block', condition: true},
    {className: styles.tabsContainer, condition: true},
    {className: className, condition: className !== undefined}
  ]

  return <div className={addClassNames(classNames)} {...rest}>
    <TabContext.Provider value={{activeTab, setActiveTab}}>
      {children}
    </TabContext.Provider>
  </div>
}

export function Tab(props: TabProps): JSX.Element {
  const {name, disabled = false, children, className, onClick, ...rest} = props;
  const {activeTab, setActiveTab} = useContext(TabContext)
  const classNames = [
    {className: 'd-inline-flex align-items-center px-3', condition: true},
    {className: styles.activeTab, condition: name === activeTab},
    {className: styles.inactiveTab, condition: name !== activeTab},
    {className: styles.disabledTab, condition: disabled},
    {className: className, condition: className !== undefined}
  ]

  return <div className={addClassNames(classNames)}
              onClick={e => {
                if (disabled) {
                  return;
                }
                if (onClick) {
                  setActiveTab(name);
                  onClick(e)
                }
              }}
              {...rest}>
    {children}
  </div>
}

