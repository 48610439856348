import React from "react";
import styles from './Rule.module.scss';
import RULES from "./rules";
import {BrandRuleExceptions} from "./token/BrandRuleExceptions";
import {ProductFieldsProvider} from "../utilities";
import {GeneralTokenRule, TokenRuleException} from "../types";

/**
 * This component renders a Rule, no matter which one is it. It gets the data raw from the output config
 * JSON with some contextual data, and makes the decision based on them to render its children (the actual
 * rule renderings). It just makes the decision and acts as an adapter between the parent and the actual
 * rule components
 *
 * @param type: The ID of the rule. See RULES.[rule_obj].id
 * @param isBrandRule: if the rule is a brand rule (necessary to figure out its width. Brand rules are rendered
 *                     on the whole width, while token rules are rendered inline.
 * @param fieldDisplay: The display of the current token being edited.
 * @param onChange: Callback that updates upstream any change occurred on this rule
 * @param onRemove: Callback that notifies that the user removed the rule
 * @param productFieldsProvider: A ProductFieldsProvider instance that indexes and provides easy access
 *                               to the fields of the current product template.
 * @param uniqueValueIndex: a UniqueValuesIndex instance that provides easy access to the current product's referenced
 *                          unique value sets and unique values
 * @param extra: the raw data of the rule (eg. for TextCaseRule - transform, for DefaultRule: defaultValue)
 * @returns {JSX.Element}
 * @constructor
 */

type RuleType = {
  type: string,
  isBrandRule?: boolean,
  fieldDisplay: string | null,
  onChange: (newRule: GeneralTokenRule) => void,
  onRemove: () => void,
  productFieldsProvider: ProductFieldsProvider,
  exceptions?: TokenRuleException[]
}

export default function Rule({
                               type,
                               isBrandRule = false,
                               fieldDisplay,
                               onChange,
                               onRemove,
                               productFieldsProvider,
                               ...extra
                             }: RuleType) {
  const onChangeCallback = (newRuleValues: Omit<GeneralTokenRule, 'type'>) => {
    onChange({type, ...extra, ...newRuleValues} as GeneralTokenRule);
  }

  let ruleInfo = RULES.getRuleInfo(type);

  let RuleComponent = ruleInfo.component;

  const ruleComponent = <RuleComponent type={type}
                                       onChange={onChangeCallback}
                                       productFieldsProvider={productFieldsProvider}
                                       onRemove={onRemove} fieldDisplay={fieldDisplay}
                                       {...extra}/>

  return <div className={styles.rule}
              style={{
                width: isBrandRule ? '100%' : 'unset'
              }}>
    {ruleComponent}
    <BrandRuleExceptions
      exceptions={extra.exceptions || []}
      productFieldsProvider={productFieldsProvider}
      onChange={(excList: TokenRuleException[]) => {
        onChangeCallback({exceptions: excList});
      }}/>
  </div>
}
