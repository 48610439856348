import React, {useState} from 'react';
import GrayModal from "../../../dataDisplay/GrayModal";
import {createFragmentContainer} from "react-relay";
import {graphql} from "graphql";
import AcknowledgeOrderErrors from "../../../mutations/orders/AcknowledgeOrderErrors";
import {ErrorAlert} from "../../../commons/errors";


function hasBatchErrors(batch) {
  return batch.error || batch.errors.length > 0;
}

export class ErrorAggregator {
  constructor(error, errors) {
    this.error = error
    this.errors = this.aggregateByStyleNumber(errors)
    this.missingStyleNumbers = []
    this.extraStyleNumbers = []
  }

  aggregateByStyleNumber(errors) {
    if (!errors) {
      return {}
    }
    let byStyleNumbers = {}
    for (let err of errors) {
      let {reason, variant, product} = err;
      if (reason === 'no_unified_product') {
        if (!byStyleNumbers.hasOwnProperty(product.style_number)) {
          byStyleNumbers[product.style_number] = {}
        }
        if (!byStyleNumbers[product.style_number].hasOwnProperty(variant.color)) {
          byStyleNumbers[product.style_number][variant.color] = []
        }
        byStyleNumbers[product.style_number][variant.color].push(
          variant.secondary_size ? `${variant.size} / ${variant.secondary_size}` : variant.size
        );
      } else if (reason === 'no_style_number') {
        byStyleNumbers[product.style_number] = {};
      }
    }
    return byStyleNumbers;
  }

  addMissingProducts(styleNumbers) {
    this.missingStyleNumbers = [...styleNumbers];
  }

  addExtraProducts(styleNumbers) {
    this.extraStyleNumbers = [...styleNumbers];
  }
}

export function getProductName(styleNumber, errors) {
  for (let err of errors) {
    if (err.product.style_number === styleNumber) {
      return err.product.name;
    }
  }
  return '';
}


export function getCompanyNameFromOrder(order, manualOrder) {
  if (order && order.fromBrand) {
    return order.fromBrand.name
  }
  if (manualOrder && manualOrder.fromBrand) {
    return manualOrder.fromBrand.name
  }
  return 'the brand';
}

function hasOrderErrors(order) {
  if (!order) {
    return null;
  }
  return order.orderConfirmationExtraProducts.edges.length + order.orderConfirmationMissingProducts.edges.length > 0
}


function RootLevelError({errors}) {
  return errors.error && <p>{errors.error}</p>;
}


export function NotFoundProductsErrors({errors, originalErrors}) {
  if (Object.keys(errors.errors).length === 0) {
    return null;
  }
  return <>
    <p>{Object.keys(errors.errors).length} products could not be found in the brand's collections</p>
    <ul>
      {Object.keys(errors.errors).map(styleNumber => {
        return <li key={styleNumber}>
          {styleNumber} - {getProductName(styleNumber, originalErrors)}
          <ul className={'text-muted'}>
            {Object.keys(errors.errors[styleNumber]).map(color => {
              return <li key={color}>
                {color}: {errors.errors[styleNumber][color].join(', ')}
              </li>
            })}
          </ul>
        </li>
      })}
    </ul>
  </>
}

function ExtraProductsError({styleNumbers}) {
  if (styleNumbers.length === 0) {
    return null;
  }
  return <>
    <p>These products are not in the Product Catalogue</p>
    <ul>
      {styleNumbers.map(s => <li key={s}>{s}</li>)}
    </ul>
  </>
}

function MissingProductsError({styleNumbers}) {
  if (styleNumbers.length === 0) {
    return null;
  }
  return <>
    <p>These products are not in the Order Confirmation</p>
    <ul>
      {styleNumbers.map(s => <li key={s}>{s}</li>)}
    </ul>
  </>
}

function ErrorsDisplay({errors, originalErrors, company}) {
  return <>
    <p>Please ask <strong>{company}</strong> to update their product data to fix this.</p>

    <RootLevelError errors={errors}/>
    <NotFoundProductsErrors errors={errors} originalErrors={originalErrors}/>
    <ExtraProductsError styleNumbers={errors.extraStyleNumbers}/>
    <MissingProductsError styleNumbers={errors.missingStyleNumbers}/>
  </>;
}

function ErrorsAcknowledgementsModal({isOpen, toggle, batch, order, manualOrder, relay}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);


  if ((!batch || !hasBatchErrors(batch)) && !hasOrderErrors(order)) {
    return null;
  }
  let errors = new ErrorAggregator(batch.error, batch.errors);
  if (order) {
    errors.addMissingProducts(new Set(order.orderConfirmationMissingProducts.edges.map(e => e.node.styleNumber)));
    errors.addExtraProducts(new Set(order.orderConfirmationExtraProducts.edges.map(e => e.node.styleNumber)));
  }

  return <GrayModal
    size={'lg'}
    isOpen={isOpen}
    toggle={toggle}
    title={'Some errors have occurred'}
    headerBg={'warning'}
    primaryHeaderButton={{
      size: 'sm',
      text: 'I Understand',
      isDisabled: isLoading,
      onClick: () => {
        setIsLoading(true);
        AcknowledgeOrderErrors(
          relay.environment,
          {order: order ? order.id : manualOrder.id},
          resp => {
            setIsLoading(false);
            toggle();
          },
          err => {
            setIsLoading(false);
            setError(err)
          }
        )
      }
    }}
    secondaryHeaderButton={{
      size: 'sm',
      text: 'Hide for now',
      onClick: toggle
    }}
    bodyContent={<div className="py-3 px-5">
      {/* backend error */}
      <ErrorAlert error={error}/>

      <ErrorsDisplay errors={errors}
                     originalErrors={batch.errors}
                     company={getCompanyNameFromOrder(order, manualOrder)}/>
    </div>}
  />
}


export default createFragmentContainer(
  ErrorsAcknowledgementsModal,
  {
    batch: graphql`
      fragment ErrorsAcknowledgementsModal_batch on OrderConfirmationBatchNode {
        brand {
          id
          name
        }
        error
        errors {
          product
          reason
          variant
        }
      }
    `,
    order: graphql`
      fragment ErrorsAcknowledgementsModal_order on OrderNode {
        id
        orderConfirmationExtraProducts {
          edges {
            node {
              id
              styleNumber
            }
          }
        }
        orderConfirmationMissingProducts {
          edges {
            node {
              id
              styleNumber
            }
          }
        }
        fromBrand {
          id
          name
        }
      }
    `,
    manualOrder: graphql`
      fragment ErrorsAcknowledgementsModal_manualOrder on ManuallyUploadedOrderNode {
        id
        fromBrand {
          id
          name
        }
      }
    `
  }
)



