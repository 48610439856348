/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DistributeDataMappingConfigUseSystem = "JMESPATH" | "V2_CONFIG" | "%future added value";
export type ModalContent_ddt = {
    readonly id: string;
    readonly name: string;
    readonly schema?: unknown | null | undefined;
    readonly ordering?: ReadonlyArray<string> | undefined;
    readonly imagesNamingFormat?: unknown | null | undefined;
    readonly schemaFieldsForImage?: ReadonlyArray<{
        readonly name: string | null;
        readonly category: string | null;
        readonly path: string | null;
        readonly type: ReadonlyArray<string | null> | null;
    } | null> | null | undefined;
    readonly distributedatamappingconfigSet: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly isActive: boolean;
                readonly config?: unknown | null | undefined;
                readonly configBeforeVariant: unknown | null;
                readonly useSystem: DistributeDataMappingConfigUseSystem;
                readonly sheetName: string | null;
                readonly skipFirstVariant: boolean;
                readonly usedUniqueValues: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly value: string;
                            readonly jsonSchemaValue: string | null;
                        } | null;
                    } | null>;
                } | null;
                readonly productTemplate: {
                    readonly id: string;
                } | null;
            } | null;
        } | null>;
    };
    readonly " $refType": "ModalContent_ddt";
};
export type ModalContent_ddt$data = ModalContent_ddt;
export type ModalContent_ddt$key = {
    readonly " $data"?: ModalContent_ddt$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ModalContent_ddt">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "configureImageNaming"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModalContent_ddt",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DistributeDataMappingConfigNodeConnection",
      "kind": "LinkedField",
      "name": "distributedatamappingconfigSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DistributeDataMappingConfigNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DistributeDataMappingConfigNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isActive",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configBeforeVariant",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useSystem",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sheetName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "skipFirstVariant",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UsedUniqueValueConnection",
                  "kind": "LinkedField",
                  "name": "usedUniqueValues",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UsedUniqueValueEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UniqueValueNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "jsonSchemaValue",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductTemplateNode",
                  "kind": "LinkedField",
                  "name": "productTemplate",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "condition": "configureImageNaming",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "config",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "configureImageNaming",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schema",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordering",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "configureImageNaming",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imagesNamingFormat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductTemplateField",
          "kind": "LinkedField",
          "name": "schemaFieldsForImage",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "DistributeDataTemplateNode",
  "abstractKey": null
};
})();
(node as any).hash = 'd2eacafcc4c546cf7efabff91499acf4';
export default node;
