import React from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {RuleIcon} from "../../../icons/RuleIcon";
import Dropdown from "../../../../../../../atoms/Dropdown";
import Input from "../../../../../../../atoms/Input";
import {useDDTMappingConfigFormContext} from "../../../../DDTMappingConfigFormContext";
import UniqueValueSingleSelectDropdown from "../../../dropdowns/UniqueValueSingleSelectDropdown";
import {ProductFieldsProvider} from "../../../../utilities";
import {OperatorType, TokenRuleException} from "../../../../types";

const ruleComparatorOptions = [
  {label: 'is', value: 'eq'},
  {label: 'is not', value: 'neq'}
]

type RuleExceptionProps = {
  field: string | null,
  value: string | null,
  op: OperatorType,
  onChange: (token: TokenRuleException) => void,
  onRemove: () => void,
  productFieldsProvider: ProductFieldsProvider,
}

export default function RuleException({field, value, op, onChange, onRemove, productFieldsProvider}: RuleExceptionProps) {
  let {getUniqueValueDetailsByUniqueValue} = useDDTMappingConfigFormContext()
  let selectedField = null;
  let selectedFieldUniqueValueSet = false;
  if (field) {
    selectedField = productFieldsProvider.getByPath(field);
    const selectedFieldUniqueValueSetKey = selectedField?.type?.filter(t => t.startsWith('$$'))[0] || null;
    if (selectedFieldUniqueValueSetKey) {
      selectedFieldUniqueValueSet = true
    }
  }
  return <Row className={'mx-0'}>
    <Col className={'d-flex flex-row align-items-center mt-4 px-3'}>
      <RuleIcon src={'fa-asterisk'}/>
      <span className={'mx-2'}>Except when</span>
      <Dropdown className={'mx-2'}
                options={productFieldsProvider.getDropdownOptions()}
                placeholder={'Select data point'}
                currentValue={selectedField ? {value: selectedField.path, label: selectedField.name} : null}
                onSelectOption={selectedOption => {
                  onChange({field: selectedOption.value || null, value: '', op: 'eq'})
                }}/>

      {field && <Dropdown
        className={'mx-2'}
        options={ruleComparatorOptions}
        currentValue={ruleComparatorOptions.filter(option => option.value === op)[0]}
        onSelectOption={(selectedOption) => {
          onChange({field, value, op: (selectedOption.value || "eq") as OperatorType});
        }}
      />}

      {field && <div className={'mx-2'}>
        {
          selectedFieldUniqueValueSet
            ?
            <UniqueValueSingleSelectDropdown
              type={selectedField?.type?.filter(t => t.startsWith('$$')) || []}
              value={value && getUniqueValueDetailsByUniqueValue(value) ? getUniqueValueDetailsByUniqueValue(value)?.value : "select value..."}
              onOptionChange={option => {
                onChange({field, value: option.value || null, op})
              }}
              presentValues={[]}/>
            :
            <Input value={value || ""} onChange={e => {
              onChange({field, value: e.target.value, op})
            }}/>
        }
      </div>}

      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}
