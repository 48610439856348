/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UniqueValueRepresentationsDropdownQueryVariables = {
    type: string;
};
export type UniqueValueRepresentationsDropdownQueryResponse = {
    readonly getUniqueValueSet: {
        readonly id: string;
        readonly representations: ReadonlyArray<string>;
    } | null;
};
export type UniqueValueRepresentationsDropdownQuery = {
    readonly response: UniqueValueRepresentationsDropdownQueryResponse;
    readonly variables: UniqueValueRepresentationsDropdownQueryVariables;
};



/*
query UniqueValueRepresentationsDropdownQuery(
  $type: ID!
) {
  getUniqueValueSet(id: $type) {
    id
    representations
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "type"
      }
    ],
    "concreteType": "UniqueValueSetNode",
    "kind": "LinkedField",
    "name": "getUniqueValueSet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "representations",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UniqueValueRepresentationsDropdownQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UniqueValueRepresentationsDropdownQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9b84ece8baea39e1069f588a7d9cb3b",
    "id": null,
    "metadata": {},
    "name": "UniqueValueRepresentationsDropdownQuery",
    "operationKind": "query",
    "text": "query UniqueValueRepresentationsDropdownQuery(\n  $type: ID!\n) {\n  getUniqueValueSet(id: $type) {\n    id\n    representations\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ef4793270989a98d7cf1768e6662bf3b';
export default node;
