import {arraysHaveTheSameElements} from "./arrayUtils";

export function cloneObject<T extends object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function areObjectsEqual(o1: Record<string, any>, o2: Record<string, any>): boolean {
  if (o1 === null) {
    return o2 === null
  }
  if (o1 === undefined) {
    return o2 === undefined
  }

  if (typeof o1 === 'object' && Object.keys(o1).length === 0) {
    return Object.keys(o2).length === 0
  }

  if (typeof o1 === 'object' && !Array.isArray(o1) && Object.keys(o1).length > 0) {
    return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => areObjectsEqual(o1[p], o2[p]))
  } else {
    return Array.isArray(o1) && Array.isArray(o2)
      ? arraysHaveTheSameElements(o1, o2)
      : o1 === o2;
  }

}
