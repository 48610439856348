import React from "react";
import {CardBody, Row} from "reactstrap";
import styles from "./OrderTableLoadingState.module.scss"
import {SimpleCardHeader} from "../atoms/Card";

export default function OrderTableLoadingState() {
  return <>
    <SimpleCardHeader>
      <Row className={"mx-0 mb-2"}>
        <div className={"ml-auto mr-1 " + styles.buttonPlaceHolder} style={{width: "7rem"}}/>
        <div className={"ml-3 mr-1 " + styles.buttonPlaceHolder} style={{width: "7rem"}}/>
        <div className={"ml-3 " + styles.buttonPlaceHolder} style={{width: "7rem"}}/>
      </Row>
    </SimpleCardHeader>
    <CardBody>
      <Row className={"mx-0 px-1"}>
        <div className={styles.buttonPlaceHolder} style={{minWidth: "226px"}}/>
        <div className={"ml-auto"}>
          <div className={'pagination d-flex justify-content-end'}>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.buttonPlaceHolder}
                 style={{width: "32px"}}/>
            <div style={{width: "62px"}}/>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.buttonPlaceHolder}
                 style={{width: "32px"}}>
            </div>
          </div>
        </div>
      </Row>
      <div className={"py-2"}>
        <hr className={"my-4"} style={{width: "1450px"}}/>
      </div>
      <div className={"mb-3"}>
        <div className={styles.rowPlaceHolder}/>
        <div className={"mt-3 " + styles.rowPlaceHolder}/>
        <div className={"mt-3 " + styles.rowPlaceHolder}/>
        <div className={"mt-3 " + styles.rowPlaceHolder}/>
        <div className={"mt-3 " + styles.rowPlaceHolder}/>
      </div>
    </CardBody>
  </>
}
