import React, {useState} from "react";
import {Col, Container, Row} from "reactstrap";
import styles from "./ImageNumericValueSuffix.module.scss"
import {Suffix} from "../types";
import Checkbox from "../../../../atoms/Checkbox";
import {useClickOutside} from "../../../../commons/hooks";
import addClassNames from "../../../../classNameUtils";
import Button from "../../../../atoms/Button";
import TokenSeparator from "./tokenRepresentations/TokenSeparator";

type SuffixControllerProps = {
  suffix: Suffix,
  onSuffixChange: (suffix: Suffix) => void,
  onClickOutside: () => void
}

function isInputValid(isAlphabetic: boolean, value: string) {
  if (value.length === 0) {
    return false
  } else if (isAlphabetic) {
    return /^[a-zA-Z]+$/.test(value);
  } else {
    return !isNaN(Number(value))
  }
}

export function SuffixController({
                                   suffix,
                                   onSuffixChange,
                                   onClickOutside
                                 }: SuffixControllerProps) {
  const focusRef = useClickOutside(onClickOutside)
  let [isAlphabetic, setIsAlphabetic] = useState(isNaN(Number(suffix.value_start)))

  let doNotApplyLabelClasses = addClassNames([
    {className: styles.containerText + " p-0", condition: true},
    {className: styles.grayText + " p-0", condition: !suffix.only_for_same_value}
  ])

  return <div className={styles.suffixController} ref={focusRef}>
    <Container className={styles.dropDownMenu}>
      <Row noGutters style={{height: "3rem"}} className={"align-items-center"}>
              <span className={styles.containerText}>
                Value starts from
              </span>
        <input value={suffix.value_start}
               data-testid="value-input"
               onChange={e => {
                 let value = e.target.value.charAt(e.target.value.length - 1)
                 if (isInputValid(isAlphabetic, value)) {
                   onSuffixChange({...suffix, value_start: value})
                 }
               }}
               className={"my-2 " + styles.input}/>
      </Row>
      <Row noGutters>
        <hr className={styles.horizontalLine}/>
      </Row>
      <Row className={"align-items-center pl-1 mt-2 mb-1"} noGutters>
        <Checkbox checked={suffix.only_for_same_value}
                  className={"ml-2"}
                  data-testid="same-value-checkbox"
                  onClick={() => onSuffixChange({...suffix, only_for_same_value: !suffix.only_for_same_value})}/>
        <span className={styles.containerText + " p-0"}>
                Only apply to images with the same name
              </span>
      </Row>
      <Row className={"align-items-center pl-1 my-1 ml-4"} noGutters>
        <Checkbox checked={suffix.skip_first}
                  disabled={!suffix.only_for_same_value}
                  className={"ml-2"}
                  data-testid="skip-first-checkbox"
                  onClick={() => onSuffixChange({...suffix, skip_first: !suffix.skip_first})}/>
        <span className={doNotApplyLabelClasses}>
                Do not apply to the first image
              </span>
      </Row>
      <Row noGutters>
        <hr className={styles.horizontalLine}/>
      </Row>
      <Row className={"align-items-center pl-1 pt-2"} noGutters>
        <Checkbox checked={isAlphabetic}
                  className={"ml-2"}
                  data-testid="alphanumeric-checkbox"
                  onClick={() => {
                    if (isAlphabetic) {
                      setIsAlphabetic(false)
                      onSuffixChange({...suffix, value_start: "1"})
                    } else {
                      setIsAlphabetic(true)
                      onSuffixChange({...suffix, value_start: "a"})
                    }
                  }}/>
        <span className={styles.containerText + " p-0"}>
                Use alphabetic values
        </span>
      </Row>
    </Container>
  </div>
}

type ImageNumericValuesSuffixProps = {
  suffix: Suffix,
  onSuffixChange: (suffix: Suffix) => void,
  separatorValue: string
}

export default function ImageNumericValueSuffix({
                                                  suffix,
                                                  separatorValue,
                                                  onSuffixChange
                                                }: ImageNumericValuesSuffixProps) {
  const [isConsoleOpen, setIsConsoleOpen] = useState(false)
  const [isCursorOnNumericToken, setIsCursorOnNumericToken] = useState(false)

  return <Col className={styles.container}>
    <Row style={{width: "inherit"}} noGutters>
      <Col className={styles.suffixColumns + " ml-2 pl-1"}>
        <TokenSeparator string={separatorValue}
                        isOnShortRow={true}
                        onChange={(value: { string: string; }) => onSuffixChange({...suffix, separator: value.string})}/>
      </Col>
      <Col className={styles.suffixColumns}>
        <Button
          className={styles.numericToken}
          data-testid="numeric-token"
          onClick={() => setIsConsoleOpen(!isConsoleOpen)}
          onMouseEnter={() => setIsCursorOnNumericToken(true)}
          onMouseLeave={() => setIsCursorOnNumericToken(false)}>
          {isNaN(Number(suffix.value_start)) ? "Alphabetic value" : "Numeric value"}
        </Button>
        {isConsoleOpen && <SuffixController
          suffix={suffix}
          onSuffixChange={onSuffixChange}
          onClickOutside={() => {
            if (!isCursorOnNumericToken) {
              setIsConsoleOpen(false)
            }
          }}/>}
      </Col>
    </Row>
  </Col>
}
