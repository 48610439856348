import React from 'react';

export type ExampleFieldType = {
  [index: string]: string;
}

export type contextType = {
  productExample: ExampleFieldType,
  tokenExample: string | null,
  products: { id: string, name: string } [],
  onExampleProductSelect: (id: string) => void,
  refetchExampleProducts: (query: string, callback: () => void) => void,
}

const ExampleDataContext = React.createContext({} as contextType);

export default ExampleDataContext;


