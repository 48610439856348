import React from "react";
import SelectDdtContent, {DdtType} from "./DistributeDataModalContentComponents/SelectDdtContent";
import LoadingContent, {LoadingContentSpinner} from "./DistributeDataModalContentComponents/LoadingContent";
import ErrorModalContent from "./ErrorModalContent";
import OutputReadyModalContent from "./OutputReadyModalContent";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {steps} from "./DownloadButtonWithDdtModal";
import {DownloadButtonWithDdtModalQueryResponse} from "./__generated__/DownloadButtonWithDdtModalQuery.graphql";
import {ErrorAlert} from "../../commons/errors";
import LoadingModalContent from "./DistributeDataModalContentComponents/LoadingModalContent";

type DistributeDataModalContentControlProps = {
  toggle: () => void,
  currentStep: typeof steps[keyof typeof steps],
  setCurrentStep: (val: typeof steps[keyof typeof steps]) => void,
  ddts: DownloadButtonWithDdtModalQueryResponse["listDistributeDataTemplates"],
  selectedDdt: DdtType | null,
  setSelectedDdt: (val: DdtType) => void,
  downloadImages: boolean,
  setDownloadImages: (val: boolean) => void,
  mergeSameProducts: boolean,
  setMergeSameProducts: (val: boolean) => void,
  orderNumbers: string[],
  outputProcessId: string | null,
  setOutputProcessId: (val: string | null) => void,
  result: {id: string} | null,
  setResult: (val: {id: string}) => void,
  error: string | null,
  setError: (val: string) => void,
  environment: RelayModernEnvironment,
}

export default function DistributeDataModalContentControl({
                                                            toggle,
                                                            currentStep,
                                                            setCurrentStep,
                                                            ddts, // available DDTs to export to
                                                            selectedDdt,
                                                            setSelectedDdt,
                                                            downloadImages,
                                                            setDownloadImages,
                                                            mergeSameProducts,
                                                            setMergeSameProducts,
                                                            orderNumbers,
                                                            outputProcessId,
                                                            setOutputProcessId,
                                                            result,
                                                            setResult,
                                                            error,
                                                            setError,
                                                            environment,
                                                          }: DistributeDataModalContentControlProps) {
  switch (currentStep) {
    case steps.SELECTING_DDT:
      return <SelectDdtContent ddts={ddts!}
                               ddt={selectedDdt}
                               setDdt={setSelectedDdt}
                               downloadImages={downloadImages}
                               setDownloadImages={setDownloadImages}
                               mergeSameProducts={mergeSameProducts}
                               setMergeSameProducts={setMergeSameProducts}
                               hasMultipleOrderNumbers={orderNumbers.length >= 2}/>
    case steps.DOING_INITIAL_MUTATION:
      return <LoadingContentSpinner toggle={toggle}/>
    case steps.ERROR:
      return <ErrorModalContent error={error} toggle={toggle}/>;
    case steps.WAITING_FOR_OUTPUT_TO_BE_GENERATED:
      return <LoadingContent environment={environment}
                             outputProcessId={outputProcessId}
                             onFinished={(output) => {
                               setOutputProcessId(null);
                               setResult(output);
                               setCurrentStep(steps.OUTPUT_READY)
                             }}
                             onFailed={err => {
                               setCurrentStep(steps.ERROR);
                               setOutputProcessId(null);
                               setError(err);
                             }}>
        <LoadingModalContent toggle={toggle} environment={environment} hasDownloadImages={downloadImages}
                             outputProcessId={outputProcessId} outputType={selectedDdt?.outputType}/>
      </LoadingContent>
    case steps.OUTPUT_READY:
      return <OutputReadyModalContent environment={environment} toggle={toggle} result={result}/>
    default:
      return <ErrorAlert error={`Unknown state ${currentStep}`}/>;
  }
}
