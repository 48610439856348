import React from "react";
import {Input, Col, Row} from "reactstrap";
import Dropdown from "../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../general/SecondaryActionButton";
import styles from "../FieldRules.module.scss";
import RemoveRuleIcon from "../RemoveRuleIcon";

export default function ReplacementBase({
                                          index,
                                          value,
                                          onChange,
                                          onRemove,
                                          tokens,
                                          // customizations
                                          noChangeValue = false,
                                          text = 'Replace',
                                          icon = 'fa-exchange-alt'
                                        }) {
  const options = tokens.map((t, i) => {
    return {
      label: t.display,
      value: i,
      keep: t.path
    }
  }).filter(t => t.keep);
  const selectedValue = options.filter(o => o.value === index)[0] || null;

  return <Row className={'mx-0'}>
    <Col className={'d-flex align-items-center px-0'} style={{lineHeight: '2rem'}}>
      <div
        className={'d-flex justify-content-center align-items-center rounded-circle ' + styles.iconBackground}>
        <i className={`fas ${icon} text-white`}
           style={{
             fontSize: '12px'
           }}/>
      </div>

      <span className="mx-2">
        {text}
      </span>

      <Dropdown
        className={'mx-1'}
        placeholder={"Select a data point"}
        toggleButtonProps={{
          tag: SecondaryActionButton,
          size: 'sm',
          iconRight: <i className="fas fa-caret-down pl-1"/>,
          className: styles.dropdownToken
        }}
        options={options}
        value={selectedValue}
        onChange={({value}) => {
          // protect against invalid values
          if (!tokens[value] || !tokens[value].path) {
            return;
          }
          onChange({index: value})
        }}
      />

      {!noChangeValue &&
      <>
        <span className="mx-2">
          with
        </span>

        <Input style={{width: '10rem', borderRadius: "8px"}}
               placeholder={'Custom text'}
               value={value || ''}
               onChange={e => {
                 onChange({value: e.target.value});
               }}
        />
      </>}

      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}
