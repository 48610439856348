import React from "react";
import textCase from '../../../../assets/svg/ddtRules/textcase.svg';
import {FieldRules} from "../fieldRulesUtils";
import styles from "./fieldRules/FieldRules.module.scss";
import {capitalize} from "../../../../stringUtils";
import {ProductFieldsProvider} from "../utilities";
import Dropdown, {isMultiOption, isSelectableOption, SelectableOption} from "../../../../atoms/Dropdown";
import Button from "../../../../atoms/Button";

type SelectableProductFieldOption = (SelectableOption & { type?: string[] | null })

const FIELD_RULES = [
  {
    divider: true
  },
  {
    header: true,
    label: "ADD RULES",
  },
  {
    label: <span><i className={'fas fa-remove-format ml-0 mr-2'}/> Cut down characters</span>,
    value: FieldRules.REDUCE_LENGTH,
  },
  {
    label: <span><img src={textCase} className={'mr-2'} alt=""/> Specify Text Case</span>,
    value: '',
    disabled: true
  },
  {
    label: <span><i className={'fas fa-sync-alt ml-0 mr-2'}/> Set Conditional Rule</span>,
    value: FieldRules.REPLACE_CONTENT,
  },
  {
    label: <span><i className={'fas fa-list ml-0 mr-2'}/> Enumerate sizes</span>,
    value: FieldRules.ENUMERATE_SUBVARIANTS,
  }
]

type ProductFieldDropdownProps = {
  productFieldsProvider: ProductFieldsProvider,
  onSelect: (item: { value: string; label: JSX.Element | string; type: string[] }) => void,
  usedRuleTypes: string [],
  tokenCount: number,
}

function ProductFieldDropdown({
                                productFieldsProvider,
                                onSelect,
                                usedRuleTypes = [],
                                tokenCount = 0,
                              }: ProductFieldDropdownProps) {
  let rawOptions = productFieldsProvider.getMultiMenuOptions()
  let fieldRules = [
    ...FIELD_RULES.map(f => {
      return {...f, disabled: f.disabled || (!!f.value && FieldRules.isDisabled(tokenCount, f.value, usedRuleTypes))}
    })
  ]

  let options = [...rawOptions, ...fieldRules]

  const getTypeByValue = (value: string) => {
    const onlyOptions = rawOptions.map(op => isMultiOption(op) && op.options).flat() as SelectableProductFieldOption[]
    const nonNestedOptions = rawOptions.filter((op): op is SelectableProductFieldOption => isSelectableOption(op))
    const candidates = [...onlyOptions, ...nonNestedOptions]

    return candidates.filter(option => option.value === value).length ?
      candidates.filter(option => option.value === value)[0].type :
      undefined
  }

  return <Dropdown currentValue={{label: <i className="fas fa-plus"/>}}
                   onSelectOption={({label, value}) => {
                     if (value) {
                       const type = getTypeByValue(value)
                       if (value.split('.') && value.split('.')[0] === "orderconfirmation") {
                         // append the order confirmation name to the item:
                         let orderConfirmationName = capitalize(value.split('.')[1])
                         onSelect({value, label: label + ': ' + orderConfirmationName, type: type || []})
                       } else {
                         onSelect({label, value, type: type || []})
                       }
                     }
                   }}
                   ToggleTag={(props) =>
                     <Button onClick={props.toggleDropdowns}
                             className={styles.dropdownToken}>
                       <i className="fas fa-plus mr-0"/>
                     </Button>}
                   options={options}
                   className={"mb-3"}/>
}

export default ProductFieldDropdown;
