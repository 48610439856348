import React from 'react';
import {
  OrderViaBarcodesQuantitiesAndPricesProps,
} from "../types";
import {graphql, QueryRenderer} from "react-relay";
import {ErrorAlert} from "../../../../commons/errors";
import Loading from "../../../../atoms/Loading";
import styles from './OrderViaBarcodesQuantitiesAndPrices.module.scss'
import OrderViaBarcodesQuantitiesAndPricesTable from "./OrderViaBarcodesQuantitiesAndPricesTable";
import {
  OrderViaBarcodesQuantitiesAndPricesQuery
} from "./__generated__/OrderViaBarcodesQuantitiesAndPricesQuery.graphql";

const query = graphql`
    query OrderViaBarcodesQuantitiesAndPricesQuery($gtins: [String!], $country: String!) {
      productsTable(gtins: $gtins) {
        count
        edges {
          node {
            id
            styleNumber
            variants {
              edges {
                node {
                  variantCode
                  variantName
                  hexCode
                  subvariants {
                    edges {
                      node {
                        id
                        gtin
                        name
                        prices(country: $country) {
                          rrp {
                            currency
                            amount
                          }
                          wsp {
                            currency
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`

export const noDataIcon = <i className={`fa-solid fa-circle-exclamation ${styles.noDataIcon}`} />

export const cellText = (text: string) => {
  return <p className={text.length > 8 ? styles.smallTableTextCell : styles.normalTableTextCell}>{text}</p>
}

export default function OrderViaBarcodesQuantitiesAndPrices({
                                                              existingBarcodes,
                                                              currency,
                                                              variants,
                                                              setVariants,
                                                              environment
                                                            }: OrderViaBarcodesQuantitiesAndPricesProps) {
  return <QueryRenderer<OrderViaBarcodesQuantitiesAndPricesQuery>
    environment={environment}
    query={query}
    variables={{
      gtins: existingBarcodes,
      country: currency?.country || ""
    }}
    render={(({props, error}) => {
      if (error) {
        return <ErrorAlert error={error} />
      }

      if(props && props.productsTable) {
        return <OrderViaBarcodesQuantitiesAndPricesTable products={props.productsTable}
                                                         currency={currency?.currency || ""}
                                                         variants={variants}
                                                         setVariants={setVariants}/>
      }

      return <div className={`d-flex align-items-center justify-content-center ${styles.container}`}>
        <Loading />
      </div>
    })}/>
}
