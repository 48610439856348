/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderProductTableQueryVariables = {
    id?: Array<string> | null | undefined;
    first?: number | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    after?: string | null | undefined;
};
export type OrderProductTableQueryResponse = {
    readonly productsTable: {
        readonly totalCount: number | null;
        readonly pageInfo: {
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly styleName: string | null;
                readonly styleNumber: string | null;
                readonly thumbnail: {
                    readonly url: string | null;
                } | null;
                readonly collection: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly brand: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly missingData: ReadonlyArray<{
                    readonly path: string;
                    readonly display: string | null;
                }>;
                readonly variants: {
                    readonly totalCount: number | null;
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly variantName: string | null;
                            readonly variantCode: string | null;
                            readonly hexCode: string | null;
                            readonly missingData: ReadonlyArray<{
                                readonly path: string;
                                readonly display: string | null;
                            }>;
                            readonly subvariants: {
                                readonly edges: ReadonlyArray<{
                                    readonly node: {
                                        readonly id: string;
                                        readonly name: string | null;
                                        readonly secondary: string | null;
                                        readonly missingData: ReadonlyArray<{
                                            readonly path: string;
                                            readonly display: string | null;
                                        }>;
                                    } | null;
                                } | null>;
                            } | null;
                        } | null;
                    } | null>;
                } | null;
                readonly quantity: number | null;
                readonly price: {
                    readonly amount: number;
                    readonly currency: string;
                } | null;
            } | null;
        } | null>;
    } | null;
};
export type OrderProductTableQuery = {
    readonly response: OrderProductTableQueryResponse;
    readonly variables: OrderProductTableQueryVariables;
};



/*
query OrderProductTableQuery(
  $id: [ID!]
  $first: Int
  $last: Int
  $before: String
  $after: String
) {
  productsTable(orders: $id, first: $first, after: $after, last: $last, before: $before) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      node {
        styleName
        styleNumber
        thumbnail {
          url
        }
        collection {
          id
          name
        }
        brand {
          id
          name
        }
        missingData {
          path
          display
        }
        variants {
          totalCount
          edges {
            node {
              variantName
              variantCode
              hexCode
              missingData {
                path
                display
              }
              subvariants {
                edges {
                  node {
                    id
                    name
                    secondary
                    missingData {
                      path
                      display
                    }
                  }
                }
              }
            }
          }
        }
        quantity
        price {
          amount
          currency
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "orders",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "styleName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "styleNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductImage",
  "kind": "LinkedField",
  "name": "thumbnail",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollection",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Brand",
  "kind": "LinkedField",
  "name": "brand",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingProductDataField",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductVariantConnection",
  "kind": "LinkedField",
  "name": "variants",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductVariantEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductVariant",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variantName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variantCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hexCode",
              "storageKey": null
            },
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductSubvariantConnection",
              "kind": "LinkedField",
              "name": "subvariants",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductSubvariantEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductSubvariant",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v11/*: any*/),
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "secondary",
                          "storageKey": null
                        },
                        (v16/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderProductTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "productsTable",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderProductTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "productsTable",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "998071b1becd5d41e1f5b2679fffb87e",
    "id": null,
    "metadata": {},
    "name": "OrderProductTableQuery",
    "operationKind": "query",
    "text": "query OrderProductTableQuery(\n  $id: [ID!]\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n) {\n  productsTable(orders: $id, first: $first, after: $after, last: $last, before: $before) {\n    totalCount\n    pageInfo {\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        styleName\n        styleNumber\n        thumbnail {\n          url\n        }\n        collection {\n          id\n          name\n        }\n        brand {\n          id\n          name\n        }\n        missingData {\n          path\n          display\n        }\n        variants {\n          totalCount\n          edges {\n            node {\n              variantName\n              variantCode\n              hexCode\n              missingData {\n                path\n                display\n              }\n              subvariants {\n                edges {\n                  node {\n                    id\n                    name\n                    secondary\n                    missingData {\n                      path\n                      display\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n        quantity\n        price {\n          amount\n          currency\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6631fae31d8362d420e08fefb98e0c87';
export default node;
