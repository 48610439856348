import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import styles from "../FieldRules.module.scss";
import Dropdown from "../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../general/SecondaryActionButton";
import RemoveRuleIcon from "../RemoveRuleIcon";
import React from "react";
import TokenSeparator from "../../tokenRepresentations/TokenSeparator";

export default function ReplacementSeparator({
                                               index,
                                               value,
                                               onChange,
                                               onRemove,
                                               tokens,
                                               text = 'Change separator between',
                                               icon = 'fa-minus'
                                             }) {
  const options = tokens.map((t, i) => {
    return {
      label: t.display,
      value: i,
      keep: t.path
    }
  }).filter(t => t.keep);
  //remove last options since no separator comes after
  options.pop()
  const selectedValue = options.filter(o => o.value === index - 1)[0] || null;
  return <Row className={'mx-0 mt-4 pt-2'}>
    <Col className={'d-flex align-items-center px-0'}>
      <div
        className={'d-flex justify-content-center align-items-center rounded-circle ' + styles.iconBackground}>
        <i className={`fas ${icon} text-white`}
           style={{
             fontSize: '12px'
           }}/>
      </div>

      <span className="mx-2">
        {text}
      </span>

      <Dropdown
        className={'mx-1'}
        placeholder={"Select a data point"}
        toggleButtonProps={{
          tag: SecondaryActionButton,
          size: 'sm',
          iconRight: <i className="fas fa-caret-down pl-1"/>,
          className: styles.dropdownToken
        }}
        options={options}
        value={selectedValue}
        onChange={({value}) => {
          // protect against invalid values
          if (!tokens[value] || !tokens[value].path) {
            return;
          }
          onChange({index: value + 1})
        }}
      />
      {index &&
      <>
        <span className="mx-2">
          and
        </span>
        <Dropdown
          disabled={true}
          value={{label: tokens[index + 1].display}}
          toggleButtonProps={{
            tag: SecondaryActionButton,
            size: 'sm',
            iconRight: <i className="fas fa-caret-down pl-1"/>,
            className: styles.dropdownToken
          }}/>
        <span className="mx-2">
          to
        </span>
        <div className={"mr-2"}>
          <TokenSeparator
            string={value !== null ? value : "-"}
            isOnShortRow={true}
            onChange={(e) => {
              onChange({value: e.string})
            }}/>
        </div>
      </>}
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}
