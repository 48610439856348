import React from "react";
import Token from "./components/tokenRepresentations/Token";
import ProductFieldDropdown from "./components/ProductFieldDropdown";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import {FieldRules} from "./fieldRulesUtils";
import ReduceLengthFieldRule from "./components/fieldRules/reduceLength/ReduceLengthFieldRule";
import AlterContentFieldRule from "./components/fieldRules/alterContent/AlterContentFieldRule";
import RemoveContentFieldRule from "./components/fieldRules/removeContent/RemoveContentFieldRule";
import {
  EnumerateSubvariantsFieldRule
} from "./components/fieldRules/enumerateSubvariants/EnumerateSubvariantsFieldRule";
import {
  FieldRuleType, GeneralRulesTypes, GeneralTokenRule,
  isEnumerateSubvariantFieldRule,
  isProductPathToken,
  isReplaceContentFieldRule, NewTokenType, ProductPathTokenType,
  RowConfigType, SeparatorTokenType,
  Suffix
} from "./types";
import {hasType, ProductFieldsProvider} from "./utilities";
import ImageNumericValueSuffix from "./components/ImageNumericValueSuffix";
import {useFeatureFlag} from "../../../context/FeatureFlagsContext";
import styles from './ExpandedFieldRulesListItem.module.scss';
import Dropdown from "../../../atoms/Dropdown";
import TokenMeasurementsMenuTag from "./TokenMeasurementsMenuTag";
import {useDDTMappingConfigFormContext} from "./DDTMappingConfigFormContext";
import {GENERATE_DESCRIPTION_DEFAULT_VALUES} from "./components/GenerateDescriptionFieldEditor";

function getScrollPositionFromDom() {
  return document?.getElementById('list-group')?.scrollTop || 0
}

function isFieldRule(val: string) {
  return FieldRules.all().includes(val)
}

function getMeasurementsCategory(displayName: string | null) {
  const categories = [
    {
      category: 'length',
      keywords: ['length', 'width', 'height']
    }, {
      category: 'weight',
      keywords: ['weight']
    }
  ];
  if(displayName) {
    return categories.find(category => {
      return category.keywords.find(keyword => displayName.toLowerCase().includes(keyword));
    })?.category || null;
  }
  return null;
}

const getTokenDefaultRules = (val: string): (GeneralTokenRule[] | undefined) => {
  if(val === "_gen_description") {
    return [{
      type: GeneralRulesTypes.GenerateDescriptionTokenRule,
      language: GENERATE_DESCRIPTION_DEFAULT_VALUES.language,
      sentences: GENERATE_DESCRIPTION_DEFAULT_VALUES.sentences,
      isUniqueDescription: GENERATE_DESCRIPTION_DEFAULT_VALUES.isUniqueDescription
    }]
  }
  return undefined
}

type ExpandedFieldRulesListItemProps = {
  rowConfig: RowConfigType,
  setScrollPosition: (arg: number) => void,
  onFieldTokenClick: (outputField: string, i: number) => void,
  outputField: string,
  onRemoveToken: (outputField: string, i: number) => void,
  onTokenChange: (outputField: string, i: number, newVal: SeparatorTokenType | ProductPathTokenType) => void,
  productFieldsProvider: ProductFieldsProvider,
  onAddFieldRule: (outputField: string, value: string) => void,
  onAddToken: (outputField: string, token: NewTokenType) => void,
  isConfigureImageNaming: boolean,
  onChangeFieldRule: (
    outputField: string,
    i: number,
    newVal: FieldRuleType
  ) => void,
  onRemoveFieldRule: (outputField: string, i: number) => void
  onSuffixChange: (suffix: Suffix) => void,
}

export default function ExpandedFieldRulesListItem({
                                                     rowConfig,
                                                     setScrollPosition,
                                                     onFieldTokenClick,
                                                     outputField,
                                                     onTokenChange,
                                                     onSuffixChange,
                                                     onAddToken,
                                                     onAddFieldRule,
                                                     productFieldsProvider,
                                                     onRemoveToken,
                                                     onChangeFieldRule,
                                                     onRemoveFieldRule,
                                                     isConfigureImageNaming,
                                                   }: ExpandedFieldRulesListItemProps) {
  const outputPageRedesignFeature = useFeatureFlag('output.image_naming_modal');
  const {allMeasurements} = useDDTMappingConfigFormContext();

  return <Row className={'mx-0 pt-2 d-flex align-items-center'}>
    {!isConfigureImageNaming && <span className="mr-2 pr-1 mb-3" style={{color: '#333333'}}>Display</span>}
    {rowConfig.tokens.map((tokenConfig, i) => {
      let conditionsWithMatchOnToken = rowConfig.rules?.map(rule => {
        return isReplaceContentFieldRule(rule) ?
          rule.conditions.filter(condition => typeof condition.match_on_token === "number") :
          [];
      }).flat();
      const tokenType = isProductPathToken(tokenConfig) ? (productFieldsProvider.getByPath(tokenConfig?.path)?.type || null) : null;

      let matchedTokenIndexes = conditionsWithMatchOnToken ?
        conditionsWithMatchOnToken.map(condition => condition.match_on_token) :
        null;
      return <React.Fragment key={i}>
        <Token info={tokenConfig}
               display={isProductPathToken(tokenConfig) ? tokenConfig.display : null}
               onClick={() => {
                 setScrollPosition(getScrollPositionFromDom());
                 onFieldTokenClick(outputField, i);
               }}
               type={tokenType}
               isMatchedByIndex={matchedTokenIndexes ? matchedTokenIndexes.includes(i) : false}
               onRemove={() => onRemoveToken(outputField, i)}
               onChange={(newVal: {string: string }) => onTokenChange(outputField, i, newVal)}/>
        {isProductPathToken(tokenConfig) && hasType(tokenType, 'number') && getMeasurementsCategory(tokenConfig.display) &&
          <Dropdown
            currentValue={{label: <i className="fas fa-sliders m-0"/>}}
            options={[]}
            MenuTag={(props) => <TokenMeasurementsMenuTag
              measurements={allMeasurements ? allMeasurements.filter(m => {
                return m?.category === getMeasurementsCategory(tokenConfig.display)
              }) : []}
              tokenDisplay={isProductPathToken(tokenConfig) && tokenConfig.display ? tokenConfig.display : null}
              onMeasurementSelect={(measurement) => onTokenChange(outputField, i, {...tokenConfig, measurement})}
              selectedMeasurement={tokenConfig.measurement || null}
              {...props}/>
            }
            className={`mb-3 ${styles.measurementsDropdown}`}
            caret={false}
          />
        }
      </React.Fragment>
    })}
    {rowConfig.suffix && outputPageRedesignFeature.active &&
      <ImageNumericValueSuffix
        suffix={rowConfig.suffix}
        separatorValue={rowConfig.suffix.separator}
        onSuffixChange={onSuffixChange}
      />
    }
    <ProductFieldDropdown productFieldsProvider={productFieldsProvider}
                          usedRuleTypes={(rowConfig.rules || []).map(r => r.type)}
                          tokenCount={rowConfig.tokens.length}
                          onSelect={(item: { value: string; label: string | JSX.Element; type: string[] }) => {
                            if (isFieldRule(item.value)) {
                              onAddFieldRule(outputField, item.value);
                            } else {
                              onAddToken(outputField, {
                                name: typeof item.label === "string" ? item.label : item.value,
                                path: item.value,
                                type: item.type,
                                ...(getTokenDefaultRules(item.value) && {rules: getTokenDefaultRules(item.value)})
                              });
                            }
                          }}/>
    <Row className={'mx-0 mt-2'}/>
    <Col className={'p-0'} style={{display: "contents"}}>
      {(rowConfig.rules || []).map((fieldRule, i) => {
        let props = {
          key: i,
          value: fieldRule,
          tokens: rowConfig.tokens,
          onChange: (newVal: FieldRuleType) => {
            onChangeFieldRule(outputField, i, newVal);
          },
          onRemove: () => {
            onRemoveFieldRule(outputField, i);
          }
        }
        if (fieldRule.type === FieldRules.REDUCE_LENGTH) {
          return <ReduceLengthFieldRule {...props}/>;
        } else if (fieldRule.type === FieldRules.REPLACE_CONTENT) {
          return <AlterContentFieldRule {...props}
                                        productFieldsProvider={productFieldsProvider}
          />;
        } else if (fieldRule.type === FieldRules.REMOVE_CONTENT as string) {
          return <RemoveContentFieldRule {...props}
                                         productFieldsProvider={productFieldsProvider}
          />;
        } else if (isEnumerateSubvariantFieldRule(fieldRule)) {
          return <EnumerateSubvariantsFieldRule {...props}
                                                value={fieldRule}
                                                productFieldsProvider={productFieldsProvider}
          />;
        }
        return null;
      })}
    </Col>
  </Row>
}
