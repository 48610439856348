import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";
import {UpdateDdtMappingConfigMutation} from "./__generated__/UpdateDdtMappingConfigMutation.graphql";


const mutation = graphql`
  mutation UpdateDdtMappingConfigMutation($input: UpdateDistributeDataMappingConfigMutationInput!) {
    updateDistributeDataMappingConfig(input: $input) {
      instance {
        id
        useSystem
        config
        configBeforeVariant
        isActive
        skipFirstVariant
      }
    }
  }
`;

export default createMutationBoilerplate<UpdateDdtMappingConfigMutation>(mutation);
