import React, {ChangeEventHandler, useContext, useEffect, useRef, useState} from "react";
import Loading from "../../../../atoms/Loading";
import styles from './ExamplePreviewBadge.module.scss';
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ExampleDataContext from "./contexts/ExampleDataContext";
import SearchInput from "../../../../atoms/SearchInput";
import {UncontrolledTooltip} from "reactstrap";
import addClassNames from "../../../../classNameUtils";
import Tooltip from "../../../../atoms/Tooltip";

export function useOutsideAlerter(ref: React.RefObject<HTMLInputElement>, onClick: () => void) {
  useEffect(() => {
    function handleClickOutside({target}: MouseEvent) {
      if (ref.current && !ref.current.contains(target as Node)) {
        onClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClick, ref]);
}

type InputNestProps = {
  value: string,
  onChange: ChangeEventHandler<HTMLInputElement>
}

export function InputNest({value, onChange}: InputNestProps) {
  return <CardHeader>
    <Row className={"m-0 no-gutters"}>
      <Col>
        <SearchInput delay={500}
                     value={value}
                     autoFocus
                     className={styles.inputGroupTextArea + " pl-0 custom-form-group"}
                     onChange={onChange}/>
      </Col>
    </Row>
  </CardHeader>;
}

type ProductExampleSuggestion = {
  onClick: () => void
  name: string
}

export function ProductExampleSuggestion({onClick, name}: ProductExampleSuggestion) {
  return <div className={styles.exampleProductRow}
              onClick={onClick}>
    <div>{name}</div>
  </div>
}

export const isReadyForDisplay = (products: string | any[], query: string, isLoading: boolean) => {
  return products && products.length && !isLoading
}

export function NoProductsSuggestionsToDisplayYet({isLoading}: { isLoading: boolean }) {
  return <Row className={"d-flex justify-content-center"}>
    <p className={"text-muted"}>
      {isLoading ? "Loading" : "The preview will be applied to all columns"}
    </p>
    {isLoading && <Loading/>}
  </Row>;
}


export function ProductSearchExpandedBadge({onClose}: { onClose: () => void }) {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const {products, onExampleProductSelect, refetchExampleProducts} = useContext(ExampleDataContext);
  const wrapperRef = React.createRef<HTMLInputElement>();

  useOutsideAlerter(wrapperRef, onClose);

  const callback = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    refetchExampleProducts(query, callback)
  }, [query])

  return <Card innerRef={wrapperRef} className={styles.expandedContainerCard}>
    <InputNest value={query} onChange={e => {
      setQuery(e.target.value);
    }}/>
    {isReadyForDisplay(products, query, isLoading) ?
      products.map(prod => {
        return <ProductExampleSuggestion key={prod.id} onClick={() => {
          onExampleProductSelect(prod.id);
          onClose();
        }} name={prod.name}/>
      }) :
      <NoProductsSuggestionsToDisplayYet isLoading={isLoading}/>}
  </Card>
}

type ExamplePreviewBadgeProps = {
  page: string | null,
  index: number,
  sourceRowDisplay?: string,
  field: string | null,
  isConfigureImageNaming: boolean,
  productData: { brandName: string } | null,
  showInfo?: 'length' | 'brand'
}

function ExamplePreviewBadge({
                               page,
                               index,
                               sourceRowDisplay,
                               field,
                               isConfigureImageNaming,
                               productData,
                               showInfo = 'length'
                             }: ExamplePreviewBadgeProps) {
  const [expanded, setExpanded] = useState(false);
  const {productExample, tokenExample} = useContext(ExampleDataContext);
  const [isExampleTruncated, setIsExampleTruncated] = useState(false)
  const spanRef = useRef<HTMLSpanElement>(null)

  let example;
  let isPlaceholder = false;
  let isEmptyColumn = false;
  if (field) {
    // a preview on the root config view
    example = productExample && productExample[field];
    if (!example) {
      example = 'Column is empty'
      isEmptyColumn = true;
    }
  } else if (tokenExample) {
    // a preview on a single token
    example = tokenExample
  } else {
    example = 'Click on a column from the list to set data requirements for it';
    isPlaceholder = true;
  }

  useEffect(() => {
    setIsExampleTruncated((spanRef.current && (spanRef.current.scrollWidth > spanRef.current.offsetWidth)) || false)
  }, [productExample, tokenExample, example])

  let exampleClasses = addClassNames([
    {className: styles.headerText, condition: true},
    {className: styles.exampleText, condition: !isPlaceholder && !isEmptyColumn},
    {className: "mr-auto", condition: (index === 0 || !page) && !isConfigureImageNaming},
    {className: "ml-auto", condition: isEmptyColumn},
    {className: styles.mediumMarginRight, condition: isEmptyColumn},
    {className: styles.whiteText, condition: isPlaceholder || isEmptyColumn},
  ])

  let showInfoClasses = addClassNames([
    {className: styles.headerText, condition: true},
    {className: styles.whiteText, condition: true},
    {className: "mx-2", condition: true},
  ])

  let previewDropdownClasses = addClassNames([
    {className: styles.headerText, condition: true},
    {className: styles.whiteText, condition: true},
    {className: styles.previewDropdownToggle, condition: true},
    {className: "ml-auto", condition: true},
  ])

  let pageClasses = addClassNames([
    {className: "mx-2", condition: true},
    {className: styles.pageInfo, condition: true},
    {className: styles.yellowText, condition: !!sourceRowDisplay},
    {className: styles.whiteText, condition: !sourceRowDisplay},
  ])

  let lengthInformation = ""

  if (isEmptyColumn) {
    lengthInformation = "0 characters"
  } else {
    lengthInformation = example.length + "  character" + (example.length !== 1 && 's')
  }

  const placeholderTooltipText = <>
    The items on the list represent columns from the file<br/>
    used to create this template.<br/>
    <br/>
    Setting requirements will let you transform data in the<br/>
    exact way you want it on download.
  </>

  return <div style={{marginLeft: "-0.75rem", marginRight: "-0.75rem"}}>
    <div className={styles.container + " align-items-center px-5"}>
      {(index > 0 && !isPlaceholder) && <div
        className={'d-flex justify-content-center align-items-center mr-1 ' + styles.indexCircle}>
        {index}
      </div>
      }
      {isConfigureImageNaming && <div className={styles.whiteText}
                                      style={{display: "flex", alignItems: "center", flexShrink: 0}}>
        <span>Add data points and apply rules from the options below</span>
      </div>}
      {sourceRowDisplay && <>
        <div className={'mx-2 text-white'}>{sourceRowDisplay}</div>
      </>
      }
      {sourceRowDisplay && <i className={"fas fa-chevron-right " + styles.chevron}/>}
      {(page && !isPlaceholder) && <>
        <div className={pageClasses}>{page}</div>
      </>
      }
      <div className={"d-flex align-items-center " + (isConfigureImageNaming ? "ml-auto" : "")}
           style={{maxWidth: '60%'}}>
        <Tooltip text={example}
                 tooltipGap={"1rem"}
                 placement={"bottom"}
                 isDisplayed={isExampleTruncated && !isPlaceholder}
                 wrapperStyle={{maxWidth: '-webkit-fill-available'}}>
          <span ref={spanRef} className={exampleClasses}>
            {example}
            {isPlaceholder && index === 0 &&
              <Tooltip text={placeholderTooltipText}
                       mode={"help"}
                       arrow={false}
                       placement={"bottomRight"}
                       wrapperStyle={{marginLeft: "0.375rem"}}>
                <i className="fa-light fa-circle-question"/>
              </Tooltip>}
          </span>
        </Tooltip>
      </div>
      {index !== 0 && <div className={'text-white'}>
        <span>|</span>
      </div>}
      {!isPlaceholder && !isConfigureImageNaming && example?.length && <div className={showInfoClasses}>
        {showInfo === 'length' && <>{lengthInformation}</>}
        {showInfo === 'brand' && <>{productData?.brandName || "No brand name found"}</>}
      </div>}
      {!isConfigureImageNaming && !sourceRowDisplay && <div className={previewDropdownClasses}
                                                            id={'previewId-' + index}
                                                            onClick={() => setExpanded(true)}>
        Change Preview <i className="fas fa-caret-down ml-1"
                          style={{
                            fontSize: '12px'
                          }}/>
        <UncontrolledTooltip placement='bottom' target={'previewId-' + index}>
          Change preview example
        </UncontrolledTooltip>
      </div>}
    </div>
    {expanded && <div className={styles.expandedContainer}>
      <ProductSearchExpandedBadge onClose={() => setExpanded(false)}/>
    </div>}
  </div>
}


export default ExamplePreviewBadge;
