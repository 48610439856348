/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FashionProductItem_product = {
    readonly id: string;
    readonly name: string;
    readonly collectionName: string | null;
    readonly brandName: string | null;
    readonly styleNumber: string | null;
    readonly unifiedImageGroups: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly images: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly url: string | null;
                        } | null;
                    } | null>;
                };
            } | null;
        } | null>;
    } | null;
    readonly unifiedproductSet: {
        readonly totalCount: number | null;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly colorName: string | null;
                readonly colorCode: string | null;
                readonly size: string | null;
                readonly imageGroups: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                        } | null;
                    } | null>;
                };
            } | null;
        } | null>;
    } | null;
    readonly attachedUniqueValues: ReadonlyArray<{
        readonly jsonSchemaValue: string | null;
        readonly value: string;
    } | null> | null;
    readonly " $refType": "FashionProductItem_product";
};
export type FashionProductItem_product$data = FashionProductItem_product;
export type FashionProductItem_product$key = {
    readonly " $data"?: FashionProductItem_product$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FashionProductItem_product">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FashionProductItem_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collectionName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "styleNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnifiedProductImageGroupNodeConnection",
      "kind": "LinkedField",
      "name": "unifiedImageGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnifiedProductImageGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UnifiedProductImageGroupNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnifiedProductImageNodeConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UnifiedProductImageNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UnifiedProductImageNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DisplayUnifiedProductNodeConnection",
      "kind": "LinkedField",
      "name": "unifiedproductSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DisplayUnifiedProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DisplayUnifiedProductNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "colorName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "colorCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnifiedProductImageGroupNodeConnection",
                  "kind": "LinkedField",
                  "name": "imageGroups",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UnifiedProductImageGroupNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UnifiedProductImageGroupNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UniqueValueNode",
      "kind": "LinkedField",
      "name": "attachedUniqueValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jsonSchemaValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DisplayProductNode",
  "abstractKey": null
};
})();
(node as any).hash = '8a1d5db1d5d750e342f8010923908e9e';
export default node;
