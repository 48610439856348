import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "relay-runtime";
import {UpdateOrderCommentMutation} from "./__generated__/UpdateOrderCommentMutation.graphql";

export default createMutationBoilerplate<UpdateOrderCommentMutation> (
  graphql`
    mutation UpdateOrderCommentMutation($input: UpdateOrderMutationInput!) {
      updateOrder(input: $input) {
        instance {
          retailerComment
          brandComment
        }
      }
    }
  `
)
