import React from 'react';
import {graphql, QueryRenderer} from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Dropdown from "../../../../../atoms/Dropdown";
import {capitalize} from "../../../../../stringUtils";
import {UniqueValueRepresentationsDropdownQuery} from "./__generated__/UniqueValueRepresentationsDropdownQuery.graphql";


const query = graphql`
  query UniqueValueRepresentationsDropdownQuery($type: ID!) {
    getUniqueValueSet(id: $type) {
      id
      representations
    }
  }
`
type UniqueValueRepresentationsDropdownProps = {
  type: string,
  environment: RelayModernEnvironment,
  representation: string | null,
  setRepresentation: (val: string | null) => void,
}

export default function UniqueValueRepresentationsDropdown({
                                                             type,
                                                             environment,
                                                             representation,
                                                             setRepresentation,
                                                           }: UniqueValueRepresentationsDropdownProps) {
  return <QueryRenderer<UniqueValueRepresentationsDropdownQuery>
    variables={{type}}
    environment={environment}
    query={query}
    render={({error, props}) => {
      if (error) {
        return <p className="text-dark">Failed to load the representations</p>
      }
      if (props) {
        let reprOptions: { value: string, label: string }[] = (
          props.getUniqueValueSet?.representations || []
        ).map((repr: string) => ({
          label: capitalize(repr),
          value: repr
        }))

        if (reprOptions.length === 0) {
          return null;
        }

        let options = [{
          label: 'English',
          value: ''
        }].concat(reprOptions);

        let currentValue = (representation === null ? {label: 'English'} : options.filter(x => {
          return x.value === representation
        })[0]) || {label: 'Unknown'}

        return <Dropdown
          currentValue={currentValue}
          options={options}
          onSelectOption={op => {
            setRepresentation(op.value || null);
          }}
        />
      }
      return <Dropdown currentValue={{label: 'Loading'}} options={[]} disabled={true}/>
    }}
  />
}
