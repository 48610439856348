import {commitMutation, graphql} from "react-relay";
import Environment from "../Environment";


const mutation = graphql`
  mutation ChangePasswordMutation($input: ChangePasswordMutationInput!) {
    changePassword(input: $input) {
      user {
        id
        firstName
        lastName
        email
        avatar
      }
    }
  }
`;

function ChangePasswordMutation(password, confirmPassword, onSuccess, onError) {

  let input = {
    password, confirmPassword
  };
  const variables = {
    input: input
  };

  commitMutation(
    Environment,
    {
      mutation: mutation,
      variables: variables,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        } else if (response && !errors) {
          onSuccess(response);
        }
      },
      onError: (error) => {
        onError([{message: error}])
      }
    }
  )
}

export default ChangePasswordMutation;
