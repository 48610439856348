import React, {useEffect} from 'react';
import {createFragmentContainer, graphql} from "react-relay";
import Pagination from "../../../atoms/Pagination";
import {PaginationNav, usePagination} from "../../../commons/pagination";
import styles from './OrdersPagination.module.scss';

type OrdersPaginationProps = {
  pagination: {
    totalCount: number | null,
    pageInfo: {
      startCursor: string | null,
      endCursor: string | null
    }
  } | null
}

function OrdersPagination({pagination}: OrdersPaginationProps) {
  const {updatePageInfoFromConnection} = usePagination();

  useEffect(() => {
    if (pagination) {
      updatePageInfoFromConnection(pagination)
    }
  }, []);

  return <PaginationNav Tag={(props) => <Pagination {...props} buttonClassName={styles.paginationButton}/>} />
}

export default createFragmentContainer(
  OrdersPagination,
  {
    pagination: graphql`
      fragment OrdersPagination_pagination on NodeConnection {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
      }
    `,
  }
)
