import React from 'react'
import styles from "../dateDelta/DateDeltaRule.module.scss";
import {DefaultInput} from "../../../../../../../atoms/Input";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {pluralizeByCount} from "../../../../../../../stringUtils";

type RoundPeriodRuleProps = {
  date_output_format: string,
  next_month_day: number,
  onChange: (val: {
    date_output_format: string,
    next_month_day: number
  }) => void,
  onRemove: () => void
}

export default function RoundPeriodRule({
                                          date_output_format,
                                          next_month_day,
                                          onChange,
                                          onRemove
                                        }: RoundPeriodRuleProps) {

  const onDateOutputFormatChange = (newDateOutputFormat: string) => {
    onChange({date_output_format: newDateOutputFormat, next_month_day})
  }

  const onNextMonthDayChange = (newNextMonthDayChange: string) => {
    if (!isNaN(Number(newNextMonthDayChange))) {
      onChange({date_output_format, next_month_day: Number(newNextMonthDayChange) || 0})
    }
  }

  return <div className={'d-flex align-items-center'}>
    <div className={styles.iconContainer}>
      <i className={`fa-regular fa-calendar-lines ${styles.icon}`}/>
    </div>
    <span className={styles.lightText}>Round the date to the next</span>
    <DefaultInput value={next_month_day || ""}
                  onChange={e => onNextMonthDayChange(e.target.value)}
                  width={'3rem'}
                  className={'ml-2'}
                  invalid={!next_month_day}
    />
    <span className={`${styles.lightText} ml-2`}>
      {pluralizeByCount("day", next_month_day)} and then format it as
    </span>
    <DefaultInput value={date_output_format || ""}
                  onChange={e => onDateOutputFormatChange(e.target.value)}
                  width={'6.5rem'}
                  className={'ml-2'}
                  invalid={!date_output_format}
    />
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
