import React from 'react';
import Pagination from "../../../ui-kit/src/general/Pagination";
import {createFragmentContainer} from "react-relay";
import {graphql} from "graphql";
import {hackyGetRelayArraySlice} from "../../../ui-kit/src/arrayUtils";

function ProductPagination({pagination, onPageChange, perPage}) {
  let totalProductCount = pagination.totalCount;
  let pageInfo = pagination.pageInfo;
  let [startPos, endPos] = hackyGetRelayArraySlice(pageInfo.startCursor, pageInfo.endCursor);
  if (!totalProductCount) {
    return null;
  }
  return <Pagination
    total={Math.ceil(totalProductCount / perPage)}
    rangeStart={Math.floor(startPos / perPage)}
    rangeEnd={Math.ceil((startPos + 1) / perPage)}
    onChangePage={onPageChange}
  />
}


export default createFragmentContainer(
  ProductPagination,
  {
    pagination: graphql`
      fragment ProductPagination_pagination on DisplayProductNodeManualConnection {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
      }
    `,
  }
)