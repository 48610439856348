/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LastDownloadTypeEnum = "DOWNLOAD" | "SENT" | "%future added value";
export type ReceivedDataTypeEnum = "MASTER_DATA" | "ORDER_DATA" | "SHIPMENT_DATA" | "%future added value";
export type OrderRow_order = {
    readonly __typename: "OrderNode";
    readonly id: string;
    readonly orderNumber: string;
    readonly dateCreated: unknown;
    readonly customerReference: string | null;
    readonly retailerStore: {
        readonly name: string;
    } | null;
    readonly orderConfirmationMissingProducts: {
        readonly totalCount: number | null;
    } | null;
    readonly orderConfirmationExtraProducts: {
        readonly totalCount: number | null;
    } | null;
    readonly orderConfirmationBatch: {
        readonly id: string;
        readonly errorCount: number | null;
    } | null;
    readonly fromBrand: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly toRetailer: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly lastDownload: {
        readonly type: LastDownloadTypeEnum;
        readonly endDate: unknown;
    } | null;
    readonly missingData: {
        readonly percent: number | null;
    } | null;
    readonly deliveryPeriod: {
        readonly startDate: unknown | null;
    } | null;
    readonly receivedData: ReadonlyArray<{
        readonly type: ReceivedDataTypeEnum;
    } | null>;
    readonly brandComment: string | null;
    readonly retailerComment: string | null;
    readonly " $refType": "OrderRow_order";
} | {
    readonly __typename: "ManuallyUploadedOrderNode";
    readonly id: string;
    readonly orderNumbers: ReadonlyArray<string>;
    readonly uploaded: unknown;
    readonly batch: {
        readonly error: string | null;
        readonly errors: ReadonlyArray<{
            readonly reason: string | null;
            readonly product: unknown | null;
            readonly variant: unknown | null;
        } | null> | null;
        readonly uploads: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly name: string | null;
                } | null;
            } | null>;
        };
    } | null;
    readonly fromBrand: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly toRetailer: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly missingData: {
        readonly percent: number | null;
    } | null;
    readonly " $refType": "OrderRow_order";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "OrderRow_order";
};
export type OrderRow_order$data = OrderRow_order;
export type OrderRow_order$key = {
    readonly " $data"?: OrderRow_order$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrderRow_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandNode",
  "kind": "LinkedField",
  "name": "fromBrand",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerNode",
  "kind": "LinkedField",
  "name": "toRetailer",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingDataType",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderRow_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RetailerStoreNode",
          "kind": "LinkedField",
          "name": "retailerStore",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DisplayUnifiedProductNodeConnection",
          "kind": "LinkedField",
          "name": "orderConfirmationMissingProducts",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DisplayUnifiedProductNodeConnection",
          "kind": "LinkedField",
          "name": "orderConfirmationExtraProducts",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderConfirmationBatchNode",
          "kind": "LinkedField",
          "name": "orderConfirmationBatch",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "errorCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LastDownloadType",
          "kind": "LinkedField",
          "name": "lastDownload",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DeliveryPeriodType",
          "kind": "LinkedField",
          "name": "deliveryPeriod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceivedDataType",
          "kind": "LinkedField",
          "name": "receivedData",
          "plural": true,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandComment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "retailerComment",
          "storageKey": null
        }
      ],
      "type": "OrderNode",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderNumbers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploaded",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderConfirmationBatchNode",
          "kind": "LinkedField",
          "name": "batch",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "error",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderConfirmationError",
              "kind": "LinkedField",
              "name": "errors",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "product",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "variant",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderConfirmationUploadNodeConnection",
              "kind": "LinkedField",
              "name": "uploads",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderConfirmationUploadNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderConfirmationUploadNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/)
      ],
      "type": "ManuallyUploadedOrderNode",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();
(node as any).hash = 'af4e50dd941f0f3fb1727ff218afe8b0';
export default node;
