/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModalContent_productTemplates = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly name: string | null;
            readonly schemaFields?: ReadonlyArray<{
                readonly path: string | null;
                readonly name: string | null;
                readonly type: ReadonlyArray<string | null> | null;
                readonly category: string | null;
            } | null> | null | undefined;
        } | null;
    } | null>;
    readonly " $refType": "ModalContent_productTemplates";
};
export type ModalContent_productTemplates$data = ModalContent_productTemplates;
export type ModalContent_productTemplates$key = {
    readonly " $data"?: ModalContent_productTemplates$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ModalContent_productTemplates">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "configureImageNaming"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "ddt"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModalContent_productTemplates",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductTemplateNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductTemplateNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "condition": "configureImageNaming",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "ddt",
                      "variableName": "ddt"
                    },
                    {
                      "kind": "Literal",
                      "name": "includeImageFields",
                      "value": true
                    }
                  ],
                  "concreteType": "ProductTemplateField",
                  "kind": "LinkedField",
                  "name": "schemaFields",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "path",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductTemplateNodeConnection",
  "abstractKey": null
};
})();
(node as any).hash = '29c353d23c3c27ab36183099ee3ed713';
export default node;
