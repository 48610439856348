import {Environment, Network, RecordSource, Store} from "relay-runtime";
import {integrationAuthCode} from "./Consts";
import {getOnixUrl} from "./ui-kit/src/commons/http";

const store = new Store(new RecordSource());

export function getIntegrationToken() {
  return localStorage.getItem(integrationAuthCode)
}

// IMPORTANT !!!
// Get the onix graphql URL from the env variable. Make sure to have
// REACT_APP_ONIX_BACKEND_ENDPOINT defined in your env variables.
// Guide to how you can add custom env variables in your React App.
// Append REACT_APP_ to your env variable name if you want it to be picked up by React.
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
// console.log(JSON.stringify(process.env));
let graphQLUrl = getOnixUrl("/graphql/")
// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(operation,
                    variables,) {
  // get the auth token
  let authToken = getIntegrationToken();
  // make sure we don't pass the authToken null, will throw a 401 error.
  if (authToken === null) {
    authToken = ""
  }

  return fetch(graphQLUrl, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Vuuh-Integration': authToken
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => {
    return response.json();
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);

const integrationEnvironment = new Environment({
  network,
  store,
});

export default integrationEnvironment;


export function setIntegrationToken(token) {
  localStorage.setItem(integrationAuthCode, token);
}

export function deleteIntegrationToken() {
  localStorage.removeItem(integrationAuthCode);
}
