import React from "react";

function isFontAwesomeIcon(src) {
  return typeof (src) === "string" && src && src.startsWith('fa');
}


function ensureFaPrefix(src) {
  if (!src.startsWith('far') && !src.startsWith('fas')) {
    src = 'fas ' + src;
  }
  return src;
}

function isReactElement(elem) {
  return elem.hasOwnProperty('$$typeof')
}

export function RuleIcon({src, color = 'dark'}) {
  if (isFontAwesomeIcon(src)) {
    src = ensureFaPrefix(src);
    return <i className={`${src} mx-0 text-${color}`}
              style={{lineHeight: '16px', width: '16px', textAlign: 'center'}}/>
  }
  if (isReactElement(src)) {
    return src;
  }
  return <img alt={'Rule icon'} height='16px' width={'16px'} src={src}/>
}


export function CircleRuleIcon({src, color = 'dark'}) {
  if (isFontAwesomeIcon(src)) {
    src = ensureFaPrefix(src)
    return <i className={`${src} mx-0 bg-${color} text-white`}
              style={{
                lineHeight: '24px',
                width: '24px',
                textAlign: 'center',
                verticalAlign: 'center',
                fontSize: '12px',
                borderRadius: '50%'
              }}/>
  }
  return <img alt={'Circle rule icon'} height='16px' width={'16px'} src={src}/>
}
