import React, {useEffect, useState} from "react";
import {
  DropdownMenuProps,
  DropdownToggleProps,
  MultipleSelectionDropdown,
  SelectableOption
} from "../../../../../atoms/Dropdown";
import Button from "../../../../../atoms/Button";
import UniqueValueSetDropdownMenuTag from "./UniqueValueSetDropdownMenuTag";

type UniqueValueDropdownProps = {
  type: string,
  onChange: (value: { value: string [] }) => void,
  value: SelectableOption[],
  caretPosition?: 'start' | 'end'
}

function MenuTagAdapter({type, value, props}: { props: DropdownMenuProps, type: string, value: SelectableOption[] }) {
  return <UniqueValueSetDropdownMenuTag type={[type]} selectedOptions={value} {...props}/>
}

export default function UniqueValueMultipleSelectDropdown({type, onChange, value, caretPosition = 'start'}: UniqueValueDropdownProps) {
  const [values, setValues] = useState<SelectableOption[]>(value);
  const [isOpen, setIsOpen] = useState(false);

  let buttonText = "Select a value..."
  if (values?.length === 1) {
    buttonText = values[0].label as string
  } else if (values?.length === 2) {
    buttonText = values[0].label + " and one other"
  } else if (values?.length > 2) {
    buttonText = values[0].label + " and " + (values?.length - 1) + " others"
  }

  useEffect(() => {
    setValues(value);
  }, [value]);

  useEffect(() => {
    if (!isOpen) {
      const justTheValues = values.map(val => val.value).filter(val => val) as string[]
      onChange({value: justTheValues});
    }
  }, [isOpen]);

  const DefaultToggleTag = (props: DropdownToggleProps) => {
    setIsOpen(props.isOpen)

    return <Button {...props} onClick={props.toggleDropdowns}>
      {caretPosition === 'start' && <i className="fas fa-caret-down pl-1"/>}
      <span>{buttonText}</span>
      {caretPosition === 'end' && <i className="fas fa-caret-down pl-1"/>}
    </Button>
  }

  return <MultipleSelectionDropdown
    ToggleTag={DefaultToggleTag}
    onSelectOption={(newValues) => {
      setValues(newValues)
    }}
    placeholder={'Select a value'}
    currentValue={values}
    options={[]}
    MenuTag={(props) => <MenuTagAdapter type={type} value={values} props={props}/>}
  />
}

