import React, {useEffect} from 'react'
import styles from './DownloadOrdersDirectlySelectDdt.module.scss'
import {Row} from "reactstrap";
import Dropdown from "../../../atoms/Dropdown";
import Checkbox from "../../../atoms/Checkbox";
import {useHistory} from "react-router";
import {graphql, QueryRenderer} from "react-relay";
import Loading from "../../../atoms/Loading";
import {DownloadOrdersDirectlySelectDdtQuery} from "./__generated__/DownloadOrdersDirectlySelectDdtQuery.graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

const query = graphql`
  query DownloadOrdersDirectlySelectDdtQuery {
    listDistributeDataTemplates {
      edges {
        node {
          id
          name
          outputType
        }
      }
    }
  }
`

export type selectedDdtType = {
  value: string,
  label: string,
  outputType: string
}

type SelectDdtDropdownProps = {
  selectedDdt: selectedDdtType | null,
  setSelectedDdt: (val: selectedDdtType) => void,
  options: selectedDdtType[]
}

function SelectDdtDropdown({selectedDdt, setSelectedDdt, options}: SelectDdtDropdownProps) {
  useEffect(() => {
    const lastUsedDdtId = localStorage.getItem("lastUsedOutputId");

    if(lastUsedDdtId && !selectedDdt) {
      const lastUsedOption = options.find(op => op.value === lastUsedDdtId);

      if(lastUsedOption) {
        setSelectedDdt(lastUsedOption)
      }
    }
  }, [options]);

  return <Dropdown
    currentValue={selectedDdt}
    options={[
      {
        header: true,
        label: <div className={"d-flex"}>Templates <div className={styles.lightDropdownText}>Download</div>
        </div>
      },
      ...options.filter(option => option.outputType === "TEMPLATE"),
      {divider: true},
      {
        header: true,
        label: <div className={"d-flex"}>Integrations <div className={styles.lightDropdownText}>Send</div>
        </div>
      },
      ...options.filter(option => option.outputType === "INTEGRATION"),
    ]}
    className={'ml-3'}
    placeholder={"Select"}
    onSelectOption={option => {
      if (option.value) {
        setSelectedDdt(option as selectedDdtType)
        localStorage.setItem("lastUsedOutputId", option.value)
      }
    }}
    data-testid={"orders-dropdown-download-content-directly-select-ddt"}
  />
}

type DownloadOrdersDirectlySelectDdtProps = {
  selectedDdt: selectedDdtType | null,
  setSelectedDdt: (val: selectedDdtType) => void,
  downloadImages: boolean,
  setDownloadImages: (val: boolean) => void,
  environment: RelayModernEnvironment,
  onError: (val: string) => void,
}

function DownloadOrdersDirectlySelectDdt({
                                           selectedDdt,
                                           setSelectedDdt,
                                           downloadImages,
                                           setDownloadImages,
                                           onError,
                                           environment
                                         }: DownloadOrdersDirectlySelectDdtProps) {
  const history = useHistory();

  return <div className={styles.container}>
    <QueryRenderer<DownloadOrdersDirectlySelectDdtQuery>
      environment={environment}
      query={query}
      variables={{}}
      render={({error, props}) => {
        if (error) {
          onError(error.message);
        }

        if (props) {
          if (!props.listDistributeDataTemplates) {
            onError("Error fetching Templates");
            return;
          }
          const options: selectedDdtType[] = props.listDistributeDataTemplates.edges.map(edge => ({
            value: edge?.node?.id || '',
            label: edge?.node?.name || '',
            outputType: edge?.node?.outputType || ''
          }))

          return <>
            <Row className={"m-0"}>
              <p className={`${styles.text} my-auto`}>Template / Integration</p>
              <SelectDdtDropdown
                selectedDdt={selectedDdt}
                setSelectedDdt={setSelectedDdt}
                options={options}
              />
            </Row>
            <Row className={styles.includeImagesCheckboxRow}>
              <Checkbox
                checked={downloadImages}
                onChange={() => setDownloadImages(!downloadImages)}
                label={<label className={styles.text}>Include images</label>}
                labelProps={{style: {marginBottom: "0"}}}
                data-testid={"orders-checkbox-download-content-directly-include-images"}/>
            </Row>
            <hr className={styles.separator}/>
            <Row className={"m-0"}>
              <div className={styles.redirectCard}
                   onClick={() => {
                     history.push('/output')
                   }}>
                <div className={"d-flex align-items-center"}>
                  <i className={`${styles.starIcon} fa-solid fa-star`}/>
                  <p className={styles.redirectCardText}>Create your own output template or check out our
                    off-the-shelf<br/>
                    integrations to further automate filling out spreadsheets.</p>
                </div>
                <i className={`${styles.arrowIcon} fa-light fa-chevron-right`}/>
              </div>
            </Row>
          </>
        }

        return <Loading className={"d-flex mx-auto"}/>
      }}
    />
  </div>
}

export default DownloadOrdersDirectlySelectDdt
