import React, {useState} from 'react'
import GrayModal from "../../../dataDisplay/GrayModal";
import DownloadOrdersDirectlyInfo from "./DownloadOrdersDirectlyInfo";
import DownloadOrdersDirectlyBarcodesInput from "./DownloadOrdersDirectlyBarcodesInput";
import DownloadOrdersDirectlySelectDdt, {selectedDdtType} from "./DownloadOrdersDirectlySelectDdt";
import {ErrorAlert} from "../../../commons/errors";
import DownloadOrdersDirectlyLoading from "./DownloadOrdersDirectlyLoading";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import DownloadOrdersDirectlyDownload from "./DownloadOrdersDirectlyDownload";
import LoadingContent from "../../products/DistributeDataModalContentComponents/LoadingContent";
import GenerateDirectDownload from "../../../mutations/GenerateDirectDownloadMutation";

function DownloadOrdersDirectlyError({error} : {error: string}) {
  return <div style={{ width: '34rem', minWidth: '100%', padding: "4rem 2rem"}}>
    <ErrorAlert error={error} />
  </div>
}

const STEP = {
  INFO: -1,
  BARCODES_INPUT: 0,
  SELECT_DDT: 1,
  ERROR: 2,
  LOADING: 3,
  DOWNLOAD_ORDER: 4
}

const steps = [
  {stepText: "Add style numbers & barcodes"},
  {stepText: "Select output"}
]

type DownloadOrdersDirectlyModalProps = {
  isOpen: boolean,
  toggle: () => void,
  environment: RelayModernEnvironment
}

function DownloadOrdersDirectlyModal({isOpen, toggle, environment}: DownloadOrdersDirectlyModalProps) {
  const [step, setStep] = useState(STEP.INFO)
  const [barcodes, setBarcodes] = useState("")
  const [selectedDdt, setSelectedDdt] = useState<selectedDdtType | null>(null)
  const [downloadImages, setDownloadImages] = useState(false);
  const [outputProcessId, setOutputProcessId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [resultId, setResultId] = useState<string | null>(null);
  const hasPrimaryHeaderButton = step !== STEP.LOADING && step !== STEP.ERROR && step !== STEP.DOWNLOAD_ORDER;
  const [downloadIsStarting, setDownloadIsStarting] = useState(false);

  const onError = (error: string) => {
    setError(error);
    setStep(STEP.ERROR);
  }

  const onDownload = () => {
    setDownloadIsStarting(true);
    GenerateDirectDownload(
      environment,
      {
        ddt: selectedDdt?.value || "",
        withImages: downloadImages,
        data: barcodes.split(/[ \n]+/)
      },
      (result) => {
        setDownloadIsStarting(false);
        if(result?.generateDirectDownload?.outputProcess?.id) {
          setOutputProcessId(result.generateDirectDownload.outputProcess.id)
          setStep(STEP.LOADING)
        } else {
          onError("Something went wrong...")
        }
      },
      (err) => {
        setDownloadIsStarting(false);
        onError(Array.isArray(err) ? err[0].message : err?.message)
      }
    )
  }

  const modalPrimaryButton = step === STEP.SELECT_DDT ?
    {
      text: "Next",
      onClick: onDownload,
      isDisabled: !selectedDdt || downloadIsStarting,
      dataTestId: "orders-button-download-content-directly-download"
    } :
    {
      text: step === STEP.BARCODES_INPUT ? "Next" : "Continue",
      onClick: () => setStep(step === STEP.INFO ? STEP.BARCODES_INPUT : STEP.SELECT_DDT),
      isDisabled: step === STEP.BARCODES_INPUT && !barcodes,
      dataTestId: "orders-button-download-content-directly-continue"
    }

  const bodyContent = () => {
    if(step === STEP.INFO) {
      return <DownloadOrdersDirectlyInfo />
    } else if(step === STEP.BARCODES_INPUT) {
      return <DownloadOrdersDirectlyBarcodesInput barcodes={barcodes} setBarcodes={setBarcodes}/>
    } else if(step === STEP.SELECT_DDT) {
      return <DownloadOrdersDirectlySelectDdt selectedDdt={selectedDdt}
                                              setSelectedDdt={setSelectedDdt}
                                              downloadImages={downloadImages}
                                              setDownloadImages={setDownloadImages}
                                              environment={environment}
                                              onError={onError}/>
    } else if(step === STEP.ERROR) {
      return <DownloadOrdersDirectlyError error={error || "Something went wrong."} />
    } else if(step === STEP.LOADING) {
      return <LoadingContent
        environment={environment}
        outputProcessId={outputProcessId}
        onFinished={(output: {id: string}) => {
          setOutputProcessId(null);
          setResultId(output.id);
          setStep(STEP.DOWNLOAD_ORDER)
        }}
        onFailed={(err: string) => {
          setStep(STEP.ERROR);
          setOutputProcessId(null);
          setError(err);
        }}>
        <DownloadOrdersDirectlyLoading toggle={toggle} hasDownloadImages={downloadImages} environment={environment}
                                       outputProcessId={outputProcessId || ""} outputType={selectedDdt?.outputType}/>
      </LoadingContent>
    } else if(step === STEP.DOWNLOAD_ORDER) {
      if(!resultId) {
        onError("Error fetching result");
        return ;
      }
      return <DownloadOrdersDirectlyDownload resultId={resultId} environment={environment} onError={onError} toggle={toggle}/>
    }
    return <ErrorAlert error={"Invalid step"}/>
  }

  const hasHeader = step !== STEP.LOADING && step !== STEP.DOWNLOAD_ORDER;

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{ minWidth: "fit-content" }}
    title={hasHeader ? "Download data directly" : undefined}
    primaryHeaderButton={hasPrimaryHeaderButton ? modalPrimaryButton : undefined}
    secondaryHeaderButton={hasHeader ? {text: "Cancel", onClick: toggle, dataTestId: "orders-button-download-content-directly-cancel"} : undefined}
    steps={step === STEP.BARCODES_INPUT || step === STEP.SELECT_DDT ? steps : undefined}
    stepsIndex={step}
    bodyContent={bodyContent()}
  />
}

export default DownloadOrdersDirectlyModal;
