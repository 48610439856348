import styles from "../../../specialized/products/DistributeDataModalContentComponents/LoadingModalContent.module.scss";
import EnableSendEmailWithOutputOnCompletionMutation from "../../../mutations/EnableSendEmailWithOutputOnCompletionMutation";
import {ourToast} from "../../../atoms/Toast";
import React from "react";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Pill from "../../../atoms/Pill";

type DownloadOrdersDirectlyLoadingProps = {
  toggle: () => void,
  hasDownloadImages: boolean,
  environment: RelayModernEnvironment,
  outputProcessId: string,
  outputType?: string
}

function DownloadOrdersDirectlyLoading({toggle, hasDownloadImages, outputType, environment, outputProcessId}: DownloadOrdersDirectlyLoadingProps) {
  const infoText = hasDownloadImages ? 'This may take a while due to the processing of images' : 'This may take a while as we are changing the data to your standards';

  return <div className={`d-flex flex-column align-items-center justify-content-center p-5`}>
    <i className={`fa-solid fa-spinner fa-spin ${styles.spinner}`}/>
    <h5 className={`mt-4 ${styles.title}`}>Please Wait...</h5>
    <p className={`mt-1 mb-0 ${styles.infoText}`}>{infoText}</p>
    {outputType === "TEMPLATE" && <Pill
      className={'mt-3'}
      onClick={() => {
        EnableSendEmailWithOutputOnCompletionMutation(
          environment,
          {ddtOutputProcessId: outputProcessId},
          (result) => {
            if (result.enableSendEmailWithOutputOnCompletion?.success) {
              ourToast("success", "An email will be sent when ready")
              toggle();
            } else {
              ourToast("error", "Something went wrong");
            }
          },
          (err) => {
            ourToast("error", "Something went wrong", Array.isArray(err) ? err[0].message : err.message);
          }
        )
      }}
      data-testid={"orders-button-download-content-directly-email-notify"}
    >
      Get notified via email when ready
    </Pill>}
    <Pill onClick={toggle}
          className={'mt-2'}>
      Cancel
    </Pill>
  </div>;
}

export default DownloadOrdersDirectlyLoading
