import React, {useContext} from "react";
import styles from "./CollapsedFieldRulesListItem.module.scss";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import {ListGroupItem} from "reactstrap";
import ExampleDataContext from "./example/contexts/ExampleDataContext";
import {RowConfigType} from "./types";
import addClassNames from "../../../classNameUtils";
import Tooltip from "../../../atoms/Tooltip";

function CollapsedRowExamplePreview({field}: { field: string }) {
  const {productExample} = useContext(ExampleDataContext);

  return <div className={'d-flex align-items-center'}>
    <span className={styles.idleGrayText}>
    {productExample && productExample[field]}
  </span>
  </div>
}

const getRequiredTooltipText = (required: boolean) => <p className={"m-0"}>
  {`Click to make this column ${required ? "optional" : "required"}.`}<br/>
  <br/>
  {`Making a column ${required ? "optional" : "required"} will make the data points`}<br/>
  {`within it ${required ? "optional" : "mandatory"}.`}
</p>

interface CollapsedFieldRulesListItemProps extends React.ComponentPropsWithoutRef<"div"> {
  index: number;
  rowConfig: RowConfigType,
  onChangeRequired: () => void,
  outputField: string,
  isExpanded: boolean,
  setDisplayIndex: (index: number) => void,
  isConfigureImageNaming: boolean
}

export default function CollapsedFieldRulesListItem({
                                                      children,
                                                      index,
                                                      isConfigureImageNaming,
                                                      rowConfig,
                                                      onChangeRequired,
                                                      outputField,
                                                      isExpanded,
                                                      setDisplayIndex
                                                    }: CollapsedFieldRulesListItemProps) {
  const listItemClasses = addClassNames([
    {className: styles.listGroupItem, condition: true},
    {className: styles.grayBackground, condition: !isExpanded && !isConfigureImageNaming},
    {className: "pb-0", condition: isConfigureImageNaming},
  ])

  const caretClasses = addClassNames([
    {className: "pt-2 pl-3 pb-2 fas", condition: true},
    {className: 'fa-caret-down', condition: !isExpanded},
    {className: styles.idleGrayText, condition: !isExpanded},
    {className: 'fa-caret-up', condition: isExpanded},
  ])

  const rowIndex = addClassNames([
    {className: "index mr-2", condition: true},
    {className: 'emptyRowIndex', condition: rowConfig.tokens.length === 0},
  ])

  const requiredIcon = addClassNames([
    {className: `fa-regular fa-star ${styles.requiredIcon}`, condition: true},
    {className: styles.requiredIconEnabled, condition: rowConfig.required},
  ])

  return <ListGroupItem className={listItemClasses}>
    <div className={'mr-4 ml-3'}>
      {!isConfigureImageNaming && <Row className={'mx-0 py-3'}>
        <Col className={'pl-0 d-flex align-items-center'}>
          <div className={rowIndex}>
            {(index + 1).toString()}
          </div>
          <div className={"ml-2 d-flex flex-column"}>
            <span className={styles.outputFieldName}>{outputField}</span>
            <div className={styles.required}>
              <Tooltip
                text={getRequiredTooltipText(rowConfig.required)}
                mode={'help'}
                placement={"bottomRight"}
                arrow={false}>
                <i className={requiredIcon} onClick={onChangeRequired}/>
              </Tooltip>
              <p className={"m-0"}>{rowConfig.required ? "Required" : "Optional"} column</p>
            </div>
          </div>

        </Col>
        <Col className={"d-flex justify-content-end pr-0"}>
          {!isExpanded && <CollapsedRowExamplePreview field={outputField}/>}
          <i className={caretClasses}
             onClick={() => {
               if (isExpanded) {
                 setDisplayIndex(-1)
               } else {
                 setDisplayIndex(index)
               }
             }}/>
        </Col>
      </Row>}
      {children}
    </div>
  </ListGroupItem>
}
