import React from "react";
import styles from './RoundedTableHeader.module.scss';

function RoundedTableHeader({children, ...rest}) {
  return <thead className={'mb-3 ' + styles.roundedHeaderContainer}>
  <tr className={styles.roundedTableHeader} {...rest}>
    {children}
  </tr>
  </thead>
}

export default RoundedTableHeader;
