import ReplacementBase from "../alterContent/ReplacementBase";
import React from "react";

export function Removal({index, onChange, onRemove, tokens,}) {
  return <ReplacementBase index={index}
                          onChange={onChange}
                          onRemove={onRemove}
                          tokens={tokens}
                          noChangeValue={true}
                          text={'Remove'}
                          icon={'fa-minus-circle'}
  />
}
