import React, {useState} from 'react';
import {CardHeader, Table} from "reactstrap";
import {AddMissingDataButton, Header, MissingDataStatusPill, StatusPill, Tab} from "../layout/OrderViewLayout";
import SearchInput from "../../../atoms/SearchInput";
import PriceCatalogueOrderProducts from "./productViews/PriceCatalogueOrderProducts";
import {PaginationNav, PaginationProvider} from "../../../commons/pagination";
import BatchTotalInfo from "../components/BatchTotalInfo";
import OrderErrors, {ERROR_TYPES} from "../components/OrderErrors";
import ErrorsAcknowledgementsModal from "../components/ErrorsAcknowledgementsModal";
import DownloadButtonWithDdtModal from "../../products/DownloadButtonWithDdtModal";
import Card from "../../../atoms/Card";
import Separator from "../../../atoms/Separator";
import styles from './OrderView.module.scss';
import Pagination from "../../../atoms/Pagination";
import GrayTableHeader, {GrayTableHeaderCell} from "../../../molecules/tables/GrayTableHeader";
import {
  SingleOrderPageNormalOrdersQueryResponse
} from "../../../pages/orders/__generated__/SingleOrderPageNormalOrdersQuery.graphql";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {BrandUserContextType, RetailerUserContextType} from "../../../context/CurrentUserContext";
import {ErrorAlert} from "../../../commons/errors";
import {capitalize, normalizeDate} from "../../../stringUtils";
import {useHistory} from "react-router";

const PER_PAGE = 10;

type OrderConfirmationProductType = {
  styleNumber: string | null;
  colorName: string | null;
  size: string | null;
}

class OrderErrorsChecker {
  batchErrors: {
    product: unknown,
    reason: string,
    variant: unknown
  }[];
  batchRootError: string | null;
  missingOcProducts: {
    reason: string,
    style_number: string,
    color: string,
  }[];
  extraOcProducts: {
    reason: string,
    style_number: string,
    color: string,
  }[];

  constructor(order: SingleOrderPageNormalOrdersQueryResponse["getOrder"]) {
    this.batchErrors = order?.orderConfirmationBatch ? order.orderConfirmationBatch.errors?.filter(err => err !== null) as any : [];
    this.batchRootError = order?.orderConfirmationBatch ? order.orderConfirmationBatch.error : null;
    this.missingOcProducts = order?.orderConfirmationMissingProducts ? order.orderConfirmationMissingProducts.edges
      .filter((edge): edge is {node: OrderConfirmationProductType} => edge !== null && edge.node !== null)
      .map(({node}) => ({
        reason: ERROR_TYPES.MISSING_OC_PRODUCT,
        style_number: node.styleNumber || "",
        color: node.colorName || "",
        size: node.size || ""
      })) : [];
    this.extraOcProducts = order?.orderConfirmationExtraProducts ? order.orderConfirmationExtraProducts.edges
      .filter((edge): edge is {node: OrderConfirmationProductType} => edge !== null && edge.node !== null)
      .map(({node}) => ({
      reason: ERROR_TYPES.EXTRA_OC_PRODUCT,
      style_number: node.styleNumber || "",
      color: node.colorName || "",
      size: node.size || ""
    })) : [];
  }

  hasErrors() {
    return this.batchErrors.length > 0 || this.batchRootError || this.missingOcProducts.length > 0 || this.extraOcProducts.length > 0;
  }
}

const getLocationName = (location: ({name: string} | null)) => {
  if(location) {
    return capitalize(location.name.split("_")[0]);
  } else {
    return "-"
  }
}

type OrderViewProps = {
  order: SingleOrderPageNormalOrdersQueryResponse["getOrder"],
  isRetailer: boolean,
  environment: RelayModernEnvironment,
  user: BrandUserContextType | RetailerUserContextType,
  onClickBack: () => void
}

export default function OrderView({order, isRetailer, environment, onClickBack, user}: OrderViewProps) {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [displayErrorModalIsOpen, setDisplayErrorModalIsOpen] = useState(!order?.acknowledged)
  const hasMissingDataStatus = typeof order?.missingData?.percent === 'number' && order?.missingData?.percent < 100;
  const errors = new OrderErrorsChecker(order);

  return <>
    <PaginationProvider perPage={PER_PAGE}>
      <Card border fillSpace className={"overflow-hidden"}>
        <Header onClick={onClickBack} dataTestId={"order-button-back"}>Back to Orders</Header>
        <CardHeader style={{border: "none"}}>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>{isRetailer ? (order?.fromBrand ? order.fromBrand.name : '') : (order?.toRetailer ? order.toRetailer.name : '')}</p>
              <MissingDataStatusPill missingDataPercentage={order?.missingData?.percent}/>
              <StatusPill
                title={order?.lastDownload?.endDate ? `Downloaded ${normalizeDate(order.lastDownload.endDate as string, true, true)}` : "Not downloaded"}
                icon={"fa-solid fa-circle-arrow-down"}
                color={order?.lastDownload ? "primary" : "muted"}/>
            </div>
            <div className={styles.actionsContainer}>
              {hasMissingDataStatus && !isRetailer && <>
                <AddMissingDataButton orderId={order?.id || ""} orderNumbers={[order?.orderNumber || ""]}
                                      history={history} retailerName={order?.toRetailer?.name || ""}/>
                <Separator className={'m-0'}/>
              </>}
              {/*<Button icon={"fa-regular fa-trash-can"} className={styles.deleteButton}/>*/}
              <DownloadButtonWithDdtModal
                orderId={order?.id || null}
                environment={environment}
                orderNumbers={order?.orderNumber ? [order.orderNumber] : []}
                dataTestId={"order-button-download"}
                color={!hasMissingDataStatus ? "primary" : "secondary"}
              />
            </div>
          </div>
          <div className={styles.subtitleContainer}>
            <p className={styles.subtitle}>
              <i className={"mr-1 fa-light fa-hashtag"}/>
              {order?.orderNumber || "No order number"}
            </p>
            <Separator className={styles.separator}/>
            <p className={styles.subtitle}>
              <i className={"mr-1 fa-light fa-location-dot"}/>
              Location: {getLocationName(order?.retailerStore || null)}
            </p>
            <Separator className={styles.separator}/>
            <p className={styles.subtitle}>
              <i className={"mr-1 fa-light fa-calendar-arrow-up"}/>
              {order?.dateCreated ? capitalize(normalizeDate(order.dateCreated as string, false, true)) : "unknown"}
            </p>
          </div>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div style={{maxWidth: "13rem"}}>
              <SearchInput placeholder={'Search'}
                           delay={500}
                           value={search}
                           onChange={e => setSearch(e.target.value)}/>
            </div>
            <div className={"d-flex align-items-center"}>
              {errors.hasErrors() && <OrderErrors
                errors={errors.batchErrors}
                batchError={errors.batchRootError}
                missingOcProducts={errors.missingOcProducts}
                extraOcProducts={errors.extraOcProducts}
              />}
              <Tab className={'ml-3'}>
                <div className={styles.tabContent}>
                  Total:&nbsp;&nbsp;<p className={styles.boldText}>{order?.quantity || 0}</p>&nbsp;items&nbsp;/&nbsp;<p className={styles.boldText}>{order?.price.amount || 0}</p>&nbsp;{order?.price.currency || "EUR"}
                </div>
              </Tab>
              {((order?.productCount || 0) > PER_PAGE) && <>
                <Separator/>
                <PaginationNav Tag={Pagination} buttonClassName={styles.paginationButton}/>
              </>}
            </div>
          </div>
          <BatchTotalInfo batch={order?.orderConfirmationBatch}/>
        </CardHeader>
        <Table>
          <GrayTableHeader>
            <GrayTableHeaderCell style={{paddingInline: '50px'}} className={'py-2'}/>
            <GrayTableHeaderCell width={18} className={'py-2 pl-0'}>Style name</GrayTableHeaderCell>
            <GrayTableHeaderCell width={14} className={'py-2'}>Style number</GrayTableHeaderCell>
            <GrayTableHeaderCell width={14} className={'py-2'}>Colors & sizes</GrayTableHeaderCell>
            <GrayTableHeaderCell width={14} className={'py-2'}>Quantity</GrayTableHeaderCell>
            <GrayTableHeaderCell width={14} className={'py-2'}>Price</GrayTableHeaderCell>
            <GrayTableHeaderCell className={'py-2'}/>
          </GrayTableHeader>
          <tbody>
          {order?.id ?
            <PriceCatalogueOrderProducts
              environment={environment}
              orderId={order.id}
              hasMissingDataStatus={hasMissingDataStatus}
            /> : <ErrorAlert error={"Invalid Order id."}/>}
          </tbody>
        </Table>
      </Card>
    </PaginationProvider>
    <ErrorsAcknowledgementsModal isOpen={displayErrorModalIsOpen}
                                 toggle={() => setDisplayErrorModalIsOpen(!displayErrorModalIsOpen)}
                                 batch={order?.orderConfirmationBatch}
                                 order={order}
                                 manualOrder={null}/>
  </>
}
