import React from 'react';
import {OrderViaBarcodesAddBarcodesProps} from "./types";
import styles from './OrderViaBarcodesAddBarcodes.module.scss'
import {Textarea} from "../../../atoms/Input";
import Tooltip from "../../../atoms/Tooltip";

const exampleTooltipText = <p className={"m-0"}>
  Example:<br />
  5715324209330<br />
  5715324209331<br />
  5715324209332<br />
</p>

export default function OrderViaBarcodesAddBarcodes({barcodes, setBarcodes}: OrderViaBarcodesAddBarcodesProps) {
  return <div className={styles.container}>
    <div className={'d-flex align-items-center'}>
      <p className={styles.title}>Add each barcode/EAN on a new line</p>
      <Tooltip text={exampleTooltipText}
               mode={'help'}
               placement={"bottomRight"}
               arrow={false}>
        <i className={`fa-light fa-circle-question ${styles.infoIcon}`}/>
      </Tooltip>
    </div>
    <p className={styles.subtitle}>You can copy and paste an entire list of barcodes.</p>
    <Textarea value={barcodes}
              onChange={e => setBarcodes(e.target.value)}
              placeholder={"Enter barcodes here"}
              className={styles.textarea}/>
  </div>
}
