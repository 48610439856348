import React, {useRef, useState} from "react";
import Dropdown from "../../../../../atoms/Dropdown";
import Button from "../../../../../atoms/Button";
import styles from "./TokenSeparator.module.scss";
import addClassNames from "../../../../../classNameUtils";
import {ourToast} from "../../../../../atoms/Toast";

type StringLiteralTokenProps = {
  onChange: (value: { string: string }) => void,
  setIsInputEnabled: (value: boolean) => void,
  string: string,
  isDisabled: boolean,
  hasEmptyValue?: boolean,
}

export function StringLiteralToken({
                                     onChange,
                                     string,
                                     setIsInputEnabled,
                                     isDisabled,
                                     hasEmptyValue
                                   }: StringLiteralTokenProps) {

  const outsideAlerter = (ref: React.RefObject<HTMLInputElement>) => {
    function handleClickOutside({target}: MouseEvent) {
      if (ref.current && !ref.current.contains(target as Node)) {
        if (hasEmptyValue === false && string === "") {
          ourToast("error", "This input cannot be left empty")
        }
        return setIsInputEnabled(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  const wrapperRef = useRef(null);
  outsideAlerter(wrapperRef);

  return <input ref={wrapperRef}
                disabled={isDisabled}
                className={styles.stringLiteral + (hasEmptyValue === false && string === "" ? " " + styles.errorBorder : "")}
                autoFocus
                value={string}
                maxLength={3}
                size={3}
                style={{borderRadius: '0.5rem', width: "1.875rem"}}
                onChange={e => onChange({string: e.target.value})
                }/>
}

export const OPTION_VALUES = {
  CUSTOM: 'CUSTOM',
  DASH: '-',
  EMPTY: '',
  SLASH: '/',
  WHITE_SPACE: ' ',
  CHECKBOX: 'CHECKBOX'
}

const hasSpaces = (str: string) => {
  return (str.length >= 3 && str[0] === ' ' && str[str.length - 1] === ' ')
}

const withoutSpaces = (str: string) => {
  let noSpacesStr = str;
  if (hasSpaces(noSpacesStr)) {
    noSpacesStr = str.slice(1, noSpacesStr.length - 1);
  }
  return noSpacesStr;
}

const isCustomValue = (str: string) => {
  return withoutSpaces(str) !== OPTION_VALUES.DASH &&
    withoutSpaces(str) !== OPTION_VALUES.SLASH &&
    str !== OPTION_VALUES.EMPTY &&
    str !== OPTION_VALUES.WHITE_SPACE;
}

const getOptionType = (str: string) => {
  if (isCustomValue(str)) {
    return OPTION_VALUES.CUSTOM;
  } else if (withoutSpaces(str) === '-') {
    return OPTION_VALUES.DASH;
  } else if (withoutSpaces(str) === '/') {
    return OPTION_VALUES.SLASH;
  } else if (withoutSpaces(str) === ' ') {
    return OPTION_VALUES.WHITE_SPACE
  } else if (withoutSpaces(str) === '') {
    return OPTION_VALUES.EMPTY;
  }
}

type TokenSeparatorProps = {
  string: string,
  onChange: (value: { string: string }) => void,
  isOnShortRow?: boolean,
  hasEmptyValue?: boolean,
}

export default function TokenSeparator({
                                         string,
                                         onChange,
                                         hasEmptyValue,
                                         isOnShortRow = false,
                                       }: TokenSeparatorProps) {
  const [isInputActive, setIsInputEnabled] = useState(false);
  const [includeSpace, setIncludeSpace] = useState(!isCustomValue(string) && hasSpaces(string));
  const [selectedOption, setSelectedOption] = useState(getOptionType(string));

  let options = [
    {
      label: <span className={'user-select-none'}>Dash<span className={styles.dropdownExample}>-</span></span>,
      value: OPTION_VALUES.DASH,
      icon: <i data-testid={'isSelected-icon'} className={addClassNames([
        {className: `${styles.activeOption} fa-solid fa-circle-dot`, condition: selectedOption === OPTION_VALUES.DASH},
        {className: 'fa-regular fa-circle', condition: selectedOption !== OPTION_VALUES.DASH}
      ])}/>
    },
    {
      label: <span className={'user-select-none'}>Slash<span className={styles.dropdownExample}>/</span></span>,
      value: OPTION_VALUES.SLASH,
      icon: <i data-testid={'isSelected-icon'} className={addClassNames([
        {className: `${styles.activeOption} fa-solid fa-circle-dot`, condition: selectedOption === OPTION_VALUES.SLASH},
        {className: 'fa-regular fa-circle', condition: selectedOption !== OPTION_VALUES.SLASH}
      ])}/>
    },
    {
      label: <span className={'user-select-none'}>White Space</span>,
      value: OPTION_VALUES.WHITE_SPACE,
      icon: <i data-testid={'isSelected-icon'} className={addClassNames([
        {
          className: `${styles.activeOption} fa-solid fa-circle-dot`,
          condition: selectedOption === OPTION_VALUES.WHITE_SPACE
        },
        {className: 'fa-regular fa-circle', condition: selectedOption !== OPTION_VALUES.WHITE_SPACE}
      ])}/>
    },
    ...(hasEmptyValue === false ? [] : [{
      label: <span className={'user-select-none'}>Empty</span>,
      value: OPTION_VALUES.EMPTY,
      icon: <i data-testid={'isSelected-icon'} className={addClassNames([
        {className: `${styles.activeOption} fa-solid fa-circle-dot`, condition: selectedOption === OPTION_VALUES.EMPTY},
        {className: 'fa-regular fa-circle', condition: selectedOption !== OPTION_VALUES.EMPTY}
      ])}/>
    }]),
    {
      label: <span className={'user-select-none'}>Custom</span>,
      value: OPTION_VALUES.CUSTOM,
      icon: <i data-testid={'isSelected-icon'} className={addClassNames([
        {
          className: `${styles.activeOption} fa-solid fa-circle-dot`,
          condition: selectedOption === OPTION_VALUES.CUSTOM
        },
        {className: 'fa-regular fa-circle', condition: selectedOption !== OPTION_VALUES.CUSTOM}
      ])}/>
    },
    {
      divider: true
    },
    {
      label: <span className={'user-select-none'}>Include space around</span>,
      value: OPTION_VALUES.CHECKBOX,
      icon: <i data-testid={'hasSpace-checkbox'} className={addClassNames([
        {className: styles.checkBox, condition: true},
        {className: 'fa-regular fa-square', condition: !includeSpace},
        {className: `${styles.activeOption} fa-solid fa-square-check`, condition: includeSpace}
      ])}/>,
      disabled: selectedOption === OPTION_VALUES.EMPTY || selectedOption === OPTION_VALUES.WHITE_SPACE
    }
  ]

  return <Dropdown
    ToggleTag={(props) =>
      isInputActive ?
        <StringLiteralToken isDisabled={selectedOption !== OPTION_VALUES.CUSTOM}
                            string={string}
                            onChange={onChange}
                            hasEmptyValue={hasEmptyValue}
                            setIsInputEnabled={setIsInputEnabled}/> :
        <Button
          onClick={() => {
            setSelectedOption(getOptionType(string));
            setIsInputEnabled(getOptionType(string) === OPTION_VALUES.CUSTOM);
            setIncludeSpace(hasSpaces(string));
            props.toggleDropdowns()
          }}
          {...props}
          className={styles.separatorToken + (hasEmptyValue === false && string === "" ? " " + styles.errorBorder : "")}
        >
          {string}
        </Button>}
    keepOpenAfterSelect={true}
    currentValue={{label: string}}
    options={options}
    className={isOnShortRow ? "" : " mb-3"}
    onSelectOption={(e) => {
      if (e.value !== null && e.value !== undefined) {
        if (e.value === OPTION_VALUES.CUSTOM) {
          setSelectedOption(getOptionType(e.value));
          setIsInputEnabled(true);
          if (!isCustomValue(string)) {
            setIncludeSpace(false);
            onChange({string: ''});
          }
        } else if (e.value === OPTION_VALUES.CHECKBOX) {
          onChange({string: includeSpace ? withoutSpaces(string) : ` ${withoutSpaces(string)} `});
          setIncludeSpace(!includeSpace);
        } else {
          setSelectedOption(getOptionType(e.value));
          if (e.value === OPTION_VALUES.WHITE_SPACE || e.value === OPTION_VALUES.EMPTY) {
            setIncludeSpace(false);
            onChange({string: e.value});
          } else {
            onChange({string: !includeSpace ? e.value : ` ${e.value} `});
          }
        }
      }
    }}
  />
}
