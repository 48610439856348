import React from "react";
import greenCircleCheck from "../../../assets/svg/greenCircleCheck.svg"
import {downloadFile} from "../../../commons/http";
import {
  OutputReadyModalContentQueryResponse
} from "../__generated__/OutputReadyModalContentQuery.graphql";
import {ourToast} from "../../../atoms/Toast";
import styles from "./DownloadReadyContent.module.scss"
import Pill from "../../../atoms/Pill";

type DownloadReadyContentProps = {
  props: OutputReadyModalContentQueryResponse,
  toggle: () => void,
}

export default function DownloadReadyContent({props, toggle}: DownloadReadyContentProps) {

  return <div className={styles.container}>
    <i className={`fa-light fa-xmark ${styles.toggleButton}`} onClick={toggle}/>
    <img src={greenCircleCheck} alt={"greenCircleCheck"}/>
    <h1 className={styles.title}>Your file is ready</h1>
    <p className={styles.subtitle}>
      You can now download the file we prepared for you.
    </p>
    <Pill icon={"fa-light fa-arrow-down"}
          className={styles.downloadButton}
          onClick={() => {
            if (props.getDistributeDataOutput?.url) {
              downloadFile(props.getDistributeDataOutput.url)
            } else {
              ourToast("error", "No download url found!");
            }
          }}>
      Download
    </Pill>
  </div>
}
