import React, {useState} from "react";
import {Nav} from "reactstrap";
import CompanyManagementDropdownMenu from "./CompanyManagementDropdownMenu";
import {Environment} from "relay-runtime";
import StoreManagementModal from "./StoreManagementModal";
import {StoreType} from "./types";
import Dropdown from "./../../ui-kit/src/atoms/Dropdown"
import styles from "./CompanyManagementDropdown.module.scss"
import Button from "../../ui-kit/src/atoms/Button";
import addClassNames from "../../ui-kit/src/classNameUtils";

type CompanyManagementDropdownProps = {
  currentCompanyName: string,
  stores: StoreType []
  environment: Environment,
  retailerId: string
  handleCompanyChange: (company: { id: string }) => void,
  retailers: { name: string, id: string } []
}

export const COMPANY_MANAGEMENT_FIXED_OPTIONS = {
  ADD_A_RETAILER: "add_retailer",
  STORE_MANAGEMENT: "store_management",
  RETAILER_MANAGEMENT: "retailer_management",
}

export default function CompanyManagementDropdown({
                                                    currentCompanyName,
                                                    handleCompanyChange,
                                                    stores,
                                                    retailers,
                                                    environment,
                                                    retailerId
                                                  }: CompanyManagementDropdownProps) {
  const [isStoreManagementModalOpen, setIsStoreManagementModalOpen] = useState(false)

  const openModal = (modal: string) => {
    if (modal === COMPANY_MANAGEMENT_FIXED_OPTIONS.STORE_MANAGEMENT) {
      setIsStoreManagementModalOpen(true)
    }
  }

  return <Nav className={`ml-auto ${styles.dropdownContainer}`} navbar>
    <Dropdown currentValue={{label: ""}}
              options={[]}
              ToggleTag={(props) => {
                return <Button onClick={e => props.toggleDropdowns(e)}
                               className={addClassNames([
                                 {className: styles.dropdownMenuButton, condition: true},
                                 {className: styles.dropdownMenuButtonActive, condition: props.isOpen},
                               ])}
                               color={'transparentSecondary'}>
                  <i className={`fa-regular fa-store ${styles.userIcon} ${props.isOpen && styles.userIconActive}`}/>
                  <p className={"mb-0 " + styles.smallFont}>
                    {currentCompanyName}
                  </p>
                  <i className={`ml-2 mr-0 fa-solid fa-chevron-down ${styles.chevronIcon}`}/>
                </Button>
              }}
              MenuTag={() => <CompanyManagementDropdownMenu activeRetailerId={retailerId}
                                                            retailers={retailers}
                                                            handleCompanyChange={handleCompanyChange}
                                                            openModal={openModal}/>}
    />
    {isStoreManagementModalOpen && <StoreManagementModal environment={environment}
                                                         stores={stores}
                                                         retailerId={retailerId}
                                                         isOpen={isStoreManagementModalOpen}
                                                         toggle={() => setIsStoreManagementModalOpen(false)}/>}
  </Nav>
}
