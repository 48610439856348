import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import SecondaryActionButton from "../general/SecondaryActionButton";
import PrimaryActionButton from "../general/PrimaryActionButton";
import styles from './ArcModal.module.scss';

const ArcModal = ({
                    isOpen, toggle, isErrorModal = false,
                    title, children, loading, icon,

                    primaryActionText,
                    onPrimaryAction,
                    primaryActionDisabled = false,

                    secondaryActionText,
                    onSecondaryAction,
                    secondaryActionDisabled = false,

                    linkActionText,
                    onLinkAction

                  }) => {

  return <Modal className={styles.modalContent + " standard-modal"} isOpen={isOpen} toggle={toggle}>
    <section className={styles.modalCollectionSection + (isErrorModal ? ' ' + styles.errorModal : '')}>
      <div className={styles.modalCollectionContent + " pb-3"}>
        {icon && <ModalHeader className={styles.modalHeader + " pt-4 pb-0"}>
          <div className={"d-flex flex-column align-items-center"}>
            <div
              className={styles.outerIconBackground + ' d-flex justify-content-center align-items-center' + (isErrorModal ? ' ' + styles.errorModal : '')}>
              <div
                className={styles.innerIconBackground + ' d-flex justify-content-center align-items-center' + (isErrorModal ? ' ' + styles.errorModal : '')}>
                <i className={styles.modalIcon + " fas " + icon}/>
              </div>
            </div>
            <h5 className={styles.modalTitleText + " pt-3"}>{title}</h5>
          </div>
        </ModalHeader>}
        <ModalBody className={"py-1"}>
          {children}
        </ModalBody>
      </div>
    </section>
    <ModalFooter className={styles.modalFooter}>
      <div className={'d-flex justify-content-center'}>
        <p className={styles.buttonWrapper}>
          <>
            {onSecondaryAction && <SecondaryActionButton
              className={styles.btnSecondary}
              disabled={secondaryActionDisabled}
              onClick={onSecondaryAction}>
              {secondaryActionText}
            </SecondaryActionButton>
            }
            {onPrimaryAction && <PrimaryActionButton
              className={styles.btnPrimary}
              disabled={primaryActionDisabled || loading}
              onClick={onPrimaryAction}>
              {primaryActionText}
            </PrimaryActionButton>}
            {onLinkAction &&
            <button className={styles.cancelTextButton}
                    style={{zIndex: 1000, border: 'none', background: 'none'}}
                    onClick={onLinkAction}>
              <span className={styles.textButtonUnderline + " " + styles.cancelTextButtonUnderline}>
                {linkActionText}
              </span>
            </button>}
          </>
        </p>
      </div>
    </ModalFooter>
  </Modal>;
}

export default ArcModal;
