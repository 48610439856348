import actionTypes from "../actionTypes";

export function selectProduct(productId, unifiedProductIds) {
  return {
    type: actionTypes.SELECT_PRODUCT,
    productId,
    unifiedProductIds
  }
}

export function selectProductsForOrderConfirmation(productIds, unifiedProductIds) {
  return {
    type: actionTypes.SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION,
    products: productIds,
    unified: unifiedProductIds
  }
}


export function deselectProduct(productId, unifiedProductIds) {
  return {
    type: actionTypes.DESELECT_PRODUCT,
    productId,
    unifiedProductIds
  }
}


export function clearProductTableSelection() {
  return {
    type: actionTypes.CLEAR_PRODUCT_TABLE_SELECTION
  }
}


export function selectAll() {
  return {
    type: actionTypes.SELECT_ALL
  }
}

export function deselectAll() {
  return {
    type: actionTypes.DESELECT_ALL
  }
}


export function productIsVisible(productId, unifiedProductIds) {
  return {
    type: actionTypes.PRODUCT_IS_VISIBLE,
    id: productId,
    unified: unifiedProductIds
  }
}


export function clearVisibleProducts() {
  return {
    type: actionTypes.CLEAR_VISIBLE_PRODUCTS
  }
}

export function selectAllVisible() {
  return {
    type: actionTypes.SELECT_VISIBLE
  }
}


export function deselectAllVisible() {
  return {
    type: actionTypes.DESELECT_VISIBLE
  }
}


export function setVisibleProducts(products, totalProductCount) {
  return {
    type: actionTypes.SET_VISIBLE_PRODUCTS,
    products: products,
    totalCount: totalProductCount
  }
}