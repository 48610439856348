import React, {useEffect} from "react";
import {graphql, QueryRenderer} from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Dropdown, {SelectableOption} from "../../../../atoms/Dropdown";
import {ErrorAlert} from "../../../../commons/errors";
import {SelectDdtContentQuery} from "./__generated__/SelectDdtContentQuery.graphql";

const query = graphql`
  query SelectDdtContentQuery {
    listDistributeDataTemplates {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type DdtContentDropdownProps = {
  ddtId: string | null;
  setDdtId: (ddtId: string) => void;
  options: SelectableOption[];
};

function DdtContentDropdown ({ ddtId, setDdtId, options }: DdtContentDropdownProps) {
  const currentOption = options.filter(op => op.value === ddtId)[0];
  const label = currentOption ? currentOption.label : 'Unknown output selected';

  useEffect(() => {
    const lastUsedDdtId = localStorage.getItem("lastUsedOutputId");

    if (lastUsedDdtId && !ddtId) {
      const lastUsedOption = options.find(op => op.value === lastUsedDdtId);
      if (lastUsedOption?.value) {
        setDdtId(lastUsedOption.value);
      }
    }
  }, [options]);

  return (
    <Dropdown
      currentValue={ddtId ? { label: label } : null}
      placeholder={'Select Output'}
      onSelectOption={(op) => {
        if (op.value) {
          setDdtId(op.value);
          localStorage.setItem("lastUsedOutputId", op.value);
        }
      }}
      options={options}
    />
  );
}

export type SelectDdtContentProps = {
  ddtId: string | null,
  setDdtId: (ddtId: string) => void,
  environment: RelayModernEnvironment
}

export default function SelectDdtContent({environment, setDdtId, ddtId}: SelectDdtContentProps) {

  return <div className={'px-3 pb-3'}>
    <QueryRenderer<SelectDdtContentQuery>
      environment={environment}
      variables={{}}
      query={query}
      render={({props, error}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        }

        if (props && !props.listDistributeDataTemplates) {
          return <ErrorAlert error={'Failed to load the outputs'}/>
        }

        if (props && props.listDistributeDataTemplates) {
          const options = props.listDistributeDataTemplates.edges.map(edge => {
            if (!edge?.node) {
              return null;
            }
            return {label: edge.node.name, value: edge.node.id}
          }).filter(x => x) as SelectableOption[];

          return <div className={'px-5 py-3'}>
            <DdtContentDropdown
              ddtId={ddtId}
              setDdtId={setDdtId}
              options={options}
            />
          </div>
        }

        return 'Loading...';
      }}
    />
  </div>
}
