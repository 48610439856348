import React from "react";
import styles from "../../FieldRules.module.scss";
import RemoveRuleIcon from "../../RemoveRuleIcon";


function ActionReduceAllTokenLengths({onRemove}) {
  return <div className={styles.actionRow}>
    <div className={'d-flex'}>
      <i className="fas fa-asterisk mr-2" style={{fontSize: '12px', lineHeight: '24px'}}/>

      Reduce all token lengths equally

      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </div>
  </div>
}


export default ActionReduceAllTokenLengths;
