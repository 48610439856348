import {ConditionType, RuleType} from "./components/token/rules/setConditionalRules/types";

export type DdtSchemaType = {
  type: string,
  properties:{
    [key: string]: {
      type: string,
    }
  }
} | string[]

export type DDTConfigType = {
  outputField: string,
  config: RowConfigType
}[]

export type RowConfigType = {
  required: boolean,
  suffix?: Suffix;
  rules: FieldRuleType[]
  tokens: TokenType[]
}

export type Suffix = {
  value_start: string,
  only_for_same_value: boolean,
  skip_first: boolean,
  separator: string
}

export type OperatorType = "eq" | "neq" | "contains" | "not_contains" | "starts_with" | "ends_with"

export type Condition = {
  op: OperatorType
  path?: string | null,
  value: string | null,
  match_on_token?: number
}

export enum Location {
  ALL = "all",
  WORD = "word",
  START = "start",
  WORD_BEGIN = "word_begin",
  END = "end",
  WORD_END = "word_end",
  FIRST = "first",
  LAST = "last"
}

export type BrandRuleSetType = {
  brand: string | null,
  rules: GeneralTokenRule[],
  exceptions: TokenRuleException[]
}

export type TokenType = ProductPathTokenType | SeparatorTokenType | PlusTokenType

export type ProductPathTokenType = {
  path: string | null,
  display: string | null,
  type?: string[] | null,
  rules: GeneralTokenRule[],
  brandRules: BrandRuleSetType[],
  glossary: TokenGlossaryType[],
  uv_representation?: string,
  measurement?: string | null,
  image_index?: number
}

export type TokenGlossaryType = {
  type: string,
  raw: string,
  value: string
}

export type MeasurementDataType = {
  name: string,
  category: string
} | null;

export function isProductPathToken(token: TokenType): token is ProductPathTokenType {
  return token.hasOwnProperty('path')
}

export function isSeparatorTokenType(token: TokenType): token is SeparatorTokenType {
  return token.hasOwnProperty('string')
}

export type SeparatorTokenType = {
  string: string
}

export type PlusTokenType = {
  _plus: true
}

export type NewTokenType = {
  name: string,
  path: string,
  type: string[],
  rules?: GeneralTokenRule[]
}

export type ProductFieldType = {
  category: string | null,
  name: string,
  path: string,
  type: string[] | null
}

export type ImageDimensionsType = {
  width: number,
  height: number,
  enable_upscaling: boolean
}

// Token rules

export type CustomTextTokenRuleType = {
  before: string,
  after: string,
  ignore_if_none: boolean
}

export type CustomizeValueBrandRuleProps = {
  values: {
    from: string,
    to: string
  } [],
  onChange: (newValue: { values: { from: string, to: string }[] }) => void,
  onRemove: () => void
}

export enum GeneralRulesTypes {
  ConditionalRule = "ConditionalRule",
  FirstAvailableRule = "FirstAvailableRule",
  CustomTextRule = "CustomTextRule",
  ReplaceCharactersRule = "ReplaceCharactersRule",
  RemoveSectionRule = "RemoveSectionRule",
  TextCaseRule = "TextCaseRule",
  RemoveCharactersRule = "RemoveCharactersRule",
  NumericFormatRule = "NumericFormatRule",
  GenerateDescriptionTokenRule = "GenerateDescriptionTokenRule"
}

export type GeneralTokenRule =
  FirstAvailableRuleType
  | ConditionalRule
  | CustomTextRuleType
  | ReplaceCharactersRuleType
  | RemoveSectionRuleType
  | TextCaseRuleType
  | RemoveCharactersRuleType
  | NumericFormatRuleType
  | GenerateDescriptionTokenRuleType


export type TokenRuleException = {
  field: string | null,
  op: OperatorType,
  value: string | null,
}

export function isConditionalRule(rule: GeneralTokenRule): rule is ConditionalRule {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.ConditionalRule;
}

export type ConditionalRule = {
  type: GeneralRulesTypes.ConditionalRule,
  conditions: ConditionType[],
  replacements: RuleType[],
  kind: 'and' | 'or',
}

export function isNumericFormatRule(rule: GeneralTokenRule): rule is NumericFormatRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.NumericFormatRule;
}

export type NumericFormatRuleType = {
  type: GeneralRulesTypes.NumericFormatRule,
  numeric_format: string | null
}

export function isFirstAvailableRule(rule: GeneralTokenRule): rule is FirstAvailableRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.FirstAvailableRule;
}

export type FirstAvailableRuleType = {
  type: GeneralRulesTypes.FirstAvailableRule,
  paths: string [],
  default_value: string
}

export function isCustomTextRule(rule: GeneralTokenRule): rule is CustomTextRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.CustomTextRule;
}

export type CustomTextRuleType = {
  type: GeneralRulesTypes.CustomTextRule,
  after: string,
  before: string,
  ignore_if_none: boolean
}

export function isReplaceCharactersRule(rule: GeneralTokenRule): rule is ReplaceCharactersRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.ReplaceCharactersRule;
}

export type ReplaceCharactersRuleType = {
  type: GeneralRulesTypes.ReplaceCharactersRule
  replace_with: string
  replace_target: string
  where: Location
  exceptions?: TokenRuleException [],
  keep_text_case: boolean
}

export function isRemoveSectionRule(rule: GeneralTokenRule): rule is RemoveSectionRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.RemoveSectionRule;
}

export type RemoveSectionRuleType = {
  type: GeneralRulesTypes.RemoveSectionRule
  section_path: string
  exceptions?: TokenRuleException []
}

export function isTextCaseRule(rule: GeneralTokenRule): rule is TextCaseRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.TextCaseRule;
}

export type TextCaseRuleType = {
  type: GeneralRulesTypes.TextCaseRule
  transform: "upper" | "lower" | "title"
}

export function isRemoveCharactersRule(rule: GeneralTokenRule): rule is RemoveCharactersRuleType {
  return rule.hasOwnProperty('type') && rule.type === GeneralRulesTypes.RemoveCharactersRule;
}

export type RemoveCharactersRuleType = {
  type: GeneralRulesTypes.RemoveCharactersRule
  pattern: string
  where: Location,
  exceptions?: TokenRuleException []
}

export function isGenerateDescriptionTokenRuleType(rule: GeneralTokenRule): rule is GenerateDescriptionTokenRuleType {
  return rule.hasOwnProperty('language') && rule.hasOwnProperty('sentences') && rule.hasOwnProperty('isUniqueDescription');
}

export type GenerateDescriptionTokenRuleType = {
  type: GeneralRulesTypes.GenerateDescriptionTokenRule,
  language: string,
  sentences: string,
  isUniqueDescription: boolean
}

// Field rule objects

export type FieldRuleType =
  EnumerateSubvariantsFieldRuleType
  | ReduceLengthFieldRuleType
  | ReplaceContentFieldRuleType

export function isEnumerateSubvariantFieldRule(rule: FieldRuleType): rule is EnumerateSubvariantsFieldRuleType {
  return rule.type === "subvariant_enumerate"
}

export function isReplaceContentFieldRule(rule: FieldRuleType): rule is ReplaceContentFieldRuleType {
  return rule.type === "replace_content"
}

export function isReduceLengthFieldRule(rule: FieldRuleType): rule is ReduceLengthFieldRuleType {
  return rule.type === "reduce_length"
}

export type EnumerateSubvariantsFieldRuleType = {
  type: "subvariant_enumerate",
  enumeration_type?: 'csv' | 'range',
  csv_separator?: string,
  range_separator?: string,
  secondary_join?: string,
  mapping?: {[key: string]: string}
}

export type ReduceLengthAlterTokenType = {
  transforms: {
    index: number,
    from_value: string,
    to_value: string
  } []
  type: "alter_token"
}

export type ReplaceContentFieldRuleReplacementsType = {
  index?: number | null,
  value: string[] | string | null,
  isSeparator?: boolean
  isRemove?: boolean,
  target?: string,
  where?: string,
  keep_text_case?: boolean
}

export type ReplaceContentFieldRuleType = {
  type: "replace_content",
  replacements: ReplaceContentFieldRuleReplacementsType[],
  conditions: {
    match_on_token?: number | null,
    index?: number,
    value: string[] | string | null,
    path?: string | null,
  } []
}

export type ReduceLengthReduceAllType = {
  type: "reduce_all_token_lengths"
}

export type ReduceLengthReduceTokenType = {
  "token_indexes": (number | null) [],
  "strategy": null,
  "keep_length": number [],
  "type": "reduce_token_length"
}

export type ReduceLengthActionsType =
  ReduceLengthAlterTokenType
  | ReduceLengthReduceAllType
  | ReduceLengthReduceTokenType

export type ReduceLengthFieldRuleType = {
  type: "reduce_length",
  max_length: "" | number,
  actions: ReduceLengthActionsType []
}

//Misc

export type GlossaryEntry = {
  type: string,
  raw: string,
  display?: string,
  value: string
}
