import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import Button from "../../../atoms/Button";
import {Textarea} from "../../../atoms/Input";
import Pill from "../../../atoms/Pill";
import {Tab, Tabs} from "../../../atoms/Tabs";
import {isExcelFile, isPdfFile} from "../../../commons/validation";
import ModernFileInput from "../../../dataInput/files/ModernFileInput";
import {capitalize} from "../../../stringUtils";
import styles from "../OrderConfirmationUploadWizardModal.module.scss";
import {INVALID_FILE} from "./OrderConfirmationFileUploadStep";

const MODE_FILES = 'files';
const MODE_RAW_BARCODES = 'rawBarcodes';

function readFromFiles(files, onContent) {
  if (!files || files.length === 0) {
    onContent('');
    return;
  }
  const reader = new FileReader();
  reader.addEventListener('load', ev => {
    onContent(reader.result)
  });
  reader.readAsText(files[0], 'utf-8');
}

function BarcodesTextarea({files, onChange, currentConfigName}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    readFromFiles(files, content => {
      setValue(content)
    })
  }, [files, currentConfigName])


  return <Textarea
    rows={10}
    value={value}
    onChange={ev => {
      let content = new TextEncoder().encode(ev.target.value);
      let fakeFile = new File([content], `fakefile_${currentConfigName}.csv`);
      onChange([fakeFile]);
    }}

  />
}

export default function AddOrderConfirmationDisplay({
                                                      toggle,
                                                      currentConfig,
                                                      setCurrentConfig,
                                                      configs,
                                                      files,
                                                      setCurrentDisplay,
                                                      setFilesForConfig,
                                                      replaceFilesForConfig,
                                                      removeFile,
                                                      onLastStepCompleted,
                                                      allowRawBarcodeMode = false
                                                    }) {
  const [mode, setMode] = useState(MODE_FILES)

  let currentConfigName = configs[currentConfig].name;

  return <div className={styles.stepUploadFiles}>
    <div className="d-flex justify-content-center mb-3">
      <Tabs active={currentConfigName}>
        {configs.map((item, index) => {
          let currentFiles = files[item.name];
          return <Tab onClick={() => setCurrentConfig(index)} name={item.name} key={index}>
            {capitalize(item.name)} {currentFiles.length > 0 ? `(${currentFiles.length})` : ''}
          </Tab>
        })}
      </Tabs>
    </div>

    {allowRawBarcodeMode && mode !== MODE_RAW_BARCODES && <Row className={'my-2'}>
      <Col className={'d-flex justify-content-center'}>
        <Pill
          onClick={() => {
            setMode(MODE_RAW_BARCODES)
          }}
          data-testid="orders-button-add-oc-via-barcodes">
          Add an order via barcodes
        </Pill>
      </Col>
    </Row>}

    {mode === MODE_FILES && <ModernFileInput
      allowMultipleSelection={true}
      value={files[currentConfigName]}
      onChange={(files) => {
        if (isPdfFile(files[0]) || isExcelFile(files[0])) {
          setFilesForConfig(currentConfigName, files);
        } else {
          setCurrentDisplay(INVALID_FILE);
        }
      }}
      onRemove={(f) => removeFile(currentConfigName, f)}
      title={'Add order confirmation file'}
      subtitle={null}
      dataTestId={"orders-input-add-oc-file"}
    />
    }
    {mode === MODE_RAW_BARCODES && <BarcodesTextarea
      currentConfigName={currentConfigName}
      files={files[currentConfigName]}
      onChange={files => {
        replaceFilesForConfig(currentConfigName, files)
      }}/>}

    <Row className={'mt-3'}>
      <Col className={'d-flex justify-content-center'}>
        <Button color={'primary'} onClick={() => onLastStepCompleted(files)} data-testid={"orders-button-add-oc-submit-files"}>
          Submit files
        </Button>
      </Col>
    </Row>
  </div>
}
