/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateDDTImagesNamingFormatInput = {
    ddtId: string;
    config?: unknown | null | undefined;
    imageDimensions?: unknown | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateDdtImageNamingFormatMutationVariables = {
    input: UpdateDDTImagesNamingFormatInput;
};
export type UpdateDdtImageNamingFormatMutationResponse = {
    readonly updateDdtImageNamingFormat: {
        readonly ddt: {
            readonly id: string;
            readonly imagesNamingFormat: unknown | null;
            readonly imageDimensions: unknown | null;
        } | null;
    } | null;
};
export type UpdateDdtImageNamingFormatMutation = {
    readonly response: UpdateDdtImageNamingFormatMutationResponse;
    readonly variables: UpdateDdtImageNamingFormatMutationVariables;
};



/*
mutation UpdateDdtImageNamingFormatMutation(
  $input: UpdateDDTImagesNamingFormatInput!
) {
  updateDdtImageNamingFormat(input: $input) {
    ddt {
      id
      imagesNamingFormat
      imageDimensions
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDDTImagesNamingFormatPayload",
    "kind": "LinkedField",
    "name": "updateDdtImageNamingFormat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNode",
        "kind": "LinkedField",
        "name": "ddt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imagesNamingFormat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageDimensions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDdtImageNamingFormatMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDdtImageNamingFormatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "691f799044e45381be096e4eddfaa27f",
    "id": null,
    "metadata": {},
    "name": "UpdateDdtImageNamingFormatMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDdtImageNamingFormatMutation(\n  $input: UpdateDDTImagesNamingFormatInput!\n) {\n  updateDdtImageNamingFormat(input: $input) {\n    ddt {\n      id\n      imagesNamingFormat\n      imageDimensions\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1617a78d49ffd8ddd2a7dfa7aea13c25';
export default node;
