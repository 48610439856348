import React from 'react';
import styles from './DownloadOrdersDirectlyInfo.module.scss'
import greyQuestionMarkIcon from '../../../assets/svg/greyQuestionMarkIcon.svg';
import {Row} from "reactstrap";

function DownloadOrdersDirectlyInfo() {
  return <div className={styles.container}>
    <Row className={"justify-content-center mx-0 mb-3"}>
      <img src={greyQuestionMarkIcon} alt={"Info"}/>
    </Row>
    <Row className={"justify-content-center m-0"}>
      <h4 className={styles.title}>What does this mean?</h4>
    </Row>
    <Row className={"justify-content-center mx-0"}>
      <p className={"mb-0 " + styles.subtext}>Make a list of style numbers or/and barcodes to generate data (and<br/>
        images) that you can download directly using one of your outputs. This<br/>
        will not add an order to the Orders page.</p>
    </Row>
  </div>
}

export default DownloadOrdersDirectlyInfo;
