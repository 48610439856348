import React, {useState} from "react";
import propTypes from 'prop-types';

/***
 * Paragraph that hides the content when it's too long
 *
 * It must have a single string children to work properly. If it has multiple children, it will hide the rest
 * and if it's not a string, it will be rendered as it is.
 */
function ShowMoreParagraph({hideOverLength = 500, hideOverLines = 3, children}) {
  const [expanded, setExpanded] = useState(false);
  let showMoreEnabled = false;
  let content;
  if (Array.isArray(children)) {
    content = children[0];
  } else {
    content = children;
  }
  if (typeof content !== 'string') {
    return <p>{content}</p>;
  }
  if (content.length > hideOverLength) {
    showMoreEnabled = true
  }
  if (!showMoreEnabled) {
    return <p>{content}</p>;
  }
  if (showMoreEnabled) {
    let style = {};
    if (expanded) {

    } else {
      style = {
        whiteSpace: 'normal',
        WebkitLineClamp: hideOverLines,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
      }
    }
    return <React.Fragment>
      <p style={style}>
        {content}
      </p>
      <button onClick={e => {
        e.preventDefault();
        setExpanded(!expanded)
      }}>
        {expanded ? 'Show less' : 'Show more'}
      </button>
    </React.Fragment>
  }
}

ShowMoreParagraph.propTypes = {
  // ove what text length the "Show more" feature is triggered.
  hideOverLength: propTypes.number,
  // when "Show more" is triggered, how many lines will be always visible.
  hideOverLines: propTypes.number,
}

export default ShowMoreParagraph;
