import actionTypes from "../actionTypes";
import {addToArray, ensureNoDuplicates, removeFromArray} from "../common/arrayCommons";


let initialState = {
  visibleProducts: [],  // a list of {id: '...', unified: [unifiedProducts]}
  productCount: 0,
  selectAllVisibleIsChecked: false,  // precomputed value to see if the select all from page checkbox should be checked or not

  allSelected: false,

  selectedProducts: [],
  selectedUnifiedProducts: [],

  allSelectedIgnoredProducts: [],
  allSelectedIgnoredUnifiedProducts: []
};

function allVisibleProductsAreSelected(state) {
  if (state.allSelected) {
    // is true when no Id from visibleProducts is in allSelectedIgnoredProducts
    return state.visibleProducts.filter(item => state.allSelectedIgnoredProducts.indexOf(item.id) !== -1).length === 0;
  } else {
    // is true when all Ids from visibleProducts are in selectedProducts
    return state.visibleProducts.filter(item => state.selectedProducts.indexOf(item.id) === -1).length === 0;
  }
}


function getVisibleProductIds(state) {
  return state.visibleProducts.map(item => item.id);
}

function getVisibleUnifiedProductIds(state) {
  return state.visibleProducts.map(item => item.unifiedproductSet.edges.map(e => e.node.id)).flat();
}

function getAllSelectedState(allSelected) {
  return {
    allSelected: allSelected,
    selectedProducts: [],
    selectedUnifiedProducts: [],
    allSelectedIgnoredProducts: [],
    allSelectedIgnoredUnifiedProducts: []
  };
}

function productsReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {

    case actionTypes.CLEAR_PRODUCT_TABLE_SELECTION:
      return initialState;

    case actionTypes.SELECT_PRODUCT:

      if (state.allSelected) {
        Object.assign(newState, state, {
          allSelectedIgnoredProducts: removeFromArray(state.allSelectedIgnoredProducts, [action.productId]),
          allSelectedIgnoredUnifiedProducts: removeFromArray(state.allSelectedIgnoredUnifiedProducts, action.unifiedProductIds),
        });
      } else {
        Object.assign(newState, state, {
          selectedProducts: addToArray(state.selectedProducts, [action.productId]),
          selectedUnifiedProducts: addToArray(state.selectedUnifiedProducts, action.unifiedProductIds),
        });
      }
      break;
    case actionTypes.SELECT_PRODUCTS_FROM_ORDER_CONFIRMATION:
      Object.assign(newState, state, {
        allSelected: false,
        allSelectedIgnoredProducts: [],
        allSelectedIgnoredUnifiedProducts: [],
        selectedProducts: addToArray(state.selectedProducts, action.products),
        selectedUnifiedProducts: addToArray(state.selectedUnifiedProducts, action.unified),
      });
      break;
    case actionTypes.DESELECT_PRODUCT:
      if (state.allSelected) {
        Object.assign(newState, state, {
          allSelectedIgnoredProducts: addToArray(state.allSelectedIgnoredProducts, [action.productId]),
          allSelectedIgnoredUnifiedProducts: addToArray(state.allSelectedIgnoredUnifiedProducts, action.unifiedProductIds)
        });
      } else {
        Object.assign(newState, state, {
          selectedProducts: removeFromArray(state.selectedProducts, [action.productId]),
          selectedUnifiedProducts: removeFromArray(state.selectedUnifiedProducts, action.unifiedProductIds)
        });
      }
      break;

    case actionTypes.SELECT_ALL:
      Object.assign(newState, state, getAllSelectedState(true));
      break;

    case actionTypes.DESELECT_ALL:
      Object.assign(newState, state, getAllSelectedState(false));
      break;

    case actionTypes.SELECT_VISIBLE:
      if (state.allSelected) {
        Object.assign(newState, state, {
          allSelectedIgnoredProducts: removeFromArray(
              state.allSelectedIgnoredProducts,
              getVisibleProductIds(state)
          ),
          allSelectedIgnoredUnifiedProducts: removeFromArray(
              state.allSelectedIgnoredUnifiedProducts,
              getVisibleUnifiedProductIds(state)
          )
        })
      } else {
        Object.assign(newState, state, {
          selectedProducts: addToArray(state.selectedProducts, getVisibleProductIds(state)),
          selectedUnifiedProducts: addToArray(state.selectedUnifiedProducts, getVisibleUnifiedProductIds(state)),
        });
      }
      break;

    case actionTypes.DESELECT_VISIBLE:
      if (state.allSelected) {
        Object.assign(newState, state, {
          allSelectedIgnoredProducts: addToArray(state.allSelectedIgnoredProducts, getVisibleProductIds(state)),
          allSelectedIgnoredUnifiedProducts: addToArray(state.allSelectedIgnoredUnifiedProducts, getVisibleUnifiedProductIds(state))
        })
      } else {
        Object.assign(newState, state, {
          selectedProducts: removeFromArray(state.selectedProducts, getVisibleProductIds(state)),
          selectedUnifiedProducts: removeFromArray(state.selectedUnifiedProducts, getVisibleUnifiedProductIds(state)),
        });
      }
      break;

    case actionTypes.PRODUCT_IS_VISIBLE:
      Object.assign(newState, state, {
        visibleProducts: addToArray(state.visibleProducts, [{id: action.id, unified: action.unified}])
      });
      break;

    case actionTypes.CLEAR_VISIBLE_PRODUCTS:
      Object.assign(newState, state, {
        visibleProducts: [],
      });
      break;

    case actionTypes.SET_VISIBLE_PRODUCTS:
      Object.assign(newState, state, {
        visibleProducts: action.products,
        productCount: action.totalCount
      })
      break;

    default:
      return state;
  }
  Object.assign(newState, {
    selectAllVisibleIsChecked: allVisibleProductsAreSelected(newState),
    selectedProducts: ensureNoDuplicates(newState.selectedProducts),
    selectedUnifiedProducts: ensureNoDuplicates(newState.selectedUnifiedProducts),
    allSelectedIgnoredProducts: ensureNoDuplicates(newState.allSelectedIgnoredProducts),
    allSelectedIgnoredUnifiedProducts: ensureNoDuplicates(newState.allSelectedIgnoredUnifiedProducts)
  });
  return newState;
}


export default productsReducer;



