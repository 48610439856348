import React, {InputHTMLAttributes} from 'react';
import styles from './Radio.module.scss';

type RadioProps = {
  label: string,
  containerClassNames?: string
} & InputHTMLAttributes<HTMLInputElement>

export default function Radio(props: RadioProps) {
  const {label, type, containerClassNames, ...rest} = props;
  return <div className={`${containerClassNames || ''} ${styles.container}`}>
    <input type="radio" {...rest}/>
    <label className={styles.label}>
      {label}
    </label>
  </div>
}
