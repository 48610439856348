/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LoginRequiredContainerQueryVariables = {};
export type LoginRequiredContainerQueryResponse = {
    readonly currentUser: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly avatar: string | null;
        readonly isSystemAdmin: boolean | null;
        readonly retailer: {
            readonly stores: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly name: string;
                        readonly address: string | null;
                        readonly zipCode: string | null;
                        readonly city: string | null;
                    } | null;
                } | null>;
            };
            readonly id: string;
            readonly name: string;
            readonly logo: string | null;
            readonly hasPermissions: {
                readonly changeDetails: boolean | null;
                readonly manageUsers: boolean | null;
            } | null;
        } | null;
        readonly retailers: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                } | null;
            } | null>;
        } | null;
        readonly activeFeatureFlags: ReadonlyArray<string>;
    } | null;
};
export type LoginRequiredContainerQuery = {
    readonly response: LoginRequiredContainerQueryResponse;
    readonly variables: LoginRequiredContainerQueryVariables;
};



/*
query LoginRequiredContainerQuery {
  currentUser {
    id
    firstName
    lastName
    email
    avatar
    isSystemAdmin
    retailer {
      stores {
        edges {
          node {
            id
            name
            address
            zipCode
            city
          }
        }
      }
      id
      name
      logo
      hasPermissions {
        changeDetails
        manageUsers
      }
    }
    retailers {
      edges {
        node {
          id
          name
        }
      }
    }
    activeFeatureFlags
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSystemAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerNode",
        "kind": "LinkedField",
        "name": "retailer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerStoreNodeConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerStoreNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RetailerStoreNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zipCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerPermissions",
            "kind": "LinkedField",
            "name": "hasPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changeDetails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "manageUsers",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerNodeConnection",
        "kind": "LinkedField",
        "name": "retailers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeFeatureFlags",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginRequiredContainerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginRequiredContainerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d0846cd7368eec2ac6d3a2604e977c6b",
    "id": null,
    "metadata": {},
    "name": "LoginRequiredContainerQuery",
    "operationKind": "query",
    "text": "query LoginRequiredContainerQuery {\n  currentUser {\n    id\n    firstName\n    lastName\n    email\n    avatar\n    isSystemAdmin\n    retailer {\n      stores {\n        edges {\n          node {\n            id\n            name\n            address\n            zipCode\n            city\n          }\n        }\n      }\n      id\n      name\n      logo\n      hasPermissions {\n        changeDetails\n        manageUsers\n      }\n    }\n    retailers {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    activeFeatureFlags\n  }\n}\n"
  }
};
})();
(node as any).hash = '93995f1d61453ae88fb7c011e0e14b94';
export default node;
