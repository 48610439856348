import {multiPartHttpRequest} from "../commons/http";

type UploadFileForCompletionResponseType = {
  distribute_data_output_process_id: string,
  order_confirmation_upload_id: string
}

export function uploadFileForCompletion(
  {ddtId, file, includeImages}: { ddtId: string, file: File, includeImages: boolean },
  onSuccess: (data: UploadFileForCompletionResponseType) => void,
  onError: (error: string) => void,
): void {
  multiPartHttpRequest(
    '/internal/v1/upload-file-for-completion/',
    {ddt_id: ddtId, include_images: includeImages},
    {file},
    (resp: UploadFileForCompletionResponseType) => {
      onSuccess(resp);
    },
    (err: Error[]) => {
      onError(err[0].message)
    }
  )
}
