/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteRetailerStoreMutationInput = {
    id: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteRetailerStoreMutationVariables = {
    input: DeleteRetailerStoreMutationInput;
};
export type DeleteRetailerStoreMutationResponse = {
    readonly deleteRetailerStore: {
        readonly successful: boolean | null;
        readonly retailer: {
            readonly id: string;
            readonly name: string;
            readonly stores: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                    } | null;
                } | null>;
            };
        };
    } | null;
};
export type DeleteRetailerStoreMutation = {
    readonly response: DeleteRetailerStoreMutationResponse;
    readonly variables: DeleteRetailerStoreMutationVariables;
};



/*
mutation DeleteRetailerStoreMutation(
  $input: DeleteRetailerStoreMutationInput!
) {
  deleteRetailerStore(input: $input) {
    successful
    retailer {
      id
      name
      stores {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteRetailerStoreMutationPayload",
    "kind": "LinkedField",
    "name": "deleteRetailerStore",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerNode",
        "kind": "LinkedField",
        "name": "retailer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerStoreNodeConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerStoreNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RetailerStoreNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRetailerStoreMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteRetailerStoreMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a9f1850d6236970e48f25ab1bd41311a",
    "id": null,
    "metadata": {},
    "name": "DeleteRetailerStoreMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRetailerStoreMutation(\n  $input: DeleteRetailerStoreMutationInput!\n) {\n  deleteRetailerStore(input: $input) {\n    successful\n    retailer {\n      id\n      name\n      stores {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9747bf8d681441d0d163111768fcd603';
export default node;
