import React, {useState} from 'react'
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {Col, Row } from 'reactstrap';
import Dropdown, {SelectableOption, useDropdownState} from "../../../../../../../atoms/Dropdown";
import MultiTextInput from "../../../../../../../atoms/MultiTextInput";
import ConditionalRuleReplacements from "./ConditionalRuleReplacements";
import {optionsValues} from "../../../fieldRules/alterContent/ReplacementText";
import ConditionalRuleIcon from "./ConditionalRuleIcon";
import {ConditionalRuleProps, ConditionType, RuleType} from "./types";

export const ruleTypes = {
  REMOVE_DATA_POINTS: 'remove_data_points',
  REMOVE_TEXT: 'remove_text',
  REPLACE_DATA_POINT: 'replace_data_point',
  REPLACE_TEXT: 'replace_text',
  REDUCE_CONTENT: 'ReduceContentRule'
}

export function ConditionalRule({onRemove, productFieldsProvider, onChange,
                                conditions, replacements, kind}: ConditionalRuleProps) {
  const [editedValue, setEditedValue] = useState('');
  const operatorDropdown = useDropdownState({
    options: [
      {value: 'eq', label: 'is'},
      {value: 'neq', label: 'is not'},
      {value: 'contains', label: 'contains'},
      {value: 'contains_word', label: 'contains word'},
      {value: 'not_contains', label: 'does not contain'},
      {value: 'not_contains_word', label: 'does not contain word'},
      {value: 'starts_with', label: 'starts with'},
      {value: 'starts_with_word', label: 'starts with word'},
      {value: 'ends_with', label: 'ends with'},
      {value: 'ends_with_word', label: 'ends with word'},
      {value: 'is_empty', label: 'is empty'},
    ],
    currentValue: conditions[0].op || 'eq'
  })

  const ruleOptions = [
      {
        value: ruleTypes.REDUCE_CONTENT,
        label: 'Reduce content',
        icon: <i className={'fa-light fa-fw fa-text-slash'}/>,
        params: {length: 5, mode: 'chars', strategy: "end", ruleType: ruleTypes.REDUCE_CONTENT},
      },
      // {value: REMOVE_DATA_POINTS, label: 'Remove data points', icon: <i className={'fa-regular fa-circle-minus'}/>, params: {}},
      {
        value: ruleTypes.REMOVE_TEXT,
        label: 'Remove text',
        icon: <i className={'fa-light fa-fw fa-text-slash'}/>,
        params: {index: null, target: '', value: '', where: optionsValues.ALL_OCCURRENCES, keep_text_case: false, ruleType: ruleTypes.REMOVE_TEXT},
      },
      // {value: REPLACE_DATA_POINT, label: 'Replace a data point', icon: <i className={'fa-regular fa-right-left'}/>, params: {},
      {
        value: ruleTypes.REPLACE_TEXT,
        label: 'Replace text',
        icon: <i className={'fa-light fa-fw fa-right-left'}/>,
        params: {index: null, target: '', value: '', where: optionsValues.ALL_OCCURRENCES, keep_text_case: false, ruleType: ruleTypes.REPLACE_TEXT},
      },
    ]

  const onChangeCondition = (condition: ConditionType) => {
    return onChange({kind, conditions: [condition], replacements})
  }

  const onChangeRule = (index: number, replacement: RuleType) => {
    const editedReplacements = [...replacements];
    editedReplacements[index] = replacement;
    onChange({kind, conditions, replacements: [...editedReplacements]})
  }

  const onRemoveRule = (index: number) => {
    const editedReplacements = [...replacements];
    editedReplacements.splice(index, 1)
    onChange({kind, conditions, replacements: [...editedReplacements]})
  }

  const onSubmitValue = () => {
    onChangeCondition({...conditions[0], value: conditions[0].value ? [...conditions[0].value, editedValue] : [editedValue]});
    setEditedValue('');
  }

  const onRemoveValue = (index: number) => {
    const newValue = conditions[0].value;
    newValue?.length && newValue.splice(index, 1);
    onChangeCondition({...conditions[0], value: newValue})
  }

  type RuleDropdownOption = SelectableOption & {params: RuleType}

  const onSelectRuleOption = (ruleOption: RuleDropdownOption) => {
    if(ruleOption.value === ruleTypes.REMOVE_TEXT) {
      onChange({kind, conditions, replacements: [...replacements, ruleOption.params]})
    } else if(ruleOption.value === ruleTypes.REPLACE_TEXT) {
      onChange({kind, conditions, replacements: [...replacements, ruleOption.params]})
    } else if(ruleOption.value === ruleTypes.REDUCE_CONTENT) {
      onChange({kind, conditions, replacements: [...replacements, ruleOption.params]})
    }
  }

  return <div>
    <Row className={'mx-0'}>
      <Col className={'d-flex align-items-center px-0'}>
        <ConditionalRuleIcon />
        <span className={'ml-2'}>When</span>
        <Dropdown
          currentValue={{label: conditions[0].path ? productFieldsProvider.getByPath(conditions[0].path)?.name || 'Select a data point' : 'Select a data point'}}
          options={productFieldsProvider.getMultiMenuOptions()}
          onSelectOption={dataPoint => onChangeCondition({...conditions[0], path: dataPoint.value || ''})}
          className={'ml-2'}
          data-testid={'dataPointDropdown'}/>
        <Dropdown
          currentValue={operatorDropdown.value}
          options={operatorDropdown.options}
          onSelectOption={operator => onChangeCondition({...conditions[0], op: operator.value || 'eq'})}
          className={'mx-2'}
          data-testid={'opDropdown'}/>
        {conditions[0].op !== "is_empty" &&
          <MultiTextInput
          value={editedValue}
          otherValues={conditions[0].value}
          placeholder={'Insert text here...'}
          onRemove={onRemoveValue}
          onChange={e => setEditedValue(e.target.value)}
          onSubmit={onSubmitValue}
          width={'10.625rem'}/>}
        <Dropdown
          currentValue={{label: <i className="fas fa-plus m-0"/>}}
          options={ruleOptions}
          onSelectOption={(option ) => onSelectRuleOption(option as RuleDropdownOption)}
          className={'ml-2'}
          caret={false}
          data-testid={'add-rule-dropdown'}/>
        <RemoveRuleIcon
          onClick={onRemove}
          className={'ml-auto'}/>
      </Col>
    </Row>
    <ConditionalRuleReplacements replacements={replacements} onChange={onChangeRule} onRemove={onRemoveRule}/>
  </div>
}
