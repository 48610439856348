import React, {ReactNode} from "react";
import {Input, InputGroup, InputGroupAddon} from "reactstrap";
import styles from './InputWithIcon.module.scss';

type InputWithIconProps = {
  icon: string | ReactNode,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function InputWithIcon({icon, value, onChange, ...props}: InputWithIconProps) {
  let iconComponent;
  if (typeof icon === 'string') {
    iconComponent = <i className={`fas ${icon}`}/>
  } else {
    iconComponent = icon;
  }
  return <InputGroup>
    <InputGroupAddon addonType={'prepend'} className={styles.icon}>
      {iconComponent}
    </InputGroupAddon>
    <Input value={value} onChange={onChange} className={styles.input} {...props}/>
  </InputGroup>
}
