import React from 'react';
import styles from "./OrderViaBarcodesInfo.module.scss";
import {Row} from "reactstrap";
import greyQuestionMarkIcon from "../../../assets/svg/greyQuestionMarkIcon.svg";

export default function OrderViaBarcodesInfo() {
  return <div className={styles.container}>
    <Row className={"justify-content-center mx-0 mb-3"}>
      <img src={greyQuestionMarkIcon} alt={"Info"}/>
    </Row>
    <Row className={"justify-content-center mx-0"}>
      <h4 className={styles.title}>Before you continue...</h4>
    </Row>
    <Row className={"justify-content-center mx-0"}>
      <p className={"mb-0 " + styles.subtext}>Make sure that the product data related to this order has<br/>
        already been added to Vuuh.</p>
    </Row>
  </div>
}
