import React from "react";
import styles from './ProductPageLoadingState.module.scss'
import {CardBody, Col, Row, Table} from "reactstrap";
import ProductRowPlaceholder from './ProductRowPlaceholder'

export default function ProductPareLoadingState({isRetailerScope}) {
  return <>
    <div className={'d-flex ' + styles.headerPlaceholder}>
      <div className={'ml-auto ' + styles.orderConfirmationButtonPlaceholder}/>
    </div>
    <CardBody>
      <Row>
        <Col md={8}>
          <Row>
            <div className={'px-3'}>
              <div className={styles.collectionDropdownPlaceholder}/>
            </div>
            {isRetailerScope &&
            <div className={'px-3'}>
              <div className={styles.collectionDropdownPlaceholder}/>
            </div>
            }
            <div className={styles.searchBarPlaceholder}/>
          </Row>
        </Col>
        <Col md={{size: 2}}>
          <div className={'pagination d-flex justify-content-end'}>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.paginationButtonPlaceholder}/>
            <div className={'mx-3 ' + styles.paginationPlaceholder}/>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.paginationButtonPlaceholder}/>
          </div>
        </Col>
        <Col md={2}>
          <div className={styles.distributeProductsPlaceholder}/>
        </Col>
      </Row>
      <Table className={'mt-4'}>
        <thead className={'mb-3 ' + styles.roundedTableHeaderPlaceholder}/>
        <tbody>
          <ProductRowPlaceholder/>
          <ProductRowPlaceholder/>
          <ProductRowPlaceholder/>
          <ProductRowPlaceholder/>
          <ProductRowPlaceholder/>
          <ProductRowPlaceholder/>
        </tbody>
      </Table>
    </CardBody>
  </>
}
