import {commitMutation, graphql} from "react-relay";
import Environment from "../Environment";


const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserMutationInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        avatar
      }
    }
  }
`;

function UpdateUserMutation(firstName, lastName, email, avatarData, onSuccess, onError) {

  let input = {
    firstName, lastName, email
  };
  if (avatarData) {
    input.avatar = avatarData;
  }
  const variables = {
    input: input
  };

  commitMutation(
    Environment,
    {
      mutation: mutation,
      variables: variables,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        } else if (response && !errors) {
          onSuccess(response);
        }
      },
      onError: (error) => {
        onError([{message: error}])
      }
    }
  )
}

export default UpdateUserMutation;
