export function persistMarketingPageBrandId(brandId: string) {
  localStorage.setItem('marketingPageBrandId', brandId);
}

export function removeMarketingPageBrandId() {
  localStorage.removeItem('marketingPageBrandId');
}

export function getPersistedMarketingPageBrandId() {
  return localStorage.getItem('marketingPageBrandId')
}
