import React, {useContext} from "react";
import {Table} from "reactstrap";
import {NotAvailable} from "../../../..";
import RoundedTableHeader from "../../../../general/table/RoundedTableHeader";
import styles from './tabs.module.scss';
import {UniqueValueTranslatorContext} from '../../../../context/UniqueValueTranslatorContext'
import jmespath from "@metrichor/jmespath";


function getSubVariantTableValue(unifiedProduct, path) {
  return jmespath.search(unifiedProduct, path);
}


function VariantViewTab({currentVariant, tabConfig, variants}) {
  const translateUniqueValue = useContext(UniqueValueTranslatorContext)
  const subvariants = currentVariant.subVariants;
  return <Table>
    <RoundedTableHeader>
      <th>Size</th>
      {tabConfig.prices.map((p, i) => <th key={i}>{p.name}</th>)}
    </RoundedTableHeader>
    <tbody>
    {subvariants.map(({subVariantName, unifiedProductId, secondarySubvariantName}, index) => {
      let unified = currentVariant.unifiedProducts.filter(u => u.id === unifiedProductId)[0];
      return <React.Fragment key={index}>
        <tr className={styles.sizingTableRow}>
          <td width={'25%'}>
            {translateUniqueValue(subVariantName)}
            {secondarySubvariantName ? ` / ${translateUniqueValue(secondarySubvariantName)}` : null}
          </td>
          {tabConfig.prices.map((price, priceIndex) => {
            return <td key={priceIndex}>
              {translateUniqueValue(getSubVariantTableValue(unified, price.path)) || <NotAvailable/>}
            </td>
          })}
        </tr>
      </React.Fragment>
    })}
    </tbody>
  </Table>
}

export default VariantViewTab;


