import React from "react";
import Input, {InputProps} from "../../../../../../../atoms/Input";

type BeforeEditorProps = {
  before: string,
  onBeforeChange: (newVal: string) => void,
}

type AfterEditorProps = {
  after: string,
  onAfterChange: (newVal: string) => void
}

type BeforeAndAfterEditorProps = BeforeEditorProps & AfterEditorProps;

function InputWrapper(props: InputProps) {
  return <Input style={{width: '10rem'}} placeholder={'Enter custom text'} {...props}/>
}

export function ModeBeforeEditor({before, onBeforeChange}: BeforeEditorProps) {
  return <>
    <span className={'mr-2'}>Custom text in front</span>
    <InputWrapper value={before} onChange={ev => onBeforeChange(ev.target.value)}/>
  </>
}

export function ModeAfterEditor({after, onAfterChange}: AfterEditorProps) {
  return <>
    <span className={'mr-2'}>Custom text after</span>
    <InputWrapper value={after} onChange={ev => onAfterChange(ev.target.value)}/>
  </>
}

export function ModeBeforeAndAfterEditor({before, after, onAfterChange, onBeforeChange}: BeforeAndAfterEditorProps) {
  return <>
    <span className={'mr-2'}>Custom text before</span>
    <InputWrapper value={before} onChange={ev => onBeforeChange(ev.target.value)}/>
    <span className={'mx-2'}>and after</span>
    <InputWrapper value={after} onChange={ev => onAfterChange(ev.target.value)}/>
  </>
}
