/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderViaBarcodesAddInfoBrandOrRetailerDropdownQueryVariables = {};
export type OrderViaBarcodesAddInfoBrandOrRetailerDropdownQueryResponse = {
    readonly listRetailerDataSubscriptions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly brand: {
                    readonly id: string;
                    readonly name: string;
                };
                readonly retailer: {
                    readonly id: string;
                    readonly name: string;
                };
            } | null;
        } | null>;
    } | null;
};
export type OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery = {
    readonly response: OrderViaBarcodesAddInfoBrandOrRetailerDropdownQueryResponse;
    readonly variables: OrderViaBarcodesAddInfoBrandOrRetailerDropdownQueryVariables;
};



/*
query OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery {
  listRetailerDataSubscriptions(state: "active") {
    edges {
      node {
        id
        brand {
          id
          name
        }
        retailer {
          id
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "state",
        "value": "active"
      }
    ],
    "concreteType": "DataSubscriptionNodeConnection",
    "kind": "LinkedField",
    "name": "listRetailerDataSubscriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DataSubscriptionNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataSubscriptionNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNode",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerNode",
                "kind": "LinkedField",
                "name": "retailer",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "listRetailerDataSubscriptions(state:\"active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c24ec922f364fae55ba8bfe28b8ce1a5",
    "id": null,
    "metadata": {},
    "name": "OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery",
    "operationKind": "query",
    "text": "query OrderViaBarcodesAddInfoBrandOrRetailerDropdownQuery {\n  listRetailerDataSubscriptions(state: \"active\") {\n    edges {\n      node {\n        id\n        brand {\n          id\n          name\n        }\n        retailer {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '00d9a36975fc3c6fbdfc913fe00f5d83';
export default node;
