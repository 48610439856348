import React, {useEffect} from 'react';
import {graphql} from "graphql";
import {createFragmentContainer} from 'react-relay';
import propTypes from 'prop-types';
import {Table} from "reactstrap";
import FashionProductItem from "./FashionProductItem";
import {connect} from "react-redux";
import {
  clearVisibleProducts,
  deselectAll,
  deselectAllVisible,
  selectAll,
  selectAllVisible, selectProductsForOrderConfirmation, setVisibleProducts
} from "../../../actions/products";
import CustomCheckbox from "../../../ui-kit/src/dataInput/CustomCheckbox";
import RoundedTableHeader from "../../../ui-kit/src/general/table/RoundedTableHeader";
import {ensureNoDuplicates} from "../../../common/arrayCommons";
import NoContentYet from "../../dataDisplay/NoContentYet";
import ErrorBoundaryRow from "../../../ui-kit/src/specialized/products/row/ErrorBoundaryRow";

const translateIdToDisplayId = (id) => {
  if (atob(id).split(':')[0] === "UnifiedProductNode") {
    return btoa("DisplayUnifiedProductNode:" + atob(id).split(':')[1])
  } else if (atob(id).split(':')[0] === "ProductNode") {
    return btoa("DisplayProductNode:" + atob(id).split(':')[1])
  } else {
    return id
  }
}

function FashionProductList({
                              loading, error, products, orderConfirmationBatch, setVisibleProducts,
                              environment,
                              onSelectAll, onDeselectAll, allSelected,
                              selectedProducts, selectedUnifiedProducts,
                              allSelectedIgnoredProducts, allSelectedIgnoredUnifiedProducts,
                              selectAllVisible, deselectAllVisible,
                              selectAllVisibleIsChecked,
                              selectProductsForOrderConfirmation,
                              setIsFirstOrderConfirmationLoad, isFirstOrderConfirmationLoad
                            }) {

  useEffect(() => {
    setVisibleProducts(products.edges.map(e => e.node), products.totalCount)
  }, [])

  useEffect(() => {
    // when we receive the orderConfirmationBatch prop, we need to auto-update the
    // selection
    if (!orderConfirmationBatch) {
      setIsFirstOrderConfirmationLoad(true)
      return;
    }
    if (isFirstOrderConfirmationLoad) {
      setIsFirstOrderConfirmationLoad(false)
      let products = ensureNoDuplicates(orderConfirmationBatch.identifiedProducts.edges.map(e => {
        return translateIdToDisplayId(e.node.product.id);
      }))
      let unified = orderConfirmationBatch.identifiedProducts.edges.map(e => {
        return translateIdToDisplayId(e.node.unifiedProduct.id);
      })
      selectProductsForOrderConfirmation(products, unified);
    }
  }, [])

  if (products.totalCount === 0) {
    return <NoContentYet
      header={'No products yet'}
      paragraph={'Create a collection to see its products'}
      shadow={false}
      icon={'fa-box-open'}
      linkText={'Go to collections'}
      linkLocation={'/product/collections'}
    />
  } else {

    let selectedProductCount;
    if (allSelected) {
      selectedProductCount = products.totalCount - allSelectedIgnoredProducts.length;
    } else {
      selectedProductCount = selectedProducts.length;
    }
    let canSelectAll = selectedProductCount < products.totalCount;
    let allProductsAreSelected = selectedProductCount === products.totalCount

    return <Table className={'mt-4'}>
      <RoundedTableHeader>
        <td className={"pl-3 pt-0 pb-1"}>
          <CustomCheckbox
            onChange={() => {
              if (selectAllVisibleIsChecked || allProductsAreSelected) {
                deselectAllVisible();
              } else {
                selectAllVisible()
              }
            }}
            checked={allProductsAreSelected}
            indeterminate={(!allProductsAreSelected && selectAllVisibleIsChecked) || (!allProductsAreSelected && selectedUnifiedProducts.length > 0)}
            alternateIndeterminate={selectedUnifiedProducts.length > 0}

          />
        </td>
        <td>
          <p className={'h-100 m-0'}>
            {canSelectAll && <a href="#" onClick={onSelectAll}>Select all</a>}
            {!canSelectAll && <a href="#" onClick={onDeselectAll}>Deselect all</a>}
          </p>
        </td>
        <td>Name</td>
        <td>Collection</td>
        <td>Brand</td>
        <td>Style</td>
        <td></td>
      </RoundedTableHeader>
      <tbody>
      {products.edges.map(({node}) => <ErrorBoundaryRow key={node.id}>
        <FashionProductItem
          environment={environment}
          key={node.id} // this is actually the fragment container that has the __id set to the node's id
          product={node}/>
      </ErrorBoundaryRow>)}
      </tbody>
    </Table>
  }
}

FashionProductList.propTypes = {
  loading: propTypes.bool,
  error: propTypes.string,
  products: propTypes.object,

  selectedProducts: propTypes.array,
  selectedUnifiedProducts: propTypes.array,

  onSelectAll: propTypes.func,
  onDeselectAll: propTypes.func,
  allSelected: propTypes.bool,
  allSelectedIgnoredProducts: propTypes.array,
  allSelectedIgnoredUnifiedProducts: propTypes.array,

  selectAllVisible: propTypes.func,
  deselectAllVisible: propTypes.func,
  selectAllVisibleIsChecked: propTypes.bool,
};

FashionProductList.defaultProps = {
  loading: false,
  error: undefined,
  products: {},  // the relay fragment
};


const mapStateToProps = (state) => {
  return {
    selectedProducts: state.products.selectedProducts,
    selectedUnifiedProducts: state.products.selectedUnifiedProducts,
    allSelected: state.products.allSelected,
    allSelectedIgnoredProducts: state.products.allSelectedIgnoredProducts,
    allSelectedIgnoredUnifiedProducts: state.products.allSelectedIgnoredUnifiedProducts,
    visibleProducts: state.products.visibleProducts,
    selectAllVisibleIsChecked: state.products.selectAllVisibleIsChecked,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onSelectAll: () => {
      dispatch(selectAll());
    },
    onDeselectAll: () => {
      dispatch(deselectAll());
    },
    clearVisibleProducts: () => {
      dispatch(clearVisibleProducts())
    },
    selectAllVisible: () => {
      dispatch(selectAllVisible());
    },
    deselectAllVisible: () => {
      dispatch(deselectAllVisible());
    },
    setVisibleProducts: (products, count) => {
      dispatch(setVisibleProducts(products, count))
    },
    selectProductsForOrderConfirmation: (products, unified) => {
      dispatch(selectProductsForOrderConfirmation(products, unified))
    }
  }
};

export default createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(FashionProductList),
    {
      products: graphql`
        fragment FashionProductList_products on DisplayProductNodeManualConnection {
          totalCount
          edges {
            node {
              ...FashionProductItem_product
              id
              name
              collectionName
              unifiedproductSet {
                totalCount
                edges {
                  node {
                    id
                    colorName
                    colorCode
                    size
                  }
                }
              }
            }
          }
        }
      `,
      orderConfirmationBatch: graphql`
      fragment FashionProductList_orderConfirmationBatch on OrderConfirmationBatchNode {
        identifiedProducts {
          edges {
            node {
              extraData
              unifiedProduct {
                id
              }
              product {
                id
              }
              
            }
          }
        }
      }
    `
    },
)
