/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RegisterQueryVariables = {
    token: string;
};
export type RegisterQueryResponse = {
    readonly getRetailerByClaimToken: {
        readonly errors: ReadonlyArray<{
            readonly code: string;
            readonly message: string;
        } | null> | null;
        readonly retailer: {
            readonly id: string;
            readonly name: string;
            readonly vat: string | null;
        } | null;
        readonly emailAddress: string | null;
        readonly phoneNumber: string | null;
        readonly zipCode: string | null;
        readonly city: string | null;
        readonly address: string | null;
    } | null;
};
export type RegisterQuery = {
    readonly response: RegisterQueryResponse;
    readonly variables: RegisterQueryVariables;
};



/*
query RegisterQuery(
  $token: String!
) {
  getRetailerByClaimToken(token: $token) {
    errors {
      code
      message
    }
    retailer {
      id
      name
      vat
    }
    emailAddress
    phoneNumber
    zipCode
    city
    address
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ErrorNode",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerNode",
  "kind": "LinkedField",
  "name": "retailer",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vat",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetailerInviteNode",
        "kind": "LinkedField",
        "name": "getRetailerByClaimToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RetailerInviteNode",
        "kind": "LinkedField",
        "name": "getRetailerByClaimToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a28a1404774211321a9c2f9619ee1202",
    "id": null,
    "metadata": {},
    "name": "RegisterQuery",
    "operationKind": "query",
    "text": "query RegisterQuery(\n  $token: String!\n) {\n  getRetailerByClaimToken(token: $token) {\n    errors {\n      code\n      message\n    }\n    retailer {\n      id\n      name\n      vat\n    }\n    emailAddress\n    phoneNumber\n    zipCode\n    city\n    address\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd721d75cc000a76c33648e697ead803f';
export default node;
