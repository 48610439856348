import React from 'react'
import styles from './DownloadOrdersDirectlyBarcodesInput.module.scss'
import {Row} from "reactstrap";
import {Textarea} from "../../../atoms/Input";
import Tooltip from "../../../atoms/Tooltip";

const exampleTooltipText = <p className={"m-0"}>
Example:<br />
  5715324209330<br />
  5715324209331<br />
  5715324209332<br />
  <br />
  Style numbers will include all colors and sizes of products.
</p>

type DownloadOrdersDirectlyBarcodesInputProps = {
  barcodes: string,
  setBarcodes: (val: string) => void
}

function DownloadOrdersDirectlyBarcodesInput({barcodes, setBarcodes} : DownloadOrdersDirectlyBarcodesInputProps) {
  return <div className={styles.container}>
    <Row className={"align-items-center justify-content-center mx-0 mb-1"}>
      <h4 className={`mb-0 ${styles.title}`}>
        Add each style number or/and barcode/EAN on a new line
      </h4>
      <Tooltip
        text={exampleTooltipText}
        mode={'help'}
        placement={"bottomRight"}
        arrow={false}>
        <i className={`fa-light fa-circle-question ${styles.infoIcon}`}/>
      </Tooltip>
    </Row>
    <Row className={"justify-content-center mx-0 mb-4"}>
      <p className={`mb-0 ${styles.subtext}`}>You can copy and paste entire lists of style numbers or/and barcodes.</p>
    </Row>
    <Row>
      <Textarea value={barcodes}
                onChange={(e) => setBarcodes(e.target.value)}
                placeholder={"Enter style numbers or/and barcodes here"}
                className={styles.textarea}
                data-testid={"orders-input-download-content-directly-textarea"}/>
    </Row>
  </div>
}

export default DownloadOrdersDirectlyBarcodesInput;
