import noInputFlowsSvg from "../assets/noContentPlaceholders/noInputFlows.svg";
import {Col} from "reactstrap";
import React from "react";
import styles from './NoContentAvailable.module.scss';


type NoContentAvailableProps = {
  image?: string,
  title?: string | JSX.Element,
  subtitle?: string | JSX.Element,
  className?: string
}


export default function NoContentAvailable({image, title, subtitle, className}: NoContentAvailableProps): JSX.Element {
  return <Col className={`d-flex d-column justify-content-center align-items-center ${className}`}>
    <div className={styles.wrapper}>
      <img src={image || noInputFlowsSvg} alt=""/>
      {title && typeof title === "string" ?
        <h2 className={styles.title}>
          {title}
        </h2> : title}
      {subtitle && typeof subtitle === "string" ?
        <p className={styles.subtitle}>
          {subtitle}
        </p> : subtitle}
    </div>
  </Col>
}

export const noContentStyles = styles;
