import React, {useEffect, useState} from 'react';
import Button from './Button';
import Input from "./Input";

export default function KeyValueInlineInput({value, onChange}: {
  value: { [key: string]: string },
  onChange: (value: { [key: string]: string }) => void
}) {
  const [items, setItems] = useState<{ key: string, value: string }[]>(Object.keys(value).map(k => ({
    key: k,
    value: value[k]
  })));


  useEffect(() => {
    let converted: { [key: string]: string } = {}
    for (let item of items) {
      converted[item.key] = item.value
    }
    onChange(converted);
  }, [items])

  return <div className={'d-inline-flex my-2'}>

    {items.map((item, index) => {
      return <div key={'' + index} className={'d-flex'}>
        {index < items.length - 1 && index > 0 && <span className="mx-2">,</span>}
        {index === items.length - 1 && <span className="mx-2">and</span>}

        <Input value={item.key}
               width={'5rem'}
               onChange={(e) => setItems(items.map((oldval, j) => {
                 if (j === index) {
                   return {key: e.target.value, value: oldval.value}
                 }
                 return oldval;
               }))}
        />
        <span className="mx-2">to</span>
        <Input value={item.value}
               width={'5rem'}
               onChange={(e) => setItems(items.map((oldval, j) => {
                 if (j === index) {
                   return {key: oldval.key, value: e.target.value}
                 }
                 return oldval;
               }))}
        />

      </div>
    })}

    <Button className={'ml-2'} icon={'fa-plus'} onClick={() => setItems([...items, {key: '', value: ''}])}/>
  </div>
}
