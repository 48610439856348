import React from "react";
import {withUser} from "../../../../context/UserContext";
import {Col, FormGroup, Label, Row} from "reactstrap";
import PrimaryActionButton from "../../../../ui-kit/src/general/PrimaryActionButton";
import styles from "../SettingsModal.module.scss"

class RetailerForm extends React.Component {

  getBase64 = (file, onLoadFinish) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      onLoadFinish(reader.result);
    };
  };


  render() {
    return <div>
      <Row>
        <p className={styles.subTitle + " mb-4 font-weight-bold"}>Retailer information</p>
      </Row>
      <Row>
        <FormGroup>
          <Row>
            <Col md={4}>
              <Label>Name</Label>
            </Col>
            <Col md={8} className={'p-0'}>
              <input
                disabled={!this.props.hasPermission}
                type="text" className={'form-control'}
                value={this.props.retailerNameValue}
                onChange={e => {
                  this.props.onRetailerNameUpdate(e.target.value)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className={'mb-0'}>
          <Row>
            <Col md={4}>
              <Label>Logo</Label>
            </Col>
            <Col md={8}>
              {!this.props.logoContent &&
              <>
                <Row className={styles.uploadButtonContainer}>
                  <PrimaryActionButton className={styles.browseButton} disabled={!this.props.hasPermission}>
                    Upload Logo
                  </PrimaryActionButton>
                  <input
                    disabled={!this.props.hasPermission}
                    id={"logo-input"}
                    type="file"
                    className={styles.browseInput}
                    onChange={e => {
                      if (e.target.files && (e.target.files[0].type.split('/')[0] === 'image')) {
                        this.getBase64(e.target.files[0], (result) => {
                          this.props.onRetailerLogoUpdate(result)
                        });
                      }
                    }}/>
                </Row>
              </>
              }
            </Col>
          </Row>
        </FormGroup>
      </Row>
      {!this.props.logoContent &&
      <Row>
        <p className={styles.grayMessageText + " pt-3"}>Recommended dimensions: 140x100</p>
      </Row>
      }
      {this.props.logoContent &&
      <>
        <Row>
          <div className={"d-flex justify-content-center align-items-center"}>
            <img id={"retailer-logo"}
                 style={{maxWidth: "140px", maxHeight: "100px"}}
                 src={this.props.logoContent}
                 alt={this.props.user.retailer.name}/>
          </div>
        </Row>
        <Row>
          <div className={styles.removeLogoText + " pt-2"}>
            {this.props.hasPermission && <a href={'#'}
                                            onClick={() => {
                                              this.props.onRetailerLogoUpdate(null)
                                            }}>Remove Logo</a>}
          </div>
        </Row>
      </>
      }
      <Row className={"pt-3"}>
        {this.props.errors.length > 0 &&
        <p id={"error-text"} className={styles.errorText}>{"Error: " + this.props.errors[0].retailerErrors}</p>}
      </Row>
    </div>
  }
}


export default withUser(RetailerForm);
