import {ProductFieldsProvider} from "../../../../utilities";

export type ConditionType = {
  match_on_token: null,
  path: string,
  value: string[],
  op: string
}

export type ConditionalRuleProps = {
  productFieldsProvider: ProductFieldsProvider,
  onChange: (newVal: {
    kind: string,
    conditions: ConditionType[],
    replacements: RuleType[]
  }) => void,
  onRemove: () => void,
  conditions: ConditionType[],
  replacements: RuleType[],
  kind: 'and' | 'or'
}

export type ReplacementRuleType = {
  index: null,
  value: string,
  target: string,
  keep_text_case: boolean,
  where: string,
  ruleType: 'remove_text' | 'replace_text'
}

export type ReduceContentRuleType = {
  length: number,
  mode: string,
  strategy: string,
  ruleType: 'ReduceContentRule'
}

export type RuleType = ReplacementRuleType | ReduceContentRuleType

export function isReplacementRuleType(obj: RuleType): obj is ReplacementRuleType {
  return obj.ruleType === 'remove_text' || obj.ruleType === 'replace_text';
}

export function isReduceContentRuleType(obj: RuleType): obj is ReduceContentRuleType {
  return obj.ruleType === 'ReduceContentRule';
}
