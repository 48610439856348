import React, {useReducer, useState} from "react";
import {Badge} from "reactstrap";
import {capitalize} from "../../../stringUtils";
import AddOrderConfirmationDisplay from './AddOrderConfirmationDisplay'
import InvalidFileIntermediateStep from './InvalidFileIntermediateStep';


export function OrderConfirmConfigPill({name, active, skipped, forceHover, intermediateClass = false}) {
  let style = {}
  if (skipped) {
    style.textDecoration = 'line-through';
  }
  return <Badge
    className={"my-1 " + (intermediateClass ? intermediateClass : '') + (active ? ' active' : '') + (forceHover ? ' forceHover' : '')}
    style={style}>
    {capitalize(name)}
  </Badge>
}

export function createDictFromConfigs(configs) {
  let data = {};
  for (let item of configs) {
    data[item.name] = [];
  }
  return data;
}

export function _fileUploadReducer(state, action) {
  let newState = {...state}

  if (action.type === 'ADD_FILES_FOR_CONFIG') {
    newState[action.name] = [...newState[action.name], ...action.files]
  } else if (action.type === 'REMOVE_FILE') {
    newState[action.name] = newState[action.name].filter((file) => {
      return file !== action.file
    })
  } else if (action.type === 'REPLACE_FILES') {
    newState[action.name] = action.files;
  }
  return newState;
}


export const ADD_ORDER_CONFIRMATION = 0
export const INVALID_FILE = 3

export function DisplayRenderer({
                                  currentDisplay,
                                  configs,
                                  toggle,
                                  currentConfig,
                                  setCurrentConfig,
                                  setCurrentDisplay,
                                  files,
                                  onLastStepCompleted,
                                  removeFile,
                                  setFilesForConfig,
                                  replaceFilesForConfig,
                                  allowRawBarcodeMode = false
                                }) {
  if (currentDisplay === ADD_ORDER_CONFIRMATION) {
    return <AddOrderConfirmationDisplay configs={configs}
                                        toggle={toggle}
                                        files={files}
                                        currentConfig={currentConfig}
                                        setCurrentConfig={setCurrentConfig}
                                        setCurrentDisplay={setCurrentDisplay}
                                        onLastStepCompleted={onLastStepCompleted}
                                        removeFile={removeFile}
                                        setFilesForConfig={setFilesForConfig}
                                        replaceFilesForConfig={replaceFilesForConfig}
                                        allowRawBarcodeMode={allowRawBarcodeMode}/>
  } else if (currentDisplay === INVALID_FILE) {
    return <InvalidFileIntermediateStep
      error={'Invalid file format.'}
    />
  } else {
    return null;
  }
}

export default function OrderConfirmationFileUploadStep({toggle, configs, submitFiles, allowRawBarcodeMode = false}) {
  const [files, dispatch] = useReducer(_fileUploadReducer, createDictFromConfigs(configs));
  const [currentConfig, setCurrentConfig] = useState(0)
  const [currentDisplay, setCurrentDisplay] = useState(ADD_ORDER_CONFIRMATION);


  const setFilesForConfig = (name, files) => {
    return dispatch({type: 'ADD_FILES_FOR_CONFIG', name, files});
  }

  const removeFile = (name, file) => {
    return dispatch({type: 'REMOVE_FILE', name, file});
  }

  const replaceFilesForConfig = (name, files) => {
    return dispatch({type: 'REPLACE_FILES', name, files})
  }

  const onLastStepCompleted = (extraFiles = {}) => {
    // we pass the extra files here because the state change doesn't
    // have enough time to go through on the last item. We dispatch the state with the
    // new file (for the last config), and then we call this immediately, but the actual
    // state change and the updating of "files" is done in the next update cycle.
    submitFiles({...files, ...extraFiles});
  }
  return <DisplayRenderer configs={configs} toggle={toggle} files={files}
                          currentConfig={currentConfig}
                          setCurrentConfig={setCurrentConfig}
                          setCurrentDisplay={setCurrentDisplay}
                          onLastStepCompleted={onLastStepCompleted}
                          removeFile={removeFile}
                          setFilesForConfig={setFilesForConfig}
                          replaceFilesForConfig={replaceFilesForConfig}
                          currentDisplay={currentDisplay}
                          allowRawBarcodeMode={allowRawBarcodeMode}/>
}
