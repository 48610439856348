/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DistributeDataOutputProcessState = "FAILED" | "FINISHED" | "RUNNING" | "STARTED" | "%future added value";
export type GenerateDdtOutputWithOrdersInput = {
    orders: Array<string | null>;
    ddt: string;
    withImages?: boolean | null | undefined;
    allowOrdersWithoutOrderConfirmationBatches?: boolean | null | undefined;
    mergeSameProducts?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type GenerateDdtOutputWithOrdersMutationVariables = {
    input: GenerateDdtOutputWithOrdersInput;
};
export type GenerateDdtOutputWithOrdersMutationResponse = {
    readonly generateDdtOutputWithOrders: {
        readonly outputProcess: {
            readonly id: string;
            readonly state: DistributeDataOutputProcessState;
        } | null;
        readonly ordersWithoutOrderConfirmationBatches: {
            readonly " $fragmentRefs": FragmentRefs<"OrdersWithoutOrderConfirmationModalContent_orders">;
        } | null;
    } | null;
};
export type GenerateDdtOutputWithOrdersMutation = {
    readonly response: GenerateDdtOutputWithOrdersMutationResponse;
    readonly variables: GenerateDdtOutputWithOrdersMutationVariables;
};



/*
mutation GenerateDdtOutputWithOrdersMutation(
  $input: GenerateDdtOutputWithOrdersInput!
) {
  generateDdtOutputWithOrders(input: $input) {
    outputProcess {
      id
      state
    }
    ordersWithoutOrderConfirmationBatches {
      ...OrdersWithoutOrderConfirmationModalContent_orders
    }
  }
}

fragment OrdersWithoutOrderConfirmationModalContent_orders on OrderNodeConnection {
  edges {
    node {
      id
      orderNumber
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DistributeDataOutputProcessNode",
  "kind": "LinkedField",
  "name": "outputProcess",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateDdtOutputWithOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateDdtOutputWithOrdersPayload",
        "kind": "LinkedField",
        "name": "generateDdtOutputWithOrders",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderNodeConnection",
            "kind": "LinkedField",
            "name": "ordersWithoutOrderConfirmationBatches",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrdersWithoutOrderConfirmationModalContent_orders"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateDdtOutputWithOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateDdtOutputWithOrdersPayload",
        "kind": "LinkedField",
        "name": "generateDdtOutputWithOrders",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderNodeConnection",
            "kind": "LinkedField",
            "name": "ordersWithoutOrderConfirmationBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "orderNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b9ce2edd9dba7d283cf3ff319dfd0da",
    "id": null,
    "metadata": {},
    "name": "GenerateDdtOutputWithOrdersMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateDdtOutputWithOrdersMutation(\n  $input: GenerateDdtOutputWithOrdersInput!\n) {\n  generateDdtOutputWithOrders(input: $input) {\n    outputProcess {\n      id\n      state\n    }\n    ordersWithoutOrderConfirmationBatches {\n      ...OrdersWithoutOrderConfirmationModalContent_orders\n    }\n  }\n}\n\nfragment OrdersWithoutOrderConfirmationModalContent_orders on OrderNodeConnection {\n  edges {\n    node {\n      id\n      orderNumber\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a476334c83852f8897c454c2b2c2cbea';
export default node;
