import React from "react";
import {QueryRenderer, graphql} from "react-relay";
import OrderViewLoadingState from "../../specialized/orders/loadingStates/OrderViewLoadingState";
import ManualOrderView from "../../specialized/orders/manuallyUploaded/OrderView";
import AutomaticOrderView from "../../specialized/orders/automaticallyCreated/OrderView";
import {ErrorAlert} from "../../commons/errors";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {BrandUserContextType, isRetailer, RetailerUserContextType} from "../../context/CurrentUserContext";
import {
  SingleOrderPageManuallyUploadedOrdersQuery, SingleOrderPageManuallyUploadedOrdersQueryResponse
} from "./__generated__/SingleOrderPageManuallyUploadedOrdersQuery.graphql";
import {
  SingleOrderPageNormalOrdersQuery,
  SingleOrderPageNormalOrdersQueryResponse
} from "./__generated__/SingleOrderPageNormalOrdersQuery.graphql";

const queryManuallyUploadedOrders = graphql`
  query SingleOrderPageManuallyUploadedOrdersQuery ($id: ID!) {
    getManuallyUploadedOrder (id: $id) {
      id
      orderNumbers
      uploaded
      outputProcesses {
        totalCount
        edges {
          node {
            id
            orders {
              totalCount
              edges {
                node {
                  lastDownload {
                    endDate
                  }
                }
              }
            }
          }
        }
      }
      price {
        currency
        amount
      }
      quantity
      acknowledged
      products {
        count
      }
      ...ErrorsAcknowledgementsModal_manualOrder
      batch {
        id
        totalItemQuantity
        errors {
          reason
          product
          variant
        }
        error
        ...BatchTotalInfo_batch
        ...ErrorsAcknowledgementsModal_batch
        uploads {
          edges {
            node {
              id
              name
              fileUrl
              totalQuantity
            }
          }
        }
      }
      missingData {
        percent
      }
      fromBrand {
        id
        name
      }
      toRetailer {
        id
        name
      }
    }
  }
`;

const queryOrders = graphql`
  query SingleOrderPageNormalOrdersQuery($id: ID!) {
    getOrder(id: $id) {
      id
      dateCreated
      lastDownload {
        endDate
      }
      retailerStore {
        id
        name
      }
      missingData {
        percent
      }
      productCount
      acknowledged
      ...ErrorsAcknowledgementsModal_order
      fromBrand {
        id
        name
      }
      toRetailer {
        id
        name
      }
      price {
        amount
        currency
      }
      quantity
      orderNumber
      orderConfirmationBatch {
        id
        ...BatchTotalInfo_batch
        ...ErrorsAcknowledgementsModal_batch
        error
        errors {
          product
          reason
          variant
        }
      }
      orderConfirmationExtraProducts {
        edges {
          node {
            styleNumber
            colorName
            size
          }
        }
      }
      orderConfirmationMissingProducts {
        edges {
          node {
            styleNumber
            colorName
            size
          }
        }
      }
    }
  }
`;

type SingleOrderPageProps = {
  user: BrandUserContextType | RetailerUserContextType,
  id: string,
  environment: RelayModernEnvironment,
  onClickBack: () => void
}

export default function SingleOrderPage({user, id, environment, onClickBack}: SingleOrderPageProps) {
  const isAutomaticOrder = !atob(id).startsWith('ManuallyUploadedOrderNode');

  return <QueryRenderer<SingleOrderPageManuallyUploadedOrdersQuery | SingleOrderPageNormalOrdersQuery>
    environment={environment}
    query={isAutomaticOrder ? queryOrders : queryManuallyUploadedOrders}
    variables={{id}}
    render={({error, props}) => {
      if (error) {
        return <ErrorAlert error={"Something went wrong"} />
      } else if (props) {
        if(isAutomaticOrder) {
          const orderData = (props as SingleOrderPageNormalOrdersQueryResponse).getOrder
          return <AutomaticOrderView isRetailer={isRetailer(user)}
                                     environment={environment}
                                     user={user}
                                     order={orderData}
                                     onClickBack={onClickBack}/>
        } else {
          const orderData = (props as SingleOrderPageManuallyUploadedOrdersQueryResponse).getManuallyUploadedOrder
          return <ManualOrderView isRetailer={isRetailer(user)}
                                  environment={environment}
                                  user={user}
                                  order={orderData}
                                  onClickBack={onClickBack}/>
        }
      }
      return <OrderViewLoadingState/>
    }}
  />
}
