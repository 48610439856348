import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import React, {Component} from 'react';
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
// Containers
import {DefaultLayout} from './containers';
// Import Main styles for this application
import './scss/style.scss'

// import { renderRoutes } from 'react-router-config';
import store from "./store";
import './ui-kit/src/assets/fontawesome-pro/css/all.css';
import {EnvFetcher} from "./ui-kit/src/commons/http";

// Pages
import {Login} from './views/Pages';
import IntegrationsLayout from "./views/Pages/integrations/IntegrationsLayout";
import Register from "./views/Pages/Register";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Pages/ForgotPassword/ResetPassword";

class App extends Component {
  render() {
    return (
      <EnvFetcher onLoad={(envConfig) => {
          if (!envConfig.sentry_dsn) {
            return;
          }
          Sentry.init({
            dsn: envConfig.sentry_dsn,
            environment: envConfig.environment_name,
            release: envConfig.release_name,
            integrations: [new BrowserTracing()]
          })
        }}>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login}/>
              <Route exact path="/login/:newAccount" name="Login Page" component={Login}/>
              <Route exact path="/register" name="Register Page" component={Register}/>
              <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword}/>
              <Route exact path="/password-reset" name="Forgot Password Page" component={ResetPassword}/>
              <Route path="/integrations" name="Integrations" component={IntegrationsLayout}/>
              <Route path="/" name="Home" component={DefaultLayout}/>
            </Switch>
          </BrowserRouter>
        </Provider>
      </EnvFetcher>
    );
  }
}

export default App;
