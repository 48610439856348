import React from "react";
import RuleException from "./rules/commons/RuleException";
import {TokenRuleException} from "../../types";
import {ProductFieldsProvider} from "../../utilities";

type BrandRuleExceptionsProps = {
  exceptions: TokenRuleException[],
  onChange: (val: TokenRuleException[]) => void,
  productFieldsProvider: ProductFieldsProvider,
}

export function BrandRuleExceptions({exceptions, onChange, productFieldsProvider}: BrandRuleExceptionsProps) {

  return <div className="pl-5 d-flex flex-column">
    {exceptions.map((exc, i) => {
      return <RuleException
        key={i}
        field={exc.field}
        value={exc.value}
        op={exc.op}
        productFieldsProvider={productFieldsProvider}
        onChange={newException => {
          onChange(exceptions.map((oldException, j) => {
            if (i === j) {
              return newException;
            } else {
              return oldException;
            }
          }))
        }}
        onRemove={() => {
          onChange(
            exceptions.filter((e, j) => i !== j)
          );
        }}
      />
    })}
  </div>
}
