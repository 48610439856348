import {OrderViaBarcodesQuantitiesAndPricesTableProps, VariantType} from "../types";
import React, {useEffect} from "react";
import {cloneObject} from "../../../../objectUtils";
import {Table} from "reactstrap";
import styles from "./OrderViaBarcodesQuantitiesAndPrices.module.scss";
import GrayTableHeader, {GrayTableHeaderCell} from "../../../../molecules/tables/GrayTableHeader";
import OrderViaBarcodesQuantitiesAndPricesRow from "./OrderViaBarcodesQuantitiesAndPricesRow";

const round2 = (x: number) => {
  return +x.toFixed(2)
}

export default function OrderViaBarcodesQuantitiesAndPricesTable({products,
                                                                   currency,
                                                                   variants,
                                                                   setVariants,
                                                                 }: OrderViaBarcodesQuantitiesAndPricesTableProps) {
  useEffect(() => {
    const newVariants: VariantType[] = []
    products?.edges.forEach((product, i) => {
      product?.node?.variants?.edges.forEach((variant, j) => {
        newVariants.push({
          id: product?.node?.id ? `${product.node.id}_${j}` : `${j}`,
          styleNumber: product?.node?.styleNumber || "Unknown",
          variantName: variant?.node?.variantName || "Unknown",
          variantCode: variant?.node?.variantCode || "Unknown",
          hexCode: variant?.node?.hexCode || "#FFFFFF",
          subvariants: variant?.node?.subvariants?.edges.map(subvariant => ({
            id: subvariant?.node?.id || "",
            gtin: subvariant?.node?.gtin || "Unknown",
            name: subvariant?.node?.name || "-",
            quantity: null,
            discount: null,
            finalPrice: null,
            totalPrice: null,
            prices: {
              rrp: subvariant?.node?.prices?.rrp || null,
              wsp: subvariant?.node?.prices?.wsp || null
            },
          })) || []
        })
      })
    })

    setVariants(newVariants)
  }, [products]);

  const onQuantityChange = (val: string, variantIndex: number, subvariantIndex: number) => {
    const newVariants = cloneObject(variants) as typeof variants;
    newVariants[variantIndex].subvariants[subvariantIndex].quantity = val || "0";

    const finalPrice = newVariants[variantIndex].subvariants[subvariantIndex].finalPrice;
    const quantity = newVariants[variantIndex].subvariants[subvariantIndex].quantity;
    if(finalPrice != null && quantity != null) {
      newVariants[variantIndex].subvariants[subvariantIndex].totalPrice = round2(finalPrice * Number(quantity));
    }

    setVariants(newVariants);
  }

  const onDiscountChange = (val: string, variantIndex: number, subvariantIndex: number) => {
    if (Number(val) <= 100 || val === "") {
      const newVariants = cloneObject(variants) as typeof variants;
      newVariants[variantIndex].subvariants[subvariantIndex].discount = val.replace(",", ".") || "0";

      const wspPrice = newVariants[variantIndex].subvariants[subvariantIndex].prices.wsp;
      const discount = newVariants[variantIndex].subvariants[subvariantIndex].discount;
      if (wspPrice && wspPrice.amount && discount) {
        newVariants[variantIndex].subvariants[subvariantIndex].finalPrice = round2(wspPrice.amount - (Number(discount) / 100 * wspPrice.amount));

        const finalPrice = newVariants[variantIndex].subvariants[subvariantIndex].finalPrice;
        const quantity = newVariants[variantIndex].subvariants[subvariantIndex].quantity;
        if (finalPrice != null && quantity != null) {
          newVariants[variantIndex].subvariants[subvariantIndex].totalPrice = round2(finalPrice * Number(quantity))
        }
      }

      setVariants(newVariants);
    }
  }

  return <Table className={styles.container}>
    <GrayTableHeader className={'d-flex px-4'} theadClassName={styles.tableHeader}>
      <GrayTableHeaderCell className={styles.tableHeaderCell} width={34}>
        Colors & Sizes
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        RRP ({currency})
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        WSP ({currency})
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        Quantity
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        Discount (%)
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        Price ({currency})
      </GrayTableHeaderCell>
      <GrayTableHeaderCell className={`${styles.tableHeaderCell} justify-content-center`} width={11}>
        Total ({currency})
      </GrayTableHeaderCell>
    </GrayTableHeader>
    <tbody>
    {variants.map((variant, i) => {
      return <OrderViaBarcodesQuantitiesAndPricesRow
        isFirst={i === 0}
        key={variant.id}
        variant={variant}
        onQuantityChange={(val, j) => onQuantityChange(val, i, j)}
        onDiscountChange={(val, j) => onDiscountChange(val, i, j)}/>
    })}
    </tbody>
  </Table>
}
