import React, {useEffect} from 'react';
import {QueryRenderer, graphql} from "react-relay";
import ProductRow from "./ProductRow";
import {usePagination} from "../../../commons/pagination";
import {ErrorAlert} from "../../../commons/errors";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {OrderProductTableQuery, OrderProductTableQueryResponse} from "./__generated__/OrderProductTableQuery.graphql";
import Loading from "../../../atoms/Loading";

const productsTableQuery = graphql`
  query OrderProductTableQuery($id: [ID!], $first: Int, $last: Int, $before: String, $after: String) {
    productsTable(orders: $id, first: $first, after: $after, last: $last, before: $before) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          styleName
          styleNumber
          thumbnail {
            url
          }
          collection {
            id
            name
          }
          brand {
            id
            name
          }
          missingData {
            path
            display
          }
          variants {
            totalCount
            edges {
              node {
                variantName
                variantCode
                hexCode
                missingData {
                  path
                  display
                }
                subvariants {
                  edges {
                    node {
                      id
                      name
                      secondary
                      missingData {
                        path
                        display
                      }
                    }
                  }
                }
              }
            }
          }
          quantity
          price {
            amount
            currency
          }
        }
      }
    }
  }
`

export type VariantType = {
  node: {
    variantName: string,
    variantCode: string,
    hexCode: string,
    missingData: {
      display: string,
      path: string
    }[]
    subvariants: {
      edges: {
        node: {
          id: string,
          name: string,
          secondary: string | null,
          missingData: {
            display: string,
            path: string
          }[]
        }
      }[]
    }
  }
}

export type ProductType = {
  styleName: string | null,
  styleNumber: string | null,
  thumbnail: {
    url: string | null
  },
  variants: {
    totalCount: number,
    edges: VariantType[]
  },
  brand: {
    name: string
  } | null,
  collection: {
    name: string
  } | null
  missingData: {
    display: string,
    path: string
  }[],
  allMissingData: {
    display: string,
    path: string
  }[],
  quantity: number | null,
  price: {
    amount: number,
    currency: string
  } | null
}

type ProductViewType = {
  pageData: {
    totalCount: number
    pageInfo: {
      startCursor: string,
      endCursor: string
    },
  }
  products: ProductType[],
  hasMissingDataStatus: boolean
}

function ProductView({products, pageData, hasMissingDataStatus}: ProductViewType) {
  const {setTotalCount, setFirstCursor, setLastCursor} = usePagination();

  useEffect(() => {
    setTotalCount(pageData.totalCount);
    setFirstCursor(pageData.pageInfo.startCursor);
    setLastCursor(pageData.pageInfo.endCursor);
  }, [pageData.pageInfo.endCursor, pageData.pageInfo.startCursor, pageData.totalCount, setFirstCursor, setLastCursor, setTotalCount])

  return <>
    {products
      .sort((prod1, prod2) => prod2.allMissingData.length - prod1.allMissingData.length)
      .map((product, i) => <ProductRow key={i} product={product} hasMissingDataStatus={hasMissingDataStatus}/>)}
  </>
}

type OrderProductTableType = {
  environment: RelayModernEnvironment,
  hasMissingDataStatus: boolean,
  id: string,
}

const getProducts = (productsTableData: OrderProductTableQueryResponse["productsTable"]) => {
  return productsTableData?.edges.map(product => {
    const allMissingData = [
      ...product?.node?.missingData || [],
      ...(product?.node?.variants?.edges || []).map(variant => variant?.node?.missingData).flat(),
      ...(product?.node?.variants?.edges || []).map(variant => (variant?.node?.subvariants?.edges|| []).map(sub => sub?.node?.missingData)).flat(Infinity)
    ]
    const uniqueAllMissingData = Array.from(new Set(allMissingData.map(data => JSON.stringify(data)))).map(data => JSON.parse(data));

    return {
      styleName: product?.node?.styleName || null,
      styleNumber: product?.node?.styleNumber || null,
      thumbnail: {
        url: product?.node?.thumbnail?.url || null
      },
      variants: {
        totalCount: product?.node?.variants?.totalCount || 0,
        edges: product?.node?.variants?.edges.map(edge => ({
          node: {
            variantName: edge?.node?.variantName || "",
            variantCode: edge?.node?.variantCode || "",
            hexCode: edge?.node?.hexCode || "",
            missingData: edge?.node?.missingData.map(data => ({
              display: data.display || "",
              path: data.path || ""
            })) || [],
            subvariants: {
              edges: edge?.node?.subvariants?.edges.map(subEdge => ({
                node: {
                  id: subEdge?.node?.id || "",
                  name: subEdge?.node?.name || "",
                  secondary: subEdge?.node?.secondary || "",
                  missingData: edge?.node?.missingData.map(data =>  ({
                    display: data.display || "",
                    path: data.path || ""
                  })) || []
                }
              })) || []
            }
          }
        })) || []
      },
      brand: (product?.node?.brand?.name ? {
        name: product.node.brand.name
      } : null),
      collection: (product?.node?.collection?.name ? {
        name: product.node.collection.name
      } : null),
      missingData: product?.node?.missingData.map(data => ({
        display: data.display || "",
        path: data.path || ""
      })) || [],
      allMissingData: uniqueAllMissingData,
      quantity: product?.node?.quantity || null,
      price: product?.node?.price ? {
        amount: product?.node?.price.amount || 0,
        currency: product?.node?.price.currency || ""
      } : null
    }
  }) || []
}

function OrderProductTable({environment, hasMissingDataStatus, id}: OrderProductTableType) {
  const {pagination} = usePagination();

  return <QueryRenderer<OrderProductTableQuery>
    query={productsTableQuery}
    variables={{id: [id], ...pagination}}
    environment={environment}
    render={({props, error}) => {
      if (error) {
        return <ErrorAlert error={error}/>;
      }
      if (props) {
        if (!props?.productsTable) {
          return <ErrorAlert error={"Error fetching products"} />
        }
        const productsTableData = props.productsTable;
        const pageData = {
          totalCount: productsTableData.totalCount || 0,
          pageInfo: {
            startCursor: productsTableData.pageInfo.startCursor || "",
            endCursor: productsTableData.pageInfo.endCursor || "",
          }
        }
        return <ProductView products={getProducts(productsTableData)} pageData={pageData} hasMissingDataStatus={hasMissingDataStatus}/>

      }
      return <Loading className={'d-flex mx-auto'}/>;
    }}
  />
}


export default OrderProductTable;
