import React, {useState} from 'react';
import {OrderViaBarcodesQuantitiesAndPricesRowProps, SubvariantType} from "../types";
import addClassNames from "../../../../classNameUtils";
import styles from "./OrderViaBarcodesQuantitiesAndPrices.module.scss";
import SimpleTableRow, {SimpleTableCell} from "../../../../molecules/tables/SimpleTableRow";
import {isNumeric} from "../../../../stringUtils";
import {noDataIcon} from "./OrderViaBarcodesQuantitiesAndPrices";
import OrderViaBarcodesQuantitiesAndPricesSubRow from "./OrderViaBarcodesQuantitiesAndPricesSubRow";

const getVariantPrice = (subvariants: SubvariantType[], priceType: "rrp" | "wsp") => {
  if (subvariants.some(s => s.prices[priceType]?.amount == null)) {
    return noDataIcon;
  }

  const min = Math.min(...subvariants.map(s => s.prices[priceType]!.amount))
  const max = Math.max(...subvariants.map(s => s.prices[priceType]!.amount))
  let text = `${min} - ${max}`;
  if(min === max) {
    text = `${min}`;
  }

  return <p className={text.length > 8 ? styles.smallTableTextCell : styles.normalTableTextCell}>{text}</p>
}

const getVariantCell = (subvariants: SubvariantType[], cellType: "quantity" | "discount" | "finalPrice" | "totalPrice", isRange: boolean) => {
  if (subvariants.some(s => s[cellType] == null || !isNumeric(s[cellType]!, cellType !== "quantity"))) {
    return noDataIcon;
  }

  let text = '';
  if (isRange) {
    const min = Math.min(...subvariants.map(s => Number(s[cellType]!)))
    const max = Math.max(...subvariants.map(s => Number(s[cellType]!)))

    if (min === max) {
      text = `${min}`;
    } else {
      text = `${min} - ${max}`;
    }
  } else {
    const sum = subvariants.reduce((acc, s) => acc + Number(s[cellType]!), 0)
    text = `${sum}`;
  }

  return <p className={text.length > 8 ? styles.smallTableTextCell : styles.normalTableTextCell}>{text}</p>
}

export default function OrderViaBarcodesQuantitiesAndPricesRow({
                                                                 isFirst,
                                                                 variant,
                                                                 onQuantityChange,
                                                                 onDiscountChange
                                                               }: OrderViaBarcodesQuantitiesAndPricesRowProps) {
  const [isRowOpen, setIsRowOpen] = useState(isFirst);

  return <div className={addClassNames([
    {className: styles.tableRowContainer, condition: true},
    {className: styles.openedTableRow, condition: isRowOpen},
    {className: styles.closedTablerow, condition: !isRowOpen},
  ])}>
    <SimpleTableRow className={addClassNames([
      {className: styles.tableRow, condition: true},
    ])}>
      <SimpleTableCell className={'d-flex py-3'} width={34}>
        <i className={`fa-solid fa-fw fa-caret-${isRowOpen ? "down" : "right"} ${styles.caretIcon}`}
           onClick={() => setIsRowOpen(!isRowOpen)}/>
        <div className={"ml-3"}>
          <p className={styles.styleNumberText}>{variant.styleNumber}</p>
          <div className={'d-flex align-items-center mt-1'}>
            <div style={{backgroundColor: variant.hexCode}}
                 className={styles.colorCircle}/>
            <p className={styles.colorSubtext}>{variant.variantName} ({variant.variantCode})</p>
          </div>
        </div>
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantPrice(variant.subvariants, "rrp")}
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantPrice(variant.subvariants, "wsp")}
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantCell(variant.subvariants, "quantity", false)}
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantCell(variant.subvariants, "discount", true)}
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantCell(variant.subvariants, "finalPrice", true)}
      </SimpleTableCell>
      <SimpleTableCell className={'d-flex justify-content-center py-3'} width={11}>
        {getVariantCell(variant.subvariants, "totalPrice", false)}
      </SimpleTableCell>
    </SimpleTableRow>
    {isRowOpen && <div className={styles.subvariantsContainer}>
      {variant.subvariants.map((subvariant, i) => {
        return <OrderViaBarcodesQuantitiesAndPricesSubRow
          key={subvariant.id}
          subvariant={subvariant}
          onQuantityChange={(val) => onQuantityChange(val, i)}
          onDiscountChange={(val) => onDiscountChange(val, i)}/>
      })}
    </div>}
  </div>
}
