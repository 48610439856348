import React, {useEffect, useRef} from 'react';

// https://coreui.io/docs/components/bootstrap/forms/#checkboxes-and-radios-1


function CustomCheckbox({onChange, checked, label, indeterminate, alternateIndeterminate, size, ...props}) {
  const inputRef = useRef()
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  // We render the input as readonly because of how custom checkboxes are implemented in HTML5+CSS3
  //
  // How it works: it hides the actual <input> and renders another styled thing on top of it
  // whose display is influenced by the input state. But the actual events and interaction is done
  // with the wrapper element (div.custom-control.custom-checkbox).
  //
  // We put the readOnly on the input to suppress a warning in the console.
  return <div className={`custom-control custom-checkbox d-inline-block`}
              onClick={onChange}>
    <input type="checkbox" className={`custom-control-input`}
           checked={checked} readOnly ref={inputRef} {...props}/>
    <label className={`custom-control-label ${alternateIndeterminate && 'alternate-indeterminate'} ${size === 'sm' ? 'checkbox-size-sm' : ''}`}>
      {label}
    </label>
  </div>
}

export default CustomCheckbox;
