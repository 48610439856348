import React from 'react';
import {ruleTypes} from "./ConditionalRule";
import {isReduceContentRuleType, isReplacementRuleType, RuleType} from './types';
import {RemoveTextRule, ReplacementText} from "../../../fieldRules/alterContent/ReplacementText";
import {ErrorAlert} from "../../../../../../../commons/errors";
import ReduceContentRule from "../reduceContent/ReduceContentRule";

type ReplacementRuleProps = {
  ruleParams: RuleType,
  onChange: (val: RuleType) => void,
  onRemove: () => void
}

function ReplacementRule({ruleParams, onChange, onRemove}: ReplacementRuleProps) {
  if(isReplacementRuleType(ruleParams) && ruleParams.ruleType === ruleTypes.REPLACE_TEXT) {
    return <ReplacementText
      target={ruleParams.target}
      value={ruleParams.value}
      keep_text_case={ruleParams.keep_text_case}
      where={ruleParams.where}
      onChange={modifiedKey => onChange({
        ...ruleParams,
        ...modifiedKey
      })}
      onRemove={onRemove}/>
  } else if(isReplacementRuleType(ruleParams) && ruleParams.ruleType === ruleTypes.REMOVE_TEXT) {
    return <RemoveTextRule
      target={ruleParams.target}
      keep_text_case={ruleParams.keep_text_case}
      where={ruleParams.where}
      onChange={modifiedKey => onChange({
        ...ruleParams,
        ...modifiedKey
      })}
      onRemove={onRemove}/>
  } else if(isReduceContentRuleType(ruleParams) && ruleParams.ruleType === ruleTypes.REDUCE_CONTENT) {
    return <ReduceContentRule
      length={ruleParams.length}
      mode={ruleParams.mode}
      strategy={ruleParams.strategy}
      onChange={(newValue) => onChange({...ruleParams, ...newValue})}
      onRemove={onRemove}/>
  } else {
    return <ErrorAlert error={'Rule not found'} />
  }
}

type ConditionalRuleReplacementsType = {
  replacements: RuleType[],
  onChange: (index: number, val: RuleType) => void,
  onRemove: (index: number) => void,
}

export default function ConditionalRuleReplacements({replacements, onChange, onRemove}: ConditionalRuleReplacementsType) {
  return <div style={{marginLeft: '2.125rem'}}>
    {replacements && replacements.map((replacement, index) => <React.Fragment key={index}>
      <hr style={{marginBlock: '1.5rem', marginRight: '2.125rem'}}/>
      <ReplacementRule
        ruleParams={replacement}
        onChange={val => onChange(index, val)}
        onRemove={() => onRemove(index)}/>
    </React.Fragment>
    )}
  </div>

}
