import {commitMutation, Environment, graphql} from "react-relay";
import {UserRegistrationDataType} from "../ui-kit/src/specialized/userForms/creatingAccount/types";
import {PayloadError} from "relay-runtime";


const mutation = graphql`
  mutation CreateRetailerUserMutation($input: CreateRetailerUserMutationInput!) {
    createRetailerUser(input: $input) {
      user {
        id
        email
        brandSet {
          edges {
            node {
              id
              name
            }
          }
        }
      }

    }
  }
`;

type CreateRetailerUserMutation = {
  firstName: string,
  lastName?: string,
  companyName: string,
  email: string,
  password: string,
  repeatPassword: string,
  vatCvr: string,
  address: string,
  city: string,
  postNumber: string,
  checked: boolean,
  phoneNumber: string,
  registerToken: string | null
}

function CreateRetailerUserMutation({
                                      companyName,
                                      email,
                                      password,
                                      repeatPassword,
                                      vatCvr,
                                      address,
                                      city,
                                      postNumber,
                                      checked,
                                      firstName,
                                      lastName,
                                      phoneNumber,
                                      registerToken = null
                                    }: CreateRetailerUserMutation,
                                    environment: Environment,
                                    onSuccess: (email: string, password: string) => void,
                                    onError: (errors: readonly PayloadError[] | Error) => void) {

  let input = {
    email: email,
    password: password,
    repeatPassword: repeatPassword,
    name: companyName,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    company: {
      vat: vatCvr,
      address: address,
      city: city,
      postNumber: postNumber,
    },
    termsChecked: checked && "1.0",
    token: registerToken
  };
  const variables = {
    input: input
  };

  commitMutation(
    environment,
    {
      mutation: mutation,
      variables: variables,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        } else if (response && !errors) {
          onSuccess(email, password);
        }
      },
      onError: (error) => {
        onError(error)
      }
    }
  )
}

export default CreateRetailerUserMutation;
