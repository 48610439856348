import ChangeCurrentRetailer from "../mutations/ChangeCurrentRetailer";
import {ourToast} from '../ui-kit/src/atoms/Toast';

export function handleCompanyChange(company) {
  ChangeCurrentRetailer(
      company.id,
      resp => {
        window.location.href = "/";
      },
      err => {
        ourToast('error', err[0].message);
      }
  )
}