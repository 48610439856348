import React, {isValidElement} from "react";
import styles from "./NoSearchResults.module.scss";
import binocularsIcon from "../assets/noContentPlaceholders/binocularsIcon.svg";
import Button from "../atoms/Button";
import addClassNames from "../classNameUtils";

type ActionButtonProps = {
  onClick: () => void,
  icon?: JSX.Element,
  text?: string,
};

type NoSearchResultsProps = {
  title?: string,
  subtitle?: string,
  actionButton?: ActionButtonProps | JSX.Element,
  className?: string
}

function isActionButtonProps(actionButton: ActionButtonProps | JSX.Element): actionButton is ActionButtonProps {
  return !isValidElement(actionButton);
}

export default function NoSearchResults({title, subtitle, actionButton, className}: NoSearchResultsProps) {

  return <div className={addClassNames([
    {className: styles.noSearchResults, condition: true},
    {className: className, condition: !!className}
  ])}>
    <img src={binocularsIcon} alt={"binoculars"}/>
    <h1 className={styles.noSearchResultsTitle}>{title || "No results"}</h1>
    <h3 className={styles.noSearchResultsText}>{subtitle || "None of the items match what you are looking for."}</h3>
    {actionButton && (
      isActionButtonProps(actionButton) ? (
        <Button onClick={actionButton.onClick} className="mt-4">
          {actionButton.icon ? actionButton.icon : (
            <i className={`fa-light fa-rotate-left ${styles.noSearchResultsButtonIcon}`}/>
          )}
          <p className={styles.noSearchResultsButton}>{actionButton.text || "Reset search and filters"}</p>
        </Button>
      ) : actionButton
    )}
  </div>
}
