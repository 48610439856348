import React from "react";
import styles from './OrderViaBarcodesNoConnectionsWarning.module.scss';
import yellowExclamationMarkIcon from "../../../assets/svg/yellowExclamationMarkIcon.svg";
import Pill from "../../../atoms/Pill";
import {useHistory} from "react-router";

export default function OrderViaBarcodesNoConnectionsWarning({toggle, isRetailer}: {toggle: () => void, isRetailer: boolean}) {
  const history = useHistory();

  const goToConnectionsHandler = () => {
    if (isRetailer) {
      history.push('/brands');
    } else {
      history.push('/distributedata/retailers')
    }
  }

  return <div className={styles.container}>
    <div className={'d-flex justify-content-center mb-3'}>
      <img src={yellowExclamationMarkIcon} alt={"Warning"}/>
    </div>
    <div className={'d-flex justify-content-center'}>
      <h4 className={styles.title}>No {isRetailer ? "brands" : "retailers"} to add an order for</h4>
    </div>
    <div className={'d-flex justify-content-center'}>
      <p className={styles.subtitle}>To add an order, please connect to a {isRetailer ? "brand" : "retailer"} first.</p>
    </div>
    <div className={'d-flex justify-content-center'}>
      <Pill onClick={goToConnectionsHandler}>
        <p className={styles.buttonText}>Go to the {isRetailer ? "Brands" : "Retailers"} page</p>
      </Pill>
    </div>
    <i className={`fa-thin fa-x ${styles.toggleIcon}`} onClick={toggle}/>
  </div>
}
