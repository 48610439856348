import React from "react";
import Checkbox from "../../../../atoms/Checkbox";
import ModernFileInput from "../../../../dataInput/files/ModernFileInput";


export type SelectFileContentProps = {
  files: File[];
  setFiles: (files: File[]) => void;
  includeImages: boolean;
  setIncludeImages: (val: boolean) => void;
}

export default function SelectFileContent({files, setFiles, includeImages, setIncludeImages}: SelectFileContentProps) {
  return <div className={'px-3 pb-3'}>
    <p className={'text-center mt-4'} style={{fontSize: '0.875rem', fontWeight: 400}}>
      <i className="fa-light fa-exclamation-circle mr-2"/>
      Add additional data (as specified in the output) to a custom file with existing content
    </p>
    <div className="d-flex justify-content-center my-3">
      <Checkbox checked={includeImages}
                onChange={() => setIncludeImages(!includeImages)}
                label={'Include images in the download'}
                labelProps={{
                  style: {
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    marginBottom: 0,
                    lineHeight: '1.5rem'
                  }
                }}/>
    </div>
    <div className="px-3">
      <ModernFileInput value={files}
                       onChange={setFiles}
                       onRemove={(f) => setFiles(files.filter(f_ => f.name !== f_.name))}/>
    </div>
  </div>
}
