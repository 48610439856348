import React, {useState} from 'react';
import {Tab} from "../layout/OrderViewLayout";
import {ListGroup, ListGroupItem, Popover} from "reactstrap";
import styles from './OrderErrors.module.scss';


export const ERROR_TYPES = {
  NO_UNIFIED_PRODUCT: 'no_unified_product',
  NO_STYLE_NUMBER: 'no_style_number',
  EXTRA_OC_PRODUCT: 'extra_oc_prod',
  MISSING_OC_PRODUCT: 'missing_oc_prod',
  BARCODE_NOT_FOUND: 'barcode_not_found',
}


function RootLevelError({error}) {
  return <ListGroupItem>
    {error}
  </ListGroupItem>;
}

function ProductLevelError({error}) {
  if (error.reason === ERROR_TYPES.NO_UNIFIED_PRODUCT) {
    return <ListGroupItem>
      <strong>Variant not found for {error.product.name}</strong>
      <br/>
      <span>{error.product.style_number}, {error.variant.color}, {error.variant.size} / {error.variant.secondary_size}</span>
    </ListGroupItem>
  }
  if (error.reason === ERROR_TYPES.NO_STYLE_NUMBER) {
    return <ListGroupItem>
      <strong>Style number not found</strong>
      <br/>
      <span>{error.product.style_number}</span>
    </ListGroupItem>
  }
  if (error.reason === ERROR_TYPES.EXTRA_OC_PRODUCT) {
    return <ListGroupItem>
      <strong>OC has extra</strong>
      <br/>
      <span>{error.style_number}, {error.color}, {error.size}</span>
    </ListGroupItem>
  }
  if (error.reason === ERROR_TYPES.MISSING_OC_PRODUCT) {
    return <ListGroupItem>
      <strong>Missing from OC</strong>
      <br/>
      <span>{error.style_number}, {error.color}, {error.size}</span>
    </ListGroupItem>
  }
  if (error.reason === ERROR_TYPES.BARCODE_NOT_FOUND) {
    return <ListGroupItem>
      <strong>Barcode not found</strong>
      <br/>
      <span>{error.variant && error.variant.gtin}</span>
    </ListGroupItem>
  }
  return null;
}

export default function OrderErrors({errors = [], batchError, extraOcProducts = [], missingOcProducts = []}) {
  const [isOpen, setIsOpen] = useState(false);

  function renderErrors(errorArr) {
    return errorArr && errorArr.length > 0 && errorArr.map((err, i) => <ProductLevelError key={i} error={err}/>)
  }

  return <>
    <Tab id={'order-errors'} isClickable>
      <i className="fas fa-exclamation-circle mr-2 text-warning"/>
      There are some errors
    </Tab>
    <Popover placement={'bottom-start'}
             isOpen={isOpen}
             toggle={() => setIsOpen(!isOpen)}
             target={'order-errors'}
             className={styles.popover}>
      <ListGroup>
        {batchError && <RootLevelError error={batchError}/>}
        {renderErrors(errors)}
        {renderErrors(extraOcProducts)}
        {renderErrors(missingOcProducts)}
      </ListGroup>
    </Popover>
  </>
}
