import React, {ComponentType, useContext} from 'react';
import {RetailerUserContextType} from "../ui-kit/src/context/CurrentUserContext";

const UserContext = React.createContext<RetailerUserContextType | null>(null);

export default UserContext;


export function withUser<Props>(Component: ComponentType<Props>) {

  function UserContextContainer(props: Props) {
    return <UserContext.Consumer>
      {user => {
        return <Component user={user} {...props}/>
      }}
    </UserContext.Consumer>
  }

  return UserContextContainer;
}


export function useCurrentUser() {
  return useContext(UserContext);
}