import React, {useState} from "react";
import styles from '../FieldRules.module.scss';
import Input from "reactstrap/lib/Input";
import removeCharsIcon from '../../../../../../assets/svg/ddtRules/remove_chars.svg';
import transformIcon from '../../../../../../assets/svg/ddtRules/transform.svg';
import ActionReduceTokenLength from './actions/ActionReduceTokenLength';
import ActionReduceAllTokenLengths from "./actions/ActionReduceAllTokenLengths";
import ActionAlterToken from "./actions/ActionAlterToken";
import RemoveRuleIcon from "../RemoveRuleIcon";
import addClassNames from "../../../../../../classNameUtils";
import Dropdown from "../../../../../../atoms/Dropdown";

const ACTIONS = {
  alter_token: {
    label: 'Alter Content Appearance',
    icon: transformIcon,
    base: {transforms: [{index: null, from_value: '', to_value: ''}]},
    component: ActionAlterToken
  },
  reduce_all_token_lengths: {
    label: 'Shorten Data Points Equally',
    icon: removeCharsIcon,
    base: {},
    component: ActionReduceAllTokenLengths
  },
  reduce_token_length: {
    label: 'Shorten Specific Data Points',
    icon: removeCharsIcon,
    base: {token_indexes: [0], strategy: null, keep_length: [0]},
    component: ActionReduceTokenLength
  }
}

function getActionBase(actionId) {
  return {...ACTIONS[actionId].base, type: actionId};
}


function addAction(actionId, rule) {
  return {
    ...rule,
    actions: [...(rule.actions || []), getActionBase(actionId)]
  }
}

function updateAction(rule, index, changes) {
  return {
    ...rule,
    actions: rule.actions.map((origVal, i) => {
      if (i === index) {
        return {...origVal, ...changes};
      } else {
        return origVal;
      }
    })
  }
}


function ReduceLengthFieldRule({value, onChange, onRemove, tokens}) {
  let [isExpanded, setIsExpanded] = useState(false)

  let caretClasses = addClassNames([
    {className: "fa-solid mr-3 ml-auto", condition: true},
    {className: "fa-caret-down", condition: !isExpanded},
    {className: "fa-caret-up", condition: isExpanded},
  ])

  tokens = tokens || [];
  return <div className={styles.container}>
    <div className={'d-flex align-items-center'}>
      <span style={{fontWeight: "normal"}}>When above</span>
      <Input className={'d-inline-block ' + styles.charactersLimitInput}
             autoFocus={true}
             value={value.max_length || ''}
             placeholder={"?"}
             onChange={e => {
               let parsed;
               try {
                 parsed = parseInt(e.target.value);
                 onChange({...value, max_length: parsed});
               } catch (e) {
               }
             }}/>
      <span style={{fontWeight: "normal"}}>characters</span>
      <div className="ml-2 d-inline-block">
        <Dropdown
          currentValue={{label: <i className="fas fa-plus m-0"/>}}
          options={Object.keys(ACTIONS).map(actName => {
            return {
              value: actName,
              label: <div key={actName}>
                <img src={ACTIONS[actName].icon} alt=""/>
                {ACTIONS[actName].label}
              </div>,
            }
          })}
          onSelectOption={(option) =>
            onChange(addAction(option.value, value))
          }
          caret={false}/>
      </div>
      {value.actions.length ?
        <i className={caretClasses} onClick={() => setIsExpanded(!isExpanded)}/> :
        <RemoveRuleIcon onClick={onRemove} className={'ml-auto'}/>
      }
    </div>

    {isExpanded && (value.actions || []).map((action, i) => {
      let ActionComponent = ACTIONS[action.type].component;
      return <div key={i}>
      <hr style={{marginBottom: "1.5rem", marginTop: "1.5rem"}}/>
      <ActionComponent
        {...action}
        onChange={changes => {
          onChange(updateAction(value, i, changes))
        }}
        onRemove={() => {
          onChange({...value, actions: value.actions.filter((a, index) => i !== index)});
        }}
        tokens={tokens}
      />
      </div>
    })}
  </div>
}

export default ReduceLengthFieldRule;
