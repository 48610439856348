import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";


const mutation = graphql`
  mutation AuthorizeWooCommerceShopMutation($input: AuthorizeWooCommerceShopInput!) {
    authorizeWoocommerceShop(input: $input) {
      woocommerceShop {
        distributeDataTemplate {
          name
          engine
        }
        authorizationCode
        rootUrl
        id
      }
    }
  }
`;

function AuthorizeWooCommerceShopMutation(
  environment,
  data,
  onSuccess,
  onError
) {
  createMutationBoilerplate(mutation)(environment, data, onSuccess, onError)
}

export default AuthorizeWooCommerceShopMutation;
