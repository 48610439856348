import React from "react";
import Dropdown from "../../../../../../atoms/Dropdown";


type KindValues = 'and' | 'or';

type KindDropdownProps = {
  value: KindValues,
  onChange: (newKind: KindValues) => void,
  displayAs: 'dropdown' | 'label'
}

function isValidKind(k: string | null | undefined): k is KindValues {
  return !!(k && ['and', 'or'].includes(k));
}
export default function KindDropdown({value, onChange, displayAs}: KindDropdownProps) {
  if (displayAs === 'dropdown') {
    return <Dropdown currentValue={{label: value}}
                     bordered
                     onSelectOption={({value}) => isValidKind(value) && onChange(value)}
                     options={[
                       {label: 'and', value: 'and', disabled: value === 'and'},
                       {label: 'or', value: 'or', disabled: value === 'or'},
                     ]}/>

  } else if (displayAs === 'label') {
    return <span>{value}</span>
  }
}
