import React, {useContext} from "react";
import SingleOrderPage from "../../../ui-kit/src/pages/orders/SingleOrderPage";
import integrationEnvironment from "../../../integrationEnvironment";
import {useParams} from "react-router";
import IntegrationContext from "../../../context/IntegrationContext";

export default function IntegrationOrderView({}) {
  const {id} = useParams();
  const context = useContext(IntegrationContext)

  return <SingleOrderPage
    user={{retailer: {id: context.owner}}}
    environment={integrationEnvironment}
    id={id}
    backLink={"/integrations/woocommerce/orders/"}
  />
}