import React, {useEffect, useState} from "react";
import styles from "../../FieldRules.module.scss";
import Dropdown from "../../../../../../../dataInput/Dropdown";
import RemoveRuleIcon from "../../RemoveRuleIcon";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";
import Input from "reactstrap/lib/Input";
import removeCharsIcon from '../../../../../../../assets/svg/ddtRules/remove_chars.svg';

function getStrategyString(s) {
  return {
    end: 'From the end',
    begin: 'From the beginning',
    words_eq: 'Equally from all words'
  }[s]
}


export function firstUnusedToken(tokens, used_indexes) {
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].path && used_indexes.indexOf(i) === -1) {
      return i;
    }
  }
  return null;
}


export function getInitialConditions(tokens, tokenIndexes, keepLength) {
  return tokenIndexes.map((i, j) => {
    if (keepLength[j] === 0 || keepLength[j] === undefined) {
      return null;
    }
    return {
      token: i,
      limit: keepLength[j]
    }
  }).filter(x => x);
}


export function buildTokenIndexesAndKeepLength(tokenIndexes, conditions, oldKeepLength) {
  let token_indexes = [];
  let keep_length = [];
  let changed = false;

  for (let i = 0; i < tokenIndexes.length; i++) {
    let token = tokenIndexes[i];
    let limit = oldKeepLength[i];
    let cond = conditions.filter(c => c.token === token);
    if (cond.length === 0) {
      token_indexes.push(token);
      keep_length.push(0);
      changed = true;
    } else {
      token_indexes.push(token);
      keep_length.push(cond[0].limit);
      if (cond[0].limit !== limit) {
        changed = true;
      }
    }
  }

  return [token_indexes, keep_length, changed];
}

export function DataPointsChooser({token_indexes, onChange, tokens}) {
  let options = tokens.map((t, i) => ({
      value: i,
      keep: t.path,
      label: t.display,
      disabled: token_indexes.indexOf(i) !== -1  // disable tokens that are already referenced
    })
  ).filter(t => t.keep);

  return <>
    {token_indexes.length === 0 && <Dropdown
      className={'mx-2 px-1'}
      toggleButtonProps={{
        tag: SecondaryActionButton,
        size: 'sm',
        iconRight: <i className="fas fa-caret-down pl-1"/>,
        className: styles.dropdownToken
      }}
      value={null}
      options={options}
      onChange={({value}) => {
        onChange({
          token_indexes: [value],
          keep_length: [0],
        })
      }}
      overflowTooltip={false}
    />}
    {token_indexes.map((tokenIndex, i) => {
      return <React.Fragment key={i}>
        <Dropdown
          className={'mx-2 px-1'}
          toggleButtonProps={{
            tag: SecondaryActionButton,
            size: 'sm',
            iconRight: <i className="fas fa-caret-down pl-1"/>,
            className: styles.dropdownToken
          }}
          value={tokenIndex !== null && {label: tokens[tokenIndex].display}}
          options={options}
          onChange={({value}) => {
            // remove the old index and add the new one
            // we need to make sure there are no duplicated here because it
            // doesn't make sense to mark the same token as candidate for trimming multiple times.
            onChange({
              token_indexes: token_indexes.filter(v => v !== tokenIndex).concat([value])
            })
          }}
          overflowTooltip={false}
        />
        {i < token_indexes.length - 2 && ', '}
        {i === token_indexes.length - 2 && ' and '}
      </React.Fragment>
    })}
  </>
}


export function StrategyChooser({strategy, onChange}) {
  return <Dropdown
    toggleButtonProps={{
      tag: SecondaryActionButton,
      size: 'sm',
      iconRight: <i className="fas fa-caret-down pl-1"/>,
      className: styles.dropdownToken
    }}
    placeholder={'Select a strategy'}
    caret={true}
    value={strategy && {label: getStrategyString(strategy)}}
    options={[
      {
        value: 'end',
        label: <div><img src={removeCharsIcon} className={'mr-2'} alt=""/>{getStrategyString('end')}</div>
      },
      {
        value: 'begin',
        label: <div><img src={removeCharsIcon} className={'mr-2'} alt=""/>{getStrategyString('begin')}</div>
      },
      {
        value: 'words_eq',
        label: <div><img src={removeCharsIcon} className={'mr-2'} alt=""/>{getStrategyString('words_eq')}</div>
      },
    ]}
    onChange={onChange}
    overflowTooltip={false}
  />
}

export function RuleMoreActionsDropdown({tokens, token_indexes, conditions, onAddDataPoint, onAddCondition}) {
  let existingConditions = new Set(conditions.map(c => c.token));
  let allTokenIndexes = new Set(token_indexes);

  const conditionCandidates = [...allTokenIndexes].filter(x => !existingConditions.has(x))

  return <Dropdown
    className={'ml-2 pl-1'}
    toggleButtonProps={{tag: SecondaryActionButton, size: 'sm', className: styles.dropdownToken}}
    placeholder={<i className="fas fa-plus"/>}
    options={[
      {
        value: 'new',
        label: 'Add another data point',
        disabled: firstUnusedToken(tokens, token_indexes) === null,
        icon: 'fa-plus-circle',
        className: styles.dropdownToken

      },
      {
        value: 'condition',
        label: 'Add a condition',
        disabled: conditionCandidates.length === 0,
        icon: 'fa-asterisk',
        className: styles.dropdownToken

      }
    ]}
    onChange={({value}) => {
      if (value === 'new') {
        onAddDataPoint();
      } else if (value === 'condition') {
        onAddCondition();
      }
    }}
    overflowTooltip={false}
  />
}


export function ConditionRow({tokens, onChange, onRemove, condition, alreadyLimitedTokens, tokenIndexes = []}) {
  const selectedToken = tokens[condition.token];

  return <li className={'d-flex align-items-center my-2'}>
    <i className="fas fa-asterisk mr-2" style={{fontSize: '14px', lineHeight: '24px'}}/>

    <span className="mr-2 pr-1">
      Do not shorten
    </span>

    <Dropdown
      className={'d-inline-block'}
      toggleButtonProps={{
        tag: SecondaryActionButton,
        size: 'sm',
        iconRight: <i className="fas fa-caret-down pl-1"/>,
        className: styles.dropdownToken

      }}
      options={tokens.map((t, i) => {
        if (!tokenIndexes.includes(i)) {
          return null
        }
        return {keep: t.path, label: t.display, value: i, disabled: alreadyLimitedTokens.includes(i)};
      }).filter(x => x && x.keep)}
      value={selectedToken && {label: selectedToken.display}}
      placeholder={'Select a data point'}
      onChange={({value}) => {
        onChange({token: parseInt(value)})
      }}
      overflowTooltip={false}
    />

    <span className="mx-2 px-1">
      if shorter than
    </span>

    <Input value={isNaN(condition.limit) ? '' : condition.limit}
           className={'d-inline-block mx-0 ' + styles.charactersLimitInput}
           placeholder={'?'}
           onChange={e => {
             let val = e.target.value;
             if (!isNaN(val)) {
               onChange({limit: parseInt(val)});
             }

           }}/>
    <span className="mx-2 px-1">
      character{condition.limit !== 1 && 's'}
    </span>

    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </li>
}


function ActionReduceTokenLength({onChange, onRemove, token_indexes, strategy, keep_length, tokens}) {
  // conditions must always reflect the state of the token_indexes and keep_length.
  // even when the limit is 0 or null, there should also be a condition reflecting that.
  const [conditions, setConditions] = useState(getInitialConditions(tokens, token_indexes, keep_length));


  useEffect(() => {
    // transform the conditions array to the token_indexes and keep_length pair and send them upstream
    let [newTokenIndexes, newKeepLength, changed] = buildTokenIndexesAndKeepLength(
      token_indexes,
      conditions,
      keep_length
    );
    if (changed) {
      onChange({token_indexes: newTokenIndexes, keep_length: newKeepLength});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions])

  return <div className={styles.actionRow}>
    <div className={'d-flex align-items-center'}>
      <div
        className={"d-flex justify-content-center align-items-center rounded-circle mt-1 mr-2 " + styles.iconBackground}>
        <img src={removeCharsIcon} className={'mb-0'} style={{filter: "brightness(1000%)"}} alt=""/></div>
      Shorten

      <DataPointsChooser token_indexes={token_indexes} onChange={onChange} tokens={tokens}/>

      <StrategyChooser strategy={strategy}
                       onChange={({value}) => {
                         onChange({strategy: value});
                       }}/>

      {strategy && <RuleMoreActionsDropdown tokens={tokens}
                                            token_indexes={token_indexes}
                                            conditions={conditions}
                                            onAddCondition={() => {
                                              setConditions([...conditions, {token: null, limit: ''}]);
                                            }}
                                            onAddDataPoint={() => {
                                              onChange({
                                                token_indexes: [...token_indexes, firstUnusedToken(tokens, token_indexes)],
                                                keep_length: [...keep_length, 0]
                                              })
                                            }}/>}

      <RemoveRuleIcon onClick={onRemove} className={'ml-auto'}/>
    </div>
    <ul className={'mt-3'}>
      {conditions.map((cond, i) => {
        return <div>
          <hr key={i}/>
          <ConditionRow
            alreadyLimitedTokens={conditions.map(c => c.token)}
            tokenIndexes={token_indexes}
            tokens={tokens}
            condition={cond}
            onChange={(newValues) => {
              setConditions(conditions.map((c, j) => {
                if (i === j) {
                  return {...c, ...newValues};
                } else {
                  return c;
                }
              }))
            }}
            onRemove={() => {
              setConditions(conditions.filter((c, j) => j !== i));
            }}
          />
        </div>
      })}
    </ul>
  </div>
}


export default ActionReduceTokenLength;
