/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RemoveAuthorizationForWooCommerceShopInput = {
    url: string;
    authCode: string;
    clientMutationId?: string | null | undefined;
};
export type RemoveAuthorizationForWoocommerceShopMutationVariables = {
    input: RemoveAuthorizationForWooCommerceShopInput;
};
export type RemoveAuthorizationForWoocommerceShopMutationResponse = {
    readonly removeAuthorizationForWoocommerceShop: {
        readonly woocommerceShop: {
            readonly distributeDataTemplate: {
                readonly name: string;
                readonly engine: string | null;
            } | null;
            readonly authorizationCode: string;
            readonly rootUrl: string | null;
            readonly id: string;
            readonly consumerKey: string | null;
            readonly consumerSecret: string | null;
        } | null;
    } | null;
};
export type RemoveAuthorizationForWoocommerceShopMutation = {
    readonly response: RemoveAuthorizationForWoocommerceShopMutationResponse;
    readonly variables: RemoveAuthorizationForWoocommerceShopMutationVariables;
};



/*
mutation RemoveAuthorizationForWoocommerceShopMutation(
  $input: RemoveAuthorizationForWooCommerceShopInput!
) {
  removeAuthorizationForWoocommerceShop(input: $input) {
    woocommerceShop {
      distributeDataTemplate {
        name
        engine
        id
      }
      authorizationCode
      rootUrl
      id
      consumerKey
      consumerSecret
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engine",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorizationCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consumerKey",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consumerSecret",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAuthorizationForWoocommerceShopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveAuthorizationForWooCommerceShopPayload",
        "kind": "LinkedField",
        "name": "removeAuthorizationForWoocommerceShop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WooCommerceShop",
            "kind": "LinkedField",
            "name": "woocommerceShop",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataTemplateNode",
                "kind": "LinkedField",
                "name": "distributeDataTemplate",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveAuthorizationForWoocommerceShopMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveAuthorizationForWooCommerceShopPayload",
        "kind": "LinkedField",
        "name": "removeAuthorizationForWoocommerceShop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WooCommerceShop",
            "kind": "LinkedField",
            "name": "woocommerceShop",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataTemplateNode",
                "kind": "LinkedField",
                "name": "distributeDataTemplate",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28d7851f45f8fe37d0563e42897de480",
    "id": null,
    "metadata": {},
    "name": "RemoveAuthorizationForWoocommerceShopMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAuthorizationForWoocommerceShopMutation(\n  $input: RemoveAuthorizationForWooCommerceShopInput!\n) {\n  removeAuthorizationForWoocommerceShop(input: $input) {\n    woocommerceShop {\n      distributeDataTemplate {\n        name\n        engine\n        id\n      }\n      authorizationCode\n      rootUrl\n      id\n      consumerKey\n      consumerSecret\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '350c95151e8f40b34c17a10b9d2a2d5b';
export default node;
