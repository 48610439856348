import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import styles from "./StoreManagementModal.module.scss"
import {Environment} from "relay-runtime";
import {ourToast} from "../../ui-kit/src/atoms/Toast";
import {StoreType} from "./types";
import EditStoreModal from "./EditStoreModal";
import DeleteRetailerStoreMutation from "../../ui-kit/src/mutations/DeleteRetailerStoreMutation";
import {ModalErrorAlert, PossibleError} from "../../ui-kit/src/commons/errors";
import Button from "../../ui-kit/src/atoms/Button"
import GrayModal from "../../ui-kit/src/dataDisplay/GrayModal";
import AddStoreModal from "./AddStoreModal";
import translateErrorMessage from "./helpers";
import {noop} from "../../common/utilities";
import addClassNames from "../../ui-kit/src/classNameUtils";

type StoreRowProps = {
  store: StoreType,
  environment: Environment,
  isLoading: boolean
  setIsLoading: (state: boolean) => void
  isOnlyStore: boolean,
  isReRender: boolean,
  isLastRow?: boolean,
}

export function StoreRow({
                           store,
                           environment,
                           setIsLoading,
                           isOnlyStore,
                           isReRender,
                           isLastRow
                         }: StoreRowProps) {
  let [error, setError] = useState<PossibleError>("")
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  useEffect(() => {
    setError("")
  }, [isReRender, setError])


  const onDelete = () => {
    setIsLoading(true)
    DeleteRetailerStoreMutation(
      environment,
      {id: store.id},
      (data) => {
        ourToast("success", "Deleted successfully");
        setIsLoading(false)
        setError("")
      },
      (error) => {
        setError(error)
        setIsLoading(false)
      }
    )
  }

  let iconClasses = addClassNames([
    {className: "fa-solid fa-circle-minus ml-4", condition: true},
    {className: styles.redIcon, condition: !isOnlyStore},
    {className: styles.grayIcon, condition: isOnlyStore},
  ])

  let rowClasses = addClassNames([
    {className: styles.lastRow, condition: isLastRow},
    {className: styles.rowContainer, condition: true},
    {className: "mx-5", condition: true},
  ])


  let detailsRowText = "Address not available"

  if (store.address || store.zipCode || store.city) {
    detailsRowText = [store.address, store.zipCode, store.city].filter(x => x).join(", ")
  }

  return <Row className={rowClasses}>
    <Col>
      <Row>
        <p className={"mb-0 " + styles.storeName}> {store.name}</p>
      </Row>
      <Row>
        <span className={styles.storeAddress}>{detailsRowText}</span>
      </Row>
      {error && <Row>
        <ModalErrorAlert error={error} translator={translateErrorMessage}/>
      </Row>}
    </Col>
    <Col className={"d-flex align-items-center"}>
      <Button className={"ml-auto"} onClick={() => setIsEditModalOpen(true)}>
        Edit Settings
      </Button>
      <i role={"img"} className={iconClasses} onClick={!isOnlyStore ? onDelete : noop}/>
    </Col>
    {isEditModalOpen && <EditStoreModal
      store={store}
      environment={environment}
      isOpen={isEditModalOpen}
      toggle={() => setIsEditModalOpen(false)}
    />}
  </Row>
}

type StoreManagementModalBodyProps = {
  environment: Environment,
  stores: StoreType [],
  retailerId: string
}

export function StoreManagementModalBody({stores, environment, retailerId}: StoreManagementModalBodyProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isReRender, setIsReRender] = useState(false)

  useEffect(() => {
    setIsReRender(true)
  }, [stores])

  useEffect(() => {
    setIsReRender(false)
  }, [isReRender])

  return <div className={styles.content}>
    <Col className={"px-0"}>
      <Row className={"ml-5 mr-0 my-4"}>
        <Button onClick={() => setIsAddModalOpen(true)} disabled={isLoading}>
          + Add a location
        </Button>
        {isAddModalOpen && <AddStoreModal isOpen={isAddModalOpen}
                                          toggle={() => setIsAddModalOpen(false)}
                                          environment={environment}
                                          retailerId={retailerId}/>}
      </Row>
      {stores.map((store, i) => {
        return <StoreRow key={store.id}
                         isLastRow={i === stores.length - 1}
                         isReRender={isReRender}
                         isOnlyStore={stores.length === 1}
                         store={store}
                         environment={environment}
                         isLoading={isLoading}
                         setIsLoading={setIsLoading}/>
      })}
    </Col>
  </div>
}

type StoreManagementModalProps = {
  isOpen: boolean,
  toggle: () => void,
  environment: Environment,
  retailerId: string
  stores: StoreType []
}

export default function StoreManagementModal({
                                               isOpen,
                                               toggle,
                                               retailerId,
                                               stores,
                                               environment
                                             }: StoreManagementModalProps) {

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{minWidth: '55.25rem'}}
    secondaryHeaderButton={{text: "Close", onClick: toggle}}
    title={"Store Management"}
    headerContent={<Row className={styles.modalHeader} noGutters>
      <h3 className={"mb-0 " + styles.title}>Locations</h3>
      <i className={"fa-light fa-xmark ml-auto " + styles.closeIcon} onClick={toggle}/>
    </Row>}
    bodyContent={<StoreManagementModalBody
      stores={stores}
      retailerId={retailerId}
      environment={environment}/>}
  />

}
