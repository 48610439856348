import React, {useState} from "react";
import Input, {InputProps} from "./Input";
import Button from "./Button";
import styles from "./MultiTextInput.module.scss"
import {Row} from "reactstrap";
import {useClickOutside} from "../commons/hooks";
import addClassNames from "../classNameUtils";

interface MultiTextInputType extends InputProps {
  isOnDarkBackground?: boolean,
  otherValues: string | string [] | null,
  value: string,
  width: string,
  onRemove: (value: number) => void,
  onSubmit: () => void,
  wrapperClassName?: string,
  disabled?: boolean
  buttonStyle?: React.CSSProperties
}

type ValuesContainerType = {
  otherValues: string [] | null,
  width: string,
  onRemove: (value: number) => void,
  isToDisplayInstructions: boolean,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  onClickOutside: () => void
}

function ValuesContainer({
                           onClickOutside,
                           otherValues,
                           onRemove,
                           isToDisplayInstructions,
                           width,
                           onMouseEnter,
                           onMouseLeave
                         }: ValuesContainerType) {
  const focusRef = useClickOutside(onClickOutside)

  return <div className={styles.container} ref={focusRef}
              style={{width: width}}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}>
    {isToDisplayInstructions &&
      <Row style={{margin: "0.75rem"}}>
        <p className={styles.text + " " + styles.grayColor}>Press enter to add another value</p>
      </Row>}
    {isToDisplayInstructions && otherValues && otherValues.length > 0 &&
      <hr className={"m-0"}/>
    }
    {otherValues && otherValues.map((otherValue, i) => {
      return <Row key={i} style={{margin: "0.75rem"}}>
        <p className={styles.text + " " + styles.blackColor}>{otherValue}</p>
        <i className={"fa-regular fa-circle-xmark ml-auto " + styles.deleteIcon}
           onClick={() => onRemove(i)}/>
      </Row>
    })}
  </div>
}

type UncontrolledMultiTextInput = {
  values: string[],
  setValues: (v: string[]) => void,
  width?: string
}

export function UncontrolledMultiTextInput({values, setValues, width = '20rem'}: UncontrolledMultiTextInput) {
  const [currentInput, setCurrentInput] = useState('');

  return <MultiTextInput
    value={currentInput}
    onChange={e => setCurrentInput(e.target.value)}
    onSubmit={() => {
      setValues([...values, currentInput]);
      setCurrentInput('');
    }}
    onRemove={index => {
      let newVals = [...values];
      newVals.splice(index, 1);
      setValues(newVals);
    }}
    otherValues={values}
    width={width}
  />
}


export default function MultiTextInput({
                                         isOnDarkBackground = false,
                                         value,
                                         otherValues,
                                         onRemove,
                                         onSubmit,
                                         width,
                                         wrapperClassName = '',
                                         disabled = false,
                                         buttonStyle,
                                         ...props
                                       }: MultiTextInputType) {
  const {placeholder = "Insert text here...", ...rest} = props
  let [isInInputState, setIsInInputState] = useState(false)
  let [isToBeKeptOpen, setIsToBeKeptOpen] = useState(true)
  const focusRef = useClickOutside(() => setIsInInputState(isToBeKeptOpen))
  let buttonText = placeholder

  let buttonClasses = addClassNames([
    {className: wrapperClassName, condition: !!wrapperClassName},
    {className: styles.darkDisabled, condition: isOnDarkBackground && disabled}
  ])

  if (otherValues?.length === 0 && isToBeKeptOpen) {
    setIsToBeKeptOpen(false)
  }

  if (typeof otherValues === 'string') {
    otherValues = [otherValues];
  }

  if (otherValues?.length === 1) {
    buttonText = otherValues[0]
  } else if (otherValues?.length === 2) {
    buttonText = otherValues[0] + " and one other"
  } else if (otherValues && otherValues?.length > 2) {
    buttonText = otherValues[0] + " and " + (otherValues?.length - 1) + " others"
  }

  const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value) {
      onSubmit();
    }
  }

  if (isInInputState) {
    return <div className={wrapperClassName ? wrapperClassName : ''}>
      <Input value={value}
             inputRef={focusRef}
             width={width}
             disabled={disabled}
             {...rest}
             onKeyDown={_handleKeyDown}
             autoFocus={true}/>
      {((otherValues && otherValues?.length > 0) || value?.length > 0) &&
        <ValuesContainer otherValues={otherValues}
                         onClickOutside={() => setIsToBeKeptOpen(false)}
                         isToDisplayInstructions={!!value}
                         onRemove={onRemove}
                         width={width}
                         onMouseEnter={() => setIsToBeKeptOpen(true)}
                         onMouseLeave={() => setIsToBeKeptOpen(false)}
        />}
    </div>
  } else {
    return <Button onClick={() => setIsInInputState(true)}
                   disabled={disabled}
                   style={{height: "2rem", ...buttonStyle}}
                   className={buttonClasses}
    >{buttonText}</Button>
  }
}
