import {QueryRenderer, QueryRendererProps} from "react-relay";
import React, {ReactNode, useEffect, useRef} from "react";
import {OperationType} from "relay-runtime";
import {normalizeError} from "../../commons/errors";


type IntervalActionProps = {
  action: () => void
  delay: number,
  children: ReactNode
}

function IntervalAction({action, delay, children}: IntervalActionProps): JSX.Element {
  const ref = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (ref.current) {
      clearInterval(ref.current);
      ref.current = null;
    }

    ref.current = setInterval(action, delay);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    }
  }, [action, delay])

  return <>{children}</>;
}


type PollingQueryRendererBodyProps<TOperation extends OperationType> = {
  onError: (err: string) => void,
  error: string | null,
  onData: (data: TOperation["response"]) => void,
  data: TOperation["response"],
  retry: (() => void) | null,
  delay: number
  children: ReactNode,
}

function PollingQueryRendererBody<TOperation extends OperationType>(props: PollingQueryRendererBodyProps<TOperation>) {
  const {children, error, data, onError, onData, retry, delay} = props;

  useEffect(() => {
    if (error) {
      onError(normalizeError(error) || 'Unknown error');
    }
    if (data) {
      onData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data]);

  if (!retry) {
    return <>{children}</>
  }

  return <IntervalAction
    action={retry}
    delay={delay}
  >
    {children}
  </IntervalAction>;
}


type PollingQueryRendererProps<TOperation extends OperationType> = Omit<QueryRendererProps<TOperation>, 'render'> & {
  delay: number,
  onError: (error: Error | string) => void,
  onData: (data: TOperation['response']) => void,
  children: ReactNode
}

export function PollingQueryRenderer<TOperation extends OperationType>(props: PollingQueryRendererProps<TOperation>) {
  const {delay, onError, onData, children, ...rest} = props;

  return <QueryRenderer<TOperation>
    {...rest}
    render={({props, error, retry}) => {
      return <PollingQueryRendererBody
        data={props}
        error={error ? '' + error : null}
        onData={onData}
        retry={retry}
        delay={delay}
        onError={onError}
      >
        {children}
      </PollingQueryRendererBody>
    }}
  />
}
