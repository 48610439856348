import React from "react";
import {COMPANY_MANAGEMENT_FIXED_OPTIONS} from "./CompanyManagementDropdown";
import {DropdownMenu} from "../../ui-kit/src/atoms/Dropdown";
import {removeMarketingPageBrandId} from "../../ui-kit/src/pages/MarketingMaterials/commons";
import styles from "./CompanyManagementDropdownMenu.module.scss"

type GetRetailerOptionsProps = {
  retailers: { id: string; name: string }[],
  activeRetailerId: string
}

let getRetailerOptions = ({retailers, activeRetailerId}: GetRetailerOptionsProps) => {
  return retailers.map(retailer => {
    return {
      label: <span className={styles.smallFont}>{retailer.name}</span>,
      searchBase: retailer.name,
      value: retailer.id,
      disabled: false,
      icon: retailer.id === activeRetailerId ?
        <i className={`fa-regular fa-circle-dot ${styles.dropdownActiveIcon}`}/> :
        <i className={`fa-regular fa-circle ${styles.dropdownInactiveIcon}`}/>
    }
  })
}

type CompanyManagementDropdownMenuType = {
  openModal: (modal: string) => void,
  retailers: { id: string, name: string } [],
  activeRetailerId: string,
  handleCompanyChange: (company: { id: string }) => void,
}

export default function CompanyManagementDropdownMenu({
                                                        retailers,
                                                        activeRetailerId,
                                                        openModal,
                                                        handleCompanyChange
                                                      }: CompanyManagementDropdownMenuType) {
  let options = [
    ...getRetailerOptions({retailers, activeRetailerId})
      .sort((r1, r2) => {
        if (r1.value === activeRetailerId) {
          return -1;
        } else if (r2.value === activeRetailerId) {
          return 1;
        } else {
          return r1.searchBase.localeCompare(r2.searchBase);
        }
      }),
    {divider: true},
    {
      label: <span className={styles.smallFont}>Add a Retailer</span>,
      value: COMPANY_MANAGEMENT_FIXED_OPTIONS.ADD_A_RETAILER,
      disabled: true,
      icon: <i className={`fa-light fa-circle-plus ${styles.dropdownItemIcon}`}/>
    },
    {
      label: <span className={styles.smallFont}>Retailer Management</span>,
      value: COMPANY_MANAGEMENT_FIXED_OPTIONS.RETAILER_MANAGEMENT,
      disabled: true,
      icon: <i className={`fa-light fa-store ${styles.dropdownItemIcon}`}/>
    },
    {divider: true},
    {
      label: <span className={styles.smallFont}>Locations</span>,
      value: COMPANY_MANAGEMENT_FIXED_OPTIONS.STORE_MANAGEMENT,
      icon: <i className={`fa-light fa-location-dot ${styles.dropdownItemIcon}`}/>,
      className: styles.dropdownOptionHover
    },
  ]

  return <DropdownMenu options={options}
                       onSelectOption={(option) => {
                         if (option.value === COMPANY_MANAGEMENT_FIXED_OPTIONS.STORE_MANAGEMENT) {
                           openModal(COMPANY_MANAGEMENT_FIXED_OPTIONS.STORE_MANAGEMENT)
                         } else if (
                           option?.value &&
                           !Object.values(COMPANY_MANAGEMENT_FIXED_OPTIONS).includes(option?.value) &&
                           option.value !== activeRetailerId
                         ) {
                           removeMarketingPageBrandId()
                           handleCompanyChange({id: option.value})
                         }
                       }}/>

}
