import jmespath from "@metrichor/jmespath";


export class VariantImages {
  constructor(unifiedImageGroups) {
    this.groups = {}
    for (let gr of unifiedImageGroups.edges) {
      this.groups[gr.node.id] = gr.node.images.edges.map(({node}) => node)
    }
  }

  getImageGroup(id) {
    return this.groups[id] || []
  }

  getForUnifiedProducts(unifiedProducts) {
    let images = new Set();
    for (let unif of unifiedProducts) {
      if (unif.hasOwnProperty('imageGroups')) {
        for (let imgGroupId of unif.imageGroups.edges.map(({node}) => node.id)) {
          for (let img of this.getImageGroup(imgGroupId)) {
            images = images.add(img);
          }
        }
      }
    }
    return [...images];
  }
}

class VariantBuilder {
  constructor(
    product,
    variantKeys = null,
    subVariantKeys = null,
    variantDisplayExpr = null,
    secondarySubvariantKeys = null
  ) {
    this.product = product;
    this.variants = [];
    this.variantKeys = variantKeys || ['colorName', 'colorCode']
    this.variantDisplayExpr = variantDisplayExpr;
    this.subVariantKeys = subVariantKeys || ['size']
    this.secondarySubvariantKeys = secondarySubvariantKeys || ['secondarySize'];
    this.imagesBank = new VariantImages(product.unifiedImageGroups || {edges: []});
  }

  build() {
    if (!this.product) {
      return [];
    }
    return this.getColorBuckets();
  }

  unifiedMatchesBucket(bucket, unified) {
    return bucket.name === this.getVariantNameFromUnified(unified)
  }

  getFirstKeyAvailable(data, keys) {
    for (let k of keys) {
      let val = jmespath.search(data, k)
      if (val) {
        return val;
      }
    }
    return null;
  }

  getVariantNameFromUnified(unified) {
    if (this.variantDisplayExpr) {
      try {
        return jmespath.search(unified, this.variantDisplayExpr);
      } catch (e) {
        console.error(`Failed to build the product variant name: ${e}`)
        // If the jmespath expression is bad, fallback to the default behavior.
        // It's better to not crash the app XD
      }
    }
    // fallback to the default behavior, returning the first available key for variant
    return this.getFirstKeyAvailable(unified, this.variantKeys)
  }

  getSubvariantNameFromUnified(unified) {
    return this.getFirstKeyAvailable(unified, this.subVariantKeys);
  }

  getSecondarySubvariantNameFromUnified(unified) {
    return this.getFirstKeyAvailable(unified, this.secondarySubvariantKeys);
  }

  getBucket(buckets, unified) {
    let candidate = buckets.filter(b => {
      return this.unifiedMatchesBucket(b, unified);
    })
    if (candidate.length === 0) {
      const newBucket = {
        id: buckets.length,
        name: this.getVariantNameFromUnified(unified),
        unifiedProducts: [],
        subVariants: [],
        images: []
      }
      buckets.push(newBucket)
      return newBucket;
    } else {
      return candidate[0];
    }
  }

  addUnifiedToBucket(bucket, unified) {
    bucket.unifiedProducts.push(unified);
    bucket.subVariants.push({
      subVariantID: bucket.subVariants.length,
      subVariantName: this.getSubvariantNameFromUnified(unified),
      secondarySubvariantName: this.getSecondarySubvariantNameFromUnified(unified),
      unifiedProductId: unified.id
    })
  }

  getColorBuckets() {
    let buckets = [];
    for (let unifEdge of this.product.unifiedproductSet.edges) {
      let unif = unifEdge.node;
      if (unif.hasOwnProperty('data')) {
        // to not lose the ID of the unified product, we'll need it inside the products
        unif = this.injectUnifiedDataIfNecessary(unif);
      }
      let bucket = this.getBucket(buckets, unif);
      this.addUnifiedToBucket(bucket, unif);
    }
    for (let bucket of buckets) {
      bucket.images = this.imagesBank.getForUnifiedProducts(bucket.unifiedProducts);
    }
    return buckets;
  }

  injectUnifiedDataIfNecessary(unif) {
    let finalUnif = JSON.parse(JSON.stringify(unif.data));
    finalUnif.id = unif.id;
    if (unif.hasOwnProperty('imageGroups')) {
      finalUnif.imageGroups = unif.imageGroups;
    }
    return finalUnif;
  }
}


export default VariantBuilder;
