import {graphql} from "graphql";
import React from "react";
import Loading from "../../../atoms/Loading";
import {PollingQueryRenderer} from "../../../general/graphql/PollingQueryRenderer";


const query = graphql`
  query UploadingFilesStepQuery($id: ID!) {
    orderConfirmationBatch(id: $id) {
      id
      state
      error
    }
  }
`

function isFinishedOrFailed(orderConfirmationBatch) {
  return ['finished', 'failed'].includes(orderConfirmationBatch.state.toLowerCase());
}


export function UploadingFilesStep({pendingBatchId, onFinished, environment}) {
  if (!pendingBatchId) {
    return <div>
      <p className="text-dark">
        Processing files
      </p>
      <Loading/>
    </div>
  }
  return <PollingQueryRenderer
    environment={environment}
    query={query}
    variables={{id: pendingBatchId}}
    delay={1500}
    onError={err => {
      onFinished(null);
    }}
    onData={data => {
      if (data && isFinishedOrFailed(data.orderConfirmationBatch)) {
        onFinished(data.orderConfirmationBatch)
      }
    }}
  >
    <div>
      <p className="text-dark">
        Processing files
      </p>
      <Loading/>
    </div>
  </PollingQueryRenderer>
}
