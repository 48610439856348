import React, {useEffect} from "react";
import {Row} from "reactstrap";
import Dropdown from "../../../atoms/Dropdown";
import {createFragmentContainer, graphql} from "react-relay";
import styles from './SelectDdtContent.module.scss'
import Checkbox from "../../../atoms/Checkbox";
import {useHistory} from "react-router-dom";
import {SelectDdtContent_ddts$data} from "./__generated__/SelectDdtContent_ddts.graphql";

export type DdtType = {
  disabled: boolean,
  isIntegration: string | null,
  label: string,
  outputType: string,
  value: string
}

type OptionType = {
  value: string,
  label: string,
  isIntegration: string | null,
  disabled: boolean,
  outputType: string,
}

type SelectDdtContentDropdownProps = {
  ddt: DdtType | null,
  setDdt: (val: DdtType) => void,
  options: OptionType[],
}

function SelectDdtContentDropdown({ddt, setDdt, options}: SelectDdtContentDropdownProps) {
  useEffect(() => {
    const lastUsedDdtId = localStorage.getItem("lastUsedOutputId");

    if(lastUsedDdtId && !ddt) {
      const lastUsedOption = options.find(op => op.value === lastUsedDdtId);

      if(lastUsedOption) {
        setDdt(lastUsedOption)
      }
    }
  }, [options]);

  return <Dropdown currentValue={(ddt && ddt.value) ? {label: ddt.label} : {label: "Select"}}
                   options={[
                     {
                       header: true,
                       label: <div className={"d-flex"}>Templates <div className={styles.lightDropdownText}>Download</div></div>
                     },
                     ...options.filter(option => option.outputType === "TEMPLATE"),
                     {divider: true},
                     {
                       header: true,
                       label: <div className={"d-flex"}>Integrations <div className={styles.lightDropdownText}>Send</div></div>
                     },
                     ...options.filter(option => option.outputType === "INTEGRATION"),
                   ]}
                   className={'ml-3'}
                   placeholder={"Select Integration / Template"}
                   onSelectOption={option => {
                     if(option.value) {
                       setDdt(option as OptionType)
                       localStorage.setItem("lastUsedOutputId", option.value)
                     }
                   }}
  />
}

type SelectDdtContentProps = {
  ddts: SelectDdtContent_ddts$data,
  ddt: DdtType | null,
  setDdt: (val: DdtType) => void,
  downloadImages: boolean,
  setDownloadImages: (val: boolean) => void,
  hasMultipleOrderNumbers: boolean,
  mergeSameProducts: boolean,
  setMergeSameProducts: (val: boolean) => void,
}

function SelectDdtContent({
                            ddts,
                            ddt,
                            setDdt,
                            downloadImages,
                            setDownloadImages,
                            hasMultipleOrderNumbers,
                            mergeSameProducts,
                            setMergeSameProducts,
                          }: SelectDdtContentProps) {
  const history = useHistory();
  let options: OptionType[] = ddts.edges.filter(edge => {
    // this filtering is temporarily disabled because when exporting an order, we will hide all
    // DDTs with no configured order confirmation config. But we need to display them all, so that
    // users can export only the product data from that order, without the order details (eg. exporting some
    // specific product data to JOOR or WooCommerce
    return true;
    // if an order confirmation is selected, display only the ddts that are configured
    // to use them
    // if (orderConfirmationBatch) {
    //   return edge.node.orderConfirmationConfigs.edges.length > 0;
    // }
    // otherwise, just return all
    // return true;
  }).map(edge => {
    return {
      value: edge?.node?.id || "",
      label: edge?.node?.name || "",
      isIntegration: edge?.node?.engine || null,
      disabled: !edge?.node?.isGlossaryMapped,
      outputType: edge?.node?.outputType || ""
    }
  })

  return <div className={"px-5 py-4"}>
    <Row className={"m-0"}>
      <p className={`${styles.text} my-auto`}>Template / Integration</p>
      <SelectDdtContentDropdown
        ddt={ddt}
        setDdt={setDdt}
        options={options}
      />
    </Row>
    <Row className={"m-0 mt-3"}>
      <Checkbox checked={downloadImages}
                onChange={() => setDownloadImages(!downloadImages)}
                label={<label className={styles.text}>Include images</label>}
                labelProps={{style: {marginBottom: "0"}}}/>
    </Row>
    {hasMultipleOrderNumbers && <Row className={"m-0 mt-3"}>
      <Checkbox checked={!mergeSameProducts}
                onChange={() => setMergeSameProducts(!mergeSameProducts)}
                label={
                  <label className={styles.text}>
                    Treat orders separately and allow duplicate items in the download
                  </label>
                }/>
    </Row>}
    <hr className={'m-0 mt-3'}/>
    <Row className={"m-0 mt-4"}>
      <div className={styles.redirectCard}
           onClick={() => {history.push('/output')}}>
        <i className={`${styles.starIcon} fa-solid fa-star`}/>
        <p className={styles.redirectCardText}>Create your own output template or check out our off-the-shelf integrations to further automate filling out spreadsheets.</p>
        <i className={`${styles.arrowIcon} fa-light fa-chevron-right`}/>
      </div>
    </Row>
  </div>
}

export default createFragmentContainer(
  SelectDdtContent,
  {
    ddts: graphql`
      fragment SelectDdtContent_ddts on DistributeDataTemplateNodeConnection {
        edges {
          node {
            id
            name
            engine
            isGlossaryMapped
            outputType
            orderConfirmationConfigs {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
)
