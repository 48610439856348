import React from "react";
import {Col, Input, Row} from "reactstrap";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {CircleRuleIcon, RuleIcon} from "../../../icons/RuleIcon";
import styles from './FirstAvailableRule.module.scss';
import Dropdown, {isMultiOption, isSelectableOption, SelectableOption} from "../../../../../../../atoms/Dropdown";
import {ProductFieldsProvider} from "../../../../utilities";


const SET_EMPTY_VALUE = 'set-empty-value';
const READ_VALUE_FROM = 'read-value-from';

function filterOutSelfFromOptions(options: SelectableOption[], alreadySelectedPaths: (string | null)[]) {
  // Remove unwanted options
  options = options.filter(option => option.value !== "_gen_description")

  for (let option in options) {
    const parentOption = options[option];
    if (isMultiOption(parentOption)) {
      parentOption.options = parentOption.options.filter(
        submenuOption => {
          if (isSelectableOption(submenuOption) && submenuOption.value) {
            return !alreadySelectedPaths.includes(submenuOption.value)
          }

          return false;
        }
      )
    }
  }
  return options
}

type PathSelectorProps = {
  path: (string | null),
  productFieldsProvider: ProductFieldsProvider,
  alreadySelectedPaths: (string | null)[],
  onChange: (val: {path: string}) => void,
  onAddAnotherValue: () => void,
  onRemove: () => void
}

function PathSelector({path, productFieldsProvider, alreadySelectedPaths, onChange, onAddAnotherValue, onRemove}: PathSelectorProps) {
  let suffix
  suffix = productFieldsProvider.getByPath(path)
    && productFieldsProvider.getByPath(path)?.path.split(".")[0] === 'orderconfirmation'
    && productFieldsProvider.getByPath(path)?.path.split(".")[1]
  if (suffix) {
    suffix = suffix.charAt(0).toUpperCase() + suffix.slice(1)
  }

  const currentValue = (path && productFieldsProvider.getByPath(path)) ?
    productFieldsProvider.getByPath(path)?.name + (suffix ? ": " + suffix : "") :
    "Select...";

  return <Row className={'mx-0 pb-3 ' + styles.pathSelectorContainer}>
    <Col className={'d-flex px-3 align-items-center'}>
      <RuleIcon src={'fa-hashtag'}/>
      <Dropdown
        className={'ml-3'}
        options={
          filterOutSelfFromOptions(
            productFieldsProvider.getMultiMenuOptions(),
            alreadySelectedPaths
          )
        }
        currentValue={{label: currentValue}}
        onSelectOption={({value}) => {
          if (value) {
            onChange({path: value})
          }
        }}
      />
      <Dropdown
        currentValue={{label: <i className="fas fa-plus m-0"/>}}
        caret={false}
        className={'ml-2'}
        options={[{label: <><i className="fas fa-plus-circle mr-2"/> Add another value</>, value: null}]}
        onSelectOption={() => {
          onAddAnotherValue();
        }}
      />
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}

type DefaultValueInputProps = {
  value: string,
  onChange: (val: string) => void,
  onRemove: () => void,
}

function DefaultValueInput({value, onChange, onRemove}: DefaultValueInputProps) {
  return <Row className={'mx-0 pb-3 ' + styles.defaultValueContainer}>
    <Col className={'d-flex px-3 align-items-center'}>
      <CircleRuleIcon src={'fa-star-half-alt'}/>
      <strong className={'mx-3'}>Set empty value to</strong>
      <Input value={value} onChange={e => onChange(e.target.value)} style={{width: '200px'}}/>
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}

type FirstAvailableRuleProps = {
  paths: (string | null)[],
  default_value: string | null,
  productFieldsProvider: ProductFieldsProvider,
  onChange: (val: {paths: (string | null)[], default_value: (string | null)}) => void,
  onRemove: () => void
}

export default function FirstAvailableRule({
                                             paths,
                                             default_value,
                                             productFieldsProvider,
                                             onChange,
                                             onRemove,
                                           }: FirstAvailableRuleProps) {

  return <div>
    <Row className={'mx-0'}>
      <Col className={'d-flex py-3 px-0'}>
        <strong>When this data point does not have a value</strong>
        <Dropdown
          className={'ml-2'}
          currentValue={{label: <i className="fa fa-plus m-0"/>}}
          caret={false}
          options={[
            {
              label: <><i className="fas fa-star-half-alt mr-2"/> Set empty value</>,
              value: SET_EMPTY_VALUE
            },
            {
              label: <><i className="fas fa-bookmark mr-2"/> Read value from...</>,
              value: READ_VALUE_FROM
            },
          ]}
          onSelectOption={({value}) => {
            if (value === SET_EMPTY_VALUE) {
              onChange({paths, default_value: ''})
            } else if (value === READ_VALUE_FROM) {
              onChange({paths: paths ? [...paths, null] : [null], default_value})
            }
          }}
        />
        <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
      </Col>
    </Row>

    {paths.length > 0 && <Row className={'mx-0 mb-3 ' + styles.pathSelectorsHeader}>
      <Col>
        <CircleRuleIcon src={'fa-bookmark'}/>
        <strong className={'ml-2'}>Read from the first data point with a value</strong>
      </Col>
    </Row>}

    {paths.map((path, i) => {
      return <PathSelector key={i}
                           path={path}
                           alreadySelectedPaths={paths}
                           productFieldsProvider={productFieldsProvider}
                           onChange={data => {
                             onChange({
                               default_value,
                               paths: paths.map((p, j) => {
                                 if (j === i) {
                                   return data.path
                                 }
                                 return p;
                               })
                             })
                           }}
                           onRemove={() => {
                             onChange({
                               default_value,
                               paths: paths.filter((p, j) => j !== i)
                             })
                           }}
                           onAddAnotherValue={() => {
                             onChange({
                               default_value,
                               paths: [...paths, null]
                             })
                           }}/>
    })}

    {default_value !== null && <DefaultValueInput value={default_value}
                                                  onChange={value => {
                                                    onChange({paths, default_value: value})
                                                  }}
                                                  onRemove={() => {
                                                    onChange({paths, default_value: null})
                                                  }}/>}


  </div>
}
