/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FinishedContentQueryVariables = {
    uploadId: string;
};
export type FinishedContentQueryResponse = {
    readonly orderConfirmationUpload: {
        readonly id: string;
        readonly errors: ReadonlyArray<{
            readonly reason: string | null;
            readonly product: unknown | null;
            readonly variant: unknown | null;
        } | null> | null;
    } | null;
};
export type FinishedContentQuery = {
    readonly response: FinishedContentQueryResponse;
    readonly variables: FinishedContentQueryVariables;
};



/*
query FinishedContentQuery(
  $uploadId: ID!
) {
  orderConfirmationUpload(id: $uploadId) {
    id
    errors {
      reason
      product
      variant
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uploadId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "uploadId"
      }
    ],
    "concreteType": "OrderConfirmationUploadNode",
    "kind": "LinkedField",
    "name": "orderConfirmationUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderConfirmationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "product",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "variant",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinishedContentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinishedContentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "437ab4c45c0797e03790eb85c5c1c25a",
    "id": null,
    "metadata": {},
    "name": "FinishedContentQuery",
    "operationKind": "query",
    "text": "query FinishedContentQuery(\n  $uploadId: ID!\n) {\n  orderConfirmationUpload(id: $uploadId) {\n    id\n    errors {\n      reason\n      product\n      variant\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6bf98acb971f479e33240c36cb6aa1ae';
export default node;
