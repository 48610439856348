import React, {useEffect, useState} from "react";
import {Alert} from "reactstrap";
import AuthenticationFrame from "./AuthenticationFrame";
import {QueryRenderer} from "react-relay";
import {graphql} from "graphql";
import AuthorizeWooCommerceShopMutation from "../../mutations/integrations/AuthorizeWooCommerceShopMutation";
import RemoveAuthorizationForWoocommerceShopMutation
  from "../../mutations/integrations/RemoveAuthorizationForWoocommerceShopMutation";
import IntegrationContext from "../../../../context/IntegrationContext";

const query = graphql`
  query WoocommerceIntegrationQuery ($url: String!) {
    getWoocommerceShopByUrl (url: $url) {
      id
      distributeDataTemplate {
        name
        id
        retailerOwners {
          edges {
            node {
              id
            }
          }
        }
      }
      authorizationCode
      consumerKey
      consumerSecret
    }
  }
`;

function isRelevantMessageEvent(event) {
  const automatedMsgs = ["reclassifyIframeMessage", "queryIframeIdForIndex"]
  if (event.data.hasOwnProperty('msg') && automatedMsgs.includes(event.data.msg) ) {
    return false;
  }
  return event.origin !== window.location.origin;
}

export default function WoocommerceIntegration({
                                                 environment,
                                                 children,
                                                 isAuth,
                                                 setIntegrationToken,
                                                 deleteIntegrationToken,
                                               }) {

  const [origin, setOrigin] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({})
  const [authCode, setAuthCode] = useState(null)
  const [authenticationError, setAuthenticationError] = useState(null)

  const sendMessage = (message, destination = origin) => {
    window.parent.postMessage(message, destination);
  }

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (isRelevantMessageEvent(event)) {
        setOrigin(event.origin)
        setMessage(event.data)
        if (event.data.authCode) {
          deleteIntegrationToken()
          setIntegrationToken(event.data.authCode)
          setAuthCode(event.data.authCode)
        }
      }
    }, false)
    sendMessage(null, "*");
  }, [])

  const handleTriggerActivation = () => {
    sendMessage({doAuth: true}, origin);
  }

  const handleRevokeActivation = () => {
    setIsLoading(true)
    RemoveAuthorizationForWoocommerceShopMutation(
      environment,
      {
        url: origin + "/",
        authCode: authCode,
      },
      (response) => {
        setIsLoading(false)
      },
      (error) => {
        setAuthenticationError(error[0].message)
        setMessage({})
        setIsLoading(false)
      }
    )
    sendMessage({revokeAuth: true}, origin);
    setAuthCode(null)
  }

  const handleActivation = (keys) => {
    setIsLoading(true)
    AuthorizeWooCommerceShopMutation(
      environment,
      {
        url: origin,
        consumerKey: keys.consumer_key,
        consumerSecret: keys.consumer_secret
      },
      (response) => {
        sendMessage({authCode: response.authorizeWoocommerceShop.woocommerceShop.authorizationCode})
        setIsLoading(false)
      },
      (error) => {
        setAuthenticationError(error[0].message)
        setMessage({})
        setIsLoading(false)
      }
    )
  }

  const handleShopFound = (shop) => {
    if (message.keys) {
      handleActivation(message.keys)
    } else if (isAuthCodeCorrect(shop.authorizationCode)) {
      return <div>
        {children}
      </div>
    } else if (isAuth) {
      return <AuthenticationFrame integrationContext={IntegrationContext}/>
    } else {
      return <Alert color={'warning'}>
        Your shop is registered but not authenticated.
        Please proceed to the Vuuh Authentication page under the Vuuh wordpress menu.
      </Alert>
    }
  }

  const isAuthCodeCorrect = (authorizationCode) => {
    return message.authCode && authorizationCode === message.authCode
  }

  return <QueryRenderer
    query={query}
    environment={environment}
    variables={{url: origin + "/"}}
    render={({error, props}) => {
      if (error) {
        return <Alert color={'danger'}>An error occurred: {error}</Alert>;
      }
      if (props) {
        if (props.getWoocommerceShopByUrl) {
          return <IntegrationContext.Provider
            value={{
              id: props.getWoocommerceShopByUrl.distributeDataTemplate.id,
              owner: props.getWoocommerceShopByUrl.distributeDataTemplate.retailerOwners.edges[0] &&
                props.getWoocommerceShopByUrl.distributeDataTemplate.retailerOwners.edges[0].node.id,
              error: authenticationError,
              environment: environment,
              authenticated: authCode,
              isLoading: isLoading,
              handleTriggerAuthentication: handleTriggerActivation,
              handleRevokeAuthentication: handleRevokeActivation,
            }}>
            {handleShopFound(props.getWoocommerceShopByUrl)}
          </IntegrationContext.Provider>
        } else {
          return <div className={"d-flex justify-content-center"}>
            <Alert
              color={'danger'}
              style={{width: "100%"}}>
              Your shop is not registered. Please contact Vuuh Admin.
            </Alert>
          </div>
        }
      } else {
        return <p>Loading... </p>
      }
    }}
  />
}
