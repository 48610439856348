import {graphql} from "relay-runtime";
import {createMutationBoilerplate} from "../commons/graphql";
import {
  UpdateMultipleSheetsConfigurationMutation
} from './__generated__/UpdateMultipleSheetsConfigurationMutation.graphql';

export default createMutationBoilerplate<UpdateMultipleSheetsConfigurationMutation>(
  graphql`
    mutation UpdateMultipleSheetsConfigurationMutation($input: UpdateMultipleSheetsConfigurationsMutationInput!) {
      updateMultipleSheetsConfigurations(input: $input) {
        mappingConfigs {
          id
          isActive
          config
          configBeforeVariant
          sheetName
          sheetProductSelectors
          skipFirstVariant
        }
      }
    }
  `
)
