/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SingleOrderPageNormalOrdersQueryVariables = {
    id: string;
};
export type SingleOrderPageNormalOrdersQueryResponse = {
    readonly getOrder: {
        readonly id: string;
        readonly dateCreated: unknown;
        readonly lastDownload: {
            readonly endDate: unknown;
        } | null;
        readonly retailerStore: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly missingData: {
            readonly percent: number | null;
        } | null;
        readonly productCount: number;
        readonly acknowledged: boolean;
        readonly fromBrand: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly toRetailer: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly price: {
            readonly amount: number;
            readonly currency: string;
        };
        readonly quantity: number;
        readonly orderNumber: string;
        readonly orderConfirmationBatch: {
            readonly id: string;
            readonly error: string | null;
            readonly errors: ReadonlyArray<{
                readonly product: unknown | null;
                readonly reason: string | null;
                readonly variant: unknown | null;
            } | null> | null;
            readonly " $fragmentRefs": FragmentRefs<"BatchTotalInfo_batch" | "ErrorsAcknowledgementsModal_batch">;
        } | null;
        readonly orderConfirmationExtraProducts: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly styleNumber: string | null;
                    readonly colorName: string | null;
                    readonly size: string | null;
                } | null;
            } | null>;
        } | null;
        readonly orderConfirmationMissingProducts: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly styleNumber: string | null;
                    readonly colorName: string | null;
                    readonly size: string | null;
                } | null;
            } | null>;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ErrorsAcknowledgementsModal_order">;
    } | null;
};
export type SingleOrderPageNormalOrdersQuery = {
    readonly response: SingleOrderPageNormalOrdersQueryResponse;
    readonly variables: SingleOrderPageNormalOrdersQueryVariables;
};



/*
query SingleOrderPageNormalOrdersQuery(
  $id: ID!
) {
  getOrder(id: $id) {
    id
    dateCreated
    lastDownload {
      endDate
    }
    retailerStore {
      id
      name
    }
    missingData {
      percent
    }
    productCount
    acknowledged
    ...ErrorsAcknowledgementsModal_order
    fromBrand {
      id
      name
    }
    toRetailer {
      id
      name
    }
    price {
      amount
      currency
    }
    quantity
    orderNumber
    orderConfirmationBatch {
      id
      ...BatchTotalInfo_batch
      ...ErrorsAcknowledgementsModal_batch
      error
      errors {
        product
        reason
        variant
      }
    }
    orderConfirmationExtraProducts {
      edges {
        node {
          styleNumber
          colorName
          size
          id
        }
      }
    }
    orderConfirmationMissingProducts {
      edges {
        node {
          styleNumber
          colorName
          size
          id
        }
      }
    }
  }
}

fragment BatchTotalInfo_batch on OrderConfirmationBatchNode {
  id
  totalUnifiedProducts
  totalItemQuantity
  totalPrice {
    amount
    currency
  }
}

fragment ErrorsAcknowledgementsModal_batch on OrderConfirmationBatchNode {
  brand {
    id
    name
  }
  error
  errors {
    product
    reason
    variant
  }
}

fragment ErrorsAcknowledgementsModal_order on OrderNode {
  id
  orderConfirmationExtraProducts {
    edges {
      node {
        id
        styleNumber
      }
    }
  }
  orderConfirmationMissingProducts {
    edges {
      node {
        id
        styleNumber
      }
    }
  }
  fromBrand {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LastDownloadType",
  "kind": "LinkedField",
  "name": "lastDownload",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerStoreNode",
  "kind": "LinkedField",
  "name": "retailerStore",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingDataType",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acknowledged",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandNode",
  "kind": "LinkedField",
  "name": "fromBrand",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerNode",
  "kind": "LinkedField",
  "name": "toRetailer",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumber",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderConfirmationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "product",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variant",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "styleNumber",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colorName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DisplayUnifiedProductNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DisplayUnifiedProductNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v22 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DisplayUnifiedProductNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DisplayUnifiedProductNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleOrderPageNormalOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderNode",
        "kind": "LinkedField",
        "name": "getOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderConfirmationBatchNode",
            "kind": "LinkedField",
            "name": "orderConfirmationBatch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BatchTotalInfo_batch"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ErrorsAcknowledgementsModal_batch"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DisplayUnifiedProductNodeConnection",
            "kind": "LinkedField",
            "name": "orderConfirmationExtraProducts",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DisplayUnifiedProductNodeConnection",
            "kind": "LinkedField",
            "name": "orderConfirmationMissingProducts",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ErrorsAcknowledgementsModal_order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleOrderPageNormalOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderNode",
        "kind": "LinkedField",
        "name": "getOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DisplayUnifiedProductNodeConnection",
            "kind": "LinkedField",
            "name": "orderConfirmationExtraProducts",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DisplayUnifiedProductNodeConnection",
            "kind": "LinkedField",
            "name": "orderConfirmationMissingProducts",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderConfirmationBatchNode",
            "kind": "LinkedField",
            "name": "orderConfirmationBatch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalUnifiedProducts",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalItemQuantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Price",
                "kind": "LinkedField",
                "name": "totalPrice",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNode",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e39f1b2434ba7b37b0d3a792948c9b99",
    "id": null,
    "metadata": {},
    "name": "SingleOrderPageNormalOrdersQuery",
    "operationKind": "query",
    "text": "query SingleOrderPageNormalOrdersQuery(\n  $id: ID!\n) {\n  getOrder(id: $id) {\n    id\n    dateCreated\n    lastDownload {\n      endDate\n    }\n    retailerStore {\n      id\n      name\n    }\n    missingData {\n      percent\n    }\n    productCount\n    acknowledged\n    ...ErrorsAcknowledgementsModal_order\n    fromBrand {\n      id\n      name\n    }\n    toRetailer {\n      id\n      name\n    }\n    price {\n      amount\n      currency\n    }\n    quantity\n    orderNumber\n    orderConfirmationBatch {\n      id\n      ...BatchTotalInfo_batch\n      ...ErrorsAcknowledgementsModal_batch\n      error\n      errors {\n        product\n        reason\n        variant\n      }\n    }\n    orderConfirmationExtraProducts {\n      edges {\n        node {\n          styleNumber\n          colorName\n          size\n          id\n        }\n      }\n    }\n    orderConfirmationMissingProducts {\n      edges {\n        node {\n          styleNumber\n          colorName\n          size\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BatchTotalInfo_batch on OrderConfirmationBatchNode {\n  id\n  totalUnifiedProducts\n  totalItemQuantity\n  totalPrice {\n    amount\n    currency\n  }\n}\n\nfragment ErrorsAcknowledgementsModal_batch on OrderConfirmationBatchNode {\n  brand {\n    id\n    name\n  }\n  error\n  errors {\n    product\n    reason\n    variant\n  }\n}\n\nfragment ErrorsAcknowledgementsModal_order on OrderNode {\n  id\n  orderConfirmationExtraProducts {\n    edges {\n      node {\n        id\n        styleNumber\n      }\n    }\n  }\n  orderConfirmationMissingProducts {\n    edges {\n      node {\n        id\n        styleNumber\n      }\n    }\n  }\n  fromBrand {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '79bbce6a1167492ae80eb6ae06974573';
export default node;
