import React, {useEffect} from 'react';
import ProductRow from '../../ProductRow';
import {usePagination} from "../../../../../commons/pagination";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {VariantType} from "../PriceCatalogueOrderProducts";

export type ProductType = {
  id: string,
  image: string,
  styleName: string,
  styleNumber: string,
  variants: {
    totalCount: number,
    edges: VariantType[],
  },
  missingData : {
    display: string,
    path: string
  }[],
  allMissingData: {
    display: string,
    path: string
  }[]
  quantity: number | null,
  price: {
    amount: number,
    currency: string
  } | null
}

type ProductListType = {
  paginationInfo: {
    totalCount: number | null,
    pageInfo: {
      startCursor: string | null,
      endCursor: string | null
    }
  },
  products: ProductType[],
  showAggregateColorAndSizesCount: boolean,
  environment: RelayModernEnvironment,
  hasMissingDataStatus: boolean
}

export default function ProductList({paginationInfo, products, showAggregateColorAndSizesCount = false, environment, hasMissingDataStatus}: ProductListType) {
  const {setTotalCount, setFirstCursor, setLastCursor} = usePagination();

  useEffect(() => {
    paginationInfo?.totalCount && setTotalCount(paginationInfo.totalCount);
    paginationInfo?.pageInfo?.startCursor && setFirstCursor(paginationInfo.pageInfo.startCursor);
    paginationInfo?.pageInfo?.endCursor && setLastCursor(paginationInfo.pageInfo.endCursor);

  }, [paginationInfo, setFirstCursor, setLastCursor, setTotalCount])

  return <>
    {products
      .sort((prod1, prod2) => prod2.allMissingData.length - prod1.allMissingData.length)
      .map(prod => {
        return <ProductRow product={prod}
                           key={prod.id}
                           environment={environment}
                           showAggregateColorAndSizesCount={showAggregateColorAndSizesCount}
                           hasMissingDataStatus={hasMissingDataStatus}/>
      })}
  </>
}
