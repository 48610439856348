/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OutputType = "INTEGRATION" | "TEMPLATE" | "%future added value";
export type OutputReadyModalContentQueryVariables = {
    fileId: string;
};
export type OutputReadyModalContentQueryResponse = {
    readonly getDistributeDataOutput: {
        readonly id: string;
        readonly url: string | null;
        readonly distributeDataTemplate: {
            readonly id: string;
            readonly name: string;
            readonly engine: string | null;
            readonly outputType: OutputType | null;
        } | null;
        readonly productsSuccessCount: number;
        readonly productsFailedCount: number;
    } | null;
};
export type OutputReadyModalContentQuery = {
    readonly response: OutputReadyModalContentQueryResponse;
    readonly variables: OutputReadyModalContentQueryVariables;
};



/*
query OutputReadyModalContentQuery(
  $fileId: ID!
) {
  getDistributeDataOutput(id: $fileId) {
    id
    url
    distributeDataTemplate {
      id
      name
      engine
      outputType
    }
    productsSuccessCount
    productsFailedCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "fileId"
      }
    ],
    "concreteType": "DistributeDataTemplateOutputNode",
    "kind": "LinkedField",
    "name": "getDistributeDataOutput",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNode",
        "kind": "LinkedField",
        "name": "distributeDataTemplate",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "engine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "outputType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productsSuccessCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productsFailedCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutputReadyModalContentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutputReadyModalContentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c365b7e3cbfc42d906d7b24d8cef8eb9",
    "id": null,
    "metadata": {},
    "name": "OutputReadyModalContentQuery",
    "operationKind": "query",
    "text": "query OutputReadyModalContentQuery(\n  $fileId: ID!\n) {\n  getDistributeDataOutput(id: $fileId) {\n    id\n    url\n    distributeDataTemplate {\n      id\n      name\n      engine\n      outputType\n    }\n    productsSuccessCount\n    productsFailedCount\n  }\n}\n"
  }
};
})();
(node as any).hash = '6a2dfaacaa45adf9e63a4f684fc1bad1';
export default node;
