import React, {useEffect, useState} from "react";
import {CardHeader, Table} from "reactstrap";
import SearchInput from "../../../atoms/SearchInput";
import {capitalize, normalizeDate} from "../../../stringUtils";
import {
  AddMissingDataButton,
  Header,
  MissingDataStatusPill,
  StatusPill,
  Tab
} from "../layout/OrderViewLayout";
import DownloadButtonWithDdtModal from "../../products/DownloadButtonWithDdtModal";
import OrderProductTable from "./OrderProductTable";
import {PaginationNav, PaginationProvider} from "../../../commons/pagination";
import OrderErrors from "../components/OrderErrors";
import ErrorsAcknowledgementsModal from "../components/ErrorsAcknowledgementsModal";
import Card from "../../../atoms/Card";
import styles from "./OrderView.module.scss";
import {
  SingleOrderPageManuallyUploadedOrdersQueryResponse
} from "../../../pages/orders/__generated__/SingleOrderPageManuallyUploadedOrdersQuery.graphql";
import {BrandUserContextType, RetailerUserContextType} from "../../../context/CurrentUserContext";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Separator from "../../../atoms/Separator";
import Pagination from "../../../atoms/Pagination";
import GrayTableHeader, {GrayTableHeaderCell} from "../../../molecules/tables/GrayTableHeader";
import {useHistory} from "react-router";
// Hidden for now
import {FilterSlot} from "../layout/OrderViewLayout";
import SecondaryActionButton from "../../../general/SecondaryActionButton";
import Dropdown from "../../../atoms/Dropdown";
import Button from "../../../atoms/Button";
import {Tabs} from "../../navigation/Tabs";
import {downloadFile} from "../../../commons/http";
import Tooltip from "../../../atoms/Tooltip";
//


const PER_PAGE = 10;

// Hidden for now
// export function groupUploads(uploads) {
//   let grouped = {};
//   for (let upl of uploads.edges) {
//     let d = upl.node.name ? upl.node.name.split('_') : ["Location unavailable"]
//     if (d.length > 1) {
//       d.pop();
//     }
//     let groupName = d.join('_');
//     if (!grouped.hasOwnProperty(groupName)) {
//       grouped[groupName || "Location unavailable"] = {uploads: [], quantity: 0};
//     }
//     grouped[groupName || "Location unavailable"].uploads.push(upl.node);
//     grouped[groupName || "Location unavailable"].quantity += upl.node.totalQuantity;
//   }
//   return grouped;
// }

// Hidden for now
// function getUploadsDropdownOptionsForOrderConfirmationBatch(selectedOrderConfirmationConfig, orderConfirmationBatch) {
//   if (selectedOrderConfirmationConfig) {
//     return orderConfirmationBatch.uploads.edges.map(
//       edge => {
//         return {label: edge.node.name || "Location unavailable", value: edge.node.id}
//       }).filter(
//       option => option.label.split("_")[0] === selectedOrderConfirmationConfig
//     )
//   } else {
//     return orderConfirmationBatch.uploads.edges.map(edge => {
//       return {label: edge.node.name || "Location unavailable", value: edge.node.id}
//     })
//   }
// }

type OrderViewType = {
  order: SingleOrderPageManuallyUploadedOrdersQueryResponse['getManuallyUploadedOrder'],
  user: BrandUserContextType | RetailerUserContextType,
  environment: RelayModernEnvironment,
  isRetailer: boolean,
  onClickBack: () => void
}

export default function OrderView({order, user, environment, isRetailer, onClickBack}: OrderViewType) {
  const history = useHistory();
  const [selectedUploadsGroup, setSelectedUploadsGroup] = useState(null)
  const [selectedUploads, setSelectedUploads] = useState(null)
  const [search, setSearch] = useState("")
  const [displayErrorModalIsOpen, setDisplayErrorModalIsOpen] = useState(!order?.acknowledged)
  const hasMissingDataStatus = typeof order?.missingData?.percent === 'number' && order?.missingData?.percent < 100;
  const hasErrors = order?.batch?.error || (order?.batch?.errors && order?.batch?.errors?.length > 0);
  // Hidden for now
  // let downloadsOptions = order.batch.uploads.edges.map(upload => {
  //   return {label: upload.node.name, value: upload.node.fileUrl}
  // })
  // let uploadsDropdown = getUploadsDropdownOptionsForOrderConfirmationBatch(
  //   selectedUploadsGroup,
  //   order.batch
  // );
  // const groupedConfigs = groupUploads(order?.batch?.uploads);
  // const getUploadIds = () => {
  //   if (selectedUploadsGroup) {
  //     return groupedConfigs[selectedUploadsGroup].uploads.map(u => u.id);
  //   } else if (selectedUploads && selectedUploads.length > 0) {
  //     return selectedUploads.map(u => u.value);
  //   } else {
  //     return []
  //   }
  // }

  useEffect(() => {
    setSelectedUploads(null)
  }, [selectedUploadsGroup])


  const getLastDownload = () => {
    const totalOutputProcesses = order?.outputProcesses?.totalCount;
    if(totalOutputProcesses && order?.outputProcesses.edges[totalOutputProcesses - 1]?.node?.orders.totalCount) {
      const totalOrdersEdges = order?.outputProcesses.edges[totalOutputProcesses - 1]?.node?.orders.totalCount;
      if(totalOrdersEdges && order?.outputProcesses.edges[totalOutputProcesses - 1]?.node?.orders.edges[totalOrdersEdges - 1]?.node?.lastDownload?.endDate) {
        const lastDownloadDate = order?.outputProcesses.edges[totalOutputProcesses - 1]?.node?.orders.edges[totalOrdersEdges - 1]?.node?.lastDownload?.endDate;
        return lastDownloadDate ? normalizeDate(lastDownloadDate as string, true, true) : false;
      }
      return false;
    }
    return false;
  }

  function TruncateValues ({values, noDataText}: {values: string[] | undefined, noDataText?: string}) {
    if(!values || !values.length) {
      return <>{noDataText || "-"}</>
    }

    if(values.length > 3) {
      return <Tooltip text={values.join(", ")}
                      placement={'top'}>
        <>{values.slice(0, 3).join(", ") + ", ..."}</>
      </Tooltip>
    }
    return <>{values?.join(", ")}</>
  }

  const getLocations = () => {
    let locations = new Set<string>();
    if (order?.batch?.uploads.edges && order?.batch?.uploads.edges.length > 0) {
      for (let upl of order?.batch?.uploads.edges) {
        if (upl?.node?.name) {
          locations.add(capitalize(upl.node.name.split('_')[0]));
        }
      }
    }
    return locations.size ? [...locations] : [];
  }

  return <PaginationProvider perPage={PER_PAGE}>
    <Card shadow fillSpace className={"overflow-hidden"}>
      <Header onClick={onClickBack} dataTestId={"order-button-back"}>Back to Orders</Header>
      <CardHeader style={{border: "none"}}>
        <div className={'d-flex align-items-center justify-content-between'}>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{isRetailer ? (order?.fromBrand ? order.fromBrand.name : 'Not available') : (order?.toRetailer ? order.toRetailer.name : 'Not available')}</p>
            <MissingDataStatusPill missingDataPercentage={order?.missingData?.percent}/>
            <StatusPill
              title={getLastDownload() ? `Downloaded ${getLastDownload()}` : "Not downloaded"}
              icon={"fa-solid fa-circle-arrow-down"}
              color={getLastDownload() ? "primary" : "muted"}/>
          </div>
          <div className={styles.actionsContainer}>
            {hasMissingDataStatus && !isRetailer && <>
              <AddMissingDataButton orderId={order?.id || ""} orderNumbers={[...order?.orderNumbers || []]}
                                    history={history} retailerName={order?.toRetailer?.name || ""}/>
              <Separator className={'m-0'}/>
            </>}
            {/* //Hidden for now*/}
            {/*<Button icon={"fa-regular fa-trash-can"} className={styles.deleteButton}/>*/}
            {/*<Dropdown*/}
            {/*  color={'primary'}*/}
            {/*  placeholder={'Download files'}*/}
            {/*  currentValue={null}*/}
            {/*  options={downloadsOptions} onSelectOption={(option) => {*/}
            {/*  downloadFile(option.value)*/}
            {/*}}/>*/}

            <DownloadButtonWithDdtModal
              orderId={order?.id || null}
              environment={environment}
              orderNumbers={order?.orderNumbers ? [...order.orderNumbers] : []}
              dataTestId={"order-button-download"}
              color={!hasMissingDataStatus ? "primary" : "secondary"}
            />
          </div>
        </div>
        <div className={styles.subtitleContainer}>
          <p className={styles.subtitle}>
            <i className={"fa-light fa-hashtag"}/>
            <TruncateValues values={order?.orderNumbers ? [...order.orderNumbers] : []} noDataText={"No order number"}/>
          </p>
          <Separator className={styles.separator}/>
          <p className={styles.subtitle}>
            <i className={"fa-light fa-location-dot"}/>
            Location: <TruncateValues values={getLocations()}/>
          </p>
          <Separator className={styles.separator}/>
          <p className={styles.subtitle}>
            <i className={"fa-light fa-calendar-arrow-up"}/>
            {order?.uploaded ? capitalize(normalizeDate(order.uploaded as string, false, true)) : "unknown"}
          </p>
        </div>
        <div className={'d-flex align-items-center justify-content-between'}>
          <div style={{maxWidth: "13rem"}}>
            <SearchInput placeholder={'Search'}
                         delay={500}
                         value={search}
                         onChange={e => setSearch(e.target.value)}/>
          </div>

          {/* //Hidden for now*/}
          {/*<FilterSlot first>*/}
          {/*  <Tabs>*/}
          {/*    <Tabs.Entry active={selectedUploadsGroup === null}*/}
          {/*                onClick={() => setSelectedUploadsGroup(null)}>*/}
          {/*      <>All ({order?.batch?.totalItemQuantity})</>*/}
          {/*    </Tabs.Entry>*/}
          {/*    {Object.keys(groupedConfigs).map(groupName => {*/}
          {/*      return <Tabs.Entry*/}
          {/*        key={groupName}*/}
          {/*        disabled={groupedConfigs[groupName].quantity === 0}*/}
          {/*        active={selectedUploadsGroup === groupName}*/}
          {/*        onClick={() => {*/}
          {/*          setSelectedUploadsGroup(groupName);*/}
          {/*        }}>*/}
          {/*        {capitalize(groupName)} ({groupedConfigs[groupName].quantity})*/}
          {/*      </Tabs.Entry>*/}
          {/*    })}*/}
          {/*  </Tabs>*/}
          {/*</FilterSlot>*/}
          {/*<FilterSlot>*/}
          {/*  <Dropdown*/}
          {/*    id={'order-confirmation-upload-select'}*/}
          {/*    toggleButtonProps={{tag: SecondaryActionButton, size: 'sm'}}*/}
          {/*    currentValue={selectedUploads}*/}
          {/*    options={uploadsDropdown}*/}
          {/*    onSelectOption={setSelectedUploads}*/}
          {/*  />*/}
          {/*</FilterSlot>*/}

          <div className={"d-flex align-items-center"}>
            {hasErrors && <OrderErrors
              errors={order?.batch?.errors ? [...order?.batch?.errors] : undefined}
              batchError={order?.batch?.error}
            />}
            <Tab className={'ml-3'}>
              <div className={styles.tabContent}>
                Total:&nbsp;&nbsp;<p className={styles.boldText}>{order?.quantity || 0}</p>&nbsp;items&nbsp;/&nbsp;<p className={styles.boldText}>{order?.price.amount || 0}</p>&nbsp;{order?.price.currency || "EUR"}
              </div>
            </Tab>
            {((order?.products?.count || 0) > PER_PAGE) && <>
              <Separator/>
              <PaginationNav Tag={Pagination} buttonClassName={styles.paginationButton}/>
            </>}
          </div>
        </div>
      </CardHeader>
      <Table>
        <GrayTableHeader>
          <GrayTableHeaderCell style={{paddingInline: '50px'}} className={'py-2'}/>
          <GrayTableHeaderCell width={18} className={'py-2 pl-0'}>Style name</GrayTableHeaderCell>
          <GrayTableHeaderCell width={14} className={'py-2'}>Style number</GrayTableHeaderCell>
          <GrayTableHeaderCell width={14} className={'py-2'}>Colors & sizes</GrayTableHeaderCell>
          <GrayTableHeaderCell width={14} className={'py-2'}>Quantity</GrayTableHeaderCell>
          <GrayTableHeaderCell width={14} className={'py-2'}>Price</GrayTableHeaderCell>
          <GrayTableHeaderCell className={'py-2'}/>
        </GrayTableHeader>
        <tbody>
        {order?.id && <OrderProductTable environment={environment}
                                         hasMissingDataStatus={hasMissingDataStatus}
                                         // filters={{name: search, orderConfirmationUploadIn: getUploadIds()}}
                                         id={order.id}/>}
        </tbody>
      </Table>
    </Card>
    <ErrorsAcknowledgementsModal isOpen={displayErrorModalIsOpen}
                                 toggle={() => setDisplayErrorModalIsOpen(!displayErrorModalIsOpen)}
                                 batch={order?.batch}
                                 order={null}
                                 manualOrder={order}/>
  </PaginationProvider>

}
