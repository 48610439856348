/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProductFilters_brands = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly name: string;
        } | null;
    } | null>;
    readonly " $refType": "ProductFilters_brands";
};
export type ProductFilters_brands$data = ProductFilters_brands;
export type ProductFilters_brands$key = {
    readonly " $data"?: ProductFilters_brands$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProductFilters_brands">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFilters_brands",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandNodeConnection",
  "abstractKey": null
};
(node as any).hash = '472ca477be4733e65bc968b02a870682';
export default node;
