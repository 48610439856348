/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProductFilters_orderConfirmation = {
    readonly totalProducts: number | null;
    readonly totalUnifiedProducts: number | null;
    readonly uploads: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly file: string;
                readonly errors: ReadonlyArray<{
                    readonly reason: string | null;
                    readonly product: unknown | null;
                    readonly variant: unknown | null;
                } | null> | null;
            } | null;
        } | null>;
    };
    readonly " $refType": "ProductFilters_orderConfirmation";
};
export type ProductFilters_orderConfirmation$data = ProductFilters_orderConfirmation;
export type ProductFilters_orderConfirmation$key = {
    readonly " $data"?: ProductFilters_orderConfirmation$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProductFilters_orderConfirmation">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFilters_orderConfirmation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalProducts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalUnifiedProducts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderConfirmationUploadNodeConnection",
      "kind": "LinkedField",
      "name": "uploads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderConfirmationUploadNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderConfirmationUploadNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "file",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderConfirmationError",
                  "kind": "LinkedField",
                  "name": "errors",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reason",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "product",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "variant",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderConfirmationBatchNode",
  "abstractKey": null
};
(node as any).hash = '6fb36d8c377a367b787d53bfd099ab06';
export default node;
