import React from 'react';
import {DropdownMenu, DropdownMenuProps, Option} from "../../../atoms/Dropdown";
import styles from './TokenMeasurementsMenuTag.module.scss';
import addClassNames from "../../../classNameUtils";
import {MeasurementDataType} from "./types";

type TokenMeasurementsMenuTagProps = {
  tokenDisplay: string | null,
  onMeasurementSelect: (measurement: string) => void,
  selectedMeasurement: string | null,
  measurements: MeasurementDataType[];
} & DropdownMenuProps;

export default function TokenMeasurementsMenuTag({tokenDisplay, onMeasurementSelect, selectedMeasurement, measurements, ...rest}: TokenMeasurementsMenuTagProps) {
  const options : Option[] = [
    {label: 'Display as', header: true},
    ...Array.from(measurements, m  => ({
      label: m?.name || '',
      value: m?.name || '',
      icon: <i className={addClassNames([
        {condition: selectedMeasurement !== m?.name, className: `fa-regular fa-circle ${styles.radioBtn}`},
        {condition: selectedMeasurement === m?.name, className: `fa-solid fa-circle-dot ${styles.radioBtn} ${styles.selectedRadioBtn}`}
      ])}/>
    }))
  ];
  return <DropdownMenu
    options={options}
    onSelectOption={(measurement) => onMeasurementSelect(measurement.value as string)}
    className={styles.dropdownItem}/>
}

