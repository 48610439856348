import React, {useContext} from 'react';
import styles from './tabs.module.scss';
import {ListGroup, ListGroupItem} from "reactstrap";
import {UniqueValueTranslatorContext} from "../../../../context/UniqueValueTranslatorContext";
import jmespath from "@metrichor/jmespath";

const DEFAULT_TAB_CONFIG = {
  fields: [
    {name: 'Product name', value: 'name'},
    {name: 'Style number', value: 'style_number'}
  ]
}

export function getCommaSeparatedValuesFromUnifiedProducts(path, unifiedProducts, translateUniqueValue) {
  return [...new Set(unifiedProducts.map(unifiedProduct => {
    return translateUniqueValue(jmespath.search(unifiedProduct, path))
  }))].filter(x => x).join(", ");
}


function getTabValues(tabConfig, currentVariant, translateUniqueValue) {
  return tabConfig.fields.map(({name, value}) => ({
    name,
    value: getCommaSeparatedValuesFromUnifiedProducts(
      value,
      currentVariant.unifiedProducts,
      translateUniqueValue
    )
  })).filter(x => x.value);
}

export default function GenericTab({tabConfig, currentVariant}) {
  const translateUniqueValue = useContext(UniqueValueTranslatorContext)

  if (!tabConfig) {
    tabConfig = DEFAULT_TAB_CONFIG;
  }
  let values = getTabValues(tabConfig, currentVariant, translateUniqueValue);

  return <ListGroup className={styles.container}>
    {values.map(({name, value}, i) => {
      return <ListGroupItem key={i} className={styles.row}>
        <div className={styles.rowKey}>{name}</div>
        <div
          className={styles.rowValue}>{value}</div>
      </ListGroupItem>
    })}
  </ListGroup>
}
