/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DistributeDataOutputProcessState = "FAILED" | "FINISHED" | "RUNNING" | "STARTED" | "%future added value";
export type LoadingContentQueryVariables = {
    outputProcessId: string;
};
export type LoadingContentQueryResponse = {
    readonly getDistributeDataOutputProcess: {
        readonly id: string;
        readonly state: DistributeDataOutputProcessState;
        readonly errorMessage: string | null;
        readonly output: {
            readonly id: string;
            readonly url: string | null;
        } | null;
    } | null;
};
export type LoadingContentQuery = {
    readonly response: LoadingContentQueryResponse;
    readonly variables: LoadingContentQueryVariables;
};



/*
query LoadingContentQuery(
  $outputProcessId: ID!
) {
  getDistributeDataOutputProcess(id: $outputProcessId) {
    id
    state
    errorMessage
    output {
      id
      url
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "outputProcessId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "outputProcessId"
      }
    ],
    "concreteType": "DistributeDataOutputProcessNode",
    "kind": "LinkedField",
    "name": "getDistributeDataOutputProcess",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errorMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateOutputNode",
        "kind": "LinkedField",
        "name": "output",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadingContentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoadingContentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "05ff24896ea3ad2f0943f9181c841dd2",
    "id": null,
    "metadata": {},
    "name": "LoadingContentQuery",
    "operationKind": "query",
    "text": "query LoadingContentQuery(\n  $outputProcessId: ID!\n) {\n  getDistributeDataOutputProcess(id: $outputProcessId) {\n    id\n    state\n    errorMessage\n    output {\n      id\n      url\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2011d69f1ca3f99782845a5cb6f3d6cc';
export default node;
