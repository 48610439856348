import React from "react";
import styles from "./ColumnsHeader.module.scss";
import {Col} from "reactstrap";
import Button from "../../../atoms/Button";

interface ColumnsHeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string | null,
  isConfigureImageNaming: boolean,
  onCancel: () => void
  onSave: () => void
  onBack?: () => void
}

export default function ColumnsHeader({
                                        name,
                                        onBack,
                                        onCancel,
                                        onSave,
                                        children,
                                        isConfigureImageNaming
                                      }: ColumnsHeaderProps) {
  let overtitle = isConfigureImageNaming ? "Image requirements (" + name + ")" : name
  let title = isConfigureImageNaming ? "Customize image file naming" : "Data requirements"
  return <div className={"d-flex mx-3 my-2"}>
    <Col className={'px-0'}>
      <div onClick={onBack} className={"d-flex align-items-baseline"} style={{width: "fit-content", cursor: "pointer"}}>
        {isConfigureImageNaming && <i className={"fa-regular fa-chevron-left " + styles.chevron}/>}
        <p className={styles.overtitle}>{!isConfigureImageNaming && 'Output: '}{overtitle}</p>
      </div>
      <h4 className={styles.header}>{title}</h4>
      {children}
    </Col>
    <Col className={'px-0'}>
      <div className="d-flex flex-column justify-content-end h-100 w-100">
        <div className={'d-flex align-items-center justify-content-end w-100'}
             style={isConfigureImageNaming ? {} : {height: '7rem'}}>
          <Button onClick={onCancel}
                  data-testid={'columns-on-cancel'}
                  style={{height: "2rem"}}
                  className={"ml-auto"}>
            Cancel
          </Button>
          <Button color={'primary'}
                  onClick={onSave}
                  data-testid={'columns-on-save'}
                  style={{height: "2rem"}}
                  disabled={false}
                  className={"ml-3"}>
            Save
          </Button>
        </div>
        <div style={{height: '1rem'}}/>
      </div>
    </Col>
  </div>
}
