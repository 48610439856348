import InputWithIcon from "../../../../../../../general/InputWithIcon";
import React from "react";

type DefaultRuleProps = {
  defaultValue: string,
  onChange: (value: { defaultValue: string }) => void,
}

export default function DefaultRule({defaultValue, onChange}: DefaultRuleProps) {

  return <InputWithIcon icon={'fa-star-half-alt'}
                        value={defaultValue}
                        onChange={(e) => {
                          onChange({defaultValue: e.target.value})
                        }}/>
}
