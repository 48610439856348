import {createMutationBoilerplate} from "../commons/graphql";
import {graphql} from "react-relay";
import {AddRetailerStoreMutation} from "./__generated__/AddRetailerStoreMutation.graphql";

export default createMutationBoilerplate<AddRetailerStoreMutation>(
  graphql`
    mutation AddRetailerStoreMutation($input: RetailerStoreCreateMutationInput!) {
      createRetailerStore(input: $input) {
        instance {
          retailer {
            id
            name
            stores{
              edges{
                node{
                  id
                  name
                  address
                  city
                  zipCode
                }
              }
            }
          }
          name
          id
        }
      }
    }
  `
)
