import React from 'react';
import Dropdown, {useDropdownState} from "../../../../../../atoms/Dropdown";
import Input from "../../../../../../atoms/Input";
import {ProductFieldsProvider} from "../../../utilities";
import {TokenType, EnumerateSubvariantsFieldRuleType} from "../../../types";
import styles from '../FieldRules.module.scss';
import RemoveRuleIcon from "../RemoveRuleIcon";
import Button from '../../../../../../atoms/Button';
import KeyValueInlineInput from "../../../../../../atoms/KeyValueInlineInput";

type EnumerateSubvariantsFieldRuleProps = {
  value: EnumerateSubvariantsFieldRuleType,
  tokens: TokenType[],
  onChange: (value: EnumerateSubvariantsFieldRuleType) => void,
  onRemove: () => void,
  productFieldsProvider: ProductFieldsProvider,
}

export function EnumerateSubvariantsFieldRule({onRemove, onChange, value}: EnumerateSubvariantsFieldRuleProps) {
  const dropdownInfo = useDropdownState({
    options: [
      {value: 'csv', label: 'Comma separated values (S, M, L, XL)'},
      {value: 'range', label: 'As a range (S - XL)'},
    ],
    currentValue: value.enumeration_type
  })

  let csvSeparator = value.csv_separator === undefined ? ', ' : value.csv_separator;
  let rangeSeparator = value.range_separator === undefined ? ' - ' : value.range_separator;

  return <div className={`${styles.container} d-flex`}>
    <div className={''}>

      <span className={'mx-2'}>
        Enumerate sizes as
      </span>

      <Dropdown
        options={dropdownInfo.options}
        currentValue={dropdownInfo.value}
        onSelectOption={op => {
          let newType: 'csv' | 'range' = 'csv';
          if (op.value === 'csv') {
            newType = 'csv';
          } else if (op.value === 'range') {
            newType = 'range'
          }
          onChange({...value, enumeration_type: newType})
        }}
      />

      <span className="mx-2">
        with sizes separated by
      </span>

      <Input
        width={'5rem'}
        minLength={0}
        value={value.enumeration_type === 'csv' ? csvSeparator : rangeSeparator}
        onChange={ev => {
          if (value.enumeration_type === 'csv') {
            onChange({...value, csv_separator: ev.target.value})
          } else if (value.enumeration_type === 'range') {
            onChange({...value, range_separator: ev.target.value})
          }
        }}
      />

      <span className="mx-2">
        and secondary sizes joined by
      </span>

      <Input
        width={'5rem'}
        minLength={0}
        value={value.secondary_join || '/'}
        onChange={ev => {
          onChange({...value, secondary_join: ev.target.value})
        }}
      />

      <span className="mx-2">
        and values mapped as
      </span>

      <KeyValueInlineInput value={value.mapping || {}}
                           onChange={val => onChange({...value, mapping: val})}/>


    </div>
    <div className="">
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </div>
  </div>
}
