import React, {useState} from "react";
import {ProductRowLayout} from "../layout/OrderProductLayout";
import SimpleTableRow, {SimpleTableCell} from "../../../molecules/tables/SimpleTableRow";
import styles from "../automaticallyCreated/ProductRow.module.scss";
import {ProductType} from "./OrderProductTable";
import DetailedProductModal from "./DetailedProductModal";
import Pill from "../../../atoms/Pill";
import addClassNames from "../../../classNameUtils";


function ProductRow({product, hasMissingDataStatus}: {product: ProductType, hasMissingDataStatus: boolean}) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const missingData = product.allMissingData.map(data => data.display)

  return <>
    <SimpleTableRow className={addClassNames([
      {className: styles.productRow, condition: true},
      {className: styles.productRowMissingValues, condition: hasMissingDataStatus && !!missingData.length},
    ])}>
      <SimpleTableCell className={`${styles.productCell} justify-content-center px-4`}>
        <ProductRowLayout.Image image={product?.thumbnail?.url || undefined}/>
      </SimpleTableCell>
      <SimpleTableCell width={18} className={`${styles.productCell} pl-0`}>
        <p className={styles.productText} data-testid={"order-text-style-name"}>{product.styleName || "Not specified"}</p>
        {hasMissingDataStatus && !!missingData.length &&
          <ProductRowLayout.MissingDataInfo missingData={missingData}/>}
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText} data-testid={"order-text-style-number"}>{product.styleNumber || "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <ProductRowLayout.VariantsDropdown variants={product.variants} hasMissingData={hasMissingDataStatus && !!missingData.length}/>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText}>{product?.quantity || "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText}>{product.price ? `${product.price.amount} ${product.price.currency}` : "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={8} className={styles.productCell}>
        {/*//Future column*/}
      </SimpleTableCell>
      {/* //Hidden for now*/}
      {/*<SimpleTableCell className={`${styles.productCell} justify-content-center`}>*/}
        {/*<Pill onClick={toggle}>See details</Pill>*/}
      {/*</SimpleTableCell>*/}
    </SimpleTableRow>
    {/* //Hidden for now*/}
    {/*<DetailedProductModal isOpen={isOpen}*/}
    {/*                      toggle={toggle}*/}
    {/*                      product={product}/>*/}
  </>
}

export default ProductRow;
