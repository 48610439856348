import React from "react";
import IconPill from "./IconPill";
import styles from "./Pagination.module.scss";
import {useHistory} from "react-router-dom";

type PaginationButtonProps = {
  disabled: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon: string,
  className?: string
}

type NavTextProps = {
  rangeStart: number
  total: number
}

type PaginationProps = {
  rangeStart: number
  rangeEnd: number
  total: number
  onChangePage: (param: { direction: "prev" | "next" }) => void
  isLoading?: boolean,
  isDisabled?: boolean,
  className?: string,
  buttonClassName?: string
} & React.ComponentPropsWithoutRef<'button'>

function PaginationButton({disabled, onClick, icon, className, ...rest}: PaginationButtonProps) {
  return <IconPill
    icon={icon}
    className={`d-flex align-items-center px-2 py-0 my-0 ${className || ""}`}
    disabled={disabled}
    onClick={e => {
      if (disabled) {
        return;
      }
      onClick(e);
    }} {...rest}/>

}

function NoContent() {
  return <strong className={`${styles.paginationText} ${styles.noContent}`}>1 / 1</strong>;
}

function NavText({rangeStart, total}: NavTextProps) {
  return <strong className={`${styles.paginationText} ${styles.navContent}`}>{rangeStart + 1} / {total}</strong>;
}

export default function Pagination({
                                     rangeStart,
                                     rangeEnd,
                                     total,
                                     onChangePage,
                                     isLoading = false,
                                     isDisabled = false,
                                     className,
                                     buttonClassName
                                   }: PaginationProps) {
  const hasNext = rangeEnd < total && rangeEnd !== 0
  const hasPrev = rangeStart > 0;

  return <div className={`pagination d-inline-flex justify-content-end align-items-center ${className ? className : ''}`}>
    <PaginationButton icon={'fa-angle-left'}
                      data-testid={"previous-pagination-button"}
                      disabled={isLoading || !hasPrev}
                      onClick={() => onChangePage({direction: 'prev'})}
                      className={buttonClassName}/>
    {(isLoading || isDisabled) ? <NoContent/> : <NavText rangeStart={rangeStart} total={total}/>}
    <PaginationButton icon={'fa-angle-right'}
                      data-testid={"next-pagination-button"}
                      disabled={isLoading || !hasNext}
                      onClick={() => onChangePage({direction: 'next'})}
                      className={buttonClassName}/>

  </div>
};

type PaginationWithLinksProps = {
  currentPage: number,
  lastPage: number,
  nextPageUrl: string,
  previousPageUrl: string,
  className?: string
}

export function PaginationWithLinks({
  className, currentPage, lastPage, nextPageUrl, previousPageUrl
}: PaginationWithLinksProps) {
  const history = useHistory();

  return <div className={`pagination d-inline-flex justify-content-end align-items-center ${className ? className : ''}`}>
    <PaginationButton icon={'fa-angle-left'}
                      data-testid={"previous-pagination-button"}
                      disabled={currentPage <= 1}
                      onClick={() => history.push(previousPageUrl)}/>
    {/*{(isLoading || isDisabled) ? <NoContent/> : <NavText rangeStart={rangeStart} total={total}/>}*/}
    <NavText rangeStart={currentPage - 1} total={lastPage}/>
    <PaginationButton icon={'fa-angle-right'}
                      data-testid={"next-pagination-button"}
                      disabled={currentPage >= lastPage}
                      onClick={() => history.push(nextPageUrl)}
    />
  </div>
}
