/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ErrorsAcknowledgementsModal_order = {
    readonly id: string;
    readonly orderConfirmationExtraProducts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly styleNumber: string | null;
            } | null;
        } | null>;
    } | null;
    readonly orderConfirmationMissingProducts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly styleNumber: string | null;
            } | null;
        } | null>;
    } | null;
    readonly fromBrand: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly " $refType": "ErrorsAcknowledgementsModal_order";
};
export type ErrorsAcknowledgementsModal_order$data = ErrorsAcknowledgementsModal_order;
export type ErrorsAcknowledgementsModal_order$key = {
    readonly " $data"?: ErrorsAcknowledgementsModal_order$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ErrorsAcknowledgementsModal_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DisplayUnifiedProductNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DisplayUnifiedProductNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "styleNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorsAcknowledgementsModal_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DisplayUnifiedProductNodeConnection",
      "kind": "LinkedField",
      "name": "orderConfirmationExtraProducts",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DisplayUnifiedProductNodeConnection",
      "kind": "LinkedField",
      "name": "orderConfirmationMissingProducts",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandNode",
      "kind": "LinkedField",
      "name": "fromBrand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderNode",
  "abstractKey": null
};
})();
(node as any).hash = '32c1c8ab04f6b96a085064013a9cd53b';
export default node;
