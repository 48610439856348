import React, {useCallback} from 'react';
import {ProductSelectorType, ProductTemplateFieldType} from "./types";
import {Col, Row} from "reactstrap";
import styles from './ProductSelectorInput.module.scss';
import {useSheetSelection} from "./SheetSelectionContext";
import Dropdown from '../../../../../atoms/Dropdown';
import Radio from '../../../../../atoms/Radio';
import Button from "../../../../../atoms/Button";
import UniqueValueMultipleSelectDropdown
  from '../../../DDTMappingConfigV2Form/components/dropdowns/UniqueValueMultipleSelectDropdown';
import {useDDTMappingConfigFormContext} from "../../../DDTMappingConfigV2Form/DDTMappingConfigFormContext";
import {getClassNames} from "../../../../../classNameUtils";
import {capitalize} from "../../../../../stringUtils";


function getDisplayLabel(s: string | null): string {
  if (!s) {
    return 'Unknown'
  }
  return capitalize(s.split(':').pop() || "")
}

type ProductSelectorInputProps = {
  value: ProductSelectorType[],
  onChange: (newValues: ProductSelectorType[]) => void,
  onRemove: (index: number) => void,
  productTemplateId: string,
  isLastRow?: boolean
}

const ALWAYS_APPLIED = '_always_applied'


function AlwaysAppliedDisabledDropdown({onProductSelectorChoose}: { onProductSelectorChoose: () => void }) {
  const UniqueValueSelectionMenuTag = useCallback(function () {
    return <div
      className={styles.alwaysAppliedBox}>
      <Radio containerClassNames={'mb-2'} checked label={'Always applied'}/>
      <Radio label={'Applied when specific conditions are met'}
             onClick={onProductSelectorChoose}
      />
    </div>
  }, [onProductSelectorChoose])

  return <Dropdown
    currentValue={{label: 'Always applied'}}
    MenuTag={UniqueValueSelectionMenuTag}
    options={[]}
  />;
}

type SourceDropdownProps = {
  display: string,
  value: ProductSelectorType[],
  onChange: (values: ProductSelectorType[]) => void,
  onRemove: (() => void) | undefined,
  productTemplateFields: ReadonlyArray<ProductTemplateFieldType>,
  currentSelectorIndex: number
}

function SourceDropdown({display, value, onChange, onRemove, productTemplateFields, currentSelectorIndex}: SourceDropdownProps) {

  function replaceSourceForCurrentSelector(
    value: ProductSelectorType[],
    selectedSource: string | null,
  ) {
    return value.map((selector, j) => {
      if (!selectedSource) {
        return null;
      }
      if (currentSelectorIndex === j) {
        return {source: selectedSource || null, op: 'eq', values: []};
      }
      return selector;
    }).filter(x => x) as ProductSelectorType[]
  }

  return <Dropdown
    currentValue={{label: display}}
    onRemove={onRemove}
    onSelectOption={(op) => {
      if (op.value === ALWAYS_APPLIED) {
        onChange([]);
      } else {
        onChange(replaceSourceForCurrentSelector(value, op.value || null))
      }
    }}
    options={[
      {label: 'Always applied', value: ALWAYS_APPLIED},
      {divider: true},
      ...productTemplateFields.filter(f => f.type.startsWith('$$')).map(f => ({
        label: f.name,
        value: f.path,
        type: f.type
      }))
    ]}
  />
}

type ValuesDropdownProps = {
  type: string | null,
  currentSelectorIndex: number,
  productSelectors: ProductSelectorType[],
  onChange: (vals: ProductSelectorType[]) => void,
}

function ValuesDropdown({type, productSelectors, onChange, currentSelectorIndex}: ValuesDropdownProps) {
  const {getUniqueValueDetailsByUniqueValue} = useDDTMappingConfigFormContext();

  let currentSelector = productSelectors[currentSelectorIndex];

  return <>
    {!currentSelector.source && <Button disabled={true}>Select one or more</Button>}

    {type && type.startsWith('$$') && <UniqueValueMultipleSelectDropdown
      caretPosition={"end"}
      type={type}
      value={currentSelector.values.map(uniqueVal => {
        return {value: uniqueVal, label: getUniqueValueDetailsByUniqueValue(uniqueVal)?.value || getDisplayLabel(uniqueVal)}
      })}
      onChange={(change) => onChange(productSelectors.map((selector, j) => {
        if (currentSelectorIndex === j) {
          return {source: currentSelector.source, op: 'eq', values: change.value};
        }
        return selector;
      }).filter(x => x) as ProductSelectorType[])}/>}

  </>
}


export default function ProductSelectorInput({
                                               value,
                                               onChange,
                                               onRemove,
                                               productTemplateId,
                                               isLastRow = false
                                             }: ProductSelectorInputProps) {
  const {getFieldsForTemplate, getTemplateFieldDisplay, getTemplateFieldType} = useSheetSelection();

  const fields = getFieldsForTemplate(productTemplateId);
  if (!fields) {
    return <p className="text-danger">Can not display due to unknown product type</p>
  }


  return <Row className={getClassNames([
    {className: styles.container, condition: true},
    {className: styles.lastRow, condition: isLastRow},
  ])}>
    <Col className={'d-flex align-items-center'}>
      <span className={'mr-2'}>Sheet-specific data requirements are</span>

      {value.length === 0 && <AlwaysAppliedDisabledDropdown onProductSelectorChoose={() => {
        onChange([{source: '', op: 'eq', values: []}])
      }}/>}

      {value.length > 0 && <>
        {value.map((v, i) => {

          let display = (v.source ? getTemplateFieldDisplay(productTemplateId, v.source) : null) || 'Select a data point';
          let type = (v.source && getTemplateFieldType(productTemplateId, v.source)) || null;


          return <React.Fragment key={'selector-' + i}>
            {i !== 0 && <span className={'mx-2'}>and</span>}

            <SourceDropdown
              value={value}
              onChange={onChange}
              onRemove={value.length > 1 ? () => onRemove(i) : undefined}
              display={display}
              productTemplateFields={fields}
              currentSelectorIndex={i}
            />

            <span className={'mx-2'}>is</span>

            <ValuesDropdown
              productSelectors={value}
              onChange={onChange}
              currentSelectorIndex={i}
              type={type}
            />
          </React.Fragment>
        })}
        <Button
          icon={'fa-plus'}
          style={{height: '1.75rem', marginLeft: '1rem'}}
          onClick={e => {
            onChange([...value, {source: '', op: 'eq', values: []}])
          }}
        />
      </>}
    </Col>
  </Row>
}
