import React, {useState} from "react";
import "@babel/preset-typescript"
import {CardBody, Row, Table} from "reactstrap";
import GrayTableHeader, {GrayTableHeaderCell} from "../../molecules/tables/GrayTableHeader";
import OrderRow from "../../molecules/Orders/OrderRow";
import {OrderTableQueryResponse} from "../../specialized/orders/__generated__/OrderTableQuery.graphql";
import Checkbox from "../../atoms/Checkbox";
import Pill from "../../atoms/Pill";
import styles from './OrdersTable.module.scss'
import Dropdown from "../../atoms/Dropdown";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Tooltip from "../../atoms/Tooltip";
import {pluralizeByCount} from "../../stringUtils";
import addClassNames from "../../classNameUtils";
import ColumnsSelectorDropdown, {availableColumns} from "./ColumnsSelectorDropdown";
import NoContentAvailable from "../../dataDisplay/NoContentAvailable";
import noOrders from "../../assets/noContentPlaceholders/noOrders.svg";
import NoSearchResults from "../../dataDisplay/NoSearchResults";

function NoResults({resetFilters}: { resetFilters: () => void }) {
  return <div className={styles.noSearchResultsContainer}>
    <NoSearchResults
      subtitle={"None of the orders match what you are looking for."}
      actionButton={{
        onClick: resetFilters,
        text: "Reset search"
      }}
    />
  </div>
}

function NoContent() {
  return <CardBody className={"p-0"}>
    <div className={styles.noContentContainer}>
      <NoContentAvailable
        image={noOrders}
        title={"No orders yet"}
        subtitle={"Orders added by you and your retailers will appear here."}
      />
    </div>
  </CardBody>
}

export const ORDERS_TABLE_HEADER_HEIGHT = 42;

export type SelectedOrderType = {
  id: string,
  orderNumbers: string[]
}

type OrdersTableProps = {
  environment: RelayModernEnvironment,
  isRetailer: boolean,
  search: string,
  resetFilters: () => void,
  onOrderClick: (id: string) => void,
  perPage: number | null,
  orderRowHeight: number,
  selectedOrders: SelectedOrderType[],
  setSelectedOrders: (val: SelectedOrderType[]) => void,
  setIsDownloadModalOpen: (val: boolean) => void,
  retry: (() => void) | null,
  props: OrderTableQueryResponse
}

export default function OrdersTable({
                                      environment,
                                      isRetailer,
                                      search,
                                      resetFilters,
                                      selectedOrders,
                                      setSelectedOrders,
                                      onOrderClick,
                                      perPage,
                                      orderRowHeight,
                                      setIsDownloadModalOpen,
                                      props
                                    }: OrdersTableProps) {
  const [activeColumns, setActiveColumns] = useState(JSON.parse(localStorage.getItem("activePageColumns") || '["DATE_ADDED"]'))
  const selectedIds = selectedOrders.map(order => order.id)
  const ordersOnPageIds = props.listOrdersAtOnce?.edges.map(edge => edge?.node?.id) || [];
  const ordersOnPageCount = props.listOrdersAtOnce ? props.listOrdersAtOnce?.edges.length : 0;
  const isEntirePageSelected = props.listOrdersAtOnce?.edges
    .filter(edge => edge?.node?.id && selectedIds.includes(edge?.node?.id))
    .length === ordersOnPageCount;
  const areAllOrdersSelected = ordersOnPageCount > 0 && selectedIds.length === props.listOrdersAtOnce?.totalCount;
  const filtersHaveResults = search ? (!!props.listOrdersAtOnce?.totalCount && props.listOrdersAtOnce.totalCount > 0) : true;

  const onOrderSelect = (id: string, orderNumbers: string[]) => {
    if (selectedIds.includes(id)) {
      setSelectedOrders(selectedOrders.filter(order => order.id !== id))
    } else {
      setSelectedOrders([...selectedOrders,
        {
          id: id,
          orderNumbers: orderNumbers
        }]
      )
    }
  }

  const onSelectAll = () => {
    if (isEntirePageSelected) {
      setSelectedOrders(selectedOrders.filter(order => !ordersOnPageIds.includes(order.id)));
    } else {
      const newOrders = props.listOrdersAtOnce?.edges.map(edge => {
        return {
          id: edge?.node?.id || "",
          orderNumbers: ((edge?.node?.orderNumber && [edge?.node?.orderNumber]) || (edge?.node?.orderNumbers && [...edge?.node?.orderNumbers])) || []
        }
      }) || [];
      const uniqueOrders = Array.from(new Set([...selectedOrders, ...newOrders].map(order => JSON.stringify(order)))).map(order => JSON.parse(order) as SelectedOrderType);
      setSelectedOrders(uniqueOrders);
    }
  }

  const isOptionSelected = (option: string) => {
    return activeColumns.includes(option)
  }

  if (!search && props.listOrdersAtOnce?.totalCount === 0) {
    return <NoContent />
  }

  return <CardBody className={"p-0"}>
    <Row className={"mx-0 px-1"}>
      <div style={{maxWidth: "280px"}}/>
      <div className={"ml-auto"}/>
    </Row>
    <Table className={"mb-0"}>
      <GrayTableHeader className={'d-flex'}
                       style={{height: ORDERS_TABLE_HEADER_HEIGHT}}>
        <GrayTableHeaderCell width={3} className={'px-4'}>
          <Checkbox
            onChange={onSelectAll}
            indeterminate={selectedIds.length > 0 && !areAllOrdersSelected}
            checked={areAllOrdersSelected}
            disabled={ordersOnPageCount === 0}
            data-testid="orders-checkbox-select-all-page"/>
        </GrayTableHeaderCell>
        {selectedIds.length > 0 ? <>
          <GrayTableHeaderCell width={15}>
            <div className={styles.selectedOrdersCountContainer}>
              {`${selectedIds.length} selected`}
              <Tooltip text={"Deselect all"}>
                <i className={`fa-regular fa-circle-xmark ${styles.deselectAllIcon}`}
                   onClick={() => setSelectedOrders([])}/>
              </Tooltip>
            </div>
          </GrayTableHeaderCell>
          <GrayTableHeaderCell width={60} className={'justify-content-center py-2 align-middle'}>
            <Pill className={'ml-2'} color={'primary'}
                  onClick={() => setIsDownloadModalOpen(true)}
                  icon={'fa-light fa-arrow-down-to-line'}>
              Download selected orders
            </Pill>
          </GrayTableHeaderCell>
        </> : <>
          <GrayTableHeaderCell width={12}>
            {search ? `${props.listOrdersAtOnce?.totalCount} ${pluralizeByCount("order", props.listOrdersAtOnce?.totalCount || 1)}` : "Order & Number"}
          </GrayTableHeaderCell>
          {isOptionSelected(availableColumns.LOCATION) && <GrayTableHeaderCell width={11}>
            Location
          </GrayTableHeaderCell>}
          {isOptionSelected(availableColumns.DATE_ADDED) && <GrayTableHeaderCell width={10}>
            Date added
          </GrayTableHeaderCell>}
          {isOptionSelected(availableColumns.CUSTOMER_REFERENCE) && <GrayTableHeaderCell width={10} className={'text-nowrap'}>
            Customer reference
          </GrayTableHeaderCell>}
          {isOptionSelected(availableColumns.DELIVERY_PERIOD) && <GrayTableHeaderCell width={10} className={'text-nowrap'}>
            Delivery period
          </GrayTableHeaderCell>}
          {isOptionSelected(availableColumns.COMMENT) && <GrayTableHeaderCell width={11}>
            Comment
          </GrayTableHeaderCell>}
          <GrayTableHeaderCell className={'py-0 ml-auto pr-4'}>
            <div className={addClassNames([
              {
                className: styles.missingDataStatusHeaderContainer,
                condition: isOptionSelected(availableColumns.STATUS_DETAILS)
              },
              {
                className: styles.missingDataStatusHeaderContainerWithoutTags,
                condition: !isOptionSelected(availableColumns.STATUS_DETAILS)
              }
            ])}>
              <Dropdown currentValue={null}
                        options={[]}
                        className={styles.statusDropdown}
                        ToggleTag={({toggleDropdowns}) => {
                          return <Pill onClick={toggleDropdowns}
                                       color={'transparent'}
                                       icon={'fa-regular fa-angles-up-down'}
                                       disabled>
                            {isRetailer ? "Status based on all your outputs" : "Status based on retailer requirements"}
                          </Pill>
                        }}/>
              <ColumnsSelectorDropdown activeColumns={activeColumns} setActiveColumns={setActiveColumns}/>
            </div>
          </GrayTableHeaderCell>
        </>}
      </GrayTableHeader>
      <tbody>
      {
        filtersHaveResults ? props.listOrdersAtOnce?.edges.map(
          (edge, index) =>
            edge?.node && <OrderRow key={edge?.node?.id || index}
                                    environment={environment}
                                    orderRowHeight={orderRowHeight}
                                    isSelected={selectedIds.includes(edge?.node?.id || "")}
                                    onOrderSelect={onOrderSelect}
                                    order={edge.node}
                                    isRetailer={isRetailer}
                                    isEndOfPage={index + 1 === perPage}
                                    onOrderClick={onOrderClick}
                                    activeColumns={activeColumns}/>
        ) : <NoResults resetFilters={resetFilters}/>
      }
      </tbody>
    </Table>
  </CardBody>
}
