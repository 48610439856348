import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import Button from "../../../../../../../atoms/Button";
import Checkbox from "../../../../../../../atoms/Checkbox";
import Dropdown, {SelectableOption} from "../../../../../../../atoms/Dropdown";
import {CustomTextTokenRuleType} from "../../../../types";
import {ProductFieldsProvider} from "../../../../utilities";
import {ModeAfterEditor, ModeBeforeAndAfterEditor, ModeBeforeEditor} from "./TextEditors";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";

const MODE_BEFORE = 'before';
const MODE_AFTER = 'after';
const MODE_BEFORE_AFTER = 'before_after';
type Mode = 'before' | 'after' | 'before_after';


function Icon() {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#4F4F4F"/>
    <path
      d="M16.0625 15.2871H15.3984L12.7617 7.68945C12.7227 7.57227 12.5859 7.47461 12.4688 7.47461H11.5117C11.3945 7.47461 11.2578 7.57227 11.2188 7.68945L8.58203 15.2871H7.9375C7.76172 15.2871 7.625 15.4434 7.625 15.5996V15.9121C7.625 16.0879 7.76172 16.2246 7.9375 16.2246H10.4375C10.5938 16.2246 10.75 16.0879 10.75 15.9121V15.5996C10.75 15.4434 10.5938 15.2871 10.4375 15.2871H9.73438L10.3789 13.4121H13.6016L14.2461 15.2871H13.5625C13.3867 15.2871 13.25 15.4434 13.25 15.5996V15.9121C13.25 16.0879 13.3867 16.2246 13.5625 16.2246H16.0625C16.2188 16.2246 16.375 16.0879 16.375 15.9121V15.5996C16.375 15.4434 16.2188 15.2871 16.0625 15.2871ZM10.7109 12.4746L12 8.80273L13.2695 12.4746H10.7109Z"
      fill="white"/>
  </svg>

}

function getInitialTextMode(after: string, before: string) {
  if (before && after) {
    return MODE_BEFORE_AFTER
  } else if (before && !after) {
    return MODE_BEFORE
  } else if (!before && after) {
    return MODE_AFTER
  } else return MODE_BEFORE
}


type CustomTextRuleProps = CustomTextTokenRuleType & {
  productsFieldsProvider: ProductFieldsProvider,
  fieldDisplay: string,
  onChange: (newVal: CustomTextTokenRuleType) => void,
  onRemove: () => void
}
export default function CustomTextRule({after, before, ignore_if_none, fieldDisplay, onChange, onRemove}: CustomTextRuleProps) {
  const [textMode, setTextMode] = useState<Mode>(getInitialTextMode(after, before))

  const dropdownOptions: SelectableOption[] = [
    {value: MODE_BEFORE, label: 'In front'},
    {value: MODE_AFTER, label: 'After'},
    {value: MODE_BEFORE_AFTER, label: 'In front and after'},
  ]

  let currentDropdownDisplayedValue = dropdownOptions.filter(op => op.value === textMode)[0];

  let onBeforeChange = (newVal: string) => {
    onChange({before: newVal, after, ignore_if_none})
  }

  let onAfterChange = (newVal: string) => {
    onChange({before, after: newVal, ignore_if_none})
  }

  return <div>
    <Row className={"mx-0"}>
      <Col className={'d-flex align-items-center px-0'}>
        <Icon/>

        <span className={'mx-2'}>Add custom text</span>

        <Dropdown currentValue={currentDropdownDisplayedValue}
                  onSelectOption={ev => {
                    if (!ev || !ev.value) {
                      return
                    }
                    if ([MODE_AFTER, MODE_BEFORE, MODE_BEFORE_AFTER].includes(ev.value)) {
                      onChange({before: '', after: '', ignore_if_none})
                      setTextMode(ev.value as Mode);
                    }
                  }}
                  options={dropdownOptions}/>

        <span className={'mx-2'}>of</span>

        <Button disabled={true}>{fieldDisplay}</Button>
      </Col>
    </Row>

    <Row className={'mt-3 mx-0'}>
      <Col className={'d-flex align-items-center pl-3'}>
        {textMode === MODE_BEFORE && <ModeBeforeEditor before={before}
                                                       onBeforeChange={onBeforeChange}/>}
        {textMode === MODE_AFTER && <ModeAfterEditor after={after}
                                                     onAfterChange={onAfterChange}/>}
        {textMode === MODE_BEFORE_AFTER &&
          <ModeBeforeAndAfterEditor before={before}
                                    after={after}
                                    onAfterChange={onAfterChange}
                                    onBeforeChange={onBeforeChange}/>}
      </Col>
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Row>

    <Row className={'mt-3 mx-0'}>
      <Col className={'d-flex align-items-center pl-3'}>
        <Checkbox label={'Hide custom text if data point is empty'}
                  checked={ignore_if_none}
                  onChange={() => {
                    onChange({before, after, ignore_if_none: !ignore_if_none})
                  }}/>
      </Col>
    </Row>


  </div>
}

