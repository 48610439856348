import React from 'react';
import archive from './archive.svg';
import bell from './bell.svg';
import book from './book.svg';
import bookOpen from './book-open.svg';
import checkCircle from './check-circle.svg';
import close from './close.svg';
import copy from './copy.svg';
import edit from './edit.svg';
import eye from './eye.svg';
import eyeOff from './eye-off.svg';
import fileFolder from './file-folder.svg';
import messageSquare from './message-square.svg';
import paperclip from './paperclip.svg';
import plus from './plus.svg';
import plusCircle from './plus-circle.svg';
import save from './save.svg';
import search from './search.svg';
import send from './send.svg';
import settings from './settings.svg';
import share from './share.svg';
import sliders from './sliders.svg';
import trash from './trash.svg';
import upload from './upload.svg';
import uploadCloud from './upload-cloud.svg';
import user from './user.svg';
import userPlus from './user-plus.svg';
import xCircle from './x-circle.svg';


function SvgIcon({svgImagePath}) {
  return <div style={{height: '60px', width: '60px', backgroundColor: '#F1F1FA', borderRadius: '30px'}}
              className={'d-flex align-content-middle justify-content-center'}>
    <img src={svgImagePath} alt=""/>
  </div>
}

export default SvgIcon;

export function ArchiveCircleIcon(props) {
  return <SvgIcon svgImagePath={archive}/>
}

export function BellCircleIcon(props) {
  return <SvgIcon svgImagePath={bell}/>
}

export function BookCircleIcon(props) {
  return <SvgIcon svgImagePath={book}/>
}

export function BookOpenCircleIcon(props) {
  return <SvgIcon svgImagePath={bookOpen}/>
}


export function CheckCircleIcon(props) {
  return <SvgIcon svgImagePath={checkCircle}/>
}

export function CloseCircleIcon(props) {
  return <SvgIcon svgImagePath={close}/>
}

export function CopyCircleIcon(props) {
  return <SvgIcon svgImagePath={copy}/>
}

export function EditCircleIcon(props) {
  return <SvgIcon svgImagePath={edit}/>
}

export function EyeCircleIcon(props) {
  return <SvgIcon svgImagePath={eye}/>
}

export function EyeOffCircleIcon(props) {
  return <SvgIcon svgImagePath={eyeOff}/>
}

export function FileFolderCircleIcon(props) {
  return <SvgIcon svgImagePath={fileFolder}/>
}

export function MessageSquareCircleIcon(props) {
  return <SvgIcon svgImagePath={messageSquare}/>
}

export function PaperclipCircleIcon(props) {
  return <SvgIcon svgImagePath={paperclip}/>
}

export function PlusCircleIcon(props) {
  return <SvgIcon svgImagePath={plus}/>
}

export function PlusCircleCircleIcon(props) {
  return <SvgIcon svgImagePath={plusCircle}/>
}

export function SaveCircleIcon(props) {
  return <SvgIcon svgImagePath={save}/>
}

export function SearchCircleIcon(props) {
  return <SvgIcon svgImagePath={search}/>
}

export function SendCircleIcon(props) {
  return <SvgIcon svgImagePath={send}/>
}

export function SettingsCircleIcon(props) {
  return <SvgIcon svgImagePath={settings}/>
}

export function ShareCircleIcon(props) {
  return <SvgIcon svgImagePath={share}/>
}

export function SlidersCircleIcon(props) {
  return <SvgIcon svgImagePath={sliders}/>
}

export function TrashCircleIcon(props) {
  return <SvgIcon svgImagePath={trash}/>
}

export function UploadCircleIcon(props) {
  return <SvgIcon svgImagePath={upload}/>
}

export function UploadCloudCircleIcon(props) {
  return <SvgIcon svgImagePath={uploadCloud}/>
}

export function UserCircleIcon(props) {
  return <SvgIcon svgImagePath={user}/>
}

export function UserPlusCircleIcon(props) {
  return <SvgIcon svgImagePath={userPlus}/>
}

export function XCircleCircleIcon(props) {
  return <SvgIcon svgImagePath={xCircle}/>
}





