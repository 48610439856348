//key used to get the JWT token from local storage
export const authTokenKey = 'jwtKey';
//authorization code for integrations
export const integrationAuthCode = 'X-Vuuh-Integration';
//size in pixels of one table row
export const tableCellHeight = 46;
//the amount of extra items to load on the first time we display a list
export const itemBufferCount = 10;

export const IGNORED_ERRORS_CONFIG = {
  "ImageNameConfigurationModalQuery": ["brand.no_collections_available"],
  "V2DDTConfigModalQuery": ["brand.no_collections_available"]
}