import React from "react";
import styles from "./GenerateDescriptionFieldEditor.module.scss"
import wand from "../../../../assets/svg/wand.svg"
import {Row} from "reactstrap";
import Dropdown from "../../../../atoms/Dropdown";
import Checkbox from "../../../../atoms/Checkbox";
import {cloneObject} from "../../../../objectUtils";
import {
  GeneralRulesTypes,
  GenerateDescriptionTokenRuleType,
  isGenerateDescriptionTokenRuleType,
  ProductPathTokenType
} from "../types";

const LANGUAGE_OPTIONS = [
  {label: "English", value: "EN"},
  {label: "Danish", value: "DK"},
  {label: "German", value: "DE"},
  {label: "Estonian", value: "EE"},
  {label: "Spanish", value: "ES"},
  {label: "Finnish", value: "FI"},
  {label: "French", value: "FR"},
  {label: "Greek", value: "GR"},
  {label: "Italian", value: "IT"},
  {label: "Norwegian", value: "NO"},
  {label: "Dutch", value: "NL"},
  {label: "Polish", value: "PL"},
  {label: "Portuguese", value: "PT"},
  {label: "Swedish", value: "SE"},
  {label: "Turkish", value: "TR"},
]
LANGUAGE_OPTIONS.sort((x, y) => x.value.charCodeAt(0) - y.value.charCodeAt(0))

export const GENERATE_DESCRIPTION_DEFAULT_VALUES = {
  language: LANGUAGE_OPTIONS.find(option => option.label === "English")?.value || "",
  sentences: "2",
  isUniqueDescription: false
}

type UpdateGenerateDescriptionTokenType = Omit<ProductPathTokenType, "rules"> & {
  rules: { language?: string, sentences?: string, isUniqueDescription?: boolean } []
}

type GenerateDescriptionFieldEditorProps = {
  value: ProductPathTokenType,
  onChange: (value: ProductPathTokenType) => void
}

export default function GenerateDescriptionFieldEditor({value, onChange}: GenerateDescriptionFieldEditorProps) {
  const genDescriptionRule = isGenerateDescriptionTokenRuleType(value?.rules[0]) ? value?.rules[0] : null;
  const ruleHasKey = (key: "language" | "sentences" | "isUniqueDescription") => {
    return (value?.rules && value?.rules?.length > 0 && genDescriptionRule?.hasOwnProperty(key)) || false
  }

  const language = LANGUAGE_OPTIONS.filter(o => {
    return value?.rules && o.value === (ruleHasKey("language") && genDescriptionRule?.language)
  })[0];
  const sentences = (ruleHasKey("sentences") && genDescriptionRule?.sentences) || GENERATE_DESCRIPTION_DEFAULT_VALUES.sentences;
  const isUniqueDescription = ruleHasKey("isUniqueDescription") && genDescriptionRule?.isUniqueDescription;

  const addDefaultValues = (value: UpdateGenerateDescriptionTokenType) => {
    const defaultRules: GenerateDescriptionTokenRuleType = {
      ...value.rules[0],
      type: GeneralRulesTypes.GenerateDescriptionTokenRule,
      language: (ruleHasKey("language") && value.rules[0].language) || GENERATE_DESCRIPTION_DEFAULT_VALUES.language,
      sentences: (ruleHasKey("sentences") && value.rules[0].sentences) || GENERATE_DESCRIPTION_DEFAULT_VALUES.sentences,
      isUniqueDescription: (ruleHasKey("isUniqueDescription") && value.rules[0].isUniqueDescription) || GENERATE_DESCRIPTION_DEFAULT_VALUES.isUniqueDescription
    }

    return {
      ...value,
      rules: [defaultRules]
    }
  }

  return <div className={styles.container}>
    <Row className={styles.firstRow}>
      <img style={{marginRight: "0.625rem"}} src={wand} alt={"wand"}/>
      <p className={styles.label + ' mb-0'}>Automatic generation of descriptions</p>
    </Row>
    <Row className={styles.innerRow}>
      <p className={styles.label + ' mb-0 mr-1'}>Language</p>
      <Dropdown className={"ml-2"}
                currentValue={language || {label: "Select Language"}}
                options={LANGUAGE_OPTIONS}
                onSelectOption={option => {
                  if (option.value != null) {
                    let newValue: UpdateGenerateDescriptionTokenType = cloneObject(value)
                    if (newValue?.rules && newValue?.rules[0]) {
                      newValue.rules[0].language = option.value
                    } else {
                      newValue.rules = [{
                        language: option.value
                      }]
                    }
                    onChange(addDefaultValues(newValue))
                  }
                }}
      />
    </Row>
    <Row className={styles.innerRow}>
      <p className={styles.label + ' mr-1 mb-0'}>Number of sentences</p>
      <Dropdown className={"ml-2"}
                currentValue={{label: sentences, value: sentences}}
                options={
                  Array.from({length: 10}, (value, index) => {
                    return {label: (index + 1).toString(), value: (index + 1).toString()}
                  })
                }
                onSelectOption={option => {
                  if (option.value != null) {
                    let newValue: UpdateGenerateDescriptionTokenType = cloneObject(value)
                    if (newValue?.rules && newValue?.rules[0]) {
                      newValue.rules[0].sentences = option.value
                    } else {
                      newValue.rules = [{sentences: option.value}]
                    }
                    onChange(addDefaultValues(newValue))
                  }
                }}
      />
    </Row>
    <Row className={styles.innerRow + " mb-3"}>
      <Checkbox checked={isUniqueDescription}
                onClick={() => {
                  let newValue: UpdateGenerateDescriptionTokenType = cloneObject(value)
                  if (newValue?.rules && newValue?.rules[0]) {
                    newValue.rules[0].isUniqueDescription = !isUniqueDescription
                  } else {
                    newValue.rules = [{isUniqueDescription: true}]
                  }
                  onChange(addDefaultValues(newValue))
                }}
                label={
                  <p className={styles.label + ' mb-0'}>
                    Generate a unique description for each color instead of a single description for the whole product
                  </p>
                }/>
    </Row>
    {/*TODO: Decomment when data-points will be added in this rule*/}
    {/*<Row className={styles.innerRow}>*/}
    {/*  <div className={styles.innerContainer}>*/}
    {/*    <p className={styles.paragraph + ' m-4'}>*/}
    {/*      An example will be displayed when you add at least 3 data points to be included in the description.*/}
    {/*    </p>*/}
    {/*  </div>*/}
    {/*</Row>*/}
  </div>
}
