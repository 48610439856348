import React from "react";
import ConditionsAndActionsFieldRuleBase from "../alterContent/ConditionsAndActionsFieldRuleBase";
import {Removal} from "./Removal";


export default function RemoveContentFieldRule({
                                                 value,
                                                 tokens,
                                                 onChange,
                                                 onRemove,
                                                 productFieldsProvider,
                                               }) {
  return <ConditionsAndActionsFieldRuleBase
    value={value}
    onChange={onChange}
    onRemove={onRemove}
    productFieldsProvider={productFieldsProvider}
    tokens={tokens}
    options={[
      {
        icon: <i className={'fas fa-plus-circle'}/>,
        value: 'add',
        label: 'Add Another Data Point',
        onClick: () => {
          onChange({...value, conditions: [...value.conditions, {path: null, value: null}]})
        }
      },
      {
        icon: <i className={'fas fa-minus-circle'}/>,
        value: 'remove',
        label: 'Remove Contents',
        onClick: () => {
          onChange({...value, removals: [...value.removals, {index: null}]});
        }
      }
    ]}
    ActionComponent={Removal}
    actionsKey={'removals'}
  />
}
