/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WoocommerceIntegrationQueryVariables = {
    url: string;
};
export type WoocommerceIntegrationQueryResponse = {
    readonly getWoocommerceShopByUrl: {
        readonly id: string;
        readonly distributeDataTemplate: {
            readonly name: string;
            readonly id: string;
            readonly retailerOwners: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                    } | null;
                } | null>;
            };
        } | null;
        readonly authorizationCode: string;
        readonly consumerKey: string | null;
        readonly consumerSecret: string | null;
    } | null;
};
export type WoocommerceIntegrationQuery = {
    readonly response: WoocommerceIntegrationQueryResponse;
    readonly variables: WoocommerceIntegrationQueryVariables;
};



/*
query WoocommerceIntegrationQuery(
  $url: String!
) {
  getWoocommerceShopByUrl(url: $url) {
    id
    distributeDataTemplate {
      name
      id
      retailerOwners {
        edges {
          node {
            id
          }
        }
      }
    }
    authorizationCode
    consumerKey
    consumerSecret
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "concreteType": "WooCommerceShop",
    "kind": "LinkedField",
    "name": "getWoocommerceShopByUrl",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataTemplateNode",
        "kind": "LinkedField",
        "name": "distributeDataTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerNodeConnection",
            "kind": "LinkedField",
            "name": "retailerOwners",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RetailerNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authorizationCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "consumerKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "consumerSecret",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WoocommerceIntegrationQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WoocommerceIntegrationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b52dac027d7eb5c59950424808362deb",
    "id": null,
    "metadata": {},
    "name": "WoocommerceIntegrationQuery",
    "operationKind": "query",
    "text": "query WoocommerceIntegrationQuery(\n  $url: String!\n) {\n  getWoocommerceShopByUrl(url: $url) {\n    id\n    distributeDataTemplate {\n      name\n      id\n      retailerOwners {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    authorizationCode\n    consumerKey\n    consumerSecret\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4a935a9df6473c1a98840b819502698';
export default node;
