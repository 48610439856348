import Dropdown, {DropdownMenuProps, SelectableOption} from "../../../../../atoms/Dropdown";
import Button from "../../../../../atoms/Button";
import React from "react";
import UniqueValueSetDropdownMenuTag from "./UniqueValueSetDropdownMenuTag";


type UniqueValueGlossaryDropdownProps = {
  type: string[],
  presentValues: string [],
  onOptionChange: (value: SelectableOption) => void,
  value: string | undefined
}

export default function UniqueValueSingleSelectDropdown({
                                                          type,
                                                          presentValues,
                                                          onOptionChange,
                                                          value,
                                                        }: UniqueValueGlossaryDropdownProps) {

  function MenuTagAdapter(props: DropdownMenuProps) {
    return <UniqueValueSetDropdownMenuTag type={type} presentValues={presentValues} {...props}/>
  }

  return <Dropdown
    ToggleTag={(props) =>
      <Button style={{height: "2rem"}} className={"justify-content-center"} onClick={props.toggleDropdowns}>
        {value ||
          <span>
            Select a value
            <i className="fas fa-caret-down ml-1"/>
          </span>}
      </Button>}
    currentValue={{label: value ? value : "Select a value"}}
    onSelectOption={({value, label}) => {
      if (value) {
        onOptionChange({value, label})
      }
    }}
    options={[]}
    MenuTag={MenuTagAdapter}/>
}
