import React from "react";
import propTypes from 'prop-types';
import {Spacer} from "./ProductTableRow";
import styles from "./ProductTableRow.module.scss";

class ErrorBoundaryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {error: error};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // this.props.onCaughtError(error, errorInfo);
    this.setState({error: error, errorInfo: errorInfo})
  }

  render() {
    if (this.state.error) {
      return <><Spacer/>
        <tr color={'danger'} className={styles.row + ' error-boundary'}>
          <td/>
          <td/>
          <td/>
          <td width={'100%'} className={'col-md-8'}>
            <h5>An error occurred while displaying this.</h5>
            <p className={'mb-0'}>{this.state.error.toString()}</p>
          </td>
          <td/>
        </tr>
      </>
    }
    return this.props.children;
  }
}

ErrorBoundaryRow.propTypes = {
  onCaughtError: propTypes.func
}

ErrorBoundaryRow.defaultProps = {
  onCaughtError: () => {
  }
}

export default ErrorBoundaryRow;
