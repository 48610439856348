import React from 'react';
import PrimaryActionButton from "./PrimaryActionButton";
import styles from './Pagination.module.scss';

export function ChevronRight(props) {
  return <svg width="15" height="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.80469 1.80542C6.45312 2.15698 6.48828 2.68433 6.80469 3.03589L11.0586 7.0437H0.96875C0.476562 7.0437 0.125 7.43042 0.125 7.88745V9.01245C0.125 9.50464 0.476562 9.8562 0.96875 9.8562H11.0586L6.80469 13.8992C6.48828 14.2507 6.48828 14.7781 6.80469 15.1296L7.57812 15.9031C7.92969 16.2195 8.45703 16.2195 8.77344 15.9031L15.6289 9.04761C15.9453 8.7312 15.9453 8.20386 15.6289 7.85229L8.77344 1.03198C8.45703 0.715576 7.92969 0.715576 7.57812 1.03198L6.80469 1.80542Z"
      fill="#FFFFFF"/>
  </svg>;
}

export function ChevronLeft(props) {
  return <svg width="15" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16016 15.1296C9.51172 14.7781 9.47656 14.2507 9.16016 13.8992L4.90625 9.8562H15.0312C15.4883 9.8562 15.875 9.50464 15.875 9.01245V7.88745C15.875 7.43042 15.4883 7.0437 15.0312 7.0437H4.90625L9.16016 3.03589C9.47656 2.68433 9.51172 2.15698 9.16016 1.80542L8.38672 1.03198C8.07031 0.715576 7.50781 0.715576 7.19141 1.03198L0.371094 7.88745C0.0195312 8.20386 0.0195312 8.7312 0.371094 9.04761L7.19141 15.9031C7.50781 16.2195 8.03516 16.2195 8.38672 15.9031L9.16016 15.1296Z"
      fill="#FFFFFF"/>
  </svg>

}


function PaginationWrapper({className, children}) {
  return <div className={`pagination d-flex-justify-content-end ${className ? className : ''}`}>
    {children}
  </div>
}

function PaginationButton({disabled, onClick, children, className}) {
  return <PrimaryActionButton
    className={`d-flex align-items-center px-2 py-0 my-0 ${className || ""}`}
    disabled={disabled}
    onClick={e => {
      if (disabled) {
        return;
      }
      onClick(e);
    }}>
    {children}
  </PrimaryActionButton>
}


function LoadingText() {
  return <div className={'mx-1 ' + styles.loadingText}/>;
}

function NavText({rangeStart, total}) {
  return <strong style={{minWidth: '2.25rem'}} className={'font-weight-normal align-middle text-center mx-3'}>{rangeStart + 1} / {total}</strong>;
}

export default function ({rangeStart, rangeEnd, total, onChangePage, isLoading, className, buttonClassName}) {
  const hasNext = rangeEnd < total && rangeEnd !== 0
  const hasPrev = rangeStart > 0;

  return <PaginationWrapper className={className}>
    <PaginationButton data-testid={"previous-pagination-button"} disabled={isLoading || !hasPrev} onClick={e => onChangePage({direction: 'prev'})} className={buttonClassName}>
      <ChevronLeft/>
    </PaginationButton>
    {isLoading ? <LoadingText/> : <NavText rangeStart={rangeStart} total={total}/>}
    <PaginationButton data-testid={"next-pagination-button"} disabled={isLoading || !hasNext} onClick={e => onChangePage({direction: 'next'})} className={buttonClassName}>
      <ChevronRight/>
    </PaginationButton>
  </PaginationWrapper>
};
