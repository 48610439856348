/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RetailerStoreCreateMutationInput = {
    retailerId?: string | null | undefined;
    name: string;
    address?: string | null | undefined;
    zipCode?: string | null | undefined;
    city?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type AddRetailerStoreMutationVariables = {
    input: RetailerStoreCreateMutationInput;
};
export type AddRetailerStoreMutationResponse = {
    readonly createRetailerStore: {
        readonly instance: {
            readonly retailer: {
                readonly id: string;
                readonly name: string;
                readonly stores: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                            readonly name: string;
                            readonly address: string | null;
                            readonly city: string | null;
                            readonly zipCode: string | null;
                        } | null;
                    } | null>;
                };
            };
            readonly name: string;
            readonly id: string;
        };
    } | null;
};
export type AddRetailerStoreMutation = {
    readonly response: AddRetailerStoreMutationResponse;
    readonly variables: AddRetailerStoreMutationVariables;
};



/*
mutation AddRetailerStoreMutation(
  $input: RetailerStoreCreateMutationInput!
) {
  createRetailerStore(input: $input) {
    instance {
      retailer {
        id
        name
        stores {
          edges {
            node {
              id
              name
              address
              city
              zipCode
            }
          }
        }
      }
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RetailerStoreCreateMutationPayload",
    "kind": "LinkedField",
    "name": "createRetailerStore",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerStoreNode",
        "kind": "LinkedField",
        "name": "instance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerNode",
            "kind": "LinkedField",
            "name": "retailer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RetailerStoreNodeConnection",
                "kind": "LinkedField",
                "name": "stores",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RetailerStoreNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RetailerStoreNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "zipCode",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRetailerStoreMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddRetailerStoreMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "de02f4cf49b229427225abdfb64531ab",
    "id": null,
    "metadata": {},
    "name": "AddRetailerStoreMutation",
    "operationKind": "mutation",
    "text": "mutation AddRetailerStoreMutation(\n  $input: RetailerStoreCreateMutationInput!\n) {\n  createRetailerStore(input: $input) {\n    instance {\n      retailer {\n        id\n        name\n        stores {\n          edges {\n            node {\n              id\n              name\n              address\n              city\n              zipCode\n            }\n          }\n        }\n      }\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '37c4971f785cd5ca9ce201dee59e8399';
export default node;
