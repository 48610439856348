import React, {useContext, useEffect, useState} from "react";
import FashionProductList from "../../../components/collectionViews/fashion/FashionProductList";
import {Alert, Card, CardBody, Col, Row} from "reactstrap";
import NoContentYet from "../../../components/dataDisplay/NoContentYet";
import {QueryRenderer} from "react-relay";
import {graphql} from "graphql";
import ProductFilters from "../../Products/components/ProductFilters";
import ProductPagination from "../../Products/components/ProductPagination";
import ErrorBoundary from "../../../ui-kit/src/general/ErrorBoundary";
import ProductPageLoadingState from "../../../ui-kit/src/loadingPageStates/ProductPageLoadingState";
import DownloadButtonWithDdtModal from "../../../ui-kit/src/specialized/products/DownloadButtonWithDdtModal";

const query = graphql`
    query IntegrationDistributeProductViewQuery(
        $first: Int,
        $last: Int,
        $before: String,
        $after: String,
        $collection: ID,
        $brand: ID,
        $name: String,
    ) {
        listProductCollections(brand: $brand) {
            ...ProductFilters_collections
        }
        listBrands(state: "active") {
            ...ProductFilters_brands
            edges {
                node {
                    id
                }
            }
        }
        listProductsForDisplay(first: $first, after: $after, last: $last, before: $before,
            brand: $brand, collection: $collection, name: $name
        ) {
            ...ProductPageHeader_productCount
            ...ProductPagination_pagination
            ...FashionProductList_products
            pageInfo {
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                }
            }
        }
    }
`

const PER_PAGE = 10


export default function IntegrationDistributeProductView({integrationContext}) {
  const [collection, setCollection] = useState(null);
  const [brand, setBrand] = useState(null);
  const [search, setSearch] = useState(null);
  const [pagination, setPagination] = useState({first: PER_PAGE, after: null});
  const integrationDetails = useContext(integrationContext)

  useEffect(() => {
    // when filters get updated, we need to reset the pagination
    setPagination({first: PER_PAGE, after: null});
  }, [brand, collection, search])

  useEffect(() => {
    setCollection(null);
  }, [brand])

  return <div className="animated fadeIn mt-4">
    <Row>
      <Col>
        <Card className="card-shadow">
          <QueryRenderer
              environment={integrationDetails.environment}
              query={query}
              variables={{
                first: pagination.first,
                last: pagination.last,
                before: pagination.before,
                after: pagination.after,
                collection: collection && collection.value,
                brand: brand && brand.value,
                name: search,
              }}
              render={({error, props}) => {
                if (error) {
                  return <Alert color={'danger'}>Failed to fetch the products. Please refresh the page.</Alert>
                }
                if (props) {
                  if (props.listBrands.edges.length < 1) {
                    return <NoContentYet header={'No products to show'}
                                         paragraph={'Get data access from a brand to see their products.'}
                                         icon={'fa-box-open'} linkLocation={'/brands'} linkText={'Go to Brands'}
                                         shadow={false}/>
                  }
                  return <>
                    <hr className={'my-0'}/>
                    <CardBody>
                      <Row>
                        <Col md={8}>
                          <ProductFilters
                              orderConfirmation={null}
                              collections={brand ? props.listProductCollections : null}
                              collection={collection}
                              setCollection={setCollection}
                              brands={props.listBrands}
                              brand={brand}
                              setBrand={setBrand}
                              search={search}
                              setSearch={setSearch}
                          />
                        </Col>
                        <Col md={2}>
                          <ProductPagination
                              perPage={PER_PAGE}
                              pagination={props.listProductsForDisplay}
                              onPageChange={({direction}) => {
                                if (direction === 'prev') {
                                  setPagination({
                                    last: PER_PAGE,
                                    before: props.listProductsForDisplay.pageInfo.startCursor
                                  })
                                } else if (direction === 'next') {
                                  setPagination({
                                    first: PER_PAGE,
                                    after: props.listProductsForDisplay.pageInfo.endCursor
                                  })
                                }
                              }}
                          />
                        </Col>
                        <Col md={2}>
                          <DownloadButtonWithDdtModal
                              isOrderView={false}
                              collection={collection}
                              search={search}
                              preselectedDdtId={integrationDetails.id}
                              environment={integrationDetails.environment}
                              user={{
                                retailer: {
                                  id: integrationDetails.owner
                                }
                              }}
                          />
                        </Col>
                      </Row>
                      <ErrorBoundary className={"my-3"}>
                        <FashionProductList setIsFirstOrderConfirmationLoad={() => {
                        }}
                                            orderConfirmationBatch={null}
                                            environment={integrationDetails.environment}
                                            products={props.listProductsForDisplay}
                        />
                      </ErrorBoundary>
                    </CardBody>
                  </>

                } else {
                  return <ProductPageLoadingState isRetailerScope={true}/>
                }
              }}
          />
        </Card>
      </Col>
    </Row>
  </div>
}
