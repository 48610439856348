/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderConfirmationUploadWizardModal_ddts = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly isGlossaryMapped: boolean | null;
            readonly orderConfirmationConfigs: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly name: string;
                    } | null;
                } | null>;
            } | null;
        } | null;
    } | null>;
    readonly " $refType": "OrderConfirmationUploadWizardModal_ddts";
};
export type OrderConfirmationUploadWizardModal_ddts$data = OrderConfirmationUploadWizardModal_ddts;
export type OrderConfirmationUploadWizardModal_ddts$key = {
    readonly " $data"?: OrderConfirmationUploadWizardModal_ddts$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrderConfirmationUploadWizardModal_ddts">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderConfirmationUploadWizardModal_ddts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DistributeDataTemplateNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DistributeDataTemplateNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isGlossaryMapped",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreConnection",
              "kind": "LinkedField",
              "name": "orderConfirmationConfigs",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Stores",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DistributeDataTemplateNodeConnection",
  "abstractKey": null
};
})();
(node as any).hash = '2f9d76e7cd627f79db22d4db56b9a95e';
export default node;
