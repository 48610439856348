import {QueryRenderer, graphql} from "react-relay";
import DownloadReadyContent from "./DistributeDataModalContentComponents/DownloadReadyContent";
import IntegrationFinishedContent from "./DistributeDataModalContentComponents/IntegrationFinishedContent";
import {LoadingContentSpinner} from "./DistributeDataModalContentComponents/LoadingContent";
import React from "react";
import ErrorModalContent from "./ErrorModalContent";
import {ErrorAlert} from "../../commons/errors";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {OutputReadyModalContentQuery} from "./__generated__/OutputReadyModalContentQuery.graphql";

const query = graphql`
  query OutputReadyModalContentQuery($fileId: ID!) {
    getDistributeDataOutput(id: $fileId) {
      id
      url
      distributeDataTemplate {
        id
        name
        engine
        outputType
      }
      productsSuccessCount
      productsFailedCount
    }
  }
`;

type OutputReadyModalContentProps = {
  environment: RelayModernEnvironment,
  isFromOrders?: boolean,
  result: {id: string} | null,
  toggle: () => void
}

export default function OutputReadyModalContent({environment, isFromOrders = false, result, toggle}: OutputReadyModalContentProps) {
  if(result?.id) {
    return <QueryRenderer<OutputReadyModalContentQuery>
      environment={environment}
      query={query}
      variables={{fileId: result.id}}
      render={({error, props}) => {
        if (error) {
          return <ErrorModalContent error={error} toggle={toggle} isFromOrders={isFromOrders}/>
        }
        if (props) {
          return props.getDistributeDataOutput?.distributeDataTemplate?.outputType === "TEMPLATE" ?
            <DownloadReadyContent toggle={toggle} props={props}/> :
            <IntegrationFinishedContent toggle={toggle} props={props}/>
        }
        return <LoadingContentSpinner toggle={toggle}/>
      }}
    />
  } else {
    return <ErrorAlert error={"Something went wrong"}/>
  }
}
