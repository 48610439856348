import {graphql} from "graphql";
import {Alert, Modal, ModalFooter} from "reactstrap";
import SecondaryActionButton from "../../../general/SecondaryActionButton";
import {QueryRenderer} from "react-relay";
import React from "react";
import SingleProductModal from "./SingleProductModal";


const query = graphql`
  query DetailedProductModalQuery($id: ID!) {
    getProduct(id: $id) {
      id
      name
      data
      collection {
        id
        name
      }
      unifiedImageGroups {
        edges {
          node {
            id
            images {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
      unifiedproductSet {
        edges {
          node {
            id
            data
            imageGroups {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      attachedUniqueValues {
        jsonSchemaValue
        value
      }
      productTemplate {
        productRenderConfig
      }
    }
  }
`

export default function DetailedProductModal({productId, isOpen, toggle, environment}) {

  if (!isOpen) {
    return null;
  }
  return <QueryRenderer
    query={query}
    environment={environment}
    variables={{id: productId}}
    render={({props, error}) => {
      if (error) {
        return <Modal isOpen={isOpen} toggle={toggle}>
          <Alert color={'danger'}>Failed to fetch the product information</Alert>
          <ModalFooter>
            <SecondaryActionButton onClick={toggle}>Close</SecondaryActionButton>
          </ModalFooter>
        </Modal>
      }
      if (props) {
        return <SingleProductModal
          isOpen={isOpen}
          toggle={toggle}
          renderConfig={props.getProduct.productTemplate.productRenderConfig}
          product={props.getProduct}
        />
      }
      return null;
    }}
  />
}
