import Checkbox from "../../../atoms/Checkbox";
import styles from "./ProductAndVariantsTabDisplay.module.scss";
import {Tab, Tabs} from "../../../atoms/Tabs";
import React from "react";

export const PRODUCT = "Product"
export const VARIANT = "Variant"

type ProductAndVariantsTabDisplayProps = {
  isIncludeProductLineChecked: boolean,
  setIsIncludeProductLineChecked: (isIncludeProductLineChecked: boolean) => void;
  isSkipFirstVariantChecked: boolean,
  setIsSkipFirstVariantChecked: (isSkipFirstVariantChecked: boolean) => void;
  setActiveTab: (activeTab: "Product" | "Variant") => void;
  activeTab: "Product" | "Variant",

}

export default function ProductAndVariantsTabDisplay({
                                                       isIncludeProductLineChecked,
                                                       setIsIncludeProductLineChecked,
                                                       isSkipFirstVariantChecked,
                                                       setIsSkipFirstVariantChecked,
                                                       activeTab,
                                                       setActiveTab
                                                     }: ProductAndVariantsTabDisplayProps) {

  return <div className={'mb-3 mt-2'}>
    <div className={"d-flex align-items-center"} style={{minHeight: "2rem", lineHeight: "2rem"}}>
      <Checkbox checked={isIncludeProductLineChecked}
                label={'Include a product line before the product variants'}
                style={{marginBottom: "0.5rem"}}
                onChange={() => setIsIncludeProductLineChecked(!isIncludeProductLineChecked)}/>
      {isIncludeProductLineChecked ?
        <Tabs className={"ml-2"} active={activeTab}>
          <Tab name={PRODUCT} onClick={() => setActiveTab(PRODUCT)}>
            <span>Variants</span>
          </Tab>
          <Tab name={VARIANT} onClick={() => setActiveTab(VARIANT)}>
            <span>Product line</span>
          </Tab>
        </Tabs> : null}
    </div>
    {isIncludeProductLineChecked &&
      <Checkbox checked={isSkipFirstVariantChecked}
                onChange={() => setIsSkipFirstVariantChecked(!isSkipFirstVariantChecked)}
                label={"Skip the first variant"}/>}
  </div>
}
