import React, {useState} from "react";
import {createFragmentContainer, QueryRenderer, graphql} from "react-relay";
import GrayModal from "../../dataDisplay/GrayModal";
import ChooseDDTStep from "./orderConfirmationSteps/ChooseDDTStep";
import InvalidFileIntermediateStep from "./orderConfirmationSteps/InvalidFileIntermediateStep";
import OrderConformationFileUploadStep from "./orderConfirmationSteps/OrderConfirmationFileUploadStep";
import {UploadingFilesStep} from "./orderConfirmationSteps/UploadingFilesStep";
import {ErrorAlert} from "../../commons/errors";
import Loading from "../../atoms/Loading";


const query = graphql`
  query OrderConfirmationUploadWizardModalQuery {
    listDistributeDataTemplates(isActive: true) {
      edges {
        node {
          id
          name
          isGlossaryMapped
          orderConfirmationConfigs {
            edges {
              node {
                name
              }
            }
          }
        }
      }
      edges {
        node {
          id
          name
          isGlossaryMapped
          orderConfirmationConfigs {
            edges {
              node {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`

const STEP_SELECT_DDT = 0;
const STEP_SELECT_FILES = 1;

function getOrderConfirmationConfigs(ddts, currentDdt) {
  if (!currentDdt) {
    return []
  }
  return ddts.edges.filter(e => e.node.id === currentDdt)[0].node.orderConfirmationConfigs.edges.map(e => e.node);
}

function getDdts(ddts) {
  return ddts.edges.map(e => {
    return {
      id: e.node.id,
      name: e.node.name,
      orderConfirmations: e.node.orderConfirmationConfigs.edges.map(edge => edge.node.name),
      disabled: !e.node.isGlossaryMapped
    }
  }).filter(node => node.orderConfirmations.length > 0);
}


function ModalWrapper({isOpen, toggle, children}) {
  return <GrayModal size={'lg'}
                    isOpen={isOpen}
                    toggle={toggle}
                    title={"Upload order confirmations"}
                    secondaryHeaderButton={{text: 'Cancel', onClick: toggle, dataTestId: "orders-button-add-oc-cancel"}}
                    bodyContent={<div className={'p-3'}>{children}</div>}/>
}


function OrderConfirmationUploadWizardModal({
                                              isOpen,
                                              toggle,
                                              environment,
                                              pendingBatchId,
                                              onFinished,
                                              loading,
                                              error,
                                              onUploadFiles,
                                              allowRawBarcodeMode = true,
                                            }) {
  const [currentStep, setCurrentStep] = useState(STEP_SELECT_DDT);
  const [currentDdt, setCurrentDdt] = useState(null);

  // reset the wizard state when dismissing the modal
  const clearAndToggle = () => {
    setCurrentStep(STEP_SELECT_DDT);
    setCurrentDdt(null);
    toggle();
  }

  const getContent = (ddts) => {
    if (error) {
      return <InvalidFileIntermediateStep
        error={error}
      />
    } else if (loading) {
      return <UploadingFilesStep
        environment={environment}
        pendingBatchId={pendingBatchId}
        onFinished={onFinished}
      />
    } else if (currentStep === STEP_SELECT_DDT) {
      return <ChooseDDTStep
        ddts={getDdts(ddts)}
        toggle={clearAndToggle}
        onDdtChange={({value}) => {
          setCurrentDdt(value);
          setCurrentStep(STEP_SELECT_FILES);
        }}
      />
    } else if (currentStep === STEP_SELECT_FILES) {
      return <OrderConformationFileUploadStep
        allowRawBarcodeMode={allowRawBarcodeMode}
        toggle={clearAndToggle}
        configs={getOrderConfirmationConfigs(ddts, currentDdt)}
        submitFiles={files => {
          onUploadFiles(files, currentDdt);
        }}
      />
    }
    return <ErrorAlert error={"Something went wrong"}/>;
  }

  return <ModalWrapper toggle={clearAndToggle} isOpen={isOpen}>
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{}}
      render={({props, queryError}) => {
        if (queryError) {
          return <ErrorAlert error={queryError}/>
        }
        if (props && props.listDistributeDataTemplates && props.listDistributeDataTemplates.edges) {
          return getContent(props.listDistributeDataTemplates)
        }
        return <Loading/>
      }}
    />
  </ModalWrapper>
}

export default createFragmentContainer(
  OrderConfirmationUploadWizardModal,
  {
    ddts: graphql`
      fragment OrderConfirmationUploadWizardModal_ddts on DistributeDataTemplateNodeConnection {
        edges {
          node {
            id
            name
            isGlossaryMapped
            orderConfirmationConfigs {
              edges {
                node {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
);
