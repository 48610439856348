import React from 'react';
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {DefaultInput} from "../../../../../../../atoms/Input";
import Dropdown, {SelectableOption} from "../../../../../../../atoms/Dropdown";
import styles from './ReduceContentRule.module.scss';

type ReduceContentRuleProps = {
    mode: string,
    length: number,
    strategy: string,
    onChange: (val: {mode: string, length: number, strategy: string}) => void,
    onRemove: () => void
};

const checkedRadioButton = <i className={`fa-solid fa-circle-dot ${styles.checkedRadioButton}`} />;
const uncheckedRadioButton = <i className={`fa-thin fa-circle ${styles.uncheckedRadioButton}`} />;

export default function ReduceContentRule({mode, length, strategy, onChange, onRemove}: ReduceContentRuleProps) {
    const modeDropdownOptions = [
        {
            label: 'characters',
            value: 'chars',
            icon: mode === 'chars' ? checkedRadioButton : uncheckedRadioButton
        },
        {
            label: 'words',
            value: 'words',
            icon: mode === 'words' ? checkedRadioButton : uncheckedRadioButton
        }
    ]
    const currentModeOption = {
        label: modeDropdownOptions.find(op => op.value === mode)?.label || "unknown"
    }

    const strategyDropdownOptions = [
        {
            label: "from the end",
            value: "end",
            icon: strategy === 'end' ? checkedRadioButton : uncheckedRadioButton
        },
        {
            label: "from the beginning",
            value: "begin",
            icon: strategy === 'begin' ? checkedRadioButton : uncheckedRadioButton
        }
    ]
    const currentStrategyOption = {
        label: strategyDropdownOptions.find(op => op.value === strategy)?.label || "unknown"
    }

    const onLengthChange = (newLength: string) => {
        if(!isNaN(Number(newLength))) {
            onChange({length: Number(newLength) || 0, mode: mode, strategy: strategy})
        }
    }

    const onModeChange = (newMode: SelectableOption) => {
        onChange({length: length, mode: newMode.value || modeDropdownOptions[0].value, strategy: strategy})
    }

    const onStrategyChange = (newStrategy: SelectableOption) => {
        onChange({length: length, mode: mode, strategy: newStrategy.value || strategyDropdownOptions[0].value})
    }

    return <div className={'d-flex align-items-center mt-4'}>
        <div className={styles.iconContainer}>
            <i className={`fa-regular fa-text-slash ${styles.icon}`} />
        </div>
        <span className={styles.lightText}>Reduce content to</span>
        <DefaultInput value={length || ""}
               onChange={e => onLengthChange(e.target.value)}
               width={'3rem'}
               className={styles.ruleInput}
               invalid={!length}/>
        <Dropdown currentValue={currentModeOption}
                  options={modeDropdownOptions}
                  onSelectOption={onModeChange}
                  optionClassName={'d-flex align-items-center'}
                  className={styles.ruleInput}/>
        <Dropdown currentValue={currentStrategyOption}
                  options={strategyDropdownOptions}
                  onSelectOption={onStrategyChange}
                  optionClassName={'d-flex align-items-center'}
                  className={styles.ruleInput}/>
        <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </div>
}
