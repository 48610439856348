import React, {useState} from "react";
import {Row} from "reactstrap";
import Button from "../../../../../../atoms/Button";
import Dropdown from "../../../../../../atoms/Dropdown";
import addClassNames from "../../../../../../classNameUtils";
import styles from "../FieldRules.module.scss";
import RemoveRuleIcon from "../RemoveRuleIcon";
import {Condition} from "./AlterContentFieldRule";
import KindDropdown from "./KindDropdown";


export default function ConditionsAndActionsFieldRuleBase({
                                                            value,
                                                            onChange,
                                                            onRemove,
                                                            productFieldsProvider,
                                                            tokens,
                                                            options,
                                                            ActionComponent,
                                                            actionsKey
                                                          }) {
  let [isExpanded, setIsExpanded] = useState(false)

  let caretClasses = addClassNames([
    {className: "fa-solid mr-3 ml-auto", condition: true},
    {className: "fa-caret-down", condition: !isExpanded},
    {className: "fa-caret-up", condition: isExpanded},
  ])

  let conditions = value.conditions || [];
  let actions = value[actionsKey] || [];

  if (conditions.length === 0) {
    conditions = [{path: null, value: null}]
  }

  const applyChangesOn = (baseArray, key, index) => {
    if (typeof baseArray === 'string') {
      baseArray = [baseArray];
    }

    return changes => {
      onChange({
        ...value,
        [key]: baseArray.map((c, j) => {
          if (index === j) {
            return {...c, ...changes};
          }
          return c;
        })
      })
    }
  }

  return <div className={styles.container}>
    <Row className={'d-flex align-items-center mx-0'} style={{rowGap: "1rem"}} noGutters>
      <span className="mx-1">
        When
      </span>

      {conditions.map((condition, i) => {
        let match_on_token = null
        if (condition.match_on_token || typeof condition.match_on_token === "number") {
          match_on_token = condition.match_on_token
        }

        return <React.Fragment key={i}>
          {i !== 0 && <KindDropdown
            value={value.kind || 'and'}
            onChange={(newKind) => onChange({...value, kind: newKind})}
            displayAs={i === 1 ? 'dropdown' : 'label'}
          />}
          <Condition path={condition.path}
                     match_on_token={match_on_token}
                     values={typeof condition.value === 'string' ? [condition.value] : condition.value}
                     tokens={tokens}
                     op={condition.op || 'eq'}
                     canBeRemoved={conditions.length > 1}
                     onChange={applyChangesOn(conditions, 'conditions', i)}
                     onRemove={() => {
                       onChange({...value, conditions: conditions.filter((c, j) => i !== j)})
                     }}
                     productFieldsProvider={productFieldsProvider}
          />
        </React.Fragment>
      })}
      <Dropdown
        className={'d-inline-block mx-2'}
        ToggleTag={(props) =>
          <Button {...props} onClick={props.toggleDropdowns} className={styles.dropdownToggle}>
            <i className="fas fa-plus mr-0"/>
          </Button>}
        options={options}
        onSelectOption={(selectedOption) => {
          setIsExpanded(true);
          selectedOption.onClick();
        }}
        currentValue={null}
      />
      {actions.length ?
        <i className={caretClasses} onClick={() => setIsExpanded(!isExpanded)} data-testid={"conditions-caret"}/> :
        <RemoveRuleIcon onClick={onRemove} className={'ml-auto'}/>
      }

    </Row>

    {isExpanded && <div className={'pl-3'}>
      {actions.map((act, i) => {
        return <div key={i}>
          <hr style={{marginTop: "1.5rem", marginBottom: "1.5rem"}}/>
          <ActionComponent
            {...act}
            isSeparator={act.isSeparator}
            isRemove={act.isRemove || act.value === null}
            isReplaceText={act.hasOwnProperty('target') && act.hasOwnProperty('value')}
            onChange={applyChangesOn(actions, actionsKey, i)}
            onRemove={() => {
              onChange({
                ...value,
                [actionsKey]: actions.filter((r, j) => i !== j)
              })
            }}
            tokens={tokens}/>
        </div>
      })}
    </div>}
  </div>
}
