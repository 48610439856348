import React, {Component} from 'react'
import {graphql} from "graphql";
import {withRouter} from "react-router-dom";
import {createFragmentContainer} from "react-relay";
import propTypes from 'prop-types';
import {connect} from "react-redux";
import {deselectProduct, selectProduct} from "../../../actions/products";
import ErrorBoundary from "../../../ui-kit/src/general/ErrorBoundary";
import styles from './FashionProductItem.module.scss';
import ProductTableRow from "../../../ui-kit/src/specialized/products/row/ProductTableRow";
import {UniqueValueTranslatorProvider} from "../../../ui-kit/src/context/UniqueValueTranslatorContext";
import DetailedProductModal from "../../../ui-kit/src/specialized/products/detailed/DetailedProductModal";
import VariantBuilder from "../../../ui-kit/src/specialized/products/VariantBuilder";


export function buildVariants(product) {
  return new VariantBuilder(product).build()
}


class FashionProductItem extends Component {

  constructor(props) {
    super(props);
    let unified_prod_pks = [];
    this.props.product.unifiedproductSet.edges.map(({node}) =>
        unified_prod_pks.push(node.id)
    );

    this.unified_prod_pks = unified_prod_pks;
    this.variants = buildVariants(this.props.product);
    this.state = {
      detailedProductViewIsVisible: false,
      expanded: false,
    }
  }

  toggleExpanded = () => {
    this.setState({expanded: !this.state.expanded});
  }


  toggleDetailedProductView = () => {
    this.setState({
      detailedProductViewIsVisible: !this.state.detailedProductViewIsVisible
    })
  };


  isSelected = () => {
    if (this.props.allSelected) {
      return !this.props.allSelectedIgnoredProducts.includes(this.props.product.id)
    } else {
      return this.props.selectedProducts.includes(this.props.product.id)
    }
  };

  getPrimaryImage = () => {
    if (this.props.product.images.edges.length) {
      return <div className="product-thumbnail mx-auto"
                  style={{backgroundImage: 'url(' + this.props.product.images.edges[0].node.url + ')'}}/>
    } else {
      return <div className="product-thumbnail mx-auto bg-muted">
        <div className={styles.productImagePlaceholder + ' d-flex align-items-center justify-content-center'}>
          <i className="fas fa-image fa-2x text-white"/>
        </div>
      </div>
    }
  };

  isVariantSelected = (variant) => {
    if (this.props.allSelected) {
      let variantUnifiedIds = variant.unifiedProducts.map(u => u.id);
      let ignored = this.props.allSelectedIgnoredUnifiedProducts;
      let variantUnifIgnored = variantUnifiedIds.filter(x => ignored.includes(x));
      return variantUnifIgnored.length === 0
    } else {
      let variantUnifiedIds = variant.unifiedProducts.map(u => u.id);
      let selected = this.props.selectedUnifiedProducts;
      let variantUnifSelected = variantUnifiedIds.filter(x => selected.includes(x))
      return variantUnifSelected.length === variantUnifiedIds.length;
    }
  }

  partitionVariantUnifiedBySelectionStatus = (variant) => {
    let selectedUnified;
    let deselectedUnified;
    if (this.props.allSelected) {
      let ignored = this.props.allSelectedIgnoredUnifiedProducts;
      selectedUnified = variant.unifiedProducts.filter(u => !ignored.includes(u.id));
      deselectedUnified = variant.unifiedProducts.filter(u => ignored.includes(u.id));
    } else {
      let selected = this.props.selectedUnifiedProducts;
      selectedUnified = variant.unifiedProducts.filter(u => selected.includes(u.id));
      deselectedUnified = variant.unifiedProducts.filter(u => !selected.includes(u.id));
    }
    return {
      selected: selectedUnified,
      deselected: deselectedUnified,
      total: variant.unifiedProducts.length
    };
  }

  getSelectedVariants = () => {
    let configs = this.getVariantConfigs();
    return this.variants.filter(v => configs[v.id].checked).map(v => v.id);
  }

  isSubvariantSelected = (variantId, subVariantId) => {
    let configs = this.getVariantConfigs();
    return configs[variantId].subVariantsConfigs[subVariantId].isChecked;
  }

  getVariantConfigs = () => {
    let configs = {}
    for (let variant of this.variants) {
      let variantUnifPartition = this.partitionVariantUnifiedBySelectionStatus(variant);
      let checked = variantUnifPartition.selected.length === variantUnifPartition.total;
      let indeterminate = variantUnifPartition.deselected.length > 0 && variantUnifPartition.deselected.length < variantUnifPartition.total;
      configs[variant.id] = {
        checked: checked || indeterminate,
        indeterminate: indeterminate,
        subVariantsConfigs: variant.subVariants.map(x => {
          return {isChecked: this.props.selectedUnifiedProducts.includes(x.unifiedProductId)}
        })
      }
    }
    return configs
  }

  getSubVariantId(subVariants, subVariantId) {
    let subVariant = subVariants.filter(e => {
      return (e.subVariantID === subVariantId)
    })
    // buildVariants makes sure that subVariants is never empty and has at least undefined unifiedProductId,
    // which is treated later in the ProductTableRow
    return subVariant[0].unifiedProductId
  }

  render() {
    let isSelected = this.isSelected();

    let productName = this.props.product.name;
    let styleNumber = this.props.product.styleNumber;
    let collectionName = this.props.product.collectionName;
    let brandName = this.props.product.brandName;

    let variantConfigs = this.getVariantConfigs()
    return <>
      <UniqueValueTranslatorProvider legend={this.props.product && this.props.product.attachedUniqueValues}>
        <ProductTableRow
            className={'mt-3'}
            isSelected={isSelected}
            onProductCheckChange={() => {
              if (isSelected) {
                this.props.onDeselectProduct(this.props.product.id, this.unified_prod_pks);
              } else {
                this.props.onSelectProduct(this.props.product.id, this.unified_prod_pks);
              }
            }}
            collectionName={collectionName}
            brandName={brandName}
            productName={productName}
            styleNumber={styleNumber}
            images={this.props.product.unifiedImageGroups.edges.map(({node}) => node.images.edges.map(({node}) => node)).flat()}
            variants={this.variants}
            variantsConfigs={variantConfigs}

            onClick={(e) => {
              this.toggleDetailedProductView();
            }}
            variantSelectionExpanded={this.state.expanded}
            onVariantCheckChange={variant => {
              let unifIds = variant.unifiedProducts.map(u => u.id);
              let selectedVariants = this.getSelectedVariants();
              if (selectedVariants.includes(variant.id)) {
                // when this is the last selected variant which we are deselecting,
                // make sure that the whole product is deselected altogether
                this.props.onDeselectProduct(selectedVariants.length === 1 ? this.props.product.id : null, unifIds);
              } else {
                this.props.onSelectProduct(this.props.product.id, unifIds);
              }
            }}
            onProductSubvariantCheckChange={(variantId, subVariantId) => {
              let subVarId = [this.getSubVariantId(this.variants[variantId].subVariants, subVariantId)]
              if (this.isSubvariantSelected(variantId, subVariantId)) {
                this.props.onDeselectProduct(this.props.product.id, subVarId);
              } else {
                this.props.onSelectProduct(this.props.product.id, subVarId);
              }
            }}
            onEditVariantSelection={this.toggleExpanded}
            onEditVariantSelectionDone={this.toggleExpanded}
        />
      </UniqueValueTranslatorProvider>
      <ErrorBoundary>
        {this.state.detailedProductViewIsVisible && <DetailedProductModal toggle={this.toggleDetailedProductView}
                                                                          environment={this.props.environment}
                                                                          isOpen={this.state.detailedProductViewIsVisible}
                                                                          productId={this.props.product.id}/>}
      </ErrorBoundary>
    </>
  }
}

FashionProductItem.propTypes = {
  product: propTypes.object,
  refetch: propTypes.func,
  onSelectProduct: propTypes.func,
  onDeselectProduct: propTypes.func,
  // we send the whole selectedProducts array to internally check
  // if the current rendered product is selected because of Relay's fragment content incapsulation
  // Since this component declared that it needs the specific set of fields on ProductNode,
  // from outside, it is visible that this component gets a fragment over ProductNode, but
  // not actually the content of the fragment.
  selectedProducts: propTypes.array,
  selectedUnifiedProducts: propTypes.array,
  allSelected: propTypes.bool,
  allSelectedIgnoredProducts: propTypes.array,
  allSelectedIgnoredUnifiedProducts: propTypes.array,
};

FashionProductItem.defaultProps = {};


const mapStateToProps = state => {
  return {
    allSelected: state.products.allSelected,
    selectedProducts: state.products.selectedProducts,
    selectedUnifiedProducts: state.products.selectedUnifiedProducts,
    allSelectedIgnoredProducts: state.products.allSelectedIgnoredProducts,
    allSelectedIgnoredUnifiedProducts: state.products.allSelectedIgnoredUnifiedProducts
  }
};


const mapDispatchToProps = dispatch => {
  return {
    onSelectProduct: (prodId, unifiedProdIds) => {
      dispatch(selectProduct(prodId, unifiedProdIds))
    },
    onDeselectProduct: (prodId, unifiedProdIds) => {
      dispatch(deselectProduct(prodId, unifiedProdIds))
    },
  }
};

export default createFragmentContainer(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(FashionProductItem)),
    {
      product: graphql`
          fragment FashionProductItem_product on DisplayProductNode {
              id
              name
              collectionName
              brandName
              styleNumber
              unifiedImageGroups {
                  edges {
                      node {
                          id
                          images {
                              edges {
                                  node {
                                      url
                                  }
                              }
                          }
                      }
                  }
              }
              unifiedproductSet {
                  totalCount
                  edges {
                      node {
                          id
                          colorName
                          colorCode
                          size
                          imageGroups {
                              edges {
                                  node {
                                      id
                                  }
                              }
                          }
                      }
                  }
              }
              attachedUniqueValues {
                  jsonSchemaValue
                  value
              }
          }
      `
    }
)

