import React, {useContext, useState, useReducer, useEffect} from "react";
import {Modal, Row, Col, Card, CardBody} from "reactstrap";
import UserInfoForm from "./components/UserInfoForm";
import ChangePasswordForm from "./components/ChangePasswordForm";
import RetailerForm from "./components/RetailerForm";
import UserContext from "../../../context/UserContext";
import UpdateUserMutation from "../../../mutations/UpdateUserMutation";
import PrimaryActionButton from "../../../ui-kit/src/general/PrimaryActionButton";
import ChangePasswordMutation from "../../../mutations/ChangePasswordMutation";
import styles from "./SettingsModal.module.scss"
import UpdateRetailerMutation from "../../../mutations/UpdateRetailer";
import SecondaryActionButton from "../../../ui-kit/src/general/SecondaryActionButton";
import {isEmailFormat} from "../../../ui-kit/src/stringUtils";

const mutationFlags = {user: false, retailer: false, password: false}

export function _errorMessagesReducer(errorMessages, action) {
  if (action.type === "SET_USER_ERROR") {
    return [...errorMessages, {userErrors: action.errors}]
  }
  if (action.type === "SET_RETAILER_ERROR") {
    return [...errorMessages, {retailerErrors: action.errors}]
  }
  if (action.type === "SET_PASSWORD_ERROR") {
    return [...errorMessages, {passwordErrors: action.errors}]
  }
  if (action.type === "RESET_ERROR_MESSAGES") {
    return []
  }
}

export function _stateChangeReducer(mutationFlags, action) {
  if (action.type === "ACTIVATE") {
    return {...mutationFlags, [action.mutationFlagName]: true}
  }
  if (action.type === "DEACTIVATE") {
    return {...mutationFlags, [action.mutationFlagName]: false}
  }
}

function isAnyoneLoading(state) {
  return (state.user && state.retailer && state.password)
}


function SettingsModal({isOpen, toggle, isAdmin = true, ...props}) {
  const user = useContext(UserContext);
  const [state, dispatchState] = useReducer(_stateChangeReducer, mutationFlags)
  const [messages, dispatchMessages] = useReducer(_errorMessagesReducer, [])
  const [lastName, setLastName] = useState(user.lastName)
  const [firstName, setFirstName] = useState(user.firstName)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [retailerName, setRetailerName] = useState(user.retailer.name)
  const [logo, setLogo] = useState(user.retailer.logo)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (!isFirstRender && messages.length === 0) {
      toggle()
    }
  }, [messages])

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    }
  },)

  const checkPermission = (permission) => {  //Placed here since this will probably use user.context
    return permission === "CAN_EDIT_RETAILER"
  }

  const isAnyFieldTypedInto = () => {
    return (
      firstName !== user.firstName || lastName !== user.lastName || email !== user.email ||
      retailerName !== user.retailer.name || logo !== user.retailer.logo ||
      password.length > 0)
  }

  const activate = (mutationFlagName) => {
    dispatchState({type: "ACTIVATE", mutationFlagName: mutationFlagName});
  }

  const deactivate = (mutationFlagName) => {
    dispatchState({type: "DEACTIVATE", mutationFlagName: mutationFlagName});
  }

  const setErrorMessages = (type, errors) => {
    dispatchMessages({type: type, errors: errors});
  }

  const masterSubmitUpdate = () => {
    dispatchMessages({type: "RESET_ERROR_MESSAGES"});
    if (lastName !== user.lastName || firstName !== user.firstName || email !== user.email) {
      submitUserUpdate();
    }
    if (password) {
      submitPasswordChange();
    }
    if (retailerName !== user.retailer.name || logo !== user.retailer.logo) {
      submitRetailerUpdate();
    }
  }

  const submitUserUpdate = () => {
    if (!isEmailFormat(email)) {
      setErrorMessages("SET_USER_ERROR", 'This is not a valid email format')
      return;
    }
    activate("user")
    UpdateUserMutation(
      firstName,
      lastName,
      email,
      "",
      resp => {
        deactivate("user");
      },
      error => {
        setErrorMessages("SET_USER_ERROR", error[0].message)
        deactivate("user");
      }
    )
  };

  const submitPasswordChange = () => {
    if (confirmPassword !== password) {
      setErrorMessages("SET_PASSWORD_ERROR", 'Confirming the Password is required')
      return;
    }
    if (password.length < 6) {
      setErrorMessages("SET_PASSWORD_ERROR", 'Password must contain at least 6 characters')
      return;
    }
    activate("password")
    ChangePasswordMutation(
      password,
      confirmPassword,
      resp => {
        setPassword("");
        setConfirmPassword("");
        deactivate("password")
      },
      err => {
        deactivate("password")
        setErrorMessages("SET_PASSWORD_ERROR", err[0].message)
      }
    )
  };

  const submitRetailerUpdate = () => {
    activate("retailer")
    UpdateRetailerMutation(
      user.retailer.id,
      retailerName,
      (logo !== user.retailer.logo) && logo,
      resp => {
        deactivate("retailer")
        setLogo(resp.updateRetailer.retailer.logo);
      },
      err => {
        deactivate("retailer")
        setErrorMessages("SET_RETAILER_ERROR", err[0].message)
      }
    )
  };
  return <Modal style={{minWidth: '750px'}} backdrop={'static'} isOpen={isOpen} toggle={toggle}>
    <Card className={'pb-2 mb-1'}>
      <h4 className={"font-weight-bold m-3 p-1 pb-2"}>Account Settings</h4>
      <hr className={styles.extendedHr + " mt-0 mb-2"}/>
      <CardBody className={'pb-0'}>
        <Col>
          <Row>
            <Col md={5} className={'pr-0'}>
              <UserInfoForm
                firstNameValue={firstName}
                retailerName={!isAdmin ? retailerName : null}
                onFirstNameUpdate={(value) => {
                  setFirstName(value)
                }}
                lastNameValue={lastName}
                onLastNameUpdate={(value) => {
                  setLastName(value)
                }}
                emailValue={email}
                onEmailUpdate={(value) => {
                  setEmail(value)
                }}
                errors={messages.filter(message => {
                  return message.userErrors
                })}
              />
            </Col>
            {isAdmin && <Col md={{offset: 2, size: 5}} className={'pl-0'}>
              <RetailerForm
                hasPermission={checkPermission('CAN_EDIT_RETAILER')}
                retailerNameValue={retailerName}
                onRetailerNameUpdate={(value) => {
                  setRetailerName(value)
                }}
                logoContent={logo}
                onRetailerLogoUpdate={(value) => {
                  setLogo(value)
                }}
                errors={messages.filter(message => {
                  return message.retailerErrors
                })}
              />
            </Col>}
          </Row>
        </Col>
        <hr/>
        <Col className={'pt-3'}>
          <Row>
            <Col md={6}>
              <ChangePasswordForm
                passwordValue={password}
                onPasswordUpdate={(value) => {
                  setPassword(value)
                }}
                confirmPasswordValue={confirmPassword}
                onConfirmPasswordUpdate={(value) => {
                  setConfirmPassword(value)
                }}
                errors={messages.filter(message => {
                  return message.passwordErrors
                })}
              />
            </Col>
          </Row>
        </Col>
      </CardBody>
      <hr className={styles.extendedHr + " mb-4"}/>
      <Row className={'d-flex justify-content-end'}>
        <div className={'pr-3'}>
          <SecondaryActionButton
            loading={isAnyoneLoading(state)}
            onClick={() => {
              dispatchMessages({type: "RESET_ERROR_MESSAGES"});
              toggle()
            }}>
            Cancel
          </SecondaryActionButton>
        </div>
        <div className={'pr-4'}>
          <PrimaryActionButton
            id="submit-button"
            disabled={!isAnyFieldTypedInto()}
            loading={isAnyoneLoading(state)}
            onClick={() => {
              masterSubmitUpdate()
            }}>
            Save Changes
          </PrimaryActionButton>
        </div>
      </Row>
    </Card>
  </Modal>
}

export default SettingsModal