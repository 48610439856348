/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnableSendEmailWithOutputOnCompletionInput = {
    ddtOutputProcessId: string;
    clientMutationId?: string | null | undefined;
};
export type EnableSendEmailWithOutputOnCompletionMutationVariables = {
    input: EnableSendEmailWithOutputOnCompletionInput;
};
export type EnableSendEmailWithOutputOnCompletionMutationResponse = {
    readonly enableSendEmailWithOutputOnCompletion: {
        readonly success: boolean | null;
    } | null;
};
export type EnableSendEmailWithOutputOnCompletionMutation = {
    readonly response: EnableSendEmailWithOutputOnCompletionMutationResponse;
    readonly variables: EnableSendEmailWithOutputOnCompletionMutationVariables;
};



/*
mutation EnableSendEmailWithOutputOnCompletionMutation(
  $input: EnableSendEmailWithOutputOnCompletionInput!
) {
  enableSendEmailWithOutputOnCompletion(input: $input) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnableSendEmailWithOutputOnCompletionPayload",
    "kind": "LinkedField",
    "name": "enableSendEmailWithOutputOnCompletion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnableSendEmailWithOutputOnCompletionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnableSendEmailWithOutputOnCompletionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5bd5433fe798df8cc70a9646115906a7",
    "id": null,
    "metadata": {},
    "name": "EnableSendEmailWithOutputOnCompletionMutation",
    "operationKind": "mutation",
    "text": "mutation EnableSendEmailWithOutputOnCompletionMutation(\n  $input: EnableSendEmailWithOutputOnCompletionInput!\n) {\n  enableSendEmailWithOutputOnCompletion(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '9f5c118f00b1d8e98291490ba8c1f84a';
export default node;
