import React from "react";
import styles from "./DataPointsHeaderProps.module.scss";
import {Row, UncontrolledTooltip} from "reactstrap";
import {noop} from "../../../../commons/misc";

type ColumnsHeaderProps = {
  onBack: () => void,
  invalidityMessage: string | null,
}

export default function DataPointsHeader({onBack, invalidityMessage}: ColumnsHeaderProps) {
  return <Row className={styles.header + " " + (invalidityMessage && styles.saveDisabled)}>
    <i className={"fas fa-chevron-left " + styles.chevron} onClick={onBack}/>
    <span id={'go-back-area'}
          className={styles.text}
          style={!invalidityMessage ? {cursor: 'pointer'} : {}}
          onClick={invalidityMessage ? noop : onBack}>

      {invalidityMessage ? "Saving disabled" : "Back to customizing values"}
      </span>
    {invalidityMessage && <UncontrolledTooltip target={'go-back-area'} placement={"bottom"}>
      {invalidityMessage}
    </UncontrolledTooltip>}
  </Row>
}
