import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import Button from '../../../../../atoms/Button';
import styles from './SheetSelectionModalContent.module.scss';
import {ProductTemplateInfoType, SheetType} from "./types";
import {useSheetSelection} from "./SheetSelectionContext";
import {DDTMappingConfigFormContextProvider} from '../../../DDTMappingConfigV2Form/DDTMappingConfigFormContext';
import {Environment} from "react-relay";
import InfoRow from "./InfoRow";
import ActiveSheetsPills from "./ActiveSheetsPills";
import SheetControllerRows from "./SheetControllerRows";


type SheetSelectionModalContentProps = {
  outputName: string,
  sheets: SheetType[],
  onClose: () => void,
  onSave: (sheets: SheetType[]) => void,
  productTemplates: ProductTemplateInfoType[],
  environment: Environment,
  selectedSheetName: string | null
  setSelectedSheetName: (name: string | null) => void
}


export default function SheetSelectionModalContent({
                                                     outputName,
                                                     onClose,
                                                     sheets,
                                                     productTemplates,
                                                     environment,
                                                     selectedSheetName,
                                                     setSelectedSheetName,
                                                     onSave,
                                                   }: SheetSelectionModalContentProps) {
  const sheetSelection = useSheetSelection();

  useEffect(() => {
    if (sheets.length === 0 || sheetSelection.sheets.length === 0) {
      sheetSelection.setSheets(sheets);
    }
  }, [sheets])

  useEffect(() => {
    if (productTemplates.length > 0) {
      sheetSelection.setTemplates(productTemplates);
    }
  }, [productTemplates])

  return <DDTMappingConfigFormContextProvider environment={environment} allMeasurements={[]} usedUniqueValues={[]}>

    <Row className={styles.container}>
      <Col className={'px-0'}>
        <p className={`mb-0 ${styles.outputName}`}>Output: {outputName}</p>
        <h1 className={styles.title}>Data requirements</h1>
        <Row className={'mx-0'}>
          <ActiveSheetsPills selectedSheetName={selectedSheetName}
                             setSelectedSheetName={setSelectedSheetName}/>
        </Row>
      </Col>
      <Col md={3} className={'d-flex justify-content-end align-items-center'}
           style={{
             marginRight: '-1rem',
             height: '7rem'  // to align exactly with the buttons rendered from the mapping config modal
           }}>
        <Button
          onClick={() => {
            onClose();
            sheetSelection.reset()
          }}
          style={{height: '2rem'}}>
          Cancel
        </Button>
        <Button className={'ml-3'}
                color={'primary'}
                style={{height: '2rem'}}
                onClick={() => {
                  onSave(sheetSelection.sheets)
                }}>
          Save
        </Button>
      </Col>
    </Row>

    <InfoRow/>

    <SheetControllerRows selectedSheetName={selectedSheetName} setSelectedSheetName={setSelectedSheetName}/>

  </DDTMappingConfigFormContextProvider>
}
