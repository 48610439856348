import {multiPartHttpRequest} from "../commons/http";


function addKindIfNecessary(files, data) {
  let isAutoGenerated = false;
  for (let f of Object.values(files)) {
    if (f.name.startsWith('fakefile_')) {
      isAutoGenerated = true
    }
  }
  if (isAutoGenerated) {
    data = {...data, kind: 'RawBarcodes'};
  }
  return data;
}

export function uploadOrderConfirmationBatch({data, files, onUploadFinished, onError}) {
  data = addKindIfNecessary(files, data);
  multiPartHttpRequest(
    '/orderConfirmations/uploadBatch/',
    data,
    files,
    resp => {
      if (resp.orderConfirmationBatchId) {
        onUploadFinished(resp.orderConfirmationBatchId)
      } else {
        onError('Failed to upload the order confirmations. Please try again later.');
      }
    },
    err => {
      onError(err[0].message);
    }
  )
}


export function bulkAttachOrderConfirmations({data, files, onUploadFinished, onError}) {
  multiPartHttpRequest(
    '/api/v1/order-confirmations/bulk-attach/',
    data,
    files,
    resp => {
      if (resp.trackToken) {
        onUploadFinished(resp.trackToken);
      } else {
        onError("Failed to upload the order confirmations. Please try again later.")
      }
    },
    err => {
      onError(err[0].message);
    }
  )
}


export function processOrderFromFiles({files, onUploadFinished, onError}) {
  multiPartHttpRequest(
    '/api/v1/order-confirmations/process-from-files/',
    {},
    files,
    resp => {
      if (resp.orderConfirmationBatchId) {
        onUploadFinished(resp.orderConfirmationBatchId);
      } else {
        onError('Did not receive the correct info from server');
      }
    },
    err => {
      onError('' + err[0].message);
    }
  )
}
