import React, {useState} from "react";
import {Environment} from "relay-runtime";
import GrayModal from "../../ui-kit/src/dataDisplay/GrayModal";
import {PossibleError} from "../../ui-kit/src/commons/errors";
import {ourToast} from "../../ui-kit/src/atoms/Toast";
import {StoreForm} from "./StoreForm";
import AddRetailerStoreMutation from "../../ui-kit/src/mutations/AddRetailerStoreMutation";


type AddStoreModalProps =
  {
    isOpen: boolean,
    toggle: () => void,
    environment: Environment,
    retailerId: string
  }

export default function AddStoreModal({isOpen, toggle, environment, retailerId}: AddStoreModalProps) {
  let [storeDetails, setStoreDetails] = useState({name: "", zipCode: "", address: "", city: ""})
  let [isLoading, setIsLoading] = useState(false)
  let [error, setError] = useState<PossibleError>("")

  const onAddStore = () => {
    setIsLoading(true)
    AddRetailerStoreMutation(
      environment,
      {retailerId: retailerId, ...storeDetails},
      (data) => {
        setIsLoading(false)
        ourToast("success", "Updated successfully");
        toggle()
        setError("")
      },
      (error) => {
        setIsLoading(false)
        setError(error)
      }
    )
  }

  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{minWidth: '34rem'}}
    secondaryHeaderButton={{text: "Cancel", onClick: toggle}}
    primaryHeaderButton={{text: "Add Location", onClick: onAddStore, isDisabled: isLoading || storeDetails.name.trim().length === 0}}
    title={"Add a location"}
    bodyContent={<StoreForm
      storeDetails={storeDetails}
      isLoading={isLoading}
      error={error}
      setStoreDetails={setStoreDetails}
    />}
  />
}
