import React, {useContext, useEffect, useState} from 'react';
import Pagination from "../general/Pagination";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";


/**
 * Reusable pagination logic, that just works
 *
 * We have three things we need to include in our code to do proper pagination (simulated page based, but with the
 * relay's first/last/before/after.
 *
 * When to use this?
 * - When you have a query that requires pagination (connections that have a totalCount property), we can
 *   use these classes to facilitate going through the pages
 *
 * The structure (quick reference):
 *
 * - <PaginationProvider/> (from this module)
 *    - my component that contains the pagination controls and the paginated content
 *        - <PaginationNav/> (from this module)
 *        - MyPaginatedComponentDisplayWithQuery
 *             // to be used in the query that paginates. They get automatically updated
 *             // when pagination's next and previous are clicked, and causes this component to re-render.
 *            - const {pagination} = usePagination();
 *
 *            - after it finished loading ->
 *                let {setTotalCount, setFirstCursor, setLastCursor} = usePagination() // (from this module)
 *                useEffect() =>
 *                    setTotalCount(conn.totalCount)
 *                    setFirstCursor(conn.pageInfo.startCursor)
 *                    setLastCursor(conn.pageInfo.endCursor)
 *                renderConnAsUsual(conn)
 *
 * <PaginationProvider perPage={10} /> -> wrap all the code that needs pagination
 * usePagination - full access to the information about the current pagination:
 *  - pagination - {first, last, before, after} => what needs to be sent to the graphql server
 *  - totalCount / setTotalCount - the total count of the items we are paginating. Defaults to null, and while it
 *      stays null, the PaginationNav is rendered in a loading state.
 *  - firstCursor / setFirstCursor - the pagination cursor of the first rendered component
 *  - lastCursor / setLastCursor - the pagination cursor of the last rendered component
 *  - goNextPage - triggers an update of pagination to point to the next page
 *  - goPreviousPage - triggers an update of the pagination to point to the previous page
 *  - perPage - read-only value of how many items are displayed per page.
 * <PaginationNav/> - renders a pagination navigation that does all the work itself (manages its state and
 *    interacts with the context).
 */

export const DEFAULT_PER_PAGE = 10;


const PaginationStateContext = React.createContext({pagination: {}, totalCount: null});

const getInitialPagination = (perPage, isPersistentPagination) => {
  let basePagination = {first: perPage, after: null, last: null, before: null}
  let cursorId = new URLSearchParams(window.location.search).get("cursorId")
  let direction = new URLSearchParams(window.location.search).get("direction")
  if (cursorId && direction && isPersistentPagination) {
    basePagination[direction] = cursorId
    if (direction === "before") {
      basePagination["first"] = null
      basePagination["last"] = perPage
    } else {
      basePagination["first"] = perPage
      basePagination["last"] = null
    }
  }

  return basePagination
}

const getInitialExtraParams = (isPersistentPagination) => {
  if(!isPersistentPagination) {
    return {}
  }

  const extraParams = {};
  new URLSearchParams(window.location.search).forEach((value, key) => {
    if(key !== "cursorId" && key !== "direction") {
      extraParams[key] = value;
    }
  })

  return extraParams;
}

export function PaginationProvider({perPage = DEFAULT_PER_PAGE, children, isPersistentPagination = false, initialExtraState = {}}) {
  const [pagination, setPagination] = useState(getInitialPagination(perPage, isPersistentPagination));
  const [totalCount, setTotalCount] = useState(null);
  const [firstCursor, setFirstCursor] = useState(null);
  const [lastCursor, setLastCursor] = useState(null);
  const [extraState, setExtraState] = useState({...getInitialExtraParams(isPersistentPagination), ...initialExtraState});

  const history = useHistory()
  const location = useLocation();

  useEffect(() => {
      setPagination(getInitialPagination(perPage, isPersistentPagination));
      setExtraState(getInitialExtraParams(isPersistentPagination));
  }, [location, perPage]);

  const syncUrl = ({direction, cursor, ...rest}) => {
    const params = new URLSearchParams(location.search)
    direction ? params.set("direction", direction) : params.delete("direction")
    cursor ? params.set("cursorId", cursor) : params.delete("cursorId")

    const mergedParams = {...extraState, ...rest};
    Object.keys(mergedParams).forEach(key => {
      mergedParams[key] ? params.set(key, mergedParams[key]) : params.delete(key);
    })
    history.push(window.location.pathname + (params.toString() && "?" + params.toString()))
  }

  return <PaginationStateContext.Provider value={{
    pagination,
    totalCount,
    firstCursor,
    lastCursor,
    getExtraStateKey: key => {
      return extraState[key] || null;
    },
    setTotalCount,
    setFirstCursor,
    setLastCursor,
    setExtraStateKey: (key, value) => {
      setExtraState(prev => {
        const newExtraState = {...prev, [key]: value}
        if (isPersistentPagination) {
          syncUrl(newExtraState);
        }

        setPagination({first: perPage, after: null, last: null, before: null});
        return newExtraState;
      });
    },
    updatePageInfoFromConnection: conn => {
      setTotalCount(conn.totalCount);
      setFirstCursor(conn.pageInfo.startCursor);
      setLastCursor(conn.pageInfo.endCursor)
    },
    resetPagination: () => setPagination({first: perPage, after: null, last: null, before: null}),
    goNextPage: () => {
      if (isPersistentPagination) {
        syncUrl({direction: "after", cursor: lastCursor});
      }

      setPagination({first: perPage, after: lastCursor, last: null, before: null})
    },
    goPreviousPage: () => {
      if (isPersistentPagination) {
        syncUrl({direction: "before", cursor: firstCursor});
      }

      setPagination({first: null, after: null, last: perPage, before: firstCursor})
    },
    perPage
  }}>
    {children}
  </PaginationStateContext.Provider>
}


export function usePagination() {
  return useContext(PaginationStateContext);
}

function getArrayConnectionIndex(arrayConnectionAsBase64) {
  return atob(arrayConnectionAsBase64).split(':').pop();
}

function getPage(cursor, perPage) {
  return Math.ceil(getArrayConnectionIndex(cursor) / perPage)
}

export function PaginationNav({className = null, buttonClassName = null, Tag = Pagination}) {
  const {totalCount, firstCursor, lastCursor, perPage, goNextPage, goPreviousPage} = usePagination();
  if (!totalCount) {
    return <Tag isLoading className={className} buttonClassName={buttonClassName}/>
  }

  const rangeStart = getPage(firstCursor, perPage);
  const rangeEnd = getPage(lastCursor, perPage);

  return <Tag
    className={className}
    buttonClassName={buttonClassName}
    total={Math.ceil(totalCount / perPage)}
    rangeStart={rangeStart}
    rangeEnd={rangeEnd}
    onChangePage={({direction}) => {
      if (direction === 'prev') {
        goPreviousPage();
      } else if (direction === 'next') {
        goNextPage();
      }
    }}
  />
}
