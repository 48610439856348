import React from "react";
import styles from './OrderViewLayout.module.scss'
import addClassNames from "../../../classNameUtils";
import {redirectToProducts} from "../../../molecules/Orders/OrderRow";
import Button from "../../../atoms/Button";
import * as H from "history";

type HeaderType = {
  onClick: () => void,
  children: JSX.Element | JSX.Element[] | string,
  icon?: string,
  dataTestId?: string
}

export function Header({onClick, children, icon = 'fa-regular fa-chevron-left', dataTestId}: HeaderType) {
  return <div className={styles.headerStripe}>
    <div onClick={onClick} className={styles.text} data-testid={dataTestId}>
      <i className={`${icon} ${styles.headerStripeIcon} pl-2 pr-1 ml-3`}/>
      <span>{children}</span>
    </div>
  </div>;
}

type StatusPillType = {
  title: string,
  icon: string,
  color?: "primary" | "warning" | "muted"
}

export function StatusPill ({title, icon, color = "primary"}: StatusPillType) {
  return <div className={addClassNames([
    {className: styles.pillContainerPrimary, condition: color === "primary"},
    {className: styles.pillContainerMuted, condition: color === "muted"},
    {className: styles.pillContainerWarning, condition: color === "warning"}
  ])}>
    <i className={`${icon} ${styles.pillIcon}`} />
    <p className={styles.pillText}>{title}</p>
  </div>
}

type MissingDataStatusPillType = {
  missingDataPercentage: number | null | undefined
}

export function MissingDataStatusPill({missingDataPercentage}: MissingDataStatusPillType) {
  if (missingDataPercentage === null || missingDataPercentage === undefined) {
    return <StatusPill
      title={"Status not available"}
      icon={"fa-solid fa-circle-question"}
      color={"muted"}/>
  }

  const hasMissingData = missingDataPercentage !== 100
  return <StatusPill
    title={!hasMissingData ? "All data available" : "Missing data"}
    icon={`fa-solid ${!hasMissingData ? "fa-circle-check" : "fa-circle-exclamation"}`}
    color={!hasMissingData ? "primary" : "warning"}/>
}

type AddMissingDataButtonProps = {
  orderId: string,
  retailerName: string,
  orderNumbers: string[],
  history: H.History
}

export function AddMissingDataButton({orderId, retailerName, orderNumbers, history}: AddMissingDataButtonProps) {
  return <Button
    color={"primary"}
    className={styles.addMissingDataButton}
    onClick={() => redirectToProducts(orderId, retailerName, orderNumbers, history)}
  >
    <i className="fa-regular fa-plus mr-2"/>
    Add missing data
  </Button>
}

type TabProps = {
  children: string | JSX.Element | JSX.Element[],
  className?: string,
  id?: string,
  isClickable?: boolean
}

export function Tab({children, className, id, isClickable = false}: TabProps) {
  return <div className={addClassNames([
    {className: styles.tabContainer, condition: true},
    {className: styles.tabContainerClickable, condition: isClickable},
    {className: className, condition: !!className},
  ])} id={id}>
    <p className={styles.tabText}>{children}</p>
  </div>
}

type StatBadgeType = {
  children: JSX.Element | JSX.Element[],
  id: string,
  isClickable?: boolean
}

export function StatBadge({children, id, isClickable = false}: StatBadgeType) {
  return <div className={styles.statBadge + ' ' + (isClickable ? styles.isClickable : '')}
              id={id}>
    <p className={"mx-2 mb-0"}>{children}</p>
  </div>
}

type FilterSlotType = {
  children: JSX.Element | JSX.Element[],
  first?: boolean,
  right?: boolean
}

export function FilterSlot({children, first = false, right = false}: FilterSlotType) {
  return <div className={`${first ? '' : 'ml-4'} ${right ? 'ml-auto' : ''}`}>
    {children}
  </div>
}
