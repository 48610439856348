import React from "react";
import {Col, Container, Row} from "reactstrap";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import styles from "../../../Rule.module.scss";
import Button from "../../../../../../../atoms/Button";
import Input from "../../../../../../../atoms/Input"
import {CustomizeValueBrandRuleProps} from "../../../../types";

type CustomizeValueRowProps = {
  value: {
    from: string,
    to: string
  },
  onRemove: () => void,
  onChange: (newValue: { from: string, to: string }) => void
  onAdd: () => void
}

export function CustomizeValueRow({value, onChange, onRemove, onAdd}: CustomizeValueRowProps) {
  return <Row className={"my-3 align-items-center"}>
    <span className="mr-2">
        # Instead of
    </span>
    <Input value={value.from}
           placeholder={'Characters to replace'}
           style={{width: 'unset'}}
           onChange={e => {
             onChange({from: e.target.value, to: value.to})
           }}/>
    <span className={'mx-2'}>
      display
    </span>
    <Input value={value.to}
           placeholder={'Desired characters'}
           style={{width: 'unset'}}
           onChange={e => {
             onChange({from: value.from, to: e.target.value,})
           }}/>
    <Button className={"ml-3 justify-content-center"} onClick={onAdd} data-testid={"add-row-button"} style={{height: "2rem", width: "2rem"}}>
      <i className="fas fa-plus mr-0"/>
    </Button>

    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </Row>
}

export default function CustomizeValueBrandRule({
                                                  values,
                                                  onChange,
                                                  onRemove
                                                }: CustomizeValueBrandRuleProps) {
  return <Col className={'d-flex align-items-center'}>
    <Container>
      <Row>
        <i className={`fas fa-exchange-alt ${styles.brandRuleIcon}`}/>
        <span>Customize values</span>
        <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
      </Row>
      {values.map((value, i) => {
        return <CustomizeValueRow
          onAdd={() => onChange({values: [...values, {from: "", to: ""}]})}
          value={value}
          onChange={(newValues) => {
            let tempValues = [...values];
            tempValues[i] = newValues;
            onChange({values: tempValues});
          }}
          key={i}
          onRemove={() => {
            if (values.length === 1) {
              onRemove()
            } else {
              let newValues = [...values];
              newValues.splice(i, 1)
              onChange({values: newValues})
            }
          }}/>
      })}
    </Container>
  </Col>
}
