import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";


const mutation = graphql`
  mutation RemoveAuthorizationForWoocommerceShopMutation($input: RemoveAuthorizationForWooCommerceShopInput!) {
    removeAuthorizationForWoocommerceShop(input: $input) {
      woocommerceShop {
        distributeDataTemplate {
          name
          engine
        }
        authorizationCode
        rootUrl
        id
        consumerKey
        consumerSecret
      }
    }
  }
`;

function RemoveAuthorizationForWoocommerceShopMutation(
  environment,
  data,
  onSuccess,
  onError
) {
  createMutationBoilerplate(mutation)(environment, data, onSuccess, onError)

}

export default RemoveAuthorizationForWoocommerceShopMutation;
