/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateRetailerUserMutationInput = {
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    phoneNumber: string;
    email: string;
    password: string;
    repeatPassword: string;
    name: string;
    company: CompanyDataInput;
    termsChecked: string;
    token?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CompanyDataInput = {
    vat: string;
    address: string;
    city: string;
    postNumber: string;
};
export type CreateRetailerUserMutationVariables = {
    input: CreateRetailerUserMutationInput;
};
export type CreateRetailerUserMutationResponse = {
    readonly createRetailerUser: {
        readonly user: {
            readonly id: string;
            readonly email: string;
            readonly brandSet: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly name: string;
                    } | null;
                } | null>;
            } | null;
        } | null;
    } | null;
};
export type CreateRetailerUserMutation = {
    readonly response: CreateRetailerUserMutationResponse;
    readonly variables: CreateRetailerUserMutationVariables;
};



/*
mutation CreateRetailerUserMutation(
  $input: CreateRetailerUserMutationInput!
) {
  createRetailerUser(input: $input) {
    user {
      id
      email
      brandSet {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRetailerUserMutationPayload",
    "kind": "LinkedField",
    "name": "createRetailerUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandNodeConnection",
            "kind": "LinkedField",
            "name": "brandSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRetailerUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateRetailerUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "03d97b15de36dad43a082602754e582c",
    "id": null,
    "metadata": {},
    "name": "CreateRetailerUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRetailerUserMutation(\n  $input: CreateRetailerUserMutationInput!\n) {\n  createRetailerUser(input: $input) {\n    user {\n      id\n      email\n      brandSet {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8f6fa4ccd0c9e6c5bf4d6460a2aa668f';
export default node;
