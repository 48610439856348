/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderTableQueryVariables = {
    search?: string | null | undefined;
    first?: number | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    after?: string | null | undefined;
};
export type OrderTableQueryResponse = {
    readonly listOrdersAtOnce: {
        readonly totalCount: number | null;
        readonly pageInfo: {
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly __isNode: string;
                readonly orderNumber?: string | undefined;
                readonly orderNumbers?: ReadonlyArray<string> | undefined;
                readonly " $fragmentRefs": FragmentRefs<"OrderRow_order">;
            } | null;
        } | null>;
        readonly " $fragmentRefs": FragmentRefs<"OrdersPagination_pagination">;
    } | null;
};
export type OrderTableQuery = {
    readonly response: OrderTableQueryResponse;
    readonly variables: OrderTableQueryVariables;
};



/*
query OrderTableQuery(
  $search: String
  $first: Int
  $last: Int
  $before: String
  $after: String
) {
  listOrdersAtOnce(first: $first, after: $after, last: $last, before: $before, search: $search) {
    ...OrdersPagination_pagination
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges {
      node {
        __typename
        id
        ... on OrderNode {
          orderNumber
        }
        ... on ManuallyUploadedOrderNode {
          orderNumbers
        }
        __isNode: __typename
        ...OrderRow_order
      }
    }
  }
}

fragment OrderRow_order on Node {
  __isNode: __typename
  __typename
  ... on OrderNode {
    id
    orderNumber
    dateCreated
    customerReference
    retailerStore {
      name
      id
    }
    orderConfirmationMissingProducts {
      totalCount
    }
    orderConfirmationExtraProducts {
      totalCount
    }
    orderConfirmationBatch {
      id
      errorCount
    }
    fromBrand {
      id
      name
    }
    toRetailer {
      id
      name
    }
    lastDownload {
      type
      endDate
    }
    missingData {
      percent
    }
    deliveryPeriod {
      startDate
    }
    receivedData {
      type
    }
    brandComment
    retailerComment
  }
  ... on ManuallyUploadedOrderNode {
    id
    orderNumbers
    uploaded
    batch {
      error
      errors {
        reason
        product
        variant
      }
      uploads {
        edges {
          node {
            name
            id
          }
        }
      }
      id
    }
    fromBrand {
      id
      name
    }
    toRetailer {
      id
      name
    }
    missingData {
      percent
    }
  }
}

fragment OrdersPagination_pagination on NodeConnection {
  totalCount
  pageInfo {
    startCursor
    endCursor
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": "__isNode",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumbers",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v12/*: any*/),
  (v8/*: any*/)
],
v14 = [
  (v6/*: any*/)
],
v15 = [
  (v8/*: any*/),
  (v12/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandNode",
  "kind": "LinkedField",
  "name": "fromBrand",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerNode",
  "kind": "LinkedField",
  "name": "toRetailer",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingDataType",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "NodeConnection",
        "kind": "LinkedField",
        "name": "listOrdersAtOnce",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "type": "OrderNode",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "type": "ManuallyUploadedOrderNode",
                    "abstractKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OrderRow_order"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrdersPagination_pagination"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "NodeConnection",
        "kind": "LinkedField",
        "name": "listOrdersAtOnce",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateCreated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerReference",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RetailerStoreNode",
                        "kind": "LinkedField",
                        "name": "retailerStore",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DisplayUnifiedProductNodeConnection",
                        "kind": "LinkedField",
                        "name": "orderConfirmationMissingProducts",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DisplayUnifiedProductNodeConnection",
                        "kind": "LinkedField",
                        "name": "orderConfirmationExtraProducts",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderConfirmationBatchNode",
                        "kind": "LinkedField",
                        "name": "orderConfirmationBatch",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "errorCount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LastDownloadType",
                        "kind": "LinkedField",
                        "name": "lastDownload",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeliveryPeriodType",
                        "kind": "LinkedField",
                        "name": "deliveryPeriod",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReceivedDataType",
                        "kind": "LinkedField",
                        "name": "receivedData",
                        "plural": true,
                        "selections": [
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "brandComment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "retailerComment",
                        "storageKey": null
                      }
                    ],
                    "type": "OrderNode",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uploaded",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderConfirmationBatchNode",
                        "kind": "LinkedField",
                        "name": "batch",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "error",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderConfirmationError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reason",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "product",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "variant",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderConfirmationUploadNodeConnection",
                            "kind": "LinkedField",
                            "name": "uploads",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderConfirmationUploadNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderConfirmationUploadNode",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v13/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v19/*: any*/)
                    ],
                    "type": "ManuallyUploadedOrderNode",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e67672543be3f60f4ec0c0ee481ef0d",
    "id": null,
    "metadata": {},
    "name": "OrderTableQuery",
    "operationKind": "query",
    "text": "query OrderTableQuery(\n  $search: String\n  $first: Int\n  $last: Int\n  $before: String\n  $after: String\n) {\n  listOrdersAtOnce(first: $first, after: $after, last: $last, before: $before, search: $search) {\n    ...OrdersPagination_pagination\n    totalCount\n    pageInfo {\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        __typename\n        id\n        ... on OrderNode {\n          orderNumber\n        }\n        ... on ManuallyUploadedOrderNode {\n          orderNumbers\n        }\n        __isNode: __typename\n        ...OrderRow_order\n      }\n    }\n  }\n}\n\nfragment OrderRow_order on Node {\n  __isNode: __typename\n  __typename\n  ... on OrderNode {\n    id\n    orderNumber\n    dateCreated\n    customerReference\n    retailerStore {\n      name\n      id\n    }\n    orderConfirmationMissingProducts {\n      totalCount\n    }\n    orderConfirmationExtraProducts {\n      totalCount\n    }\n    orderConfirmationBatch {\n      id\n      errorCount\n    }\n    fromBrand {\n      id\n      name\n    }\n    toRetailer {\n      id\n      name\n    }\n    lastDownload {\n      type\n      endDate\n    }\n    missingData {\n      percent\n    }\n    deliveryPeriod {\n      startDate\n    }\n    receivedData {\n      type\n    }\n    brandComment\n    retailerComment\n  }\n  ... on ManuallyUploadedOrderNode {\n    id\n    orderNumbers\n    uploaded\n    batch {\n      error\n      errors {\n        reason\n        product\n        variant\n      }\n      uploads {\n        edges {\n          node {\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n    fromBrand {\n      id\n      name\n    }\n    toRetailer {\n      id\n      name\n    }\n    missingData {\n      percent\n    }\n  }\n}\n\nfragment OrdersPagination_pagination on NodeConnection {\n  totalCount\n  pageInfo {\n    startCursor\n    endCursor\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a9cb6fa64e62df8b5925a2d42d76d8a3';
export default node;
