import React from 'react';
import styles from './Pill.module.scss';
import addClassNames from '../classNameUtils';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  icon: string;
  disabled?: boolean;
}

export default function IconPill(props: Props): JSX.Element {
  const {icon, disabled, className, ...rest} = props;

  const classNames = [
    {className: styles.pill, condition: true},
    {className: styles.iconPill, condition: true},
    {className: styles.disabled, condition: disabled},
    {className: className, condition: !!className},
  ];
  return (
    <button className={addClassNames(classNames)} {...rest}>
      <i className={`fa-light ${icon} ${styles.iconPillIcon}`}/>
    </button>
  );
}
