import React, {useContext} from "react";
import V2DDTConfigModal from "../ddt/single/modals/V2DDTConfigModal";

export default function OutputIntegration({integrationContext}) {
  const integrationDetails = useContext(integrationContext)
  return <div>
    <V2DDTConfigModal ddtId={integrationDetails.id}
                      isRetailer={true}
                      isOpen={true}
                      environment={integrationDetails.environment}
                      disableBackdropToggle
                      toggle={() => window.location.reload()}/>
  </div>
}
