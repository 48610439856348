import React from "react";
import {Input} from "reactstrap";
import styles from '../../src/scss/components/_searchInput.module.scss';

function SearchInput({placeholder, value, onChange, Tag, ...props}) {
  if (!Tag) {
    Tag = Input;
  }
  let wrapperClassNames = [styles.searchInput]
  if (props.className) {
    wrapperClassNames.push(props.className);
  }

  return <div className={wrapperClassNames.join(' ')}>
    <Tag placeholder={placeholder} value={value} onChange={onChange} {...props} className={'form-control'}/>
    <i className={"fas fa-search " + styles.searchIcon + (props.disabled ? ' text-white' : '')}/>
  </div>
}

export default SearchInput;
