import {useSheetSelection} from "./SheetSelectionContext";
import {Col} from "reactstrap";
import Pill, {PillProps} from "../../../../../atoms/Pill";
import Separator from "../../../../../atoms/Separator";
import styles from "./ActiveSheetsPills.module.scss";
import React from "react";
import {getClassNames} from "../../../../../classNameUtils";


function SheetIcon({color}: { color: 'white' | 'black' }) {
  return <svg className={'mr-2'} width="12" height="11" viewBox="0 0 12 11" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.75C0 0.929688 0.65625 0.25 1.5 0.25L10.5 0.25C11.3203 0.25 12 0.929687 12 1.75L12 9.25C12 10.0937 11.3203 10.75 10.5 10.75L1.5 10.75C0.65625 10.75 0 10.0938 0 9.25L0 1.75ZM0.75 1.75L0.75 3.25L11.25 3.25V1.75C11.25 1.35156 10.8984 1 10.5 1L1.5 1C1.07813 1 0.75 1.35156 0.75 1.75ZM0.75 4L0.75 6.625L5.625 6.625V4L0.75 4ZM6.375 4V6.625L11.25 6.625L11.25 4L6.375 4ZM5.625 7.375L0.75 7.375L0.75 9.25C0.75 9.67188 1.07813 10 1.5 10L5.625 10V7.375ZM10.5 10C10.8984 10 11.25 9.67187 11.25 9.25V7.375H6.375V10H10.5Z"
      className={getClassNames([
        {className: styles.whiteSheetIcon, condition: color === 'white'},
        {className: styles.darkSheetIcon, condition: color === 'black'},
      ])}/>
  </svg>

}

function SelectionPill({
                         sheetName,
                         current,
                         children,
                         ...rest
                       }: { sheetName: string | null, current: string | null } & PillProps) {
  const isCurrent = sheetName === current;
  return <Pill className={'mr-2'} color={'ternary'} active={isCurrent} {...rest}>
    {children || sheetName}
  </Pill>
}


type ActiveSheetsPillsProps = {
  selectedSheetName: string | null,
  setSelectedSheetName: (name: string | null) => void
}

export default function ActiveSheetsPills({selectedSheetName, setSelectedSheetName}: ActiveSheetsPillsProps) {
  const {sheets} = useSheetSelection();

  if (sheets.length === 0) {
    return null;
  }

  const activeSheets = sheets.filter(sh => sh.isActive);

  return <Col className={'d-flex align-items-center px-0'}>
    <SelectionPill sheetName={null} current={selectedSheetName} onClick={() => setSelectedSheetName(null)}>
      <SheetIcon color={selectedSheetName === null ? 'white' : 'black'}/> Sheet setup
    </SelectionPill>
    <Separator/>
    {activeSheets.length === 0 && <p className={`text-muted ${styles.hintText}`}>
      Sheets will appear here when enabled
    </p>}
    {activeSheets.length > 0 && <div className={'d-flex'}>
      {activeSheets.map((sh, index) => {
        return <SelectionPill key={sh.mappingConfigId}
                              sheetName={sh.name}
                              current={selectedSheetName}
                              onClick={() => setSelectedSheetName(sh.name)}/>
      })}
    </div>}
  </Col>
}
