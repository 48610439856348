import React from 'react';
import {createFragmentContainer} from "react-relay";
import {graphql} from "graphql";
import {StatBadge} from "../layout/OrderViewLayout";
import {localizedPrice} from "../../../stringUtils";


function BatchTotalInfo({batch}) {
  if (!batch) {
    return null;
  }
  return <>
    <StatBadge>{batch.totalItemQuantity + " items"}</StatBadge>
    <StatBadge>{localizedPrice(batch.totalPrice.currency, batch.totalPrice.amount)}</StatBadge>
  </>
}

export default createFragmentContainer(
  BatchTotalInfo,
  {
    batch: graphql`
      fragment BatchTotalInfo_batch on OrderConfirmationBatchNode {
        id
        totalUnifiedProducts
        totalItemQuantity
        totalPrice {
          amount
          currency
        }
      }
    `
  }
)
