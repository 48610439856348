import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../commons/graphql";
import {Environment} from "relay-runtime";
import {
  GenerateDirectDownloadMutationResponse,
  GenerateDirectDownloadInput,
  GenerateDirectDownloadMutation
} from "./__generated__/GenerateDirectDownloadMutation.graphql";

const mutation = graphql`
  mutation GenerateDirectDownloadMutation($input: GenerateDirectDownloadInput!) {
    generateDirectDownload (input: $input) {
      outputProcess {
        id
        state
      }
    }
  }
`;

type GenerateDirectDownloadType = (
  environment: Environment,
  data: GenerateDirectDownloadInput,
  onSuccess: (val: GenerateDirectDownloadMutationResponse) => void,
  onError: (val: Error | {message: string, path: string[]}[]) => void
) => void;

const GenerateDirectDownload: GenerateDirectDownloadType = (environment, data, onSuccess, onError) => {
  createMutationBoilerplate<GenerateDirectDownloadMutation>(mutation)(environment, data, onSuccess, onError);
}

export default GenerateDirectDownload;
