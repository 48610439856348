import React from "react";
import styles from './OrderViewLoadingState.module.scss'
import {CardBody, CardHeader, Col, Row} from "reactstrap";
import Card from "../../../atoms/Card"

export default function OrderViewLoadingState() {
  return <Card fillSpace border className={"overflow-hidden"}>
    <div className={styles.headerStripePlaceholder}/>
    <CardHeader className={"pb-4"}>
      <Row className={"mx-0"}>
        <div className={"ml-auto mr-1 " + styles.buttonPlaceholder} style={{width: "156px"}}/>
      </Row>
      <Row className={"mx-0"}>
        <Col className={"px-0"}/>
        <Col className={"mt-3"}>
          <Row className={"mt-2"}>
            <div className={"ml-auto p-1 " + styles.buttonPlaceholder} style={{width: "87px"}}/>
            <div className={"ml-3 p-1 " + styles.buttonPlaceholder} style={{width: "136px"}}/>
          </Row>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row className={"mx-0 px-0"}>
        <div className={styles.buttonPlaceholder} style={{minWidth: "296px"}}/>
        <div className={"ml-4 " + styles.buttonPlaceholder} style={{minWidth: "90px"}}/>
        <div className={"ml-4 " + styles.buttonPlaceholder} style={{minWidth: "226px"}}/>
        <div className={"ml-auto"}>
          <div className={'pagination d-flex justify-content-end'}>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.buttonPlaceholder}
                 style={{width: "32px"}}/>
            <div style={{width: "62px"}}/>
            <div className={'d-flex align-items-center px-2 py-0 my-0 ' + styles.buttonPlaceholder}
                 style={{width: "32px"}}>
            </div>
          </div>
        </div>
      </Row>
      <div className={"py-2"}>
        <hr className={"my-4"} style={{width: "1450px"}}/>
      </div>
      <div className={"mb-3 mt-1"}>
        <div className={styles.rowPlaceholder}/>
        <div className={"mt-3 " + styles.rowPlaceholder}/>
        <div className={"mt-3 " + styles.rowPlaceholder}/>
      </div>
    </CardBody>
  </Card>
}
