import React from "react";
import styles from "./FieldRules.module.scss";


type RemoveRuleIconProps = {
  onClick: () => void,
  className?: string
}

export default function RemoveRuleIcon({onClick, className = ''}: RemoveRuleIconProps) {
  return <i className={"fa-solid fa-circle-xmark float-right " + styles.removeIcon + ' ' + className}
            onClick={onClick}/>
}
