import React, {useEffect} from 'react';
import {graphql, QueryRenderer} from "react-relay";
import {Redirect} from "react-router-dom";
import MyLoaderOverlay from "../../components/LoadingOverlay";
import UserContext from "../../context/UserContext";
import Environment, {deleteAuthToken} from "../../Environment";
import {FeatureFlagsProvider, Flag} from "../../ui-kit/src/context/FeatureFlagsContext";
import Page500 from "../../ui-kit/src/pages/Page500";
import NoCompanySelected from "../../ui-kit/src/specialized/userForms/NoCompanySelected";
import {handleCompanyChange} from "../companyChange";
import {initializeLoggedInUser} from "../../common/intercomIntegration";
import {
  LoginRequiredContainerQuery,
  LoginRequiredContainerQueryResponse
} from "./__generated__/LoginRequiredContainerQuery.graphql";
import {RetailerUserContextType} from "../../ui-kit/src/context/CurrentUserContext";
import {ErrorAlert} from "../../ui-kit/src/commons/errors";

const query = graphql`
    query LoginRequiredContainerQuery {
        currentUser {
            id
            firstName
            lastName
            email
            avatar
            isSystemAdmin
            retailer {
                stores {
                    edges {
                        node {
                            id
                            name
                            address
                            zipCode
                            city
                        }
                    }
                }
                id
                name
                logo
                hasPermissions {
                    changeDetails
                    manageUsers
                }
            }
            retailers {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            activeFeatureFlags
        }
    }
`

const getCurrentUser = (currentUser: LoginRequiredContainerQueryResponse['currentUser']) => {
  return {
    id: currentUser?.id || "",
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    email: currentUser?.email || "",
    avatar: currentUser?.avatar || null,
    isSystemAdmin: currentUser?.isSystemAdmin || false,
    retailer: currentUser?.retailer ? {
      id: currentUser.retailer.id,
      name: currentUser.retailer.name,
      logo: currentUser.retailer.logo,
      hasPermissions: {
        changeDetails: currentUser.retailer.hasPermissions?.changeDetails || false,
        manageUsers: currentUser.retailer.hasPermissions?.manageUsers || false,
      },
      stores: {
        edges: currentUser.retailer.stores?.edges.map(edge => ({
          node: {
            id: edge?.node?.id || "",
            name: edge?.node?.name || "",
            address: edge?.node?.address || null,
            zipCode: edge?.node?.zipCode || null,
            city: edge?.node?.city || null
          }
        })) || []
      }
    } : null,
    retailers: currentUser?.retailers ? {
      edges: currentUser.retailers.edges.map(edge => ({
        node: {
          id: edge?.node?.id || "",
          name: edge?.node?.name || "",
        }
      }))
    } : null,
    activeFeatureFlags: [...(currentUser?.activeFeatureFlags || [])] as Flag[]
  }
}

function IntercomInit({children, user}: {children: JSX.Element, user: RetailerUserContextType}) {
  useEffect(() => {
    initializeLoggedInUser(
      user.firstName + ' ' + user.lastName,
      user.email,
      user.id
    )
  }, [user])
  return children;
}

function LoginRequiredContainer({children}: {children: JSX.Element | JSX.Element[]}) {

  return <QueryRenderer<LoginRequiredContainerQuery>
    environment={Environment}
    query={query}
    variables={{}}
    render={({error, props}) => {
      if (!error && !props) {
        return <MyLoaderOverlay active={true} children={undefined}/>
      } else if (error) {
        //@ts-ignore
        if (error[0] && error[0].message === "Error decoding signature") {
          deleteAuthToken()
          return <Redirect to={"/login?next=" + window.location.href}/>
        }
        return <Page500/>;
      } else if (props && props.currentUser) {
        const currentUser = getCurrentUser(props.currentUser);

        if (!currentUser.retailer) {
          return <UserContext.Provider value={currentUser}>
            <IntercomInit user={currentUser}>
              {currentUser.retailers ? <NoCompanySelected
                selectText={'retailer'}
                options={currentUser.retailers.edges.map(({node}) => ({...node}))}
                //@ts-ignore
                onSelect={(id) => {
                  handleCompanyChange({id: id})
                }}
              /> : <ErrorAlert error={"No retailers found"} />}
            </IntercomInit>
          </UserContext.Provider>
        }
        return <UserContext.Provider value={currentUser}>
          <IntercomInit user={currentUser}>
            <FeatureFlagsProvider flags={currentUser.activeFeatureFlags}>
              {children}
            </FeatureFlagsProvider>
          </IntercomInit>
        </UserContext.Provider>;
      } else if (props && !props.currentUser) {
        deleteAuthToken();
        return <Redirect to={"/login?next=" + window.location.href}/>
      }
      return <div>:)</div>
    }}
  />
}

export default LoginRequiredContainer;
