/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DetailedProductModalQueryVariables = {
    id: string;
};
export type DetailedProductModalQueryResponse = {
    readonly getProduct: {
        readonly id: string;
        readonly name: string;
        readonly data: unknown | null;
        readonly collection: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly unifiedImageGroups: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly images: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly url: string | null;
                            } | null;
                        } | null>;
                    };
                } | null;
            } | null>;
        } | null;
        readonly unifiedproductSet: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly data: unknown | null;
                    readonly imageGroups: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                            } | null;
                        } | null>;
                    };
                } | null;
            } | null>;
        };
        readonly attachedUniqueValues: ReadonlyArray<{
            readonly jsonSchemaValue: string | null;
            readonly value: string;
        } | null> | null;
        readonly productTemplate: {
            readonly productRenderConfig: unknown | null;
        } | null;
    } | null;
};
export type DetailedProductModalQuery = {
    readonly response: DetailedProductModalQueryResponse;
    readonly variables: DetailedProductModalQueryVariables;
};



/*
query DetailedProductModalQuery(
  $id: ID!
) {
  getProduct(id: $id) {
    id
    name
    data
    collection {
      id
      name
    }
    unifiedImageGroups {
      edges {
        node {
          id
          images {
            edges {
              node {
                url
                id
              }
            }
          }
        }
      }
    }
    unifiedproductSet {
      edges {
        node {
          id
          data
          imageGroups {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    attachedUniqueValues {
      jsonSchemaValue
      value
      id
    }
    productTemplate {
      productRenderConfig
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductCollectionNode",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UnifiedProductNodeConnection",
  "kind": "LinkedField",
  "name": "unifiedproductSet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnifiedProductNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UnifiedProductNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UnifiedProductImageGroupNodeConnection",
              "kind": "LinkedField",
              "name": "imageGroups",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnifiedProductImageGroupNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UnifiedProductImageGroupNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jsonSchemaValue",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productRenderConfig",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailedProductModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "kind": "LinkedField",
        "name": "getProduct",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UnifiedProductImageGroupNodeConnection",
            "kind": "LinkedField",
            "name": "unifiedImageGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnifiedProductImageGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnifiedProductImageGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnifiedProductImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UnifiedProductImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UnifiedProductImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UniqueValueNode",
            "kind": "LinkedField",
            "name": "attachedUniqueValues",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTemplateNode",
            "kind": "LinkedField",
            "name": "productTemplate",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DetailedProductModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductNode",
        "kind": "LinkedField",
        "name": "getProduct",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UnifiedProductImageGroupNodeConnection",
            "kind": "LinkedField",
            "name": "unifiedImageGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnifiedProductImageGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnifiedProductImageGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnifiedProductImageNodeConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UnifiedProductImageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UnifiedProductImageNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UniqueValueNode",
            "kind": "LinkedField",
            "name": "attachedUniqueValues",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductTemplateNode",
            "kind": "LinkedField",
            "name": "productTemplate",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57e9e7dc273aaa7fe56c845ce2760f46",
    "id": null,
    "metadata": {},
    "name": "DetailedProductModalQuery",
    "operationKind": "query",
    "text": "query DetailedProductModalQuery(\n  $id: ID!\n) {\n  getProduct(id: $id) {\n    id\n    name\n    data\n    collection {\n      id\n      name\n    }\n    unifiedImageGroups {\n      edges {\n        node {\n          id\n          images {\n            edges {\n              node {\n                url\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    unifiedproductSet {\n      edges {\n        node {\n          id\n          data\n          imageGroups {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    attachedUniqueValues {\n      jsonSchemaValue\n      value\n      id\n    }\n    productTemplate {\n      productRenderConfig\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2028352ea2b688d42dcbcd92cf197f8b';
export default node;
