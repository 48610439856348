import React from "react";
import {withUser} from "../../../../context/UserContext";
import {Col, FormGroup, Label, Row} from "reactstrap";
import styles from "../SettingsModal.module.scss"
import PrimaryActionButton from "../../../../ui-kit/src/general/PrimaryActionButton";


class UserInfoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>
      <Row>
        <p className={styles.subTitle + " mb-4 font-weight-bold"}>User Information</p>
      </Row>
      <Row>
        <FormGroup>
          <Row>
            <Col md={3}>
              <Label className={styles.userFormLabel}>First name</Label>
            </Col>
            <Col md={8} className={'pl-4'}>
              <input
                id={"first-name-input"}
                type="text" className={'form-control'}
                value={this.props.firstNameValue}
                onChange={e => {
                  this.props.onFirstNameUpdate(e.target.value)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <Row>
            <Col md={3}>
              <Label className={styles.userFormLabel}>Last name</Label>
            </Col>
            <Col md={8} className={'pl-4'}>
              <input
                type="text" className={'form-control'}
                value={this.props.lastNameValue}
                onChange={e => {
                  this.props.onLastNameUpdate(e.target.value)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <Row>
            <Col md={3}>
              <Label className={styles.userFormLabel}>Email</Label>
            </Col>
            <Col md={8} className={'pl-4'}>
              <input
                id={"email-input"}
                type="text" className={'form-control'}
                value={this.props.emailValue}
                onChange={e => {
                  this.props.onEmailUpdate(e.target.value)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      </Row>
      {this.props.retailerName && <Row>
        <FormGroup>
          <Row>
            <Col md={3}>
              <Label className={styles.userFormLabel}>Retailer</Label>
            </Col>
            <Col md={8} className={'pl-4'}>
              <input disabled={true} className={"form-control"}
                     value={this.props.retailerName}/>
            </Col>
          </Row>
        </FormGroup>
      </Row>}
      <Row className={"pt-3"}>
        {this.props.errors.length > 0 &&
        <p id={"error-text"} className={styles.errorText}>{"Error: " + this.props.errors[0].userErrors}</p>}
      </Row>
    </div>
  }
}


export default withUser(UserInfoForm);
