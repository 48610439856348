/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SimulateOutputForProductMutationInput = {
    config: unknown;
    product: string;
    ddt?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type SimulateOutputConfigForProductMutationVariables = {
    input: SimulateOutputForProductMutationInput;
};
export type SimulateOutputConfigForProductMutationResponse = {
    readonly simulateOutputConfigForProduct: {
        readonly result: unknown | null;
    } | null;
};
export type SimulateOutputConfigForProductMutation = {
    readonly response: SimulateOutputConfigForProductMutationResponse;
    readonly variables: SimulateOutputConfigForProductMutationVariables;
};



/*
mutation SimulateOutputConfigForProductMutation(
  $input: SimulateOutputForProductMutationInput!
) {
  simulateOutputConfigForProduct(input: $input) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SimulateOutputForProductMutationPayload",
    "kind": "LinkedField",
    "name": "simulateOutputConfigForProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SimulateOutputConfigForProductMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SimulateOutputConfigForProductMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cbc0778487c5eacf84faefb08c237317",
    "id": null,
    "metadata": {},
    "name": "SimulateOutputConfigForProductMutation",
    "operationKind": "mutation",
    "text": "mutation SimulateOutputConfigForProductMutation(\n  $input: SimulateOutputForProductMutationInput!\n) {\n  simulateOutputConfigForProduct(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '6f9889a6acde008a1f9b1c4a7ce9ff53';
export default node;
