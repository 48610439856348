import {OrderViaBarcodesQuantitiesAndPricesSubRowProps} from "../types";
import styles from "./OrderViaBarcodesQuantitiesAndPrices.module.scss";
import {SimpleTableCell} from "../../../../molecules/tables/SimpleTableRow";
import Input from "../../../../atoms/Input";
import {isNumeric} from "../../../../stringUtils";
import React from "react";
import {cellText, noDataIcon} from "./OrderViaBarcodesQuantitiesAndPrices";
import addClassNames from "../../../../classNameUtils";

export default function OrderViaBarcodesQuantitiesAndPricesSubRow({
                                                                    subvariant,
                                                                    onQuantityChange,
                                                                    onDiscountChange
                                                                  }: OrderViaBarcodesQuantitiesAndPricesSubRowProps) {

  return <tr className={styles.subvariantRowContainer}>
    <SimpleTableCell className={'d-flex align-items-center py-0'} width={34}>
      <div className={styles.subvariantNameContainer}>
        <p className={styles.subvariantNameAndGtinText}>{subvariant.name}</p>
      </div>
      <p className={`${styles.subvariantNameAndGtinText} ml-3`}>{subvariant.gtin}</p>
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      {subvariant?.prices?.rrp ? cellText(subvariant.prices.rrp.amount.toString()) : noDataIcon}
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      {subvariant?.prices?.wsp ? cellText(subvariant.prices.wsp.amount.toString()) : noDataIcon}
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      <Input value={subvariant.quantity || ""}
             placeholder={subvariant.quantity != null ? "0" : undefined}
             onChange={e => onQuantityChange(e.target.value)}
             className={addClassNames([
               {className: styles.input, condition: true},
               {className: styles.requiredInput, condition: !subvariant.quantity},
             ])}
             width={"4rem"}
             invalid={!!subvariant.quantity && !isNumeric(subvariant.quantity)}
             type={"number"}/>
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      <Input value={subvariant.discount || ""}
             placeholder={subvariant.discount != null ? "0" : undefined}
             onChange={e => onDiscountChange(e.target.value)}
             className={addClassNames([
               {className: styles.input, condition: true},
               {className: styles.requiredInput, condition: !subvariant.discount},
             ])}
             width={"4rem"}
             invalid={!!subvariant.discount && !isNumeric(subvariant.discount, true)}
             type={"number"}/>
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      {subvariant?.finalPrice != null ? cellText(subvariant.finalPrice.toString()): noDataIcon}
    </SimpleTableCell>
    <SimpleTableCell className={'d-flex align-items-center justify-content-center py-0'} width={11}>
      {subvariant?.totalPrice != null ? cellText(subvariant.totalPrice.toString()) : noDataIcon}
    </SimpleTableCell>
  </tr>
}
