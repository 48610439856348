import React from "react";
import {Alert} from "reactstrap";

export default function PathNotFound() {
  return <div className={"d-flex justify-content-center"}>
    <Alert
      color={'warning'}
      style={{width: "100%"}}>
      This path of access is exclusive to supported integrations only.
    </Alert>
  </div>
}
