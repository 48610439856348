import React from 'react'
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import styles from './DateDeltaRule.module.scss'
import {DefaultInput} from "../../../../../../../atoms/Input";
import {pluralizeByCount} from "../../../../../../../stringUtils";

type DateDeltaRuleProps = {
  onChange: (val: {
    date_output_format: string,
    days: number
  }) => void,
  onRemove: () => void,
  date_output_format: string,
  days: number
}

export default function DateDeltaRule({date_output_format, days, onChange, onRemove}: DateDeltaRuleProps) {
  const onDateOutputFormatChange = (newDateOutputFormat: string) => {
    onChange({date_output_format: newDateOutputFormat, days})
  }

  const onDaysChange = (newDays: string) => {
    if (!isNaN(Number(newDays))) {
      onChange({date_output_format, days: Number(newDays) || 0})
    }
  }

  return <div className={'d-flex align-items-center'}>
    <div className={styles.iconContainer}>
      <i className={`fa-regular fa-calendar-plus ${styles.icon}`}/>
    </div>
    <span className={styles.lightText}>Add</span>
    <DefaultInput value={days || ""}
                  onChange={e => onDaysChange(e.target.value)}
                  width={'3rem'}
                  className={'ml-2'}
                  invalid={!days}
    />
    <span className={`${styles.lightText} ml-2`}>
      {pluralizeByCount('day', days)} to the date and then format it as
    </span>
    <DefaultInput value={date_output_format || ""}
                  onChange={e => onDateOutputFormatChange(e.target.value)}
                  width={'6.5rem'}
                  className={'ml-2'}
                  invalid={!date_output_format}
    />
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
