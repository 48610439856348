import React from "react";
import {ProductFieldsProvider} from "../../../../utilities";
import {ProductFieldType} from "../../../../types";
import Dropdown from "../../../../../../../atoms/Dropdown";

type SectionPathDropdownProps = {
  currentValue: ProductFieldType | null,
  fieldDisplay: string,
  onChange: (value: { section_path: string }) => void,
  productFieldsProvider: ProductFieldsProvider,
}

export default function SectionPathDropdown({
                                              currentValue,
                                              fieldDisplay,
                                              onChange,
                                              productFieldsProvider
                                            }: SectionPathDropdownProps) {
  return <Dropdown
    className={'m-1'}
    options={productFieldsProvider.getFields().filter(x => {
      return x.name !== fieldDisplay
    }).map(({path, name}) => {
      return {value: path, label: name};
    })}
    placeholder={'Select a section to remove'}
    currentValue={currentValue ? {value: currentValue.path, label: 'Removed section: ' + currentValue.name} : null}
    onSelectOption={op => {
      if (op.value) {
        onChange({section_path: op.value});
      }
    }}
  />
}
