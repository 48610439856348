/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FashionProductList_orderConfirmationBatch = {
    readonly identifiedProducts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly extraData: unknown;
                readonly unifiedProduct: {
                    readonly id: string;
                };
                readonly product: {
                    readonly id: string;
                };
            } | null;
        } | null>;
    } | null;
    readonly " $refType": "FashionProductList_orderConfirmationBatch";
};
export type FashionProductList_orderConfirmationBatch$data = FashionProductList_orderConfirmationBatch;
export type FashionProductList_orderConfirmationBatch$key = {
    readonly " $data"?: FashionProductList_orderConfirmationBatch$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FashionProductList_orderConfirmationBatch">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FashionProductList_orderConfirmationBatch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderConfirmationIdentifiedProductNodeConnection",
      "kind": "LinkedField",
      "name": "identifiedProducts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderConfirmationIdentifiedProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderConfirmationIdentifiedProductNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "extraData",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnifiedProductNode",
                  "kind": "LinkedField",
                  "name": "unifiedProduct",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductNode",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderConfirmationBatchNode",
  "abstractKey": null
};
})();
(node as any).hash = '4c1d92250cb91a75a8ad8c5ee3cfdc19';
export default node;
