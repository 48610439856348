import React from "react";
import {Row, Col, Badge} from "reactstrap";
import Dropdown from "../../../ui-kit/src/dataInput/Dropdown";
import {DelayInput} from "react-delay-input";
import {graphql} from "graphql";
import {createFragmentContainer} from 'react-relay';
import SearchInput from "../../../ui-kit/src/dataInput/SearchInput";
import styles from "./ProductPageHeader.module.scss";
import {capitalize} from "../../../common/stringCommons";

export function filterFileDropdownByOrderConfirmation(orderConfirmationUpload, orderConfirmation, orderConfirmationOptions) {
  let currentOrderConfirmation = orderConfirmation.uploads.edges.filter(order => order.node.id === orderConfirmationUpload[0])
  let currentOrderConfirmationName = currentOrderConfirmation[0].node.name.split("_")[0]
  let currentOrderConfirmationFiles = orderConfirmation.uploads.edges.filter(order =>
    order.node.name.split("_")[0] === currentOrderConfirmationName)
  let currentOrderConfirmationFilesIds = currentOrderConfirmationFiles.map(order => order.node.id)
  return orderConfirmationOptions.filter(option => currentOrderConfirmationFilesIds.includes(option.value))
}

export function buildBadges(orderConfirmation, active, onBadgeClick) {
  let badges;
  if (orderConfirmation.uploads.edges.length === 1) {
    badges = <div className={styles.uploadsWrapper}>
      <OrderConfirmationUploadPill
        active={active === null}
        name={capitalize(orderConfirmation.uploads.edges[0].node.name.split('_')[0])}
        onClick={() => {
        }}
      />
    </div>
  } else {
    let settings = {}
    orderConfirmation.uploads.edges.map(edge => {
      let upload = edge.node;
      let name = upload.name.split('_')[0];
      if (!settings[name]) {
        settings[name] = []
      }
      settings[name].push(edge.node.id)
    })
    badges = <div className={styles.uploadsWrapper}>
      <OrderConfirmationUploadPill
        active={active === null}
        name={'All'}
        onClick={() => {
          onBadgeClick(null);
        }}
      />
      {Object.keys(settings).map(key => {
        return <OrderConfirmationUploadPill
          key={key}
          active={active ? settings[key].includes(active[0]) : false}
          name={capitalize(key)}
          onClick={() => {
            onBadgeClick(settings[key]);
          }}
        />
      })}
    </div>
  }
  return badges;
}

function OrderConfirmationUploadPill({name, count, active, onClick}) {
  return <Badge className={'upload ' + (active ? 'active' : '')} onClick={onClick}>
    {name} {count && "(" + count + ")"}
  </Badge>
}

export function OrderConfirmationFilters({orderConfirmationUpload, orderConfirmation, setOrderConfirmationUpload}) {
  let badges = null;
  let orderConfirmationOptions = null;

  badges = buildBadges(
    orderConfirmation,
    orderConfirmationUpload,
    setOrderConfirmationUpload
  )
  orderConfirmationOptions = orderConfirmation.uploads.edges.map(o => {
    return {
      label: o.node.file.split('/')[o.node.file.split('/').length - 1],
      value: o.node.id,
    }
  })

  if (orderConfirmationUpload) {
    orderConfirmationOptions = filterFileDropdownByOrderConfirmation(orderConfirmationUpload, orderConfirmation, orderConfirmationOptions)
  }

  const getLabelByValue = (orderConfirmationUpload) => {
    return orderConfirmationOptions.filter(option => option.value === orderConfirmationUpload[0])[0].label
  }

  return <div className={'d-flex'}>
    {badges}
    <Dropdown
      className={'pl-2'}
      toggleButtonProps={{className: 'overflow-hidden text-truncate '}}
      placeholder={'Select a file'}
      value={orderConfirmationUpload && orderConfirmationUpload.length === 1 && getLabelByValue(orderConfirmationUpload)}
      currentValueRenderer={v => 'File: ' + v}
      onChange={val => {
        setOrderConfirmationUpload([val.value])
      }}
      options={orderConfirmationOptions}
    />
  </div>
}

function ProductFilters({collections, collection, setCollection, search, setSearch, brands, brand, setBrand, orderConfirmation, orderConfirmationUpload, setOrderConfirmationUpload}) {
  let collectionOptions;
  if (!collections) {
    collectionOptions = []
  } else {
    collectionOptions = collections.edges.map(e => {
      return {
        label: e.node.name,
        value: e.node.id,
      }
    })

  }
  let brandOptions = brands.edges.map(e => {
    return {
      label: e.node.name,
      value: e.node.id,
    }
  })
  return <Row className={styles.withOrderConfirmation + " px-3"}>
    {orderConfirmation ?
      <OrderConfirmationFilters orderConfirmationUpload={orderConfirmationUpload}
                                orderConfirmation={orderConfirmation}
                                setOrderConfirmationUpload={setOrderConfirmationUpload}/> :

      <>
        <Col md={3}>
          <Dropdown
            toggleButtonProps={{className: 'w-100'}}
            clearable
            placeholder={'Select brand'}
            value={brand}
            onChange={val => setBrand(val)}
            currentValueRenderer={v => 'Brand: ' + v.label}
            options={brandOptions}
          />
        </Col>
        <Col md={3}>
          <Dropdown
            toggleButtonProps={{className: 'w-100', disabled: !brand}}
            clearable
            placeholder={'Select collection'}
            value={collection}
            onChange={val => setCollection(val)}
            currentValueRenderer={v => 'Collection: ' + v.label}
            options={collectionOptions}
          />
        </Col>
      </>}
    <div style={{width: orderConfirmation ? "250px" : "400px"}} className={'ml-4'}>
      <SearchInput
        Tag={DelayInput}
        autoFocus={true}
        minLength={2}
        delayTimeout={500}
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder={'Search'}
      />
    </div>
  </Row>
}

export default createFragmentContainer(
    ProductFilters,
    {
      brands: graphql`
        fragment ProductFilters_brands on BrandNodeConnection {
          edges {
            node {
              id
              name
            }
          }
        } 
      `,
      collections: graphql`
       fragment ProductFilters_collections on ProductCollectionNodeConnection {
            edges {
              node {
                id
                name
              } 
            } 
         }
      `,
      orderConfirmation: graphql`
          fragment ProductFilters_orderConfirmation on OrderConfirmationBatchNode {
              totalProducts
              totalUnifiedProducts
              uploads {
                  edges {
                      node {
                          id
                          name
                          file
                          errors {
                              reason
                              product
                              variant
                          }
                      }
                  }
              }
          }
      `
    }
);