import React from "react";
import {capitalize} from "../../../../../stringUtils";
import styles from './Token.module.scss'
import addClassNames from "../../../../../classNameUtils";
import TokenSeparator from "./TokenSeparator";
import {
  BrandRuleSetType,
  GeneralTokenRule,
  isProductPathToken,
  isSeparatorTokenType,
  ProductPathTokenType,
  TokenGlossaryType,
  TokenType
} from "../../types";
import Button from "../../../../../atoms/Button";

function atLeastOne(arr: BrandRuleSetType[] | GeneralTokenRule[] | TokenGlossaryType[]) {
  return arr && arr.length > 0;
}

type FieldTokenProps = {
  onRemove: () => void,
  onClick: () => void,
  isMatchedByIndex: boolean,

} & ProductPathTokenType

function FieldToken({
                      display,
                      uv_representation,
                      rules,
                      glossary,
                      isMatchedByIndex,
                      brandRules,
                      onRemove,
                      onClick
                    }: FieldTokenProps) {
  const hasRules = atLeastOne(rules) || atLeastOne(glossary) || atLeastOne(brandRules);

  let classes = addClassNames([
    {className: styles.field, condition: true},
    {className: "mb-3", condition: true},
    {className: styles.withRules, condition: hasRules},
    {className: styles.fromDisplayToken, condition: isMatchedByIndex}
  ])

  return <Button className={classes}
                 onClick={onClick}
                 onRemove={onRemove}>
    <i className="fas fa-bars mr-2"/>
    {display} {uv_representation ? `(${capitalize(uv_representation)})` : null}
  </Button>
}

type TokenProps = {
  info: TokenType,
  display: string | null,
  onRemove: () => void,
  onClick: () => void,
  type: string[] | null,
  isMatchedByIndex: boolean,
  onChange: (value: { string: string }) => void,
}

function Token({info, display, onRemove, isMatchedByIndex, type, onChange, onClick}: TokenProps) {
  if (isProductPathToken(info)) {
    return <FieldToken path={info.path}
                       display={display}
                       onRemove={onRemove}
                       onClick={onClick}
                       rules={info.rules}
                       glossary={info.glossary}
                       isMatchedByIndex={isMatchedByIndex}
                       brandRules={info.brandRules}
                       uv_representation={info.uv_representation}
                       type={type}
                       measurement={info.measurement}/>
  } else if (isSeparatorTokenType(info)) {
    return <TokenSeparator string={info.string} onChange={onChange} isOnShortRow={false}/>;
  }
  return null;
}

export default Token;
