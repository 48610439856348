import React from 'react';
import {Col, Row} from "reactstrap";
import Dropdown, { DropdownMenu } from "../../../../../../atoms/Dropdown";
import Input from "../../../../../../atoms/Input";
import RemoveRuleIcon from "../RemoveRuleIcon";
import styles from "./ReplacementText.module.scss";
import addClassNames from "../../../../../../classNameUtils";

export const optionsValues = {
  ALL_OCCURRENCES: 'all',
  FULL_WORD: 'word',
  STRING_BEGINNING: 'start',
  WORD_BEGINNING: 'word_begin',
  STRING_END: 'end',
  WORD_END: 'word_end',
  FIRST_OCCURRENCE: 'first',
  LAST_OCCURRENCE: 'last',
  IGNORE_RULES: 'IGNORE_RULES',
}

type ReplacementTextProps = {
  value: string,
  target: string,
  onChange: (val: {
    value?: string,
    target?: string,
    keep_text_case?: boolean,
    where?: string,
  }) => void,
  onRemove: () => void,
  keep_text_case: boolean,
  where: string,
  icon?: string,
}
const DropdownOptionLabel = ({text, where, optionValue}: {text: string, where: string, optionValue: string}) => (
  <span className={addClassNames([
    {className: styles.selectableOption, condition: true},
    {className: styles.selectedOption, condition: where === optionValue},
    {className: styles.unselectedOption, condition: where !== optionValue},
  ])}>{text}</span>
)

const getOccurrencesOptions = (where: string, keep_text_case: boolean) => [
  {
    label: <DropdownOptionLabel
      text={'On all occurrences'}
      where={where}
      optionValue={optionsValues.ALL_OCCURRENCES} />,
    value: optionsValues.ALL_OCCURRENCES
  }, {
    label: <DropdownOptionLabel
      text={'As a full word'}
      where={where}
      optionValue={optionsValues.FULL_WORD} />,
    value: optionsValues.FULL_WORD
  }, {
    label: <DropdownOptionLabel
      text={'From the beginning'}
      where={where}
      optionValue={optionsValues.STRING_BEGINNING} />,
    value: optionsValues.STRING_BEGINNING
  }, {
    label: <DropdownOptionLabel
      text={'From the beginning of a word'}
      where={where}
      optionValue={optionsValues.WORD_BEGINNING} />,
    value: optionsValues.WORD_BEGINNING
  }, {
    label: <DropdownOptionLabel
      text={'From the end'}
      where={where}
      optionValue={optionsValues.STRING_END} />,
    value: optionsValues.STRING_END
  }, {
    label: <DropdownOptionLabel
      text={'From the end of a word'}
      where={where}
      optionValue={optionsValues.WORD_END} />,
    value: optionsValues.WORD_END
  }, {
    label: <DropdownOptionLabel
      text={'From the first occurrence'}
      where={where}
      optionValue={optionsValues.FIRST_OCCURRENCE} />,
    value: optionsValues.FIRST_OCCURRENCE
  }, {
    label: <DropdownOptionLabel
      text={'From the last occurrence'}
      where={where}
      optionValue={optionsValues.LAST_OCCURRENCE} />,
    value: optionsValues.LAST_OCCURRENCE
  }, {divider: true},
  {
    label: 'Ignore text-case rules',
    value: optionsValues.IGNORE_RULES,
    icon: <i
      className={`far ${keep_text_case ? 'fa-square-check' : 'fa-square'}`}
      style={{fontSize: '14px'}}/>
  }
];


export function ReplacementText({
                                          value,
                                          target,
                                          onChange,
                                          onRemove,
                                          icon = 'fa-exchange-alt',
                                          where,
                                          keep_text_case,
                                        }: ReplacementTextProps) {


  return <Row className={'mx-0'}>
    <Col className={'d-flex align-items-center px-0'} style={{lineHeight: '2rem'}}>
    <div className={'d-flex justify-content-center align-items-center rounded-circle ' + styles.iconBackground}>
      <i className={`far ${icon} text-white`} style={{fontSize: '12px'}}/>
    </div>
    <span className="mx-2">Replace</span>
    <Input style={{width: '10rem', borderRadius: "8px"}}
           placeholder={''}
           value={target || ''}
           onChange={e => {
             onChange({target: e.target.value});
           }}
    />
    <span className="mx-2">with</span>
    <Input style={{width: '10rem', borderRadius: "8px"}}
           placeholder={''}
           value={value || ''}
           onChange={e => {
             onChange({value: e.target.value});
           }}
    />
    <Dropdown
      className={'mx-2'}
      currentValue={{label: <i className="fas fa-sliders m-0"/>}}
      options={[]}
      MenuTag={() => <DropdownMenu className={styles.selectableOption} options={getOccurrencesOptions(where, keep_text_case)} onSelectOption={(option) => {
          if(option.value === optionsValues.IGNORE_RULES) {
            onChange({keep_text_case: !keep_text_case})
          } else {
            onChange({where: option.value || ''})
          }
        }}
        isSearchable={false}
      />}
      caret={false}
    />
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}

type RemoveTextRuleProps = {
  target: string,
  onChange: (val: {
    target?: string,
    keep_text_case?: boolean,
    where?: string,
  }) => void,
  onRemove: () => void,
  keep_text_case: boolean,
  where: string,
  icon?: string,
}

export function RemoveTextRule({ target,
                                 onChange,
                                 onRemove,
                                 icon = 'fa-solid fa-text-slash',
                                 where,
                                 keep_text_case}: RemoveTextRuleProps) {

  return <Row className={'mx-0'}>
    <Col className={'d-flex align-items-center px-0'} style={{lineHeight: '2rem'}}>
      <div className={'d-flex justify-content-center align-items-center rounded-circle ' + styles.iconBackground}>
        <i className={`far ${icon} text-white`} style={{fontSize: '12px'}}/>
      </div>
      <span className="mx-2">Remove </span>
      <Input style={{width: '10rem', borderRadius: "8px"}}
             placeholder={''}
             value={target || ''}
             onChange={e => {
               onChange({target: e.target.value});
             }}
      />
      <Dropdown
        className={'mx-2'}
        currentValue={{label: <i className="fas fa-sliders m-0"/>}}
        options={[]}
        MenuTag={() => <DropdownMenu className={styles.selectableOption} options={getOccurrencesOptions(where, keep_text_case)} onSelectOption={(option) => {
          if(option.value === optionsValues.IGNORE_RULES) {
            onChange({keep_text_case: !keep_text_case})
          } else {
            onChange({where: option.value || ''})
          }
        }}
        isSearchable={false}
        />}
        caret={false}
      />
      <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
    </Col>
  </Row>
}
