import {commitMutation, graphql} from "react-relay";
import Environment from "../Environment";
import {PayloadError} from "relay-runtime";

type TokenAuthType = {
  tokenAuth: {
    token: string
  }
}

function LoginMutation(email: string, password: string, onSuccess: (val: TokenAuthType) => void, onError: (val: readonly PayloadError[] | Error) => void) {
  const vars = {
    email: email,
    password: password
  };

  commitMutation(
    Environment,
    {
      mutation: graphql`
        mutation LoginMutation($email: String!, $password: String!) {
          tokenAuth(email: $email, password: $password, scope: "retailers") {
            token
          }
        }
      `,
      variables: vars,
      onCompleted: (response, errors) => {
        if (errors) {
          onError(errors);
        }
        else if (response && !errors) {
          onSuccess(response as TokenAuthType);
        }
      },
      onError: (error) => {
        onError(error)
      }
    }
  )
}

export default LoginMutation;
