import {createMutationBoilerplate} from "../../commons/graphql";
import {graphql} from "graphql";


export default createMutationBoilerplate(
  graphql`
    mutation AcknowledgeOrderErrorsMutation($input: AcknowledgeOrderErrorsMutationInput!) {
      acknowledgeOrderErrors(input: $input) {
        order {
          id
          ... on OrderNode {
            acknowledged
          }
          ... on ManuallyUploadedOrderNode {
            acknowledged
          }
        }
      }
    }
  `
)
