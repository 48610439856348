import React, {ReactNode} from "react";

interface SimpleTableCellProps extends React.ComponentPropsWithoutRef<"td"> {
  children?: ReactNode,
  width?: number | undefined,
}

export function SimpleTableCell({width, children, ...props}: SimpleTableCellProps) {
  return <td {...props} width={width ? `${width}%` : undefined}>
    {children}
  </td>
}

interface SimpleTableRow extends React.ComponentPropsWithoutRef<"tr"> {
  children: ReactNode
}

export default function SimpleTableRow({children, ...props}: SimpleTableRow) {
  return <tr {...props}>
    {children}
  </tr>
}
