/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Operator = "contains" | "eq" | "neq" | "%future added value";
export type UpdateMultipleSheetsConfigurationsMutationInput = {
    configs: Array<DDTMappingConfig>;
    clientMutationId?: string | null | undefined;
};
export type DDTMappingConfig = {
    id: string;
    isActive: boolean;
    productSelectors: Array<ProductSelector>;
    config: unknown;
    configBeforeVariant?: unknown | null | undefined;
    skipFirstVariant?: boolean | null | undefined;
};
export type ProductSelector = {
    source: string;
    op?: Operator | null | undefined;
    values: Array<string>;
};
export type UpdateMultipleSheetsConfigurationMutationVariables = {
    input: UpdateMultipleSheetsConfigurationsMutationInput;
};
export type UpdateMultipleSheetsConfigurationMutationResponse = {
    readonly updateMultipleSheetsConfigurations: {
        readonly mappingConfigs: ReadonlyArray<{
            readonly id: string;
            readonly isActive: boolean;
            readonly config: unknown | null;
            readonly configBeforeVariant: unknown | null;
            readonly sheetName: string | null;
            readonly sheetProductSelectors: unknown | null;
            readonly skipFirstVariant: boolean;
        } | null> | null;
    } | null;
};
export type UpdateMultipleSheetsConfigurationMutation = {
    readonly response: UpdateMultipleSheetsConfigurationMutationResponse;
    readonly variables: UpdateMultipleSheetsConfigurationMutationVariables;
};



/*
mutation UpdateMultipleSheetsConfigurationMutation(
  $input: UpdateMultipleSheetsConfigurationsMutationInput!
) {
  updateMultipleSheetsConfigurations(input: $input) {
    mappingConfigs {
      id
      isActive
      config
      configBeforeVariant
      sheetName
      sheetProductSelectors
      skipFirstVariant
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMultipleSheetsConfigurationsMutationPayload",
    "kind": "LinkedField",
    "name": "updateMultipleSheetsConfigurations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributeDataMappingConfigNode",
        "kind": "LinkedField",
        "name": "mappingConfigs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "config",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "configBeforeVariant",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sheetName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sheetProductSelectors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipFirstVariant",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMultipleSheetsConfigurationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMultipleSheetsConfigurationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9b257584018aa2581499f604974f4f3d",
    "id": null,
    "metadata": {},
    "name": "UpdateMultipleSheetsConfigurationMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMultipleSheetsConfigurationMutation(\n  $input: UpdateMultipleSheetsConfigurationsMutationInput!\n) {\n  updateMultipleSheetsConfigurations(input: $input) {\n    mappingConfigs {\n      id\n      isActive\n      config\n      configBeforeVariant\n      sheetName\n      sheetProductSelectors\n      skipFirstVariant\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '55f8bb5f4e24f8d788dcb35de84b0c5c';
export default node;
