/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModalContentExampleProductsRefetchQueryVariables = {
    searchQuery?: string | null | undefined;
    count?: number | null | undefined;
};
export type ModalContentExampleProductsRefetchQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ModalContent_exampleProducts">;
};
export type ModalContentExampleProductsRefetchQuery = {
    readonly response: ModalContentExampleProductsRefetchQueryResponse;
    readonly variables: ModalContentExampleProductsRefetchQueryVariables;
};



/*
query ModalContentExampleProductsRefetchQuery(
  $searchQuery: String
  $count: Int
) {
  ...ModalContent_exampleProducts_4rYdvm
}

fragment ModalContent_exampleProducts_4rYdvm on Query {
  productsExampleCandidates(first: $count, search: $searchQuery) {
    edges {
      node {
        id
        name
        styleNumber
        brandName
        collectionName
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModalContentExampleProductsRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "searchQuery",
            "variableName": "searchQuery"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ModalContent_exampleProducts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModalContentExampleProductsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "searchQuery"
          }
        ],
        "concreteType": "ExampleProductCandidateConnection",
        "kind": "LinkedField",
        "name": "productsExampleCandidates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExampleProductCandidateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExampleProductCandidate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "styleNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collectionName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ecded0a106f0cd0c8a5745ebcfbe3ed",
    "id": null,
    "metadata": {},
    "name": "ModalContentExampleProductsRefetchQuery",
    "operationKind": "query",
    "text": "query ModalContentExampleProductsRefetchQuery(\n  $searchQuery: String\n  $count: Int\n) {\n  ...ModalContent_exampleProducts_4rYdvm\n}\n\nfragment ModalContent_exampleProducts_4rYdvm on Query {\n  productsExampleCandidates(first: $count, search: $searchQuery) {\n    edges {\n      node {\n        id\n        name\n        styleNumber\n        brandName\n        collectionName\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2e4ea8293d039a1f87b8efc41aedaccb';
export default node;
