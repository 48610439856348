import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from '../../assets/img/brand/logo-1-white.svg'
import sygnet from '../../assets/img/brand/logo-1-icon-white.svg'
import {Link, withRouter} from "react-router-dom";
import {deleteAuthToken} from "../../Environment";
import {withUser} from "../../context/UserContext";
import SettingsModal from "../../views/Users/Settings/SettingsModal"
import {handleCompanyChange} from "../companyChange";
import CompanyManagementDropdown from "../../views/Stores/CompanyManagementDropdown.tsx";
import Environment from "../../Environment";
import UserSettingsDropdown from "../../ui-kit/src/specialized/navigation/UserSettingsDropdown"

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  toggleModal = () => {
    this.setState({isOpen: !this.state.isOpen});
  }
  onSettingsClick = (e) => {
    e && e.preventDefault();
    this.toggleModal();
  }
  onLogOutClick = () => {
    deleteAuthToken();
    this.props.history.push("/login");
  }

  render() {

    // eslint-disable-next-line
    const {children, user, history, ...attributes} = this.props;
    return (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile/>
          <AppNavbarBrand
            tag={(props) => <Link {...props}/>}
            to={'/product/my-products'}
            full={{src: logo, width: 96, height: 35, alt: 'Vuuh Logo'}}
            minimized={{src: sygnet, width: 30, height: 35, alt: 'Vuuh Logo'}}
          />
          {user.retailer && <CompanyManagementDropdown
            handleCompanyChange={handleCompanyChange}
            retailers={user.retailers.edges.map(({node}) => {
              return {name: node.name, id: node.id}
            })}
            environment={Environment}
            currentCompanyName={user.retailer.name}
            stores={user.retailer.stores.edges.map(edge => {
              return {
                id: edge.node.id,
                name: edge.node.name,
                zipCode: edge.node.zipCode,
                address: edge.node.address,
                city: edge.node.city
              }
            })}
            retailerId={user.retailer.id}
          />}
          <UserSettingsDropdown
            handleLogOut={this.onLogOutClick}
            handleSettings={this.onSettingsClick}
            userEmail={user.email}
            userName={user.name}/>
          {this.state.isOpen ?
            <SettingsModal isAdmin={user.retailer.hasPermissions.changeDetails}
                           isOpen={this.state.isOpen}
                           toggle={this.toggleModal}/> : null}
        </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withUser(withRouter(DefaultHeader));
