import React, {useCallback} from "react";
import transformIcon from '../../../../../../../assets/svg/ddtRules/transform.svg';
import styles from '../../FieldRules.module.scss';
import Dropdown from "../../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";
import Input from "reactstrap/lib/Input";
import RemoveRuleIcon from "../../RemoveRuleIcon";


export function Transform({index, to_value, from_value, tokens, onChange, onRemove, onAddNewTransform, removeButton}) {
  let dropdownTokenIdentifier = tokens.map((token, i) => ({dropdownIndex: i, originalIndex: token.originalIndex}))
  const getOriginalIndex = value => dropdownTokenIdentifier.filter(item => value === item.originalIndex)[0].dropdownIndex
  let activeToken = index !== null && tokens[getOriginalIndex(index)];

  return <li className={'d-flex py-2 align-items-center'}>
        <span className="mx-2 font-weight-bold">
          #
        </span>
    <Dropdown
      placeholder={'Select a data point'}
      toggleButtonProps={{
        tag: SecondaryActionButton,
        size: 'sm',
        iconRight: <i className="fas fa-caret-down pl-1"/>,
        className: styles.dropdownToken
      }}
      value={activeToken && {label: activeToken.display}}
      options={tokens.map(token => ({value: token.originalIndex, label: token.display}))}
      onChange={({value}) => onChange({index: value})}
    />
    {activeToken &&
    <>
    <span className="mx-2 px-1">
      from
    </span>
      <Input className={'d-inline-block'}
             autoFocus={true}
             placeholder={"Content to change"}
             value={from_value || ''}
             onChange={e => onChange({from_value: e.target.value})}
             style={{borderRadius: "8px"}}/>

      <span className="mx-2 px-1">
      to
      </span>
      <Input className={'d-inline-block mr-1'}
             value={to_value || ''}
             placeholder={"Alternative appearance"}
             onChange={e => onChange({to_value: e.target.value})}
             style={{borderRadius: "8px"}}/>

      <SecondaryActionButton size={'sm'} className={'mr-2 ml-1 ' + (removeButton ? 'visible ' : 'invisible ') + styles.dropdownToken} onClick={onAddNewTransform}>
        <i className={"fas fa-plus"}/>
      </SecondaryActionButton>
      <RemoveRuleIcon onClick={onRemove} className={'ml-auto'}/>
    </>
    }
  </li>
}

function ActionAlterToken({onChange, onRemove, transforms = [], tokens}) {
  tokens = tokens.map((t, i) => ({...t, originalIndex: i})).filter(t => t.path)

  const onAddNewTransform = useCallback(() => {
    onChange({
      transforms: [
        ...transforms,
        {index: null, from_value: '', to_value: ''}
      ]
    })
  }, [onChange, transforms])

  return <div className={styles.actionRow}>
    <div className={'d-flex align-items-center'}>
      <div
        className={"d-flex justify-content-center align-items-center rounded-circle mt-1 mr-2 " + styles.iconBackground}>
        {/*Brightness is set so high to turn the black svg white since it's on a black background*/}
        <img src={transformIcon} className={'mb-0'} style={{filter: "brightness(1000%)"}} alt=""/></div>
      Alter the content appearance for :
    </div>
    <ul style={{listStyleType: 'disc'}}>
      {transforms.map((transform, index) => {
        return <Transform
          {...transform}
          key={index}
          tokens={tokens}
          onChange={(newValues) => {
            onChange({
              transforms: transforms.map((t, j) => {
                if (j === index) {
                  return {...t, ...newValues};
                } else {
                  return t;
                }
              })
            })
          }}
          onRemove={() => {
            if (transforms.length === 1) {
              // when the transform is remove, delete the rule altogether.
              onRemove();
              return;
            }
            onChange({
              transforms: transforms.filter((t, j) => j !== index)
            })
          }}
          removeButton={transforms.length === index + 1}
          onAddNewTransform={onAddNewTransform}
        />
      })}
    </ul>
  </div>;
}


export default ActionAlterToken;
