import {
  FieldRuleType,
  GeneralTokenRule,
  PlusTokenType,
  SeparatorTokenType,
  Suffix,
  TokenRuleException
} from "./DDTMappingConfigV2Form/types";

export function isBackendProductPathToken(token: BackendTokenType): token is BackendProductPathTokenType {
  return token.hasOwnProperty('path')
}

export type BackendTokenType = BackendProductPathTokenType | SeparatorTokenType | PlusTokenType

export type BackendRowConfigType = {
  required: boolean,
  suffix?: Suffix;
  rules: FieldRuleType[]
  tokens: BackendTokenType[]
}

export type BackendProductPathTokenType = {
  path: string,
  brand_rules: {
    brand: string | null,
    rules: GeneralTokenRule[]
    exceptions: TokenRuleException[],
  }[],
  glossary: {[key: string]: string},
  rules: GeneralTokenRule[],
  uv_representation?: string,
  measurement?: string | null,
  image_index?: number
}
