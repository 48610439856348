import {Environment, Network, RecordSource, Store} from "relay-runtime";
import {authTokenKey, IGNORED_ERRORS_CONFIG} from "./Consts";
import {getOnixUrl} from "./ui-kit/src/commons/http";
import {isErrorIgnored} from "./ui-kit/src/ignoreGraphqlError";

const store = new Store(new RecordSource());

export function getAuthToken() {
  return localStorage.getItem(authTokenKey)
}

function fetchQuery(operation,
                    variables,) {

  // get the auth token
  let authToken = getAuthToken();
  // make sure we don't pass the authToken null, will throw a 401 error.
  if (authToken === null) {
    authToken = ""
  }

  return fetch(getOnixUrl("/graphql/"), {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': "JWT " + authToken
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  }).then(response => {
    return response.json();
  }).then(respJson => {
    if (respJson.errors && respJson.errors.length > 0 && !isErrorIgnored(IGNORED_ERRORS_CONFIG, operation.name, respJson.errors)) {
      throw respJson.errors;
    }
    return respJson;
  });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

export default environment;


export function setAuthToken(token) {
  localStorage.setItem(authTokenKey, token);
}

export function deleteAuthToken() {
  localStorage.removeItem(authTokenKey);
}
