import React, {useEffect, useState} from "react";
import ProductImagePlaceholder from "./ProductImagePlaceholder";
import styles from "../specialized/products/detailed/ProductModalContent.module.scss";
import SecondaryActionButton from "../general/SecondaryActionButton";

export default function ImageCarousel({images}) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (images && current > images.length) {
      setCurrent(0)
    }
  }, [current, images])

  if (!images || images.length === 0) {
    return <ProductImagePlaceholder size={'lg'}
                                    style={{boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)'}}/>
  }

  let filteredImages = [...new Set(images.map(x => x.url))]
  return <div className={styles.imageCarouselContainer}>
    <SecondaryActionButton size={'sm'}
                           disabled={current === 0}
                           className={styles.imgNavBtn}
                           onClick={() => {
                             setCurrent(current - 1);
                           }}>
      <i className="fas fa-angle-left"/>
    </SecondaryActionButton>
    <img src={filteredImages[current]} className={'img-fluid'} alt=""/>
    <SecondaryActionButton size={'sm'}
                           className={styles.imgNavBtn}
                           disabled={current === filteredImages.length - 1}
                           onClick={() => {
                             setCurrent(current + 1);
                           }}>
      <i className="fas fa-angle-right"/>
    </SecondaryActionButton>
  </div>
}
