import React, {useReducer} from "react";
import {Table} from "reactstrap";

function evaluateSelector(entry, selector) {
  if (typeof selector === 'string') {
    return entry[selector];
  }
  if (typeof selector === 'function') {
    return selector(entry)
  }
}


function EmptyTableRow(props) {
  return <tr>
    <td colSpan={100} className={'text-muted text-center p-3'}>No entries available</td>
  </tr>
}

function SimpleTable({columns, data, renderExpandedContent = false, rowKey, ...props}) {
  const [expandState, dispatchExpandState] = useReducer((state, action) => {
    let newState = Object.assign({}, state);
    if (action.type === 'expand') {
      newState[action.id] = true;
    } else if (action.type === 'hide') {
      newState[action.id] = false;
    }
    return newState;
  }, {})
  const createToggleExpandCallback = (i) => {
    return () => {
      if (expandState[i]) {
        dispatchExpandState({type: 'hide', id: i});
      } else {
        dispatchExpandState({type: 'expand', id: i});
      }
    }
  }

  return <Table {...props}>
    <thead>
    <tr>
      {columns.map(({name, label, render}) => {
        return <th key={name}>
          {label}
        </th>
      })}
    </tr>
    </thead>
    <tbody>
    {data.map((entry, index) => {
      let rowKeyValue = evaluateSelector(entry, rowKey);
      let isExpanded = expandState[rowKeyValue] || false;
      let expandedContent = null;
      if (isExpanded) {
        expandedContent = <tr>
          <td colSpan={100}>{renderExpandedContent(entry)}</td>
        </tr>;
      }
      return <React.Fragment>
        <tr key={rowKeyValue}>
          {columns.map(({name, render}) => {
            let content;
            if (render) {
              content = render({
                entry,
                index,
                isExpanded,
                toggleExpand: createToggleExpandCallback(rowKeyValue)
              });
            } else {
              content = entry[name];
            }
            return <td key={name}>
              {content}
            </td>
          })}
        </tr>
        {expandedContent}
      </React.Fragment>
    })}
    {data.length === 0 && <EmptyTableRow/>}
    </tbody>
  </Table>
}

export default SimpleTable;
