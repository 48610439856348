import React from "react";
import ReplaceTextOptions from "../commons/ReplaceTextOptions";
import Input from "../../../../../../../atoms/Input";
import RuleExtrasDropdown from "../commons/RuleExtrasDropdown";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import {RemoveCharactersRuleType, TokenRuleException} from "../../../../types";

type RemoveCharactersRuleProps = {
  onChange: (val: Omit<RemoveCharactersRuleType, "type">) => void,
  exceptions?: TokenRuleException[],
  onRemove: () => void,
} & Omit<RemoveCharactersRuleType, "type">;

export default function RemoveCharactersRule({pattern, where, onChange, exceptions, onRemove}: RemoveCharactersRuleProps) {

  return <div className={'d-flex mt-4 ml-4'}>
    <Input value={pattern}
           width={"10.5rem"}
           onChange={e => onChange({pattern: e.target.value, where})}
    />
    <ReplaceTextOptions onChange={(op) => onChange({pattern, where: op.where})} where={where}/>
    <RuleExtrasDropdown<Omit<RemoveCharactersRuleType, "type">>
      onChange={onChange}
      ruleData={{
        pattern,
        where,
        exceptions
      }}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
