/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SingleOrderPageManuallyUploadedOrdersQueryVariables = {
    id: string;
};
export type SingleOrderPageManuallyUploadedOrdersQueryResponse = {
    readonly getManuallyUploadedOrder: {
        readonly id: string;
        readonly orderNumbers: ReadonlyArray<string>;
        readonly uploaded: unknown;
        readonly outputProcesses: {
            readonly totalCount: number | null;
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly orders: {
                        readonly totalCount: number | null;
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly lastDownload: {
                                    readonly endDate: unknown;
                                } | null;
                            } | null;
                        } | null>;
                    };
                } | null;
            } | null>;
        };
        readonly price: {
            readonly currency: string;
            readonly amount: number;
        };
        readonly quantity: number;
        readonly acknowledged: boolean;
        readonly products: {
            readonly count: number | null;
        } | null;
        readonly batch: {
            readonly id: string;
            readonly totalItemQuantity: number | null;
            readonly errors: ReadonlyArray<{
                readonly reason: string | null;
                readonly product: unknown | null;
                readonly variant: unknown | null;
            } | null> | null;
            readonly error: string | null;
            readonly uploads: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly name: string | null;
                        readonly fileUrl: string | null;
                        readonly totalQuantity: number;
                    } | null;
                } | null>;
            };
            readonly " $fragmentRefs": FragmentRefs<"BatchTotalInfo_batch" | "ErrorsAcknowledgementsModal_batch">;
        } | null;
        readonly missingData: {
            readonly percent: number | null;
        } | null;
        readonly fromBrand: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly toRetailer: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ErrorsAcknowledgementsModal_manualOrder">;
    } | null;
};
export type SingleOrderPageManuallyUploadedOrdersQuery = {
    readonly response: SingleOrderPageManuallyUploadedOrdersQueryResponse;
    readonly variables: SingleOrderPageManuallyUploadedOrdersQueryVariables;
};



/*
query SingleOrderPageManuallyUploadedOrdersQuery(
  $id: ID!
) {
  getManuallyUploadedOrder(id: $id) {
    id
    orderNumbers
    uploaded
    outputProcesses {
      totalCount
      edges {
        node {
          id
          orders {
            totalCount
            edges {
              node {
                lastDownload {
                  endDate
                }
                id
              }
            }
          }
        }
      }
    }
    price {
      currency
      amount
    }
    quantity
    acknowledged
    products {
      count
    }
    ...ErrorsAcknowledgementsModal_manualOrder
    batch {
      id
      totalItemQuantity
      errors {
        reason
        product
        variant
      }
      error
      ...BatchTotalInfo_batch
      ...ErrorsAcknowledgementsModal_batch
      uploads {
        edges {
          node {
            id
            name
            fileUrl
            totalQuantity
          }
        }
      }
    }
    missingData {
      percent
    }
    fromBrand {
      id
      name
    }
    toRetailer {
      id
      name
    }
  }
}

fragment BatchTotalInfo_batch on OrderConfirmationBatchNode {
  id
  totalUnifiedProducts
  totalItemQuantity
  totalPrice {
    amount
    currency
  }
}

fragment ErrorsAcknowledgementsModal_batch on OrderConfirmationBatchNode {
  brand {
    id
    name
  }
  error
  errors {
    product
    reason
    variant
  }
}

fragment ErrorsAcknowledgementsModal_manualOrder on ManuallyUploadedOrderNode {
  id
  fromBrand {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumbers",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploaded",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LastDownloadType",
  "kind": "LinkedField",
  "name": "lastDownload",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "acknowledged",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "DisplayProductNodeConnection",
  "kind": "LinkedField",
  "name": "products",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalItemQuantity",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderConfirmationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "product",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variant",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderConfirmationUploadNodeConnection",
  "kind": "LinkedField",
  "name": "uploads",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderConfirmationUploadNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderConfirmationUploadNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fileUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalQuantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "MissingDataType",
  "kind": "LinkedField",
  "name": "missingData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percent",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = [
  (v2/*: any*/),
  (v16/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandNode",
  "kind": "LinkedField",
  "name": "fromBrand",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "RetailerNode",
  "kind": "LinkedField",
  "name": "toRetailer",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleOrderPageManuallyUploadedOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManuallyUploadedOrderNode",
        "kind": "LinkedField",
        "name": "getManuallyUploadedOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataOutputProcessNodeConnection",
            "kind": "LinkedField",
            "name": "outputProcesses",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataOutputProcessNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DistributeDataOutputProcessNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderNodeConnection",
                        "kind": "LinkedField",
                        "name": "orders",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderConfirmationBatchNode",
            "kind": "LinkedField",
            "name": "batch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v17/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BatchTotalInfo_batch"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ErrorsAcknowledgementsModal_batch"
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ErrorsAcknowledgementsModal_manualOrder"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleOrderPageManuallyUploadedOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManuallyUploadedOrderNode",
        "kind": "LinkedField",
        "name": "getManuallyUploadedOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DistributeDataOutputProcessNodeConnection",
            "kind": "LinkedField",
            "name": "outputProcesses",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DistributeDataOutputProcessNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DistributeDataOutputProcessNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderNodeConnection",
                        "kind": "LinkedField",
                        "name": "orders",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrderNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderConfirmationBatchNode",
            "kind": "LinkedField",
            "name": "batch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalUnifiedProducts",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Price",
                "kind": "LinkedField",
                "name": "totalPrice",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandNode",
                "kind": "LinkedField",
                "name": "brand",
                "plural": false,
                "selections": (v19/*: any*/),
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "801e52e0a7e5f212f35d28d1787609b6",
    "id": null,
    "metadata": {},
    "name": "SingleOrderPageManuallyUploadedOrdersQuery",
    "operationKind": "query",
    "text": "query SingleOrderPageManuallyUploadedOrdersQuery(\n  $id: ID!\n) {\n  getManuallyUploadedOrder(id: $id) {\n    id\n    orderNumbers\n    uploaded\n    outputProcesses {\n      totalCount\n      edges {\n        node {\n          id\n          orders {\n            totalCount\n            edges {\n              node {\n                lastDownload {\n                  endDate\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    price {\n      currency\n      amount\n    }\n    quantity\n    acknowledged\n    products {\n      count\n    }\n    ...ErrorsAcknowledgementsModal_manualOrder\n    batch {\n      id\n      totalItemQuantity\n      errors {\n        reason\n        product\n        variant\n      }\n      error\n      ...BatchTotalInfo_batch\n      ...ErrorsAcknowledgementsModal_batch\n      uploads {\n        edges {\n          node {\n            id\n            name\n            fileUrl\n            totalQuantity\n          }\n        }\n      }\n    }\n    missingData {\n      percent\n    }\n    fromBrand {\n      id\n      name\n    }\n    toRetailer {\n      id\n      name\n    }\n  }\n}\n\nfragment BatchTotalInfo_batch on OrderConfirmationBatchNode {\n  id\n  totalUnifiedProducts\n  totalItemQuantity\n  totalPrice {\n    amount\n    currency\n  }\n}\n\nfragment ErrorsAcknowledgementsModal_batch on OrderConfirmationBatchNode {\n  brand {\n    id\n    name\n  }\n  error\n  errors {\n    product\n    reason\n    variant\n  }\n}\n\nfragment ErrorsAcknowledgementsModal_manualOrder on ManuallyUploadedOrderNode {\n  id\n  fromBrand {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '4fabd076d8fccaabb23bf0999f07befa';
export default node;
