/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderViaBarcodesAddInfoLocationDropdownQueryVariables = {
    id: string;
};
export type OrderViaBarcodesAddInfoLocationDropdownQueryResponse = {
    readonly getRetailerLocations: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
            } | null;
        } | null>;
    } | null;
};
export type OrderViaBarcodesAddInfoLocationDropdownQuery = {
    readonly response: OrderViaBarcodesAddInfoLocationDropdownQueryResponse;
    readonly variables: OrderViaBarcodesAddInfoLocationDropdownQueryVariables;
};



/*
query OrderViaBarcodesAddInfoLocationDropdownQuery(
  $id: ID!
) {
  getRetailerLocations(id: $id) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "RetailerStoreNodeConnection",
    "kind": "LinkedField",
    "name": "getRetailerLocations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetailerStoreNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetailerStoreNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderViaBarcodesAddInfoLocationDropdownQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderViaBarcodesAddInfoLocationDropdownQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7b80206f52db5df68ff12381eb65978",
    "id": null,
    "metadata": {},
    "name": "OrderViaBarcodesAddInfoLocationDropdownQuery",
    "operationKind": "query",
    "text": "query OrderViaBarcodesAddInfoLocationDropdownQuery(\n  $id: ID!\n) {\n  getRetailerLocations(id: $id) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1b8a3adca4f92664a9b201b59904386d';
export default node;
