import React, {useState} from "react";
import bluredNavbar from "../../../assets/svg/navigation.svg";
import TriggerPasswordResetModal
  from "../../../ui-kit/src/specialized/userForms/forgotPassword/TriggerPasswordResetModal";
import {withRouter} from "react-router-dom";
import environment from "../../../Environment";
import ResetPasswordConfirmationModalContent
  from "../../../ui-kit/src/specialized/userForms/forgotPassword/ResetPasswordConfirmationModalContent";
import GrayModal from "../../../ui-kit/src/dataDisplay/GrayModal";
import {noop} from "../../../common/utilities";

const MODALS = {
  PASSWORD_RESET: "password_reset",
  CHECK_EMAIL: "check_email"
}

export function ForgotPassword({}) {
  let [currentModal, setCurrentModal] = useState(MODALS.PASSWORD_RESET)

  return <div className="app flex-row align-items-center">
    <img src={bluredNavbar} className="blured-navbar" alt={"Blurred nav-bar"}/>
    {currentModal === MODALS.PASSWORD_RESET ?
      <TriggerPasswordResetModal environment={environment}
                                 scope={"retailers"}
                                 onSend={() => setCurrentModal(MODALS.CHECK_EMAIL)}/> :
      <GrayModal
        isOpen={true}
        toggle={noop}
        bodyContent={
          <ResetPasswordConfirmationModalContent/>}
        style={{minWidth: "34rem"}}/>
    }
  </div>
}

export default withRouter(ForgotPassword)