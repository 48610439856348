import React, {useContext} from 'react';
import CustomCheckbox from "../../../dataInput/CustomCheckbox";
import styles from './ProductTableRow.module.scss';
import PrimaryActionButton from "../../../general/PrimaryActionButton";
import {Col, Row, UncontrolledTooltip} from 'reactstrap';
import ProductImagePlaceholder from "../../../dataDisplay/ProductImagePlaceholder";
import Dropdown from "../../../dataInput/Dropdown"
import {UniqueValueTranslatorContext} from "../../../context/UniqueValueTranslatorContext";
import NoProductNameSpecified from "../NoProductNameSpecified";

export function ProductImage({src}) {
  return <div className={`product-thumbnail ${styles.productImage}`}
              style={{backgroundImage: 'url(' + src + ')'}}/>;
}

function noop() {
}


function getSelectedCountText(variants, selectedVariants) {
  return selectedVariants.length === variants.length
    ? 'All ' + variants.length + ' colors have been selected'
    : selectedVariants.length + ' out of ' + variants.length + ' colors have been selected';
}

function getSelectedVariantsText(variants, selectedVariants) {
  return variants.filter(x => selectedVariants.includes(x.id)).map(x => x.name).join(', ');
}

export function Spacer() {
  return <tr className={styles.spacer}/>;
}

export function VariantDisplay({
                                 isSelected, onProductSubvariantCheckChange, onSelect, variantData,
                                 config: {checked, indeterminate, tooltip, subVariantsConfigs}
                               }) {
  const translateUniqueValue = useContext(UniqueValueTranslatorContext)
  let labelOptions
  let hasId = true

  if (Array.isArray(variantData.subVariants)) {
    labelOptions = [{
      label: <CustomCheckbox size={'sm'} label={"All"} checked={checked || isSelected}
                             indeterminate={indeterminate}
                             onChange={onSelect}/>, value: null
    }];
    variantData.subVariants.forEach((size) => {
      //FALLBACK:in case the sizes have undefined id's, disable the dropdown and select the whole size.
      if (size.unifiedProductId) {
        labelOptions.push({
          label: <CustomCheckbox
            size={'sm'} label={translateUniqueValue(size.subVariantName)}
            checked={subVariantsConfigs[size.subVariantID].isChecked || isSelected}
            onChange={e => {
              e.stopPropagation();
              onProductSubvariantCheckChange(variantData.id, size.subVariantID, subVariantsConfigs[size.subVariantID].isChecked)
            }}/>,
          value: size.subVariantName
        })
      } else {
        hasId = false;
      }
    })
  }
  return <Col md={3} className={'d-flex align-items-center'}>
    <CustomCheckbox size={'sm'} checked={checked || isSelected}
                    indeterminate={indeterminate}
                    onChange={onSelect}
    />
    <ProductImagePlaceholder/>

    <span id={'variant-tooltip-' + variantData.id} style={{cursor: 'pointer'}}>
    <span className={styles.expandedColorName}>{variantData.name}</span>
      {' | '}
      <span className={styles.expandedColorCode}>{variantData.code}</span>
      {Array.isArray(variantData.subVariants) && <Dropdown
        toggleButtonProps={{className: 'h-25 py-0 px-1', disabled: !hasId}}
        menuProps={{style: {minWidth: 'auto'}}}
        placeholder={'Sizes:'}
        value={{label: 'Sizes'}}
        caret={false}
        onChange={() => {
        }}
        options={labelOptions}
      />}
    </span>

    {!hasId && <UncontrolledTooltip target={'variant-tooltip-' + variantData.id} placement={'bottom'}>
      {"Too many sizes, unable to display."}
    </UncontrolledTooltip>}
  </Col>
}


export function SelectedVariantsDisplay({variants, selectedVariants, onEditVariantSelection}) {
  return <tr className={styles.variantSelection}>
    <td colSpan={100} className={'p-0'}>
      <div className={"d-inline-block m-2 ml-3 py-1"}>
        <span className={styles.selectedCount}>{getSelectedCountText(variants, selectedVariants)}</span>{' '}
        <span className={styles.selectedList}>{getSelectedVariantsText(variants, selectedVariants)}</span>
      </div>
      <>
        <PrimaryActionButton className={'ml-auto m-3'} onClick={onEditVariantSelection}>
          Edit color selection
        </PrimaryActionButton>
      </>
    </td>
  </tr>;
}

export function SelectedVariantsChooser({
                                          selectedVariants, variants,
                                          variantsConfig = {},  // dict of {id: {checked, indeterminate, tooltip}, ...}
                                          onEditVariantSelectionDone,
                                          onVariantCheckChange,
                                          onProductSubvariantCheckChange
                                        }) {
  let allAreChecked = selectedVariants.length === variants.length;
  let someAreChecked = selectedVariants.length > 0 && selectedVariants.length < variants.length;

  return <tr className={styles.variantSelectionExpanded}>
    <td colSpan={100}>
      <Row className={'mx-0'}>
        <CustomCheckbox size={'sm'}
                        indeterminate={someAreChecked}
                        checked={allAreChecked}
        /> <span className={styles.selectedCount}>{getSelectedCountText(variants, selectedVariants)}</span>

        <PrimaryActionButton className={'ml-auto'} onClick={onEditVariantSelectionDone}>
          Done
        </PrimaryActionButton>
      </Row>
      <Row>
        {variants.map(variantData => {
          return <VariantDisplay
            key={variantData.id}
            config={variantsConfig[variantData.id] || {}}
            isSelected={selectedVariants.includes(variantData.id)}
            variantData={variantData}
            onProductSubvariantCheckChange={onProductSubvariantCheckChange}
            onSelect={e => {
              e.stopPropagation();
              onVariantCheckChange(variantData)
            }}
          />
        })}
      </Row>
    </td>
  </tr>;
}


function ProductTableRow({
                           isSelected, images, productName, styleNumber, collectionName,
                           variants, selectedVariants, variantsConfigs, brandName,
                           variantSelectionExpanded = false,

                           onClick = noop,
                           onProductCheckChange = noop,
                           onVariantCheckChange = noop,
                           onEditVariantSelection = noop,
                           onEditVariantSelectionDone = noop,
                           onProductSubvariantCheckChange = noop,
                         }) {

  let mainRowExtraClass = '';

  if (!selectedVariants) {
    selectedVariants = Object.keys(variantsConfigs).filter(k => variantsConfigs[k].checked);
  }

  let selectedVariantComponent = null;
  if (selectedVariants.length > 0) {
    mainRowExtraClass = styles.withVariantSelection;
    selectedVariantComponent = <SelectedVariantsDisplay
      variants={variants}
      selectedVariants={selectedVariants}
      onEditVariantSelection={onEditVariantSelection}
    />
  }

  if (variantSelectionExpanded) {
    mainRowExtraClass = styles.withVariantSelectionExpanded
    selectedVariantComponent = <SelectedVariantsChooser
      selectedVariants={selectedVariants}
      variants={variants}
      variantsConfig={variantsConfigs}
      onEditVariantSelectionDone={onEditVariantSelectionDone}
      onVariantCheckChange={onVariantCheckChange}
      onProductSubvariantCheckChange={onProductSubvariantCheckChange}
    />
  }

  let someAreChecked = selectedVariants.length > 0 && selectedVariants.length < variants.length;
  let allAreChecked = selectedVariants.length === variants.length;

  return <React.Fragment>
    <Spacer/>
    <tr className={styles.row + ' ' + mainRowExtraClass} onClick={e => {
      onClick(e);
    }}>
      <td width={'4%'}>
        <CustomCheckbox indeterminate={someAreChecked} checked={isSelected || allAreChecked} onChange={e => {
          e.stopPropagation();
          onProductCheckChange(e);
        }}/>
      </td>
      <td width={'15%'}>
        {images.length === 0 ? <ProductImagePlaceholder/> : <ProductImage src={images[0].url}/>}
      </td>
      <td className={styles.productName + " px-0"} width={'27%'}>
        {productName || <NoProductNameSpecified/>}
      </td>
      <td className={"px-0"}>
        {collectionName}
      </td>
      {brandName && <td className={"px-0"}>
        {brandName}
      </td>}
      <td width={'21%'} className={"px-0"}>
        {styleNumber}
      </td>
    </tr>
    {selectedVariantComponent}
  </React.Fragment>
}


export default ProductTableRow;
