/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrdersWithoutOrderConfirmationModalContent_orders = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly orderNumber: string;
        } | null;
    } | null>;
    readonly " $refType": "OrdersWithoutOrderConfirmationModalContent_orders";
};
export type OrdersWithoutOrderConfirmationModalContent_orders$data = OrdersWithoutOrderConfirmationModalContent_orders;
export type OrdersWithoutOrderConfirmationModalContent_orders$key = {
    readonly " $data"?: OrdersWithoutOrderConfirmationModalContent_orders$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrdersWithoutOrderConfirmationModalContent_orders">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrdersWithoutOrderConfirmationModalContent_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderNodeConnection",
  "abstractKey": null
};
(node as any).hash = 'e3f9325d6b70ebaf2db2f37cdc57a4e1';
export default node;
