import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../commons/graphql";
import {Environment} from "relay-runtime";
import {
  EnableSendEmailWithOutputOnCompletionMutationResponse,
  EnableSendEmailWithOutputOnCompletionInput,
  EnableSendEmailWithOutputOnCompletionMutation,
} from "./__generated__/EnableSendEmailWithOutputOnCompletionMutation.graphql";

const mutation = graphql`
  mutation EnableSendEmailWithOutputOnCompletionMutation($input: EnableSendEmailWithOutputOnCompletionInput!) {
    enableSendEmailWithOutputOnCompletion(input: $input) {
      success
    }
  }
`;

type EnableSendEmailWithOutputOnCompletionType = (
  environment: Environment,
  data: EnableSendEmailWithOutputOnCompletionInput,
  onSuccess: (val: EnableSendEmailWithOutputOnCompletionMutationResponse) => void,
  onError: (val: Error) => void
) => void;

const EnableSendEmailWithOutputOnCompletion: EnableSendEmailWithOutputOnCompletionType = (environment, data, onSuccess, onError) => {
  createMutationBoilerplate<EnableSendEmailWithOutputOnCompletionMutation>(mutation)(environment, data, onSuccess, onError);
}

export default EnableSendEmailWithOutputOnCompletion;
