import {graphql} from "react-relay";
import {createMutationBoilerplate} from "../../commons/graphql";
import {SimulateOutputConfigForProductMutation} from "./__generated__/SimulateOutputConfigForProductMutation.graphql";


const mutation = graphql`
  mutation SimulateOutputConfigForProductMutation($input: SimulateOutputForProductMutationInput!) {
    simulateOutputConfigForProduct(input: $input) {
      result
    }
  }
`;

export default createMutationBoilerplate<SimulateOutputConfigForProductMutation>(mutation);
