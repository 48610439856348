import React, {useEffect} from 'react';
import Loadable from 'react-loadable'
import {useCurrentUser} from "./context/UserContext";
import environment from "./Environment";
import DefaultLayout from './containers/DefaultLayout';
import {Alert} from "reactstrap";
import {handleChunkErrors} from "./ui-kit/src/commons/errorHandling";
import AmazonCallbackView from "./ui-kit/src/pages/oauth/AmazonCallbackView";

function Loading(props) {

  useEffect(() => {
    handleChunkErrors({error: props.error})
  }, [props.error])

  if (props.error) {
    return <Alert color={'danger'}>{'' + props.error}</Alert>
  }
  return <div>Loading...</div>;
}

const Products = Loadable({
  loader: () => import('./views/Products/ProductsV2'),
  loading: Loading,
})

const Orders = Loadable({
  loader: () => import('./views/Orders/OrdersPage'),
  loading: Loading,
})

const OrdersNew = Loadable({
  loader: () => import('./views/OrdersNew/OrdersPage'),
  loading: Loading,
})

const OrderViewNew = Loadable({
  loader: () => import('./views/OrdersNew/OrderView/OrderViewPage'),
  loading: Loading,
})

const OrderView = Loadable({
  loader: () => import('./views/Orders/OrderView'),
  loading: Loading,
})

const Subscriptions = Loadable({
  loader: () => import('./views/Brands/SubscriptionListViewV2'),
  loading: Loading,
});

const Output = Loadable({
  loader: () => import('./views/Output/OutputPage'),
  loading: Loading,
})

const Inputs = Loadable({
  loader: () => import('./ui-kit/src/pages/inputs/InputsPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;
    return <Component {...props} environment={environment}/>
  }
})

const UserSettings = Loadable({
  loader: () => import('./views/Users/Settings/Settings'),
  loading: Loading,
});


const MarketingMaterials = Loadable({
  loader: () => import('./ui-kit/src/pages/MarketingMaterials/MarketingMaterialsPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;

    function Wrapper({WrappedComponent, ...props}) {
      const user = useCurrentUser();
      return <WrappedComponent currentRetailerId={user.retailer.id} {...props}/>
    }

    return <Wrapper WrappedComponent={Component} {...props} environment={environment}/>
  }
})

const MarketingFolder = Loadable({
  loader: () => import('./ui-kit/src/pages/MarketingMaterials/MarketingFolderPage'),
  loading: Loading,
  render: (loaded, props) => {
    let Component = loaded.default;

    function Wrapper({WrappedComponent, ...props}) {
      const user = useCurrentUser();
      return <WrappedComponent currentRetailerId={user.retailer.id} {...props}/>
    }

    return <Wrapper WrappedComponent={Component} {...props} environment={environment}/>
  }
})

const OrderSplittingTool = Loadable({
  loader: () => import('./ui-kit/src/pages/tools/order-splitting/OrderSplitting'),
  loading: Loading,
})

const AmazonCallback = Loadable({
  loader: () => import('./ui-kit/src/pages/oauth/AmazonCallbackView'),
  loading: Loading,
})

const BolComCallback = Loadable({
  loader: () => import('./ui-kit/src/pages/oauth/BolComCallbackView'),
  loading: Loading,
})


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {path: '/', exact: true, name: 'Home', component: DefaultLayout},
  {path: '/orders', exact: true, name: 'Orders', component: Orders},
  {path: '/__orders', exact: true, name: 'OrdersNew', component: OrdersNew},
  {path: '/__orders/:id/price-catalogue', exact: true, name: 'OrderNewNodePriceCatalogue', component: OrderViewNew},
  {
    path: '/__orders/:id/order-confirmation',
    exact: true,
    name: 'OrderNewNodeOrderConfirmation',
    component: OrderViewNew
  },
  {path: '/__orders/:id', exact: true, name: 'OrderNew', component: OrderViewNew},
  {path: '/order/:id', exact: true, name: 'Order', component: OrderView},
  {path: '/product/inventory', exact: true, name: 'Brands', component: Products},
  {path: '/brands', exact: true, name: 'Brands', component: Subscriptions},
  {path: '/output', exact: true, name: 'Output', component: Output},
  {path: '/inputs', exact: true, name: 'Input', component: Inputs},
  {path: '/users/settings', exact: true, name: 'Settings', component: UserSettings},

  {path: '/marketing', exact: true, name: 'Marketing', component: MarketingMaterials},
  {path: '/marketing/folders/:id', exact: true, name: 'Marketing Folder', component: MarketingFolder},
  {path: '/tools/order-splitting', exact: true, name: 'Order splitting', component: OrderSplittingTool},

  // oauth2 callback urls
  {path: '/oauth/callback/amazon/', exact: true, name: 'Amazon Oauth Callback', component: AmazonCallback},
  {path: '/oauth/callback/bol/', exact: true, name: 'Bol Oauth Callback', component: BolComCallback},
];

export default routes;
