import {graphql, QueryRenderer} from "react-relay";
import {ErrorAlert} from "../../../../../commons/errors";
import {DropdownMenu, DropdownMenuProps, SelectableOption} from "../../../../../atoms/Dropdown";
import React, {useEffect} from "react";
import {UniqueValueSetDropdownMenuTagQuery} from "./__generated__/UniqueValueSetDropdownMenuTagQuery.graphql";
import {useDDTMappingConfigFormContext} from "../../DDTMappingConfigFormContext";

const query = graphql`
  query UniqueValueSetDropdownMenuTagQuery($id: ID!) {
    getUniqueValueSet(id: $id) {
      values {
        edges {
          node {
            value
            jsonSchemaValue
          }
        }
      }
    }
  }
`;

type UniqueValueSetDropdownMenuProps = {
  presentValues?: string [],
  type: string
  selectedOptions?: SelectableOption[],
  uniqueValues: { jsonSchemaValue: string, value: string } []
} & DropdownMenuProps


function UniqueValueSetDropdownMenu({
                                      uniqueValues,
                                      presentValues,
                                      selectedOptions,
                                      type,
                                      ...rest
                                    }: UniqueValueSetDropdownMenuProps) {
  let {updateUniqueValues} = useDDTMappingConfigFormContext()

  useEffect(() => {
    updateUniqueValues(uniqueValues, type)
  }, [])

  let getIcon = (currentOptionValue: string) => {
    if (selectedOptions && selectedOptions.filter(o => o.value === currentOptionValue).length) {
      return <i className="fas fa-check"/>
    } else {
      return <i style={{display: 'inline-block', marginRight: '1.5rem'}}/>
    }
  }

  let options = uniqueValues.map(uniqueValue => {
    if (uniqueValue.jsonSchemaValue && uniqueValue.value) {
      return {
        value: uniqueValue.jsonSchemaValue,
        label: uniqueValue.value,
        icon: getIcon(uniqueValue.jsonSchemaValue)
      }
    }
  }).filter(option => option) as SelectableOption[]

  if (presentValues) {
    options = options.filter(option => {
      let isAlreadyPresent = false
      for (let value of presentValues) {
        if (option.value === value) {
          isAlreadyPresent = true
        }
      }
      return !isAlreadyPresent
    })
  }

  return <DropdownMenu {...rest} options={options}/>
}

type UniqueValueSetDropdownMenuTagProps = {
  type: string[],
  presentValues?: string[],
  selectedOptions?: SelectableOption[],
} & DropdownMenuProps

export default function UniqueValueSetDropdownMenuTag({
                                                        type,
                                                        selectedOptions,
                                                        presentValues,
                                                        ...rest
                                                      }: UniqueValueSetDropdownMenuTagProps) {
  let {environment, isSetPresent, getUniqueValuesDetailsBySetType} = useDDTMappingConfigFormContext()

  let t = type.filter(t => t.startsWith('$$'))[0];

  if (t && !isSetPresent(t)) {
    return <QueryRenderer<UniqueValueSetDropdownMenuTagQuery>
      environment={environment}
      query={query}
      variables={{id: t}}
      render={
        ({props, error}) => {
          if (error) {
            return <ErrorAlert error={error}/>
          }
          if (props && !props.getUniqueValueSet) {
            return <ErrorAlert error={"Unable to fetch data points"}/>
          }
          if (props && props.getUniqueValueSet && props.getUniqueValueSet.values) {
            return <UniqueValueSetDropdownMenu selectedOptions={selectedOptions}
                                               uniqueValues={props.getUniqueValueSet.values.edges.map(edge => {
                                                 if (edge?.node) {
                                                   return {...edge.node}
                                                 }
                                               }).filter(x => x) as { jsonSchemaValue: string, value: string } []
                                               }
                                               type={t}
                                               presentValues={presentValues}
                                               {...rest}/>
          }
          return <DropdownMenu options={[{loading: true}]}/>
        }
      }/>
  } else if (t && isSetPresent(t)) {
    return <UniqueValueSetDropdownMenu selectedOptions={selectedOptions}
                                       uniqueValues={getUniqueValuesDetailsBySetType(t)}
                                       type={t}
                                       presentValues={presentValues}
                                       {...rest}/>
  } else {
    return null
  }
}
