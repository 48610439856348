import React from 'react';
import {graphql, QueryRenderer, useLazyLoadQuery} from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import Button from "../../../../atoms/Button";
import {ErrorAlert} from "../../../../commons/errors";
import {downloadFile} from "../../../../commons/http";
import {FinishedContentQuery} from "./__generated__/FinishedContentQuery.graphql";

type UnmatchedErrorType = { reason: string | null, product: any, variant: any }

type UploadErrorsProps = {
  errors: ReadonlyArray<UnmatchedErrorType | null> | null
}


function UploadErrors({errors}: UploadErrorsProps) {
  if (!errors || errors.length === 0) {
    return null;
  }
  return <div className={'mt-3'}>
    <hr/>
    <p>Some warnings occurred during processing:</p>
    <ul>
      {errors.map((err, index) => {
        if (!err) {
          return null
        }
        let reason = err.reason || 'unknown';
        let message = `Unknown error (${reason})`
        if (reason === 'unknown') {
          message = 'Unknown error';
        }
        else if (reason === 'barcode_not_found') {
          message = 'Barcode not found: ' + err.variant.gtin;
        }
        else if (reason === 'no_unified_product') {
          let color = [err.variant.color, err.variant.color_code].filter(x => x).join(' - ')
          let size = [err.variant.size, err.variant.secondary_size].filter(x => x).join(' / ')
          message = `Variant not found: ${err.product.name} ${err.product.style_number} (${[color, size].filter(x => x).join(', ')})`
        }
        else if (reason === 'no_style_number') {
          message = `Style number not found: ${err.product.style_number}`
        }
        return <li key={`error-${index}`}>{message}</li>
      })}
    </ul>
  </div>
}

type FinishedContentProps = {
  downloadUrl: string | null,
  uploadId: string | null,
  environment: RelayModernEnvironment,
}


export default function FinishedContent({downloadUrl, uploadId, environment}: FinishedContentProps) {
  return <div>
    <p>The file is ready</p>
    {downloadUrl ? <Button onClick={() => downloadFile(downloadUrl)}>
      Download
    </Button> : <Button disabled>Download link not available</Button>}

    {uploadId && <QueryRenderer<FinishedContentQuery>
      environment={environment}
      variables={{uploadId}}
      query={graphql`
        query FinishedContentQuery($uploadId: ID!) {
          orderConfirmationUpload(id: $uploadId) {
             id
              errors {
                reason
                product
                variant
              }
            }
          }
      `}
      render={({error, props}) => {
        if (error) {
          return <ErrorAlert error={error}/>
        } else if (props && !props.orderConfirmationUpload) {
          return <ErrorAlert error={'Can not retrieve the error information'}/>
        } else if (props && props.orderConfirmationUpload) {
          return <UploadErrors errors={props.orderConfirmationUpload.errors}/>
        }
      }}
    />}
  </div>
}
