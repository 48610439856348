/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ErrorsAcknowledgementsModal_batch = {
    readonly brand: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly error: string | null;
    readonly errors: ReadonlyArray<{
        readonly product: unknown | null;
        readonly reason: string | null;
        readonly variant: unknown | null;
    } | null> | null;
    readonly " $refType": "ErrorsAcknowledgementsModal_batch";
};
export type ErrorsAcknowledgementsModal_batch$data = ErrorsAcknowledgementsModal_batch;
export type ErrorsAcknowledgementsModal_batch$key = {
    readonly " $data"?: ErrorsAcknowledgementsModal_batch$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ErrorsAcknowledgementsModal_batch">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ErrorsAcknowledgementsModal_batch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandNode",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderConfirmationError",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "product",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variant",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderConfirmationBatchNode",
  "abstractKey": null
};
(node as any).hash = 'd2c3e635ca7feaa40998d9f547f9b3ef';
export default node;
